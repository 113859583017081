<template>
    <div>
        <!-- Boton que conlleva a la configuracion de nuevos registros de la tabla Notificaciones Configuracion -->
        <v-tabs vertical>
            <v-tab @click="updateNotificacionesConfig()">
                <v-icon left>
                    mdi-bell-check
                </v-icon>
                Notificaciones
            </v-tab>            
            <!-- Se manda a llamar al componente que contiene el diseño de la ventana de NotificacionesConfig-->
            <v-tab-item>
                <v-card flat color="gray">
                    <v-card-text>
                        <NotificacionesConfig ref="notificaciones" />
                    </v-card-text>
                </v-card>
            </v-tab-item>            
        </v-tabs>
    </div>
</template>

<script>
import NotificacionesConfig from './NotificacionesConfig.vue';

export default {
    methods: {
        // Cuando se presiona el boton de configuracion de notificaciones, se inicializa la instancia de la configuracion de notificaciones
        updateNotificacionesConfig() {
            if (typeof this.$refs.notificaciones === 'undefined') return;
            this.$refs.notificaciones.initialize();
        },
        initialize(){
            
        }
    },
    components: {
        NotificacionesConfig//El complemento que ocuparemos se declara en components para poder ser utilizado
    }
}
</script>