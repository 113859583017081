<template>
  <div v-if="permisoVista('ordenes','r')">
    <v-dialog v-model="dialog" max-width="850" persistent>
      <v-card>
        <v-card-title>Nueva Receta</v-card-title>
        <v-card-text>
          <v-form ref="formCrearReceta">
            <v-row class="col-12">
              <v-col cols="6">
                <v-select v-model="idAlmacen" :items="dataAlmacenes" item-value="idAlmacen" item-text="nombre"
                  label="Almacén" v-bind="propsSelect" />
              </v-col>
              <v-col cols="6">
                <v-select v-model="idLineaComercial" :items="dataLineasComerciales" item-value="idComercialLinea"
                  item-text="nombre" label="Linea Comercial" v-bind="propsSelect" />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="claveOrdenTrabajo" label="Clave de la Orden de trabajo" v-bind="propsText" />
              </v-col>
              <v-col cols="6">
                <VueDatePicker v-model="fechaCompromiso" no-header format="DD-MM-YYYY" @onChange="cambioFecha">
                  <template #activator>
                    <v-text-field v-model="fechaCompromisoLabel" label="Fecha de Compromiso de Entrega" readonly
                      ref="activator" hide-details></v-text-field>
                  </template>
                </VueDatePicker>
              </v-col>
              <v-col cols="12">
                <v-autocomplete v-model="idProductoTerminado" label="Producto Terminado Esperado"
                  :items="dataProductosTerminados" :item-text="nombreArticuloTerminado" item-value="idArticulo"
                  v-bind="propsAutocomplete" @change="open_NuevoPT()" :loading="loadingCrearReceta" />
              </v-col>
              <v-col cols="12">
                <h6 class="font-weight-bold d-inline">Nombre del Trabajo: </h6>
                {{ dataCotizacion.nombreTrabajo }}
              </v-col>
              <v-col cols="6" v-if="dataCalculos.clienteMostrar">
                <h6 class="font-weight-bold d-inline">Nombre del Cliente: </h6>
                {{ dataCalculos.clienteMostrar.nombreCliente }}
              </v-col>
              <v-col cols="6">
                <h6 class="font-weight-bold d-inline">Piezas: </h6>
                {{ formatearCifra(dataCotizacion.cantidadPza) }}
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="revision" name="input-7-1" filled label="Revisión" auto-grow
                  :loading="loadingCrearReceta" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="blue darken-1" class="ml-auto" @click="close_dialog()"
            :loading="loadingCrearReceta">Cancelar</v-btn>
          <v-btn text color="error" @click="crearReceta()" :loading="loadingCrearReceta">Guardar</v-btn>
          <!--<v-btn text color="error" @click="postCrearAticulo()" :loading="loadingCrearReceta">Guardar</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogProductoTerminado" max-width="600" persistent>
      <v-card>
        <v-card-title>
          Agregar Producto Terminado
        </v-card-title>
        <v-card-subtitle>
          Se requiere crear un producto terminado para almacenar la receta de esta cotización.
        </v-card-subtitle>
        <v-card-text>
          <v-form ref="formProductoTerminado">
            <v-col cols="12">
              <v-text-field v-model="dataProductoTerminado.nombre" label="Nombre del Artículo" v-bind="propsText"
                readonly />
              <br>
              <v-text-field v-model="dataProductoTerminado.codigo" label="Código del Artículos" v-bind="propsText" />
              <br>
              <span class="subtitle-1">Salida Automatica</span>
              <v-checkbox v-model="dataProductoTerminado.salidaAutomatica" label="Desactivada" />
              <br>
              <v-autocomplete v-model="dataProductoTerminado.idUnidad" label="Unidad" :items="dataUnidades"
                item-value="idUnidad" item-text="nombre" v-bind="propsAutocomplete" readonly :clearable="false" />
              <br>
              <v-autocomplete v-model="dataProductoTerminado.IdArticuloCategoria" label="Categoría"
                :items="dataCategorias" item-value="idArticuloCategoria" item-text="nombre"
                v-bind="propsAutocomplete" />
              <br>
              <v-autocomplete v-model="dataProductoTerminado.idArticuloClase" label="Clase" :items="dataClases"
                item-value="idArticuloClase" item-text="clase" />
              <br>
              {{ idsArticuloFamilias }}
              <v-autocomplete v-model="idsArticuloFamilias" label="Familia de Productos" :items="dataFamilias"
                item-value="idArticuloFamilia" item-text="nombre" />
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="blue darken-1" @click="close_NuevoPT()" class="ml-auto" :loading="loadingNuevoPT">Cancelar</v-btn>
          <!--<v-btn text color="error" @click="close_dialog()" class="ml-auto">Cancelar receta</v-btn>-->
          <v-btn text color="error" @click="postCrearAticulo()" :loading="loadingNuevoPT">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { VueDatePicker } from "@mathieustan/vue-datepicker";

export default {
  components: {
    VueDatePicker
  },
  data() {
    return {
      nombreArticuloTerminado: (item) => {
        if (item.nombre == "Agregar Nuevo Producto Terminado") {
          return `${item.nombre}`
        }
        return `[${item.codigo}] - ${item.nombre}`
      },
      propsText: {
        outlined: false,
        dense: false,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },
      propsAutocomplete: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      propsSelect: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },

      dialog: false,

      dataCotizacion: [],
      dataCalculos: [],

      dataProductosTerminados: [],
      dataUnidades: [],
      dataCategorias: [],
      dataFamilias: [],
      dataClases: [],
      dataAlmacenes: [],
      dataLineasComerciales: [],

      dataProcesos: [],
      dataAreas: [],
      dataSubpasos: [],
      dataInsumos: [],
      dataBarnizAgua: [],
      dataBarnizUvPlasta: [],
      dataBarnizUvRegistro: [],
      //máquinas
      dataMaquinasImpresoras: [],
      dataMaquinasBarniz: [],
      dataMaquinasUvPlasta: [],
      dataMaquinasUvRegistro: [],
      dataMaquinasPlastificado: [],
      dataMaquinasSuaje: [],
      dataMaquinasSuajeManual: [],
      dataMaquinasCorte: [],
      dataMaquinasEmpalme: [],
      dataMaquinasPegado: [],
      dataMaquinasPegadoGolpe: [],
      dataMaquinasTroquelBobina: [],
      dataMaquinasTroquelManual: [],
      dataMaquinasTroquelAutomatica: [],

      idsArticuloFamilias: null,
      dataProductoTerminado: {
        nombre: null,
        codigo: null,
        salidaAutomatica: false,
        idUnidad: null,
        IdArticuloCategoria: null
      },
      dataReceta: {},
      idxPaso: 1,

      dataImpresiones: [],
      dataExtras: [],
      dataTransporte: [],

      idCotizacion: null,
      recetaCreada: false,
      idProductoTerminado: null,
      revision: "",
      idAlmacen: 1,
      idLineaComercial: null,
      claveOrdenTrabajo: null,
      fechaCompromiso: null,
      fechaCompromisoLabel: null,

      loadingNuevoPT: false,
      loadingCrearReceta: false,
      dialogProductoTerminado: false,
      recetaPorPedido: false, //Por pedido hace referencia a pasar los datos sin transformar la receta a una sola pieza
    }
  },
  methods: {
    initialize() {
      this.getProductosTerminados()
      this.getUnidades();
      this.getCategorias();
      this.getFamilias();
      this.getClases();
      this.getAlmacenes();
      this.getLineasComerciales();

      this.getProcesos();
      this.getAreas();
      this.getSubpasos();
      this.getInsumos();
      this.getMaquinas();
      this.getBarniz();
    },
    getProductosTerminados() {
      axios
        .get("Articulos/Tipo/3?precio=false")
        .then((response) => {
          this.dataProductosTerminados = response.data;
          this.dataProductosTerminados.unshift({ nombre: "Agregar Nuevo Producto Terminado" })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getUnidades() {
      axios
        .get("/Unidades")
        .then((response) => {
          this.dataUnidades = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCategorias() {
      axios
        .get("/ArticuloCategorias")
        .then((response) => {
          this.dataCategorias = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getFamilias() {
      axios
        .get("/ArticuloFamilias")
        .then((response) => {
          this.dataFamilias = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getClases() {
      axios
        .get("/Articulos/ListarArticuloClase")
        .then((response) => {
          this.dataClases = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getAlmacenes() {
      axios
        .get("/Almacenes")
        .then((response) => {
          this.dataAlmacenes = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getLineasComerciales() {
      axios
        .get("/ComercialLineas")
        .then((response) => {
          this.dataLineasComerciales = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    },

    getProcesos() {
      this.loadingTable = true;
      axios
        .get("/Areas")
        .then((response) => {
          this.dataAreas = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    getAreas() {
      this.loadingTable = true;
      axios
        .get("/MaquinaProcesos/Listar/false")
        .then((response) => {
          this.dataProcesos = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    getSubpasos() {
      axios
        .get("/CatSubpaso")
        .then((response) => {
          this.dataSubpasos = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
        })
    },
    getInsumos() {
      axios
        .get("/GKMaquinaInsumos/Listar")
        .then((response) => {
          this.dataInsumos = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getMaquinas() {
      axios
        .get("/GKMaquina/Listar")
        .then((response) => {
          this.dataHojeadora = response.data.filter(item => item.proceso === "Hojeado");
          this.dataHojeadora?.unshift({ "idMaquina": -1, "nombre": "No" });
          this.dataMaquinasImpresoras = response.data.filter(item => item.proceso === "Impresión");
          this.dataMaquinasBarniz = response.data.filter(item => item.proceso === "Barnizado");
          this.dataMaquinasUvPlasta = response.data.filter(item => item.proceso === "UV Plasta");
          this.dataMaquinasUvRegistro = response.data.filter(item => item.proceso === "UV Registro");
          this.dataMaquinasPlastificado = response.data.filter(item => item.proceso === "Plastificado");
          this.dataMaquinasSuaje = response.data.filter(item => item.proceso === "Suajado");
          this.dataMaquinasSuajeManual = response.data.filter(item => item.proceso === "Suajado")
          this.dataMaquinasCorte = response.data.filter(item => item.proceso === "Corte");
          this.dataMaquinasEmpalme = response.data.filter(item => item.proceso === "Empalme");
          this.dataMaquinasPegado = response.data.filter(item => item.proceso === "Pegado Lineal");
          this.dataMaquinasPegadoGolpe = response.data.filter(item => item.proceso === "Pegado de Golpe");
          this.dataMaquinasTroquelBobina = response.data.filter(item => item.proceso === "Troquelado Bobina");
          this.dataMaquinasTroquelManual = response.data.filter(item => item.proceso === "Troquelado Manual");
          this.dataMaquinasTroquelAutomatica = response.data.filter(item => item.proceso === "Troquelado Automatica");
        })
        .catch((error) => { console.log(error) })
    },
    getBarniz() {
      axios
        .get("/Barniz/Listar")
        .then((response) => {
          this.dataBarnizAgua = response.data.filter(item => {
            const grupo = item.grupoBarniz.toLowerCase();
            return ["base", "agua"].every(barniz => grupo.includes(barniz));
          });
          this.dataBarnizUvPlasta = response.data.filter(item => {
            const grupo = item.grupoBarniz.toLowerCase();
            return ["uv", "plasta"].every(barniz => grupo.includes(barniz));
          });
          this.dataBarnizUvRegistro = response.data.filter(item => {
            const grupo = item.grupoBarniz.toLowerCase();
            return ["uv", "registro"].every(barniz => grupo.includes(barniz));
          });
        })
        .catch((error) => {
          console.log(error)
        })
    },

    open_NuevoPT() {
      if (this.idProductoTerminado == "Agregar Nuevo Producto Terminado") {
        this.dialogProductoTerminado = true;
      }
    },
    close_NuevoPT() {
      this.idProductoTerminado = null;
      this.dialogProductoTerminado = false;
      this.$refs.formProductoTerminado.reset();
    },

    postCrearAticulo() {
      if (this.$refs.formProductoTerminado.validate()) {
        this.dataProductoTerminado.IdArticuloTipo = 3;
        this.dataProductoTerminado.IdsArticuloFamilias = [this.idsArticuloFamilias];
        this.dataProductoTerminado.idArticuloClase = null;
        this.dataProductoTerminado.FactorConversion = null;
        this.dataProductoTerminado.EjeX = null;
        this.dataProductoTerminado.EjeY = null;
        this.dataProductoTerminado.EjeZ = null;

        this.loadingNuevoPT = true;
        axios
          .post("/Articulos/CrearCompleto", this.dataProductoTerminado)
          .then((response) => {
            this.idProductoTerminado = response.data.idArticulo;
            this.dialogProductoTerminado = false;
          })
          .catch((error) => {
            console.log(error);
            this.$emit('alert_receta', error.response.status);
          })
          .finally(() => {
            this.loadingNuevoPT = false;
          })
      }
      this.idProductoTerminado = 760;
      this.dialogProductoTerminado = false;
    },
    postReceta() {
      axios
        .post("/Recetas/Crear", this.dataReceta)
        .then((response) => {
          this.postEstadoReceta();
        })
        .catch((error) => {
          console.log(error)
          this.$emit('error_datosReceta', error.response.status);
        })
    },
    postEstadoReceta() {
      let params = new URLSearchParams();
      if (this.idCotizacion !== undefined) {
        params.append('IdCotizacion', this.idCotizacion);
      }
      if (this.idProductoTerminado !== undefined) {
        params.append('idProductoTerminado', this.idProductoTerminado);
        params.append('Receta', true);
      }
      let url = `/Orden/Crear?${params.toString()}`;
      axios
        .put(url)
        .then((response) => {
          this.getRecetas();
        })
        .catch((error) => {
          console.log(error)
          this.$emit('error_datosReceta', 500);
          this.close_dialog();
          throw new Error();
        })
    },
    getRecetas() {
      axios
        .get(`Trabajoordenes/VariantesReceta/${this.idProductoTerminado}`)
        .then((response) => {
          const idRecetaNueva = response.data.recetas.reduce((max, item) => item.idReceta > max ? item.idReceta : max, response.data.recetas[0].idReceta);
          //console.log(idRecetaNueva)
          this.postIniciarProduccion(idRecetaNueva)
        })
        .catch((error) => {
          console.log(error);
          this.$emit('error_datosReceta', 500);
          this.close_dialog();
          throw new Error();
        })
    },
    postIniciarProduccion(idReceta) {
      axios
        .post("/TrabajoOrdenes/Crear", {
          IdComercialLinea: this.idLineaComercial,
          idTrabajoOrden: null,
          idVendedor: null,
          IdCliente: this.dataCotizacion.idCliente,
          IdAlmacen: this.idAlmacen,
          Maquila: false,
          FechaEntrega: this.fechaCompromiso.split("-").reverse().join("-"),
          Clave: this.claveOrdenTrabajo,
          NumPedido: null,
          MycPedido: null,
          Articulos: [
            {
              ClaveODT: null,
              IdArticulo: this.idProductoTerminado,
              Cantidad: this.dataCotizacion.cantidadPza,
              Mensaje: null,
              Recetas: [
                {
                  idArticulo: this.idProductoTerminado,
                  idReceta: idReceta
                }
              ],
              Cambios: [],
              Tiempo: 0,
              Excedentes: false,
              iniciarAuto: false
            }
          ],
          FechaInicioDeProduccion: null, //Colocar?
          Adicionar: false,
          iniciarAuto: true
        })
        .then((response) => {
          this.dialogProductoTerminado = false;
          this.$emit('success_receta')
          this.dialog = false;
          this.reiniciarReceta();
        })
        .catch((error) => {
          console.log(error);
          this.$emit('error_datosReceta', 500);
          this.close_dialog();
          throw new Error();
        })
    },
    /*
    {
    "FechaEntrega": "27-05-2024",
    "Clave": "P001 Prueba Geo",
    "NumPedido": null,
    "MycPedido": null,
    "Articulos": [
        {
            "ClaveODT": null,
            "IdArticulo": 83,
            "Cantidad": 50000,
            "Mensaje": null,
            "Recetas": [
                {
                    "idArticulo": 83,
                    "idReceta": 210
                }
            ],
            "Cambios": [],
            "Tiempo": 0,
            "Excedentes": false,
            "iniciarAuto": false
        }
    ],
    "FechaInicioDeProduccion": null,
    "Adicionar": false,
    "iniciarAuto": true
}
     */
    /*
    {
    "FechaEntrega": "2024-05-27",
    "Clave": "P001 Prueba Geo",
    "NumPedido": null,
    "MycPedido": null,
    "Articulos": [
        {
            "ClaveODT": null,
            "IdArticulo": 83,
            "Cantidad": 50000,
            "Mensaje": null,
            "Recetas": [
                {
                    "idArticulo": 83,
                    "idReceta": 225
                }
            ],
            "Cambios": [],
            "Tiempo": 0,
            "Excedentes": false,
            "iniciarAuto": false
        }
    ],
    "FechaInicioDeProduccion": null,
    "Adicionar": false,
    "iniciarAuto": true
}
     */

    show_GenerarReceta(item) {
      this.recetaCreada = item.receta;
      this.idCotizacion = item.idCotizacion;
      this.idProductoTerminado = item.idProductoTerminado ? item.idProductoTerminado : null;

      axios
        .get("/Cotizacion/Mostrar/" + item.idCotizacion)
        .then((response) => {
          this.dataCotizacion = response.data;

          this.dataProductoTerminado.nombre = response.data.nombreTrabajo;
          this.dataProductoTerminado.idUnidad = response.data.idUnidad;

          this.dataImpresiones = response.data.impresionTinta;
          this.dataExtras = response.data.extraCotizaciones;
          this.dataTransporte = response.data.transporteCotizaciones;

          this.dataCotizacion.impresionTintaCotizaciones = this.jsonImpresiones;
          this.dataCotizacion.extrasCotizaciones = this.jsonImpresiones;
          this.dataCotizacion.transporte = this.jsonTransporte;
          this.dataCotizacion.maquinaInsumos = this.jsonInsumosImpresion;
          this.dataCotizacion.barnizDelta = response.data.barnizFrenteDelta;
          this.dataCotizacion.uvPlastaDelta = response.data.uvPlastaFrenteDelta;
          this.dataCotizacion.uvRegistroDelta = response.data.uvRegistroFrenteDelta;
          this.dataCotizacion.plastificadoDelta = response.data.plastificadoFrenteDelta;
          this.dataCotizacion.maquinaProcesos = this.jsonMermas;

          axios
            .post("/Cotizacion/CalcularCostos", this.dataCotizacion)
            .then((response) => {
              this.dataCalculos = response.data
              this.dialog = true;
            })
            .catch((error) => {
              console.log(error)
              this.$emit('error_datosReceta', error.response.status)
            })
        })
        .catch((error) => {
          console.log(error)
          this.$emit('error_datosReceta', error.response.status)
        })
    },

    async crearReceta() {
      try {
        if (this.$refs.formCrearReceta.validate()) {
          this.loadingCrearReceta = true;
          this.dataReceta = await this.generarReceta();
          //console.log(this.dataReceta)
          this.postReceta();
        }
      } catch (error) {
        this.$emit('error_datosReceta', error);
        this.loadingCrearReceta = false;
      }
    },
    async generarReceta() {
      let receta = {};
      let pasos = [];

      let tintas = [];
      let papel = [];
      let maquina = [];

      this.dataCalculos.calculoCostos.materiales.tinta.forEach(function (tinta) {
        tintas.push({ idArticulo: tinta.idArticulo, cantidad: tinta.kilos })
      });
      papel.push({
        idArticulo: this.dataCalculos.idPapel,
        cantidad: this.dataCalculos.trabajoMostrar.numeroPliegos
      })
      maquina.push(
        this.dataCotizacion.idImpresionMaquina
      );

      pasos.push(this.agregarPaso("IMPRESION", this.idxPaso, "Impresión"));

      pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarInsumos(tintas));
      pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarInsumos(papel));
      pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina));
      pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(1));
      this.idxPaso++;

      if (this.dataCotizacion.barniz) {
        let barnices = [];
        let maquina = [];

        this.dataCalculos.calculoCostos.materiales.barniz.filter(item => {
          return this.dataBarnizAgua.some(material => material.idArticulo === item.idArticulo);
        }).forEach(function (barniz) {
          barnices.push({ idArticulo: barniz.idArticulo, cantidad: barniz.kilos })
        });
        maquina.push(
          this.dataCotizacion.idBarnizFrenteMaquina,
          this.dataCotizacion.idBarnizVueltaMaquina
        )

        pasos.push(this.agregarPaso("BARNIZADO", this.idxPaso, "Barnizado"));
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarInsumos(barnices));
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina));
        pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(2));
        this.idxPaso++;
      }
      if (this.dataCotizacion.uvPlasta) {
        let barnicesPlasta = [];
        let maquina = [];

        this.dataCalculos.calculoCostos.materiales.barniz.filter(item => {
          return this.dataBarnizUvPlasta.some(material => material.idArticulo === item.idArticulo);
        }).forEach(function (barniz) {
          barnicesPlasta.push({ idArticulo: barniz.idArticulo, cantidad: barniz.kilos })
        });
        maquina.push(
          this.dataCotizacion.idUVPlastaFrenteMaquina,
          this.dataCotizacion.idUVPlastaVueltaMaquina
        )

        pasos.push(this.agregarPaso("UV PLASTA", this.idxPaso, "UV Plasta"))
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarInsumos(barnicesPlasta))
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina))
        pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(3));
        this.idxPaso++;
      }
      if (this.dataCotizacion.uvRegistro) {
        let barnicesRegistro = [];
        let maquina = [];

        this.dataCalculos.calculoCostos.materiales.barniz.filter(item => {
          return this.dataBarnizUvRegistro.some(material => material.idArticulo === item.idArticulo);
        }).forEach(function (barniz) {
          barnicesRegistro.push({ idArticulo: barniz.idArticulo, cantidad: barniz.kilos })
        });
        maquina.push(
          this.dataCotizacion.idUVRegistroFrenteMaquina,
          this.dataCotizacion.idUVRegistroVueltaMaquina
        )

        pasos.push(this.agregarPaso("UV REGISTRO", this.idxPaso, "UV Registro"))
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarInsumos(barnicesRegistro))
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina))
        pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(4));
        this.idxPaso++;
      }
      if (this.dataCotizacion.plastificado) {
        let maquina = [];
        maquina.push(
          this.dataCotizacion.idPlastificadoFrenteMaquina,
          this.dataCotizacion.idPlastificadoVueltaMaquina
        )
        //Esperar solucion para insumo

        pasos.push(this.agregarPaso("PLASTIFICADO", this.idxPaso, "Plastificado"))
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina))
        pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(5));
        console.log("Ingredientes: ", pasos[this.idxPaso - 1].Ingredientes)
        this.idxPaso++;
      }
      if (this.dataCotizacion.suajado) {
        let maquina = [this.dataCotizacion.idSuajadoMaquina, this.dataCotizacion.idSuajadoMMaquina];

        pasos.push(this.agregarPaso("SUAJADO", this.idxPaso, "Suajado"));
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina));
        pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(6));
        this.idxPaso++;
      }
      if (this.dataCotizacion.corte) {
        let maquina = [this.dataCotizacion.idCorteMaquina];

        pasos.push(this.agregarPaso("CORTE", this.idxPaso, "Corte"));
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina));
        pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(7));
        this.idxPaso++;
      }
      if (this.dataCotizacion.empalme) {
        let maquina = [this.dataCotizacion.idEmpalmeMaquina];

        pasos.push(this.agregarPaso("EMPALME", this.idxPaso, "Empalme"));
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina));
        pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(8));
        this.idxPaso++;
      }
      if (this.dataCotizacion.pegado) {
        let maquina = [this.dataCotizacion.idPegadoMaquina];

        pasos.push(this.agregarPaso("PEGADO LINEAL", this.idxPaso, "Pegado Lineal"));
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina));
        pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(9));
        this.idxPaso++;
      }
      if (this.dataCotizacion.pegadoGolpe) {
        let maquina = [this.dataCotizacion.idPegadoGolpeMaquina];

        pasos.push(this.agregarPaso("PEGADO DE GOLPE", this.idxPaso, "Pegado de Golpe"));
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina));
        pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(10));
        this.idxPaso++;
      }
      if (this.dataCotizacion.troquel) {
        let maquina = [this.dataCotizacion.idTroquelMaquina]

        switch (this.dataCotizacion.idTroquelTipo) {
          case 1:
            pasos.push(this.agregarPaso("TROQUEL", this.idxPaso, "Troquelado Bobina"))
            break;
          case 2:
            pasos.push(this.agregarPaso("TROQUEL", this.idxPaso, "Troquelado Manual"))
            break;
          case 3:
            pasos.push(this.agregarPaso("TROQUEL", this.idxPaso, "Troquelado Automatica"))
            break;
        }
        pasos[this.idxPaso - 1].Ingredientes.push(...this.agregarMaquina(maquina))
        pasos[this.idxPaso - 1].subPasos.push(...this.agregarSubPasos(11));
        this.idxPaso++;
      }

      receta.Cantidad = 1;
      receta.Descripcion = this.dataCotizacion.nombreTrabajo;
      receta.IdArticulo = this.idProductoTerminado;
      receta.NumPasos = pasos.length;
      receta.Pasos = pasos;
      receta.revision = this.revision;

      return receta;
    },

    agregarPaso(proceso, idxPaso, procesoGeo) {
      let area = this.dataAreas.find(area => area.nombre == proceso);
      let idProcesos = this.dataProcesos.find(item => item.nombreProceso == procesoGeo).idProceso;
      let tiempo = this.dataCalculos.calculoCostos.analisis.datoLista.find(item => item.idProceso == idProcesos);
      let tiempoMinutos = Math.ceil(Math.ceil(tiempo.tiempoPaso) / 60);
      if (!area || !idProcesos || !tiempo) {
        throw new Error(500);
      }
      let pasos = {
        Fraccion: 1,//this.dataCotizacion.cantidadPza
        IdArea: area.idArea,
        Imagenes: [],
        Ingredientes: [],
        Nota: null,
        NumPaso: idxPaso,
        TiempoLimpieza: 0,
        TiempoMax: Math.ceil(tiempo.tiempoPaso),
        TiempoMin: Math.ceil(tiempo.tiempoPaso),
        cantidadObjetivo: null,
        idsMoldes: [],
        minutos: tiempoMinutos,//null,
        numPiezas: this.dataCotizacion.cantidadPza,//null,
        subPasos: [],
        tiempo: 1,//null,
        tipoTiempo: true,//false,
        subpasosSeriados: true,
      }

      //tipoTiempo = true //?
      //tiempo = 1 //? 1 para minutos, 2 para horas
      //numPiezas = // Piezas qye se van a realizar en el tiempo establecido
      //minutos = //Minutos que tarda en hacerse todas las piezas
      return pasos;
    },
    /**
     * Agregar los subprocesos se divide en dos partes. Ésta primera parte es la encargada de seleccionar los subprocesos relacionados con el proceso
     * @param {Number} idSubPaso - Identificador númerico del proceso para conocer que subprocesos serán añadidos segun el array subprocesos declarado en la función
     * @returns {Array} - Retorna un array con los subpasos con los datos solicitados por ManagrX / Si no se pasa el idSubPaso, dará error
     */
    agregarSubPasos(idSubPaso) {
      if (!idSubPaso) {
        throw new Error(500);
      }
      const subprocesos = [
        {
          subProcesos: ["Entonado Tinta Directa", "Tiro (Por Millar)", "Ajuste Por Placa"]
        }, {
          subProcesos: ["Tiro Barniz Base Agua", "Ajuste Barniz Base Agua"]
        }, {
          subProcesos: ["Ajuste Barniz UV", "Tiro Barniz UV"]
        }, {
          subProcesos: ["Ajuste Barniz UV Registro", "Tiro Barniz UV Registro"]
        }, {
          subProcesos: ["Ajuste Plastificado", "Tiro Plastificado"]
        }, {
          subProcesos: ["Ajuste Suaje Con Desbarbe", "Tiro Suaje Con Desbarbe"]
        }, {
          subProcesos: ["Ajuste Corte"]
        }, {
          subProcesos: ["Ajuste Empalme", "Tiro Empalme"]
        }, {
          subProcesos: ["Ajuste Pegado Lineal", "Tiro Pegado Lineal"]
        }, {
          subProcesos: ["Ajuste Pegado Golpe", "Tiro Pegado Golpe"]
        }, {
          subProcesos: ["Ajuste Troquelado", "Tiro Troquelado", "Ajuste Troquelado Bobina", "Tiro Troquelado Bobina"]
        }
      ];

      let subpasos = [];

      subpasos.push(...this.completarSubpaso(subprocesos[idSubPaso - 1].subProcesos))

      return subpasos;
    },
    /**
     * La segunda parte para agregar subpasos a la receta. Busca los subpasos en el catalogo de ManagrX y agrega la información al objeto de cada paso
     * @param {Array} subpasos - El array de strings con los subpasos que serán agregados
     * @returns {Array} Retorna un array con los subpasos con los datos solicitados por ManagrX / Si no se pasa el array de subpasos, dará error / Si no se encuentra un (o más) subpaso(s) retorna error
     */
    completarSubpaso(subpasos) {
      if (!subpasos) throw new Error(500);

      let posicion = 1;
      let data = [];

      for (let idx in subpasos) {
        /**
         * geoData es el array con los nombres de los subpasos que se usan en la cotización
         * mngrxData es el catalogo de los subpasos de ManagrX (el nombre los subprocesos deben ser iguales sin contar mayusculas o minusculas)
         */
        const geoData = this.dataCalculos.calculoCostos.analisis.datoLista.find(item => item.nombre == subpasos[idx]);
        const mngrxData = this.dataSubpasos.find(item => item.descripcion == subpasos[idx].toUpperCase());
        if (geoData && mngrxData) {
          data.push({
            idSubpaso: -1,
            idCatSubpaso: mngrxData.idCatSubpaso,
            codigo: "",
            descripcion: "",
            posicion: posicion,
            tiempo: this.recetaPorPedido ? geoData.tiempoSubPaso : Math.ceil(geoData.tiempoSubPaso / this.dataCotizacion.cantidadPza),//geoData.tiempoSubPaso
            obligatorio: true,
            valido: true,
            costoDestajo: this.recetaPorPedido ? geoData.totalCFijos : geoData.totalCFijos / this.dataCotizacion.cantidadPza,//geoData.totalCFijos
            nuevoSubpaso: false
          })
          posicion++;
        } else if (geoData && !mngrxData) {
          throw new Error();
        }
      }
      return data;
    },
    /**
     * Agregar insumos a la receta (en un paso especifico)
     * @param {Array} dataInsumos - Arreglo con los insumos. Requiere el id del insumo (idArticulo) y la cantidad que ocupa (cantidad)
     * @returns {Array} Array de objetos con las propiedades que pide ManagrX para los insumos / De no pasar el array, la función devuelve un array vacio / Si el array no tiene los datos solicitados, dará error
     */
    agregarInsumos(dataInsumos) {
      if (!dataInsumos || dataInsumos.length <= 0) return [];

      let insumos = [];
      for (let insumo of dataInsumos) {
        if (!insumo.idArticulo || !insumo.cantidad) throw new Error();
        insumos.push({
          IdArticulo: insumo.idArticulo,
          IdMaquina: null,
          IdPuesto: null,
          IdDesconocidoIns: null,
          Cantidad: this.recetaPorPedido ? insumo.cantidad : (insumo.cantidad / this.dataCotizacion.cantidadPza),
          Tiempo: null,
          Remplazar: false,
          RemplazarMaquina: false,
          Critico: true,
          Merma: null,
          Proporcional: false,
          Fraccion: null,
          EjeX: null,
          EjeY: null,
          EjeZ: null
        })
      }
      return insumos;
    },
    agregarMaquina(dataMaquinas) {
      if (!dataMaquinas) throw new Error();
      let maquinas = [];

      if (!dataMaquinas || dataMaquinas.length <= 0) return []

      for (let maquina of dataMaquinas) {
        if (maquina) {
          maquinas.push(
            {
              IdArticulo: null,
              IdMaquina: maquina,
              IdPuesto: null,
              IdDesconocidoIns: null,
              Cantidad: 1,
              Tiempo: 0,
              Remplazar: false,
              RemplazarMaquina: false,
              Critico: false,
              Merma: null,
              Fraccion: null,
              EjeX: null,
              EjeY: null,
              EjeZ: null
            }
          )
        }
      }
      return maquinas;
    },

    reiniciarReceta() {
      this.dataCotizacion = JSON.parse(JSON.stringify([]));
      this.dataReceta = JSON.parse(JSON.stringify({}));
      this.dataProductoTerminado = JSON.parse(JSON.stringify({}));
      this.$refs.formCrearReceta.reset();
      this.revision = null;
      this.idxPaso = 1;
      this.loadingCrearReceta = false;
    },
    close_dialog() {
      this.dialog = false;
      this.dialogProductoTerminado = false;
      this.$refs.formProductoTerminado?.reset()
      this.reiniciarReceta();
      this.$emit('close_receta')
    },

    formatearCifra(number, decimales = 2) {
      let numero = 0;
      if (number != null) numero = number;
      return numero.toLocaleString('en-US', { minimumFractionDigits: decimales, maximumFractionDigits: decimales });
    },
    cambioFecha() {
      if (typeof this.fechaCompromiso === "string") {
        this.fechaCompromisoLabel = this.fechaCompromiso
          .split("-")
          .reverse()
          .join("-");
      } else {
        this.fechaCompromisoLabel = formatoFecha(
          this.fechaCompromiso
        );
      }
    },
  },
  computed: {
    jsonImpresiones() {
      const dataImpresiones = this.dataImpresiones.map(item => {
        return {
          idCara: item.idCara,
          idTintaTipo: item.idTintaTipo,
          idArticulo: item.idArticulo,
          idProveedor: item.idProveedor,
          porcentajeTinta: parseInt(item.porcentajeTinta)
        }
      })
      return dataImpresiones
    },
    jsonConceptos() {
      const dataConceptos = this.dataExtras.map(item => {
        return {
          concepto: item.concepto,
          tipo: parseInt(item.tipo),
          monto: parseFloat(item.monto),
          descripcion: item.descripcion,
        }
      })
      return dataConceptos
    },
    jsonTransporte() {
      const dataTransporte = this.dataTransporte.map(item => ({
        idTransportePregunta: item.idTransportePregunta,
        respuesta: item.respuesta,
        monto: item.monto
      }));
      return dataTransporte;
    },
    jsonInsumosImpresion() {
      let insumos = [];
      if (this.dataCotizacion.impresionInsumos) {

        insumos = this.dataInsumos.filter(item => item.idMaquina === this.dataCotizacion.idImpresionMaquina)

        return insumos.map(item => {
          const insumoN = this.insumoCotizaciones.find(aplicaInsumo => aplicaInsumo.idMaquinaInsumos === item.idMaquinaInsumos);
          return {
            idMaquinaInsumos: item.idMaquinaInsumos,
            idMaquina: item.idMaquina,
            insumo: item.insumo,
            tipoInsumo: item.tipoInsumo,
            costo: item.costo,
            aplicaInsumo: insumoN ? insumoN.aplicaInsumo : false,
          };
        });
      }
      return insumos;
    },
    jsonMermas() {
      const mermas = [];
      if (this.dataImpresiones.length > 0) {
        mermas.push({
          idProceso: this.dataMaquinasImpresoras.find(item => item.proceso === "Impresión").idProceso,
        })
      }
      if (this.dataCotizacion.barniz) {
        mermas.push({
          idProceso: this.dataMaquinasBarniz.find(item => item.proceso === "Barnizado").idProceso,
        })
      }
      if (this.dataCotizacion.uvPlasta) {
        mermas.push({
          idProceso: this.dataMaquinasUvPlasta.find(item => item.proceso === "UV Plasta").idProceso,
        })
      }
      if (this.dataCotizacion.uvRegistro) {
        mermas.push({
          idProceso: this.dataMaquinasUvRegistro.find(item => item.proceso === "UV Registro").idProceso,
        })
      }
      if (this.dataCotizacion.plastificado) {
        mermas.push({
          idProceso: this.dataMaquinasPlastificado.find(item => item.proceso === "Plastificado").idProceso,
        })
      }
      if (this.dataCotizacion.suajado) {
        mermas.push({
          idProceso: this.dataMaquinasSuaje.find(item => item.proceso === "Suajado").idProceso,
        })
      }
      if (this.dataCotizacion.corte) {
        mermas.push({
          idProceso: this.dataMaquinasCorte.find(item => item.proceso === "Corte").idProceso,
        })
      }
      if (this.dataCotizacion.empalme) {
        mermas.push({
          idProceso: this.dataMaquinasEmpalme.find(item => item.proceso === "Empalme").idProceso,
        })
      }
      if (this.dataCotizacion.pegado) {
        mermas.push({
          idProceso: this.dataMaquinasPegado.find(item => item.proceso === "Pegado Lineal").idProceso,
        })
      }
      if (this.dataCotizacion.pegadoGolpe) {
        mermas.push({
          idProceso: this.dataMaquinasPegadoGolpe.find(item => item.proceso === "Pegado de Golpe").idProceso,
        })
      }
      if (this.dataCotizacion.troquel) {
        switch (this.dataCotizacion.idTroquelTipo) {
          case 1:
            mermas.push(
              {
                idProceso: this.dataMaquinasTroquelBobina.find(item => item.proceso === "Troquelado Bobina").idProceso,
              })
            break
          case 2:
            mermas.push(
              {
                idProceso: this.dataMaquinasTroquelManual.find(item => item.proceso === "Troquelado Manual").idProceso,
              })
            break
          case 3:
            mermas.push(
              {
                idProceso: this.dataMaquinasTroquelAutomatica.find(item => item.proceso === "Troquelado Automatica").idProceso,
              })
            break
        }
      }
      return mermas;
    },

    /*almacenesFiltrados() {
      if (this.editedItem.maquila) {
        return this.almacenes.filter((a) => {
          return (
            a.idCliente == this.editedItem.idCliente && a.idCliente != null
          );
        });
      } else {
        return this.almacenes.filter((a) => {
          return a.idCliente == null;
        });
      }
    },*/
  },
  mounted() {
    this.initialize();
  }
}
</script>