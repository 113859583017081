<template>
	<v-card>
		<v-tabs v-model="tab" style="padding: 0; width: 100%; margin: 20px 0 0 0;">
			<v-tab href="#tab-1" class="flex-grow-1" style="max-width: 50%; min-width: 0;" @click="initTintas()">
				Tintas
			</v-tab>
			<v-tab href="#tab-2" class="flex-grow-1" style="max-width: 50%; min-width: 0;" @click="initTipoTintas()">
				Tipo de Tintas
			</v-tab>
		</v-tabs>

		<v-tabs-items v-model="tab" style="padding: 0 20px 0 20px;">
			<v-tab-item :key="1" :value="'tab-1'">
				<v-card flat>
					<Tintas ref="Tintas" />
				</v-card>
			</v-tab-item>
			<v-tab-item :key="2" :value="'tab-2'">
				<v-card flat>
					<TipoTintas ref="TipoTintas" />
				</v-card>
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>

<script>
import Tintas from './Tintas/Tintas.vue'
import TipoTintas from './Tintas/tipoTintas.vue';

export default {
	data() {
		return {
			tab: null,
			toggle: 'tintas',
		}
	},
	components: {
		Tintas,
		TipoTintas
	},
	methods: {
		initTintas() {
			this.$refs.Tintas.initialize();
		},
		initTipoTintas() {
			this.$refs.TipoTintas?.initialize();
		}
	}
}
</script>
<style></style>