<template>
  <div v-if="permisoVista('configuraciones','r')">
    
    <h4 class="d-flex justify-left"> {{ stateCartulina ? "Mermas de Cartulina" : "Mermas de Papel" }} </h4>
    <v-data-table :headers="headers" :search="search" :items="dataMaquinaProcesos" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex align-center">
          <v-text-field v-model="search" v-bind="propsSearch" />
          <v-switch v-model="stateCartulina" label="Cartulina" @change="getMaquinaProcesos()" class="ml-3" />
        </div>
      </template>

      <template v-slot:item.nombreProceso="{ item }">
        {{ item.nombreProceso }} &#160;
        <v-tooltip v-if="permisoVista('configuraciones','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">
            Editar Máquinas
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.maquinas="{ item }">
        <v-list class="my-list">
          <v-list-item v-for="maquina in item.maquinas" :key="maquina.idMaquina" class="my-list-item">
            {{ maquina.nombreMaquina }} &#160;

            <v-tooltip v-if="permisoVista('configuraciones','u')" bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small
                  @click="show_dialogDeltas(item, maquina)">mdi-pencil-outline</v-icon>
              </template>
              <span class="white--text">
                Editar Deltas
              </span>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:header.deltaSencillo="{ header }">
        4 Deltas - Sencillo
        <v-list-item>
          <v-row>
            <v-col cols="6">
              Arranque
            </v-col>
            <v-col cols="6">
              Millar Adicional
            </v-col>
          </v-row>
        </v-list-item>
      </template>
      <template v-slot:header.deltaComplejo="{ header }">
        2 Deltas - Complejo
        <v-list-item>
          <v-row>
            <v-col cols="6">
              Arranque
            </v-col>
            <v-col cols="6">
              Millar Adicional
            </v-col>
          </v-row>
        </v-list-item>
      </template>

      <template v-slot:item.deltaSencillo="{ item }">
        <v-list class="my-list">
          <v-list-item v-for="maquina in item.maquinas" :key="maquina.idMaquina">
            <v-row>
              <v-col cols="6">{{ maquina.deltaSencillo.sencilloArranque }} {{ item.unidad }}</v-col>
              <v-col cols="6">{{ maquina.deltaSencillo.sencilloProceso }}</v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </template>
      <template v-slot:item.deltaComplejo="{ item }">
        <v-list class="my-list">
          <v-list-item v-for="maquina in item.maquinas" :key="maquina.idMaquina">
            <v-row>
              <v-col cols="6">{{ maquina.deltaComplejo.complejoArranque }} {{ item.unidad }}</v-col>
              <v-col cols="6">{{ maquina.deltaComplejo.complejoProceso }}</v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogMaquinas" max-width="500" persistent>
      <v-card>
        <v-card-title>Proceso - {{ procesoNombre }} </v-card-title>
        <br>
        <v-card-text>
          <v-autocomplete v-model="maquinasProceso" :items="dataMaquinas" v-bind="propsAutocompleteMultiple"
            :item-text="nombreMaquina" item-value="idMaquina" label="Máquinas" :loading="loadingProcesoMaquinas" />
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingProcesoMaquinas" color="blue darken-1" class="ml-auto" text
            @click="hide_dialog()">Cancelar</v-btn>
          <v-btn :loading="loadingProcesoMaquinas" color="error" text @click="postProcesoMaquinas()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeltas" max-width="500" persistent>
      <v-card>
        <v-card-title>Configuración de Mermas - {{ maquinaNombre }}</v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-card-text align="center">4 Deltas - Sencillos</v-card-text>
            <v-row>
              <v-col cols="6"> <v-text-field v-model="sencilloArranque" v-bind="propsDecimales" label="Arranque"
                  :loading="loadingDeltas" />
              </v-col>
              <v-col cols="6"> <v-text-field v-model="sencilloPaso" v-bind="propsDecimales" label="Millar Adicional"
                  :loading="loadingDeltas" /> </v-col>
            </v-row>
            <br>
            <v-card-text align="center">2 Deltas - Complejos</v-card-text>
            <v-row>
              <v-col cols="6"> <v-text-field v-model="complejoArranque" v-bind="propsDecimales" label="Arranque"
                  :loading="loadingDeltas" /></v-col>
              <v-col cols="6"> <v-text-field v-model="complejoPaso" v-bind="propsDecimales" label="Millar Adicional"
                  :loading="loadingDeltas" /></v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn :loading="loadingDeltas" color="blue darken-1" class="ml-auto" text
            @click="hide_dialogDeltas()">Cancelar</v-btn>
          <v-btn :loading="loadingDeltas" color="error" text @click="validate()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>

<script>
import axios from 'axios';
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {
    AlertSnackbar
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
      },
      propsAutocompleteMultiple: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
        required: true,
        multiple: true,
        chips: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      propsDecimales: {
				outlined: false,
				dense: false,
				type: "number",
				inputmode: "numeric",
				required: true,
				rules: [
					(v) => {
						if (v === null || v === "") {
							return "Dato requerido";
						} else if (!Number.isFinite(Number(v))) {
							return "Ingrese solo números";
						} else if (Number(v) < 0) {
							return "Dato no valido";
						}
						return true;
					},
				],
			},

      dialogMaquinas: false,
      dialogDeltas: false,

      stateCartulina: false,
      procesoId: null,
      procesoNombre: null,

      maquinaId: null,
      maquinaNombre: null,
      sencilloArranque: null,
      sencilloPaso: null,
      complejoArranque: null,
      complejoPaso: null,

      search: '',

      headers: [
        {
          text: "Proceso",
          value: "nombreProceso",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "Máquina",
          value: "maquinas",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "4 Deltas - Sencillo",
          value: "deltaSencillo",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "2 Deltas - Complejo",
          value: "deltaComplejo",
          align: "center",
          sortable: false,
          visible: true,
        }
      ],

      maquinasProceso: [],
      dataMaquinas: [],
      dataMaquinaProcesos: [],

      loadingTable: false,
      loadingDialog: false,
      loadingProcesoMaquinas: false,
      loadingDeltas: false,
    }
  },
  methods: {
    nombreMaquina: (item) => `[${item.modelo}] - ${item.marca}`,

    initialize() {
      this.getMaquinas();
      this.getMaquinaProcesos();
    },

    validate() {
      if (this.$refs.form.validate()) { this.putDeltas() }
    },

    getMaquinas() {
      axios
        .get("/Maquinas")
        .then((response) => {
          this.dataMaquinas = response.data;
        })
        .catch((error) => { console.log(error) })
    },

    getMaquinaProcesos() {
      this.loadingTable = true;
      axios
        .get("/MaquinaProcesos/Listar/" + this.stateCartulina)
        .then((response) => {
          this.dataMaquinaProcesos = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTable = false;
        })
    },

    getProcesoMaquinas() {
      this.loadingProcesoMaquinas = true;
      axios
        .get("/MaquinaProcesos/Mostrar/" + this.stateCartulina + "/" + this.procesoId)
        .then((response) => {
          this.maquinasProceso = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingProcesoMaquinas = false;
        })
    },
    postProcesoMaquinas() {
      this.loadingProcesoMaquinas = true;
      axios
        .post("/MaquinaProcesos/CrearOActualizarMaquina/" + this.stateCartulina + "/" + this.procesoId, {
          maquina: this.jsonMaquinas
        })
        .then((response) => {
          this.alerta(200)
          this.hide_dialog();
          this.getMaquinaProcesos();
        })
        .catch((error) => {
          this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingProcesoMaquinas = false;
        })
    },

    putDeltas() {
      this.loadingDeltas = true;
      axios
        .put("/MaquinaProcesos/ActualizarDelta/" + this.stateCartulina + "/" + this.procesoId + "/" + this.maquinaId, {
          "deltaSencillo": {
            "sencilloArranque": this.sencilloArranque,
            "sencilloProceso": this.sencilloPaso
          },
          "deltaComplejo": {
            "complejoArranque": this.complejoArranque,
            "complejoProceso": this.complejoPaso
          }
        })
        .then((response) => {
          this.alerta(200)
          this.hide_dialogDeltas()
          this.getMaquinaProcesos()
        })
        .catch((error) => {
          this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDeltas = false;
        })
    },

    show_dialog(item) {
      this.procesoId = item.idProceso;
      this.procesoNombre = item.nombreProceso;
      this.getProcesoMaquinas();

      this.dialogMaquinas = true;
    },
    hide_dialog() {
      this.dialogMaquinas = false;

      this.procesoId = null;
      this.procesoNombre = null;
      this.maquinasProceso = [];
    },

    show_dialogDeltas(proceso, item) {
      this.dialogDeltas = true;

      this.procesoId = proceso.idProceso;
      this.maquinaId = item.idMaquina;
      this.maquinaNombre = item.nombreMaquina;
      this.sencilloArranque = item.deltaSencillo.sencilloArranque;
      this.sencilloPaso = item.deltaSencillo.sencilloProceso;
      this.complejoArranque = item.deltaComplejo.complejoArranque;
      this.complejoPaso = item.deltaComplejo.complejoProceso;
    },
    hide_dialogDeltas() {
      this.$refs.form?.reset()
      this.dialogDeltas = false;

      this.procesoId = null;
      this.maquinaId = null;
      this.maquinaNombre = null;
      this.sencilloArranque = null;
      this.sencilloPaso = null;
      this.complejoArranque = null;
      this.complejoPaso = null;
    },

    alerta(code, color, mensaje, icono) {
      if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
      } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
      }
    },
  },
  computed: {
    jsonMaquinas() {
      return this.maquinasProceso.map(item => {
        return {
          idMaquina: item
        }
      })
    },
  },
  mounted() {
    this.initialize();
  },
}
</script>

<style scoped>
.my-list {
  background-color: rgba(0, 0, 0, 0) !important;
}

.my-list-item {
  font-size: 15px !important;
  justify-content: center;
}
</style>