<template>
  <div v-if="permisoVista('configuraciones','r')">
    <v-col cols="6">
      <v-btn v-if="permisoVista('configuraciones','c')" color="primary" @click="show_dialog()">Nueva Frecuencia</v-btn>
    </v-col>

    <v-data-table :headers="headers" :items="dataFrecuencia" :search="search" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex justify-center">
          <v-text-field v-model="search" v-bind="propsSearch" />
        </div>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip v-if="permisoVista('configuraciones','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class=" mr-1" @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip v-if="permisoVista('configuraciones','d')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1"
              @click="show_dialogDelet(item)">mdi-trash-can-outline</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogFrecuencia" persistent max-width="500px">
      <v-card>
        <v-card-title>{{ optionDialog }} Frecuencia</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="frecuenciaNombre" label="Frecuencia" v-bind="propsText" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text @click="hide_dialog()">Cancelar</v-btn>
          <v-btn :loading="loadingDialog" color="error" text @click="validate()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeletFrecuencia" max-width="500px" persistent>
      <v-card>
        <v-card-title>Eliminar Pregunta</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "[{{ frecuenciaId }}] {{ frecuenciaNombre }}"?
        </v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialogDelet" color="blue darken-1" @click="hide_dialogDelet()" background="primary"
            class="ml-auto" text>Cancelar</v-btn>
          <v-btn :loading="loadingDialogDelet" color="error" @click="deleteFrecuencia()" text>Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>

<script>
import AlertSnackbar from '@/components/AlertSnackbar.vue'
import axios from "axios"

export default {
  components: {
    AlertSnackbar
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: true,
      },
      propsText: {
        outlined: false,
        dense: true,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },

      optionDialog: null,
      dialogFrecuencia: false,
      dialogDeletFrecuencia: false,

      frecuenciaId: null,
      frecuenciaNombre: null,

      search: '',

      headers: [
        {
          text: "Código",
          value: "idFrecuencia",
          align: "center",
          sortable: false,
        },
        {
          text: "Nombre",
          value: "nombre",
          align: "center",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        },
      ],

      dataFrecuencia: [],

      loadingTable: false,
      loadingDialog: false,
      loadingDialogDelet: false,
    }
  },
  methods: {
    initialize() {
      this.getFrecuencia()
    },
    validate() {
      if (this.$refs.form.validate()) this.optionDialog == 'Crear' ? this.postFrecuencia() : this.putFrecuencia()
    },
    getFrecuencia() {
      this.loadingTable = true;
      axios
        .get("/Frecuencia/Listar")
        .then((response) => {
          this.dataFrecuencia = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    postFrecuencia() {
      this.loadingDialog = true;
      axios
        .post("/Frecuencia/Crear", {
          nombre: this.frecuenciaNombre
        })
        .then((response) => {
          this.hide_dialog()
          this.getFrecuencia()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putFrecuencia() {
      this.loadingDialog = true;
      axios
        .put("/Frecuencia/Actualizar/" + this.frecuenciaId, {
          nombre: this.frecuenciaNombre
        })
        .then((response) => {
          this.hide_dialog()
          this.getFrecuencia()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteFrecuencia() {
      this.loadingDialogDelet = true;
      axios
        .put("/Frecuencia/Desactivar/" + this.frecuenciaId)
        .then((response) => {
          this.hide_dialogDelet()
          this.getFrecuencia()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialogDelet = false;
        })
    },

    show_dialog(item) {
      this.dialogFrecuencia = true
      this.optionDialog = item ? "Editar" : "Crear"

      if (item) {
        this.frecuenciaId = item.idFrecuencia
        this.frecuenciaNombre = item.nombre
      }
    },
    hide_dialog() {
      this.$refs.form?.reset();
      this.frecuenciaNombre = null;
      this.dialogFrecuencia = false;
    },

    show_dialogDelet(item) {
      this.dialogDeletFrecuencia = true

      if (item) {
        this.frecuenciaId = item.idFrecuencia
        this.frecuenciaNombre = item.nombre
      }
    },
    hide_dialogDelet() {
      this.frecuenciaId = null;
      this.frecuenciaNombre = null;
      this.$refs.form?.reset();
      this.dialogDeletFrecuencia = false;
    },

    alerta(code, color, mensaje, icono) {
      if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
      } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>