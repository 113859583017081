<template>
  <div v-if="permisoVista('insumos','r')">
    <v-col cols="6">
      <v-btn v-if="permisoVista('insumos','c')" @click="show_dialog()" color="primary">Nuevo Papel</v-btn>
    </v-col>

    <v-data-table :headers="headers" :items="dataPapel" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex align-center">
          <v-text-field v-model="search" @keyup.enter="change_urlPapel()" @click:append="change_urlPapel()"
            @click:clear="change_urlPapel(false)" v-bind="propsSearch" />
        </div>
      </template>
      <template v-slot:[`item.gramaje`]="{ item }">
        {{ item.gramaje }} g/m2
      </template>
      <template v-slot:[`item.extras`]="{ item }">
        <li v-for="extra in item.extras" style="text-align: center; list-style-position: inside;">
          {{ extra }}
        </li>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip v-if="permisoVista('insumos','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip v-if="permisoVista('insumos','d')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1"
              @click="show_dialogDelet(item)">mdi-trash-can-outline</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1"
              @click="show_dialogProveedor(item)">mdi-storefront-outline</v-icon>
          </template>
          <span class="white--text">Proveedores</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>{{ optionDialog }} Papel </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="papelCodigo" label="Código" v-bind="propsText" />
            <v-text-field v-model="papelNombre" label="Nombre" v-bind="propsText" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text @click="hide_dialog()">Cancelar</v-btn>
          <v-btn :loading="loadingDialog" color="error" text @click="validate()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelet" max-width="500px" persistent>
      <v-card>
        <v-card-title>Eliminar Papel</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "[{{ papelCodigo }}] - {{ papelNombre }}"? </v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialogDelet" color="blue darken-1" @click="hide_dialogDelet()" background="primary"
            class="ml-auto" text>Cancelar</v-btn>
          <v-btn :loading="loadingDialogDelet" color="error" @click="deletePapel()" text>Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogProveedor" scrollable persistent max-width="1300px">
      <v-card>
        <v-card-title>Proveedores de Papel</v-card-title>
        <v-card-subtitle>{{ papelNombre }}</v-card-subtitle>
        <v-card-text>
          <v-form ref="formProveedores">
            <v-data-table :headers="headersProveedores" :items="dataProveedoresPapel" :items-per-page="-1" hide-footer
              hide-default-footer :loading="loadingTableProveedores">
              <template v-slot:[`item.nombreProveedor`]="{ item }">
                <v-autocomplete v-model="item.idProveedor" :items="dataProveedores" :item-text="nombreProveedor"
                  item-value="idProveedor" label="Proveedor" v-bind="propsAutocomplete" />
              </template>
              <template v-slot:[`item.puntaje`]="{ item }">
                <v-text-field v-model="item.puntaje" label="Puntaje" v-bind="propsDecimales" />
              </template>
              <template v-slot:[`item.gramaje`]="{ item }">
                <v-text-field v-model="item.gramaje" label="Gramaje" v-bind="propsDecimales" />
              </template>
              <template v-slot:[`item.rendimiento`]="{ item }">
                <v-text-field v-model="item.rendimiento" label="Rendimiento" v-bind="propsEnteros" />
              </template>
              <template v-slot:[`item.pliegosMaximos`]="{ item }">
                <v-text-field v-model="item.pliegosMaximos" label="Pliegos Maximos" v-bind="propsDecimales" />
              </template>

              <template v-slot:[`item.extras`]="{ item }">
                <v-checkbox v-model="item.resistenciaHumedad" label="Resistente a la humedad" />
                <v-checkbox v-model="item.gradoAlimenticio" label="Grado Alimenticio" style="margin-top: -10px;" />
              </template>
              <template v-slot:[`item.precio`]="{ item }">
                <v-text-field v-model="item.precio" label="Costo por m2" v-bind="propsDecimales" />
              </template>
              <template v-slot:[`item.tiempoEntrega`]="{ item }">
                <v-text-field v-model="item.tiempoEntrega" label="Días para entrega" v-bind="propsDecimales" />
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" @click="deletProveedor(item)">mdi-trash-can-outline</v-icon>
                  </template>
                  <span class="white--text">Eliminar</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.fechaCotizacion`]="{ item }">
                <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="item.fechaCotizacion" label="Fecha Cotización" prepend-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on" />
                  </template>
                  <v-date-picker color="primary" dark v-model="item.fechaCotizacion"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                    @change="save()" />
                </v-menu>
              </template>
            </v-data-table>
          </v-form>
          <v-btn color="primary" @click="addProveedor()">Agregar Proveedor</v-btn>
        </v-card-text>

        <v-card-actions>
          <v-btn :loading="loadingTableProveedores" color="blue darken-1" background="primary" class="ml-auto" text
            @click="hide_dialogProveedor()">Cancelar</v-btn>
          <v-btn :loading="loadingTableProveedores" color="success" text @click="putProveedoresPapel">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>

<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import AlertSnackbar from '@/components/AlertSnackbar.vue'
import axios from "axios"

export default {
  components: {
    VueDatePicker,
    AlertSnackbar
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: false,
      },
      propsText: {
        outlined: false,
        dense: false,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },
      propsDecimales: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) < 0) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },
      propsEnteros: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) < 0) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },
      propsAutocomplete: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },

      dialog: false,
      dialogDelet: false,
      optionDialog: null,
      dialogProveedor: false,

      papelId: null,
      papelCodigo: null,
      papelNombre: null,
      papelUnidad: null,
      papelPuntaje: null,
      papelGramaje: null,
      papelRendimiento: null,
      papelPliegosMaximos: null,
      papelGradoAlimenticio: false,
      papelResistenteHumedad: false,

      search: '',
      seleccion: [],
      selectExtras: [],

      headers: [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "Papel",
          value: "nombre",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
          visible: true,
        },
      ],
      headersProveedores: [
        {
          text: "Proveedor",
          value: "nombreProveedor",
          align: "center",
          sortable: false,
        }, {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
          sortable: false,
        }, {
          text: "Gramaje",
          value: "gramaje",
          align: "center",
          sortable: false,
        }, {
          text: "Rendimiento",
          value: "rendimiento",
          align: "center",
          sortable: false,
        }, {
          text: "Pliegos Maximos",
          value: "pliegosMaximos",
          align: "center",
          sortable: false,
        }, {
          text: "Extras",
          value: "extras",
          align: "center",
          sortable: false,
        }, {
          text: "Costo por m2",
          value: "precio",
          align: "center",
          sortable: false,
        }, {
          text: "Tiempo de Entrega",
          value: "tiempoEntrega",
          align: "center",
          sortable: false,
        }, {
          text: "Fecha Cotización",
          value: "fechaCotizacion",
          align: "center",
          sortable: false,
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        },
      ],

      dataPapel: [],
      dataUnidades: [],
      dataProveedores: [],
      dataProveedoresPapel: [],

      loadingTable: false,
      loadingDialog: false,
      loadingUnidades: false,
      loadingDialogDelet: false,
      loadingTableProveedores: false,

      urlPapeles: "/Papeles/Listar",
      urlPapelesDefault: "/Papeles/Listar?",

      activePicker: null,
    };
  },
  methods: {
    nombreExtras: (item) => `${item.value}`,
    nombreUnidades: (item) => `[${item.abreviatura}] - ${item.nombre}`,
    nombreProveedor: (item) => `[${item.codigo}] - ${item.nombre}`,

    initialize() {
      this.getPapel();
      this.getUnidades();
    },

    validate() {
      if (this.$refs.form.validate()) this.optionDialog == 'Crear' ? this.postPapel() : this.putPapel()
    },

    getPapel() {
      this.loadingTable = true;
      axios
        .get("/Papeles/Listar")
        .then((response) => {
          this.dataPapel = response.data;
          this.loadingTable = false;
        })
        .catch((error) => { 
          this.loadingTable = false;
          console.log(error); 
        })
    },
    getUnidades() {
      this.loadingUnidades = true;
      axios
        .get("/unidades")
        .then((response) => {
          this.dataUnidades = response.data;
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingUnidades = false;
        })
    },
    getProveedores() {
      axios
        .get("/Proveedores")
        .then((response) => {
          this.dataProveedores = response.data
        })
        .catch((error) => { console.log(error) })
    },
    getProveedoresPapel() {
      this.loadingTableProveedores = true;
      axios
        .get("/PapelProveedores/Listar/" + this.papelId)
        .then((response) => {
          this.dataProveedoresPapel = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTableProveedores = false;
        })
    },
    postPapel() {
      this.loadingDialog = true;
      axios
        .post("/Papeles/Crear",{
          Nombre: this.papelNombre,
          Codigo: this.papelCodigo,
        })
        .then((response) => {
          this.hide_dialog()
          this.getPapel()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putPapel() {
      this.loadingDialog = true;
      axios
        .put("/Papeles/Actualizar", {
          idArticulo: this.papelId,
          nombre: this.papelNombre,
          codigo: this.papelCodigo,
        })
        .then((response) => {
          this.hide_dialog()
          this.getPapel()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putProveedoresPapel() {
      if (this.$refs.formProveedores.validate()) {
        this.loadingTableProveedores = true;
        axios
          .put("/PapelProveedores/CrearActualizarDesactivar/" + this.papelId,
            this.jsonProveedoresPapel,
          )
          .then((response) => {
            this.hide_dialogProveedor();
            this.getPapel();
            //this.alerta(200);
          })
          .catch((error) => {
            //this.alerta(error.response.status);
            console.log(error);
          })  
          .finally(() => {
            this.loadingTableProveedores = false;
          })
      }
    },
    deletePapel() {
      this.loadingDialogDelet = true;
      axios
        .put("/papeles/desactivar/" + this.papelId)
        .then((response) => {
          this.hide_dialogDelet();
          this.getPapel();
          //this.alerta(200);
        })
        .catch((error) => {
          //this.alerta(error.response.status);
          console.log(error);
        })
        .finally(() => {
          this.loadingDialogDelet = false;
        })
    },

    show_dialogProveedor(item) {
      this.dialogProveedor = true;
      this.papelId = item.idArticulo;
      this.papelNombre = item.nombre
      this.getProveedores();
      this.getProveedoresPapel();
    },
    hide_dialogProveedor() {
      this.papelId = null;
      this.dataProveedoresPapel = JSON.parse(JSON.stringify([]));
      this.dialogProveedor = false
    },

    addProveedor() {
      this.dataProveedoresPapel.push(
        {
          idProveedor: null,
          nombreProveedor: null,
          idUnidad: null,
          puntaje: null,
          gramaje: null,
          rendimiento: null,
          pliegosMaximos: null,
          resistenciaHumedad: false,
          gradoAlimenticio: false,
          precio: null,
          tiempoEntrega: null,
          fechaCotizacion: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        });
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    deletProveedor(item) {
      var idx = this.dataProveedoresPapel.indexOf(item);
      if (idx < 0) return;
      this.dataProveedoresPapel.splice(idx, 1);
    },

    show_dialog(item) {
      this.dialog = true;
      this.optionDialog = item ? "Editar" : "Crear";

      if (item) {
        this.papelId = item.idArticulo
        this.papelCodigo = item.codigo
        this.papelNombre = item.nombre
      }
    },
    hide_dialog() {
      this.papelId = null;
      this.papelCodigo = null;
      this.papelNombre = null;
      this.$refs.form?.reset()
      this.dialog = false
    },

    show_dialogDelet(item) {
      this.dialogDelet = true
      if (item) {
        this.papelNombre = item.nombre
        this.papelCodigo = item.codigo
        this.papelId = item.idArticulo
      }
    },
    hide_dialogDelet() {
      this.papelNombre = null;
      this.papelCodigo = null;
      this.papelId = null;
      this.dialogDelet = false;
      this.$refs.form?.reset()
    },

    change_urlPapel(searchValue = true) {
      this.urlPapeles = this.urlPapelesDefault

      this.urlPapeles += searchValue == true && this.search ? `buscar=${this.search}` : "";
      /*if (this.search) {
        this.urlPapeles += `buscar=${this.search}`
      }
      if (this.selectExtras.length > 0) {
        this.urlPapeles += this.urlPapeles.endsWith("?") ? "" : "&";
        this.urlPapeles += this.selectExtras.map(item => 'idsExtras=' + item).join('&')
      }*/

      this.getPapel()
    },

    alerta(code) {
      this.$refs.AlertSnackbar.state_alert(code);
    },
  },
  computed: {
    jsonProveedoresPapel() {
      const dataProveedoresPapel = this.dataProveedoresPapel.map((item, index) => {
        return {
          idProveedor: item.idProveedor,

          resistenciaHumedad: item.resistenciaHumedad,
          gradoAlimenticio: item.gradoAlimenticio,

          pliegosMaximos: item.pliegosMaximos,
          rendimiento: item.rendimiento,
          puntaje: item.puntaje,
          gramaje: item.gramaje,

          costo: item.precio,
          tiempoEntrega: item.tiempoEntrega,

          fechaCotizacion: String(item.fechaCotizacion),
        }
      })
      return dataProveedoresPapel
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>