import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('optimizacion','r'))?_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.loading)?_c('div',[_c('div',{staticClass:"d-flex justify-center align-center flex-column",staticStyle:{"min-height":"80vh"}},[_c(VProgressCircular,{attrs:{"size":90,"width":6,"color":"primary","indeterminate":""}}),_c('p',{staticClass:"text-center text-h4"},[_vm._v("Cargando")])],1)]):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-items":"center","align-items":"center","max-width":"100%"}},[_c('div',{staticStyle:{"width":"100%","display":"flex","flex-direction":"column"}},[_c('h3',[_vm._v(" Selecciona una máquina para visualizar la optimización de producción ")]),_c(VAutocomplete,{staticClass:"autocomplete-input",attrs:{"items":_vm.filteredMaquinas,"item-text":_vm.getItemText,"item-value":"idMaquinaIns","search-input":_vm.search,"placeholder":"Buscar por nombre o ID de máquina","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c(VChip,{attrs:{"input-value":data.selected,"color":"primary","text-color":"white","close":""},on:{"click:close":function($event){return data.parent.selectItem(null)},"input":function($event){return data.parent.selectItem(null)}}},[_vm._v(" "+_vm._s(data.item.nombre)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,[(typeof item === 'object' && item !== null)?[_c(VListItemTitle,[_vm._v(_vm._s(item.nombre))])]:[_vm._v(" "+_vm._s(item)+" ")]],2)]}}],null,false,916874182),model:{value:(_vm.selectedMaquina),callback:function ($$v) {_vm.selectedMaquina=$$v},expression:"selectedMaquina"}})],1),(_vm.areasProduccion.length === 0)?_c('div',{staticStyle:{"width":"100%","display":"flex","justify-items":"center","margin-left":"auto","margin-right":"auto"}},[_c('div',{staticClass:"text-center d-flex flex-column align-center justify-center",staticStyle:{"width":"100%"}},[_c('div',[_c(VIcon,{staticStyle:{"font-size":"6.25rem"},attrs:{"color":"yellow darken-2"}},[_vm._v(" mdi-alert ")])],1),_c('p',{staticClass:"text-center text-h5"},[_vm._v(" No hay datos que mostrar, al parecer aún no se ha realizado ninguna simulación. ")])])]):_c('div',{staticClass:"d-flex overflow-x-auto overflow-y-auto",staticStyle:{"max-width":"100%"}},_vm._l((_vm.areasProduccion),function(area,idxArea){return _c('div',{key:("area-" + idxArea),staticClass:"pa-3 mr-5",style:(area.activo?'background-color: rgba(255, 0, 0, 0.5); border-radius: 0.5rem;' :'background-color: rgba(207, 216, 220, 0.5); border-radius: 0.5rem;')},[_c('div',{staticClass:"mb-3 d-flex align-center justify-space-between"},[_c('div',{staticStyle:{"width":"24px"}}),_c('p',{staticClass:"mb-0 text-center text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(area.nombre)+" ")]),(area.lineas.length > 1 && _vm.permisoVista('optimizacion','u'))?_c('div',[(!area.modoEditar)?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"disabled":area.disabled || !!area.savingOrden},on:{"click":function($event){return _vm.setModoEditarArea(area)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])]):_vm._e(),(!!area.modoEditar)?[(_vm.permisoVista('optimizacion','u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"disabled":!!area.savingOrden},on:{"click":function($event){return _vm.saveNuevoOrdenArea(area)}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-save ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Guardar")])]):_vm._e(),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"disabled":!!area.savingOrden},on:{"click":function($event){return _vm.cancelModoEditarArea(area)}}},'v-icon',attrs,false),on),[_vm._v("mdi-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Cancelar")])])]:_vm._e()],2):_c('div',{staticStyle:{"width":"24px"}})]),_c('div',{staticClass:"d-flex"},_vm._l((area.lineas),function(linea,idxLinea){return _c('div',{key:("linea-" + idxArea + "-" + idxLinea),staticClass:"py-3 rounded-lg column-width text-center",class:((linea.activo ? 'red lighten-2': linea.color) + " " + ((idxLinea < area.lineas.length - 1) && 'mr-3'))},[_c('div',{staticClass:"px-3"},[_c('p',{staticClass:"mb-2 white--text text-center text-h6 font-weight-medium",staticStyle:{"font-size":"1.125rem !important"}},[_vm._v(" "+_vm._s(linea.nombre)+" ")]),_c('div',{staticClass:"d-flex justify-space-between align-center pb-3",staticStyle:{"min-height":"2.25rem"}},[_c('div',{staticStyle:{"width":"24px"}}),(linea.maquinas != null && linea.maquinas.length > 0)?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-robot-industrial-outline ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(" Maquinas: "),_c('ul',_vm._l((linea.maquinas),function(m,idxM){return _c('li',{key:'m'+idxM},[_vm._v(_vm._s(m))])}),0)])]):_c('div',{staticStyle:{"width":"24px"}}),(linea.operaciones.length > 0 && _vm.permisoVista('optimizacion','u') && !area.modoEditar)?_c('div',[(!linea.modoEditar)?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white"},on:{"click":function($event){return _vm.setModoEditar(linea,area, _vm.fijo)}}},'v-icon',attrs,false),on),[_vm._v("mdi-sort ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Ordenar")])]):_vm._e(),(!!linea.modoEditar)?[(_vm.permisoVista('optimizacion','u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white","disabled":!!linea.savingOrden},on:{"click":function($event){return _vm.saveNuevoOrden(linea,area)}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-save ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Guardar")])]):_vm._e(),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white","disabled":!!linea.savingOrden},on:{"click":function($event){return _vm.cancelModoEditar(linea,area)}}},'v-icon',attrs,false),on),[_vm._v("mdi-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Cancelar")])])]:_vm._e()],2):_c('div',{staticStyle:{"width":"24px"}})],1),(!!linea.savingOrden)?_c(VCol,{staticClass:"pa-0 pb-2",attrs:{"cols":"12"}},[_c(VProgressLinear,{attrs:{"indeterminate":"","color":"white"}})],1):_vm._e()],1),_c('div',{staticClass:"px-3 linea-container"},[_c('draggable',{staticClass:"draggable-area",attrs:{"list":linea.operaciones,"animation":200,"ghost-class":"ghost-card","group":!!area.modoEditar && ("ordenes-" + (area.idArea)),"disabled":!linea.modoEditar || !!linea.savingOrden || linea.operaciones.every(function (op) { return op.fijo || op.operacionRealEnCurso; }),"filter":'[data-fijo=true], [data-operacion-en-curso=true]'},on:{"change":function($event){return _vm.onChangeDraggable($event,linea)}}},_vm._l((linea.operaciones),function(operacion){return _c('OrdenTrabajoCard',{key:operacion.idOperacion,staticClass:"mb-3",style:((operacion.operacionRealEnCurso || !linea.modoEditar || !!linea.savingOrden) ? 'cursor: default' : 'cursor: move'),attrs:{"operacion":operacion,"dependencias":_vm.dependencias,"fijo":operacion.fijo,"modoEdicion":(!linea.modoEditar || !!linea.savingOrden),"data-fijo":operacion.fijo,"data-operacion-en-curso":operacion.operacionRealEnCurso},on:{"bloquearEdicion":function($event){return _vm.escucharBloqueoCheckbox(area, operacion)},"change":_vm.setDependencias}})}),1)],1)])}),0)])}),0)])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }