import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('costos','r'))?_c('div',[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.dataCostos,"search":_vm.search,"loading":_vm.loadingTable},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c(VTextField,_vm._b({model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'v-text-field',_vm.propsSearch,false))],1)]},proxy:true},{key:"item.costo",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.costo)+" ")]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [(_vm.permisoVista('costos','u'))?_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.show_dialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])]):_vm._e()]}}],null,false,2749569534)}),_c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Editar Costo de \""+_vm._s(_vm.concepto)+"\"")]),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VTextField,_vm._b({attrs:{"label":"Costo","prefix":"$","loading":_vm.loadingDialog},model:{value:(_vm.costo),callback:function ($$v) {_vm.costo=$$v},expression:"costo"}},'v-text-field',_vm.propsText,false))],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"ml-auto",attrs:{"text":"","color":"blue darken-1","loading":_vm.loadingDialog},on:{"click":function($event){return _vm.hide_dialog()}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"text":"","color":"error","loading":_vm.loadingDialog},on:{"click":function($event){return _vm.validate()}}},[_vm._v("Actualizar")])],1)],1)],1),_c('AlertSnackbar',{ref:"AlertSnackbar"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }