<template>
  <div v-if="permisoVista('configuraciones','r')">
    <v-data-table :headers="headers" :search="search" :items="dataTiempos" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex align-center">
          <v-text-field v-model="search" v-bind="propsSearch" />
          <v-btn v-if="permisoVista('configuraciones','c')" color="primary" class="ml-3" @click="show_dialog()">Agregar Nuevo Tiempo</v-btn>
        </div>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip v-if="permisoVista('configuraciones','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>

        <v-tooltip v-if="permisoVista('configuraciones','d')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialogDelete(item)">mdi-trash-can</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title>{{ optionDialog }} Configuración de Tiempo</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="6">
                <v-autocomplete v-model="idProceso" :items="dataProcesos" item-text="nombre" item-value="idProceso"
                  label="Proceso" v-bind="propsAutocomplete" :loading="loadingDialog" />
              </v-col>
              <v-col cols="6">
                <v-autocomplete v-model="idSubproceso" :items="dataSubprocesos" item-text="nombre" item-value="idProceso"
                  label="Subproceso" v-bind="propsAutocomplete" :loading="loadingDialog" />
              </v-col>
              <v-col cols="12">
                <v-autocomplete v-model="idMaquina" :items="dataMaquinas" item-text="marca" item-value="idMaquina"
                  label="Máquina" v-bind="propsAutocomplete" :loading="loadingDialog" />
              </v-col>
              <v-card-text align="center">Tiempos de 4 Deltas - Sencillos</v-card-text>
              <v-col cols="6">
                <v-text-field v-model="tiempoMillarDeltaSencillo" label="4 Delta" suffix="%" v-bind="propsDecimales0"
                  @change="" :loading="loadingDialog" />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="piezasHoraDeltaSencillo" label="Velocidad Delta 4" suffix="Pzs/Hra"
                  v-bind="propsEnteros0" @change="calcularPorcentajeSencillo()" :loading="loadingDialog" />
              </v-col>
              <v-card-text align="center">Tiempos de 2 Deltas - Complejo</v-card-text>
              <v-col cols="6">
                <v-text-field v-model="tiempoMillarDeltaComplejo" label="2 Delta" suffix="%" v-bind="propsDecimales0"
                  :loading="loadingDialog" />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="piezasHoraDeltaComplejo" label="Velocidad Delta 2" suffix="Pzs/Hra"
                  v-bind="propsEnteros0" @change="calcularPorcentajeComplejo()" :loading="loadingDialog" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn class="ml-auto" color="blue darken-1" text @click="hide_dialog()" :loading="loadingDialog">Cancelar</v-btn>
          <v-btn color="error" text @click="validate()" :loading="loadingDialog">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500" persistent>
      <v-card>
        <v-card-title>Eliminar Tiempos</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "{{ nombreMaquina }}" Del Proceso "{{ nombreProceso
        }}"?</v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialogDelete" color="blue darken-1" class="ml-auto" text
            @click="hide_dialogDelete()">Cancelar</v-btn>
          <v-btn :loading="loadingDialogDelete" color="error" text @click="deleteTiempo()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>

<script>
import axios from 'axios'
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {
    AlertSnackbar,
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
      },
      propsAutocomplete: {
        outlined: false,
        dense: true,
        required: true,
        rules: [
          (v) => {
            if (v === null) {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },
      propsEnteros0: {
        outlined: false,
        dense: true,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null) {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (!Number.isInteger(Number(v))) {
              return "Ingrese solo números enteros";
            } else if (Number(v) < 0) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },
      propsDecimales0: {
        outlined: false,
        dense: true,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null) {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) < 0) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },

      headers: [
        {
          text: "Proceso",
          value: "proceso",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "Subproceso",
          value: "subproceso",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "Máquina",
          value: "maquina",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "4 Delta - Sencillo",
          value: "sencillo",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "Velocidad Delta 4",
          value: "sencilloVelocidad",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "2 Delta - Complejo",
          value: "complejo",
          align: "center",
          sortable: false,
          visible: true,
        }, {
          text: "Velocidad Delta 2",
          value: "complejoVelocidad",
          align: "center",
          sortable: false,
          visible: true,
        },
        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
          visible: true,
        },
      ],

      dataProcesos: [],
      dataSubprocesos: [],
      dataTiempos: [],
      dataMaquinas: [],

      search: '',
      dialog: false,
      optionDialog: '',
      dialogDelete: false,

      idMaquinaTiempos: null,
      idProceso: null,
      idSubproceso: null,
      nombreProceso: null,
      nombreSubproceso: null,
      idMaquina: null,
      nombreMaquina: null,
      tiempoMillarDeltaSencillo: null,
      piezasHoraDeltaSencillo: null,
      tiempoMillarDeltaComplejo: null,
      piezasHoraDeltaComplejo: null,


      loadingTable: false,
      loadingDialog: false,
      loadingDialogDelete: false,
    }
  },
  methods: {
    initialize() {
      this.getProcesos()
      this.getSubprocesos()
      this.getMaquinas();
      this.getTiempos();
    },

    validate() {
      if (this.$refs.form.validate()) this.optionDialog == 'Crear' ? this.postTiempo() : this.putTiempo()
    },

    getProcesos() {
      axios
        .get("/GKProceso/Listar/false")
        .then((response) => {
          this.dataProcesos = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getSubprocesos() {
      axios
        .get("/GKProceso/Listar/true")
        .then((response) => {
          this.dataSubprocesos = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getMaquinas() {
      axios
        .get("/Maquinas")
        .then((response) => {
          this.dataMaquinas = response.data
        })
        .catch((error) => {
          console.log(error)
        })

    },
    getTiempos() {
      this.loadingTable = true;
      axios
        .get("/MaquinaTiempos/Listar")
        .then((response) => {
          this.dataTiempos = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingTable = false;
        })
    },

    postTiempo() {
      this.loadingDialog = true;
      axios
        .post("/MaquinaTiempos/Crear", {
          idMaquinaTiempos: parseFloat(this.idMaquina),
          idProceso: parseFloat(this.idProceso),
          idSubproceso: parseFloat(this.idSubproceso),
          proceso: "",
          subproceso: "",
          idMaquina: parseFloat(this.idMaquina),
          maquina: "",
          sencillo: parseFloat(this.tiempoMillarDeltaSencillo),
          sencilloVelocidad: parseFloat(this.piezasHoraDeltaSencillo),
          complejo: parseFloat(this.tiempoMillarDeltaComplejo),
          complejoVelocidad: parseFloat(this.piezasHoraDeltaComplejo)
        })
        .then((response) => {
          //this.alerta(200);
          this.getTiempos();
          this.hide_dialog();
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putTiempo() {
      this.loadingDialog = true;
      axios
        .put("/MaquinaTiempos/Actualizar/" + this.idMaquinaTiempos, {
          idProceso: parseFloat(this.idProceso),
          idSubproceso: parseFloat(this.idSubproceso),
          idMaquina: parseFloat(this.idMaquina),
          sencillo: parseFloat(this.tiempoMillarDeltaSencillo),
          sencilloVelocidad: parseFloat(this.piezasHoraDeltaSencillo),
          complejo: parseFloat(this.tiempoMillarDeltaComplejo),
          complejoVelocidad: parseFloat(this.piezasHoraDeltaComplejo)
        })
        .then((response) => {
          //this.alerta(200);
          this.getTiempos();
          this.hide_dialog();
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteTiempo() {
      this.loadingDialogDelete = true;
      axios
        .put("/MaquinaTiempos/Desactivar/" + this.idMaquinaTiempos)
        .then((response) => {
          //this.alerta(200);
          this.hide_dialogDelete();
          this.getTiempos();
        })
        .catch((error) => {
          console.log(error);
          //this.alerta(error.response.status);
        })
        .finally(() => {
          this.loadingDialogDelete = false;
        })
    },

    calcularPorcentajeSencillo() {
      if (this.piezasHoraDeltaSencillo > 0) {
        this.tiempoMillarDeltaSencillo = (1000 / this.piezasHoraDeltaSencillo).toFixed(2)
      } else {
        this.tiempoMillarDeltaSencillo = 0
      }
    },
    calcularPorcentajeComplejo() {
      if (this.piezasHoraDeltaComplejo > 0) {
        this.tiempoMillarDeltaComplejo = (1000 / this.piezasHoraDeltaComplejo).toFixed(2)
      } else {
        this.tiempoMillarDeltaComplejo = 0;
      }
    },

    show_dialog(item) {
      this.optionDialog = item ? "Editar" : "Crear";
      if (item) {
        this.idMaquinaTiempos = item.idMaquinaTiempos;
        this.idProceso = item.idProceso;
        this.idSubproceso = item.idSubproceso;
        this.idMaquina = item.idMaquina;
        this.tiempoMillarDeltaSencillo = item.sencillo;
        this.piezasHoraDeltaSencillo = item.sencilloVelocidad;
        this.tiempoMillarDeltaComplejo = item.complejo;
        this.piezasHoraDeltaComplejo = item.complejoVelocidad;
      }
      this.dialog = true;
    },
    hide_dialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },

    show_dialogDelete(item) {
      this.idMaquinaTiempos = item.idMaquinaTiempos;
      this.idMaquina = item.idMaquina;
      this.nombreMaquina = item.maquina;
      this.idProceso = item.idProceso;
      this.idSubproceso = item.idSubproceso;
      this.nombreProceso = item.proceso;
      this.nombreSubproceso = item.subproceso;
      this.dialogDelete = true;
    },
    hide_dialogDelete() {
      this.idMaquinaTiempos = null;
      this.idMaquina = null;
      this.idProceso = null;
      this.idSubproceso = null;
      this.nombreProceso = null;
      this.nombreSubproceso = null;
      this.nombreMaquina = null;
      this.$refs.form.reset();
      this.dialogDelete = false;
    },

    alerta(code, color, mensaje, icono) {
      if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
      } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
      }
    },
  },
  mounted() {
    this.initialize()
  },
  computed: {
    calcularHorasSencillo() {
      let PiezasPorHora = this.piezasHoraDeltaSencillo //Piezas que se hacen en una hora
      let MillaresPorHora = this.piezasHoraDeltaSencillo / 1000 //Millares que se hacen en una hora
      //console.log("Piezas que se hacen por hora: " + PiezasPorHora)
      //console.log("Millares que se hacen por hora: " + MillaresPorHora)
      //console.log("Minutos que toma hacer un millar: " + (60 / MillaresPorHora).toFixed(2))

      if (this.piezasHoraDeltaSencillo > 0) {
        this.tiempoMillarDeltaSencillo = (1000 / this.piezasHoraDeltaSencillo).toFixed(2)
      }
      return 0
    },
  },
  watch: {

  }
}
</script>