<template>
  <div v-if="permisoVista('insumos','r')">
    <v-btn v-if="permisoVista('insumos','c')" color="primary" class="ml-3" @click="show_dialog()">Agregar Insumo</v-btn>
    <v-data-table :headers="headers" :items="dataInsumos" :search="search" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex align-center">
          <v-text-field v-model="search" @keyup.enter="" @click:append="" @click:clear="" v-bind="propsSearch" />
        </div>
      </template>

      <template v-slot:item.tipoInsumo="{ item }">
        {{ dataTipoInsumo.filter(datos => datos.id === item.tipoInsumo)[0].value }}
      </template>

      <template v-slot:item.acciones="{ item }">
        <v-tooltip v-if="permisoVista('insumos','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small @click="show_dialog(item)"> mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip v-if="permisoVista('insumos','d')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="ml-1"
              @click="show_dialogDelete(item)">mdi-trash-can-outline</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title>{{ optionDialog }} Insumo</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="idMaquina" :items="dataMaquinas" :item-text="nombreMaquina"
                  item-value="idMaquina" label="Máquina" v-bind="propsAutocomplete" :loading="loadingDialog" />
                <v-text-field v-model="insumo" label="Insumo" v-bind="propsText" :loading="loadingDialog" />
                <v-autocomplete v-model="tipoInsumo" :items="dataTipoInsumo" item-text="value" item-value="id"
                  label="Tipo de Insumo" v-bind="propsAutocomplete" :loading="loadingDialog" />
                <v-text-field v-model="costo" label="Costo" v-bind="propsDecimales" prefix="$" :loading="loadingDialog" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn class="ml-auto" text color="blue darken-1" @click="hide_dialog()" :loading="loadingDialog">Cancelar</v-btn>
          <v-btn text color="error" @click="validate()" :loading="loadingDialog">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
      <v-card>
        <v-card-title>Eliminar Insumo</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "{{ insumo }}"?</v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialogDelet" color="blue darken-1" class="ml-auto" @click="hide_dialogDelete()"
            text>Cancelar</v-btn>
          <v-btn :loading="loadingDialogDelet" color="error" @click="deleteInsumo()" text>Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>
    
<script>
import axios from 'axios';
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {

  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
      },
      propsText: {
        outlined: false,
        dense: true,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },
      propsDecimales: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) < 0) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },
      propsAutocomplete: {
        outlined: false,
        dense: false,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },

      search: '',

      headers: [
        {
          text: "Insumo",
          value: "insumo",
          align: "center",
          sortable: false,
        }, {
          text: "Tipo de Insumo",
          value: "tipoInsumo",
          align: "center",
          sortable: false,
        }, {
          text: "Costo",
          value: "costo",
          align: "center",
          sortable: false,
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        }
      ],

      dataInsumos: [],
      dataMaquinas: [],
      dataTipoInsumo: [
        {
          id: 1,
          value: "Fijos",
        }, {
          id: 2,
          value: "Por M2",
        }, {
          id: 3,
          value: "Por Tiempo",
        },{
          id: 4,
          value: "Por Pieza",
        }
      ],

      idMaquinaInsumos: null,
      idMaquina: null,
      insumo: null,
      tipoInsumo: null,
      costo: null,

      optionDialog: "",

      dialog: false,
      dialogDelete: false,

      loadingTable: false,
      loadingDialog: false,
      loadingDialogDelet: false,
    }
  },
  methods: {
    nombreMaquina: (item) => `[${item.modelo}] - ${item.marca}`,

    validate() {
      if (this.$refs.form.validate()) this.optionDialog == 'Crear' ? this.postInsumos() : this.putInsumo()
    },

    initialize() {
      this.getInsumos();
      this.getMaquinas()
    },

    getInsumos() {
      this.loadingTable = true;
      axios
        .get("/GKMaquinaInsumos/Listar")
        .then((response) => {
          this.dataInsumos = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    getMaquinas() {
      axios
        .get("/Maquinas")
        .then((response) => {
          this.dataMaquinas = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
        })
    },
    postInsumos() {
      this.loadingDialog = true;
      axios
        .post("/GKMaquinaInsumos/Crear", {
          idMaquina: this.idMaquina,
          insumo: this.insumo,
          tipoInsumo: this.tipoInsumo,
          costo: this.costo
        })
        .then((response) => {
          //this.alerta(200);
          this.getInsumos();
          this.hide_dialog();
        })
        .catch((error) => {
          console.log(error)
          //this.alerta(error.response.status);
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putInsumo() {
      this.loadingDialog = true;
      axios
        .put("/GKMaquinaInsumos/Actualizar/" + Number(this.idMaquinaInsumos), {
          idMaquina: this.idMaquina,
          insumo: this.insumo,
          tipoInsumo: this.tipoInsumo,
          costo: this.costo
        })
        .then((response) => {
          //this.alerta(200);
          this.getInsumos();
          this.hide_dialog();
        })
        .catch((error) => {
          console.log(error)
          //this.alerta(error.response.status);
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteInsumo() {
      this.loadingDialogDelet = true;
      axios
        .put("/GKMaquinaInsumos/Desactivar/" + Number(this.idMaquinaInsumos))
        .then((response) => {
          //this.alerta(200);
          this.getInsumos();
          this.hide_dialogDelete();
          this.dialo = false;
        })
        .catch((error) => {
          console.log(error)
          //this.alerta(error.response.status);
        })
        .finally(() => {
          this.loadingDialogDelet = false;
        })
    },

    show_dialog(item) {
      this.optionDialog = item ? "Editar" : "Crear";
      this.dialog = true;

      if (item) {
        this.idMaquinaInsumos = item.idMaquinaInsumos;
        this.idMaquina = item.idMaquina;
        this.insumo = item.insumo;
        this.tipoInsumo = item.tipoInsumo;
        this.costo = item.costo;
      }
    },
    hide_dialog() {
      this.dialog = false;
      this.$refs.form.reset();
      this.idMaquinaInsumos = null;
      this.idMaquina = null;
      this.insumo = null;
      this.tipoInsumo = null;
      this.costo = null;
    },

    show_dialogDelete(item) {
      this.idMaquinaInsumos = item.idMaquinaInsumos;
      this.insumo = item.insumo;
      this.dialogDelete = true;
    },
    hide_dialogDelete() {
      this.idMaquinaInsumos = null;
      this.insumo = null;
      this.$refs.form.reset();
      this.dialogDelete = false;
    },

    alerta(code, color, mensaje, icono) {
      if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
      } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
      }
    },
  },
  mounted() {
    this.initialize();
  }
}
</script>
<style></style>