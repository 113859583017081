<template>
  <!-- Declaracion del pie de pagina -->
  <v-footer
    id="core-footer"
    height="60"
    absolute
    color="transparent"
  >
  <!-- Declaracion de los items del pie de pagina -->
    <div class="footer-items">
      <span
        v-for="link in links"
        :key="link.name"
      >
        <a
          :href="link.Link"
          class="tertiary--text footer-links">{{ link.name }}</a>
      </span>
    </div>
    <v-spacer/>
    <!-- Declaracion del copyright y del boton flotante -->
    <span class="font-weight-light copyright" style="padding-right: 1rem">
      &copy;
      {{ (new Date()).getFullYear() }}
      <a
        class="text-decoration-none green--text"
        href="http://www.managrx.com/"
        target="_blank">ManagrX</a>
    </span>
    <!-- Mandamos a llamar al modulo que contiene el diseño del chat flotante desplegable  -->
    <floatChat />
  </v-footer>
</template>

<script>
 //Importamos el modulo que vayamos a utilizar para el pie de pagina  -->
import floatChat from "./FloatChat.vue";
export default {
  data: () => ({
    links: [
      // { name: 'Home', Link: '/dashboard/reportes' },
    ]
  }),
  components:{
    floatChat, //Se agrega el modulo importado para poder hacer uso de el
    // Chat
  }
}
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
