<template>
  <v-row justify-sm="center" v-if="nombreEmpresa === 'Geometrika' ">
    <v-card class="mt-4" :width="anchoMaximo" fill>
      <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <!--<v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.Avisos" href="#tab-1" @click="initAvisos()">
          Avisos De Configuración
          <v-icon>mdi mdi-alert</v-icon>
        </v-tab>-->

        <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.precotizacion.PreCotizacion" href="#tab-2"
          @click="initPreCotizacion()">
          Cotizaciones
          <v-icon>mdi-file-document-edit-outline</v-icon>
        </v-tab>

        <v-menu bottom open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-tab v-tooltip.bottom="modoAyuda" v-bind="attrs" v-on="on" href="#tab-3"
              :class="{ 'my-selected-insumos': tab === 'tab-3' || tab === 'tab-4' || tab === 'tab-5' || tab === 'tab-6' || tab === 'tab-8' }">
              Insumos
              <v-icon>mdi mdi-package-variant-closed</v-icon>
            </v-tab>
          </template>

          <v-list>
            <v-list-item>
              <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.insumos.papeles" href="#tab-3" @click="initPapeles();">
                Papeles
                <v-icon>mdi-paper-roll-outline</v-icon>
              </v-tab>
            </v-list-item>
            <v-list-item>
              <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.insumos.tintas" href="#tab-4">
                Tintas
                <v-icon>mdi mdi-format-color-fill</v-icon>
              </v-tab>
            </v-list-item>
            <v-list-item>
              <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.insumos.barnices" href="#tab-5">
                Barniz
                <v-icon>mdi mdi-spray</v-icon>
              </v-tab>
            </v-list-item>
            <v-list-item>
              <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.insumos.vasos" href="#tab-6" @click="initVasos()">
                Vasos
                <v-icon>mdi-cup</v-icon>
              </v-tab>
            </v-list-item>
            <v-list-item>
              <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.insumos.insumosMaquinas" href="#tab-7"
                @click="initInsumos()">
                Insumos de máquinas
                <v-icon>mdi mdi-screw-machine-round-top</v-icon>
              </v-tab>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu bottom right open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-tab v-tooltip.bottom="modoAyuda" v-bind="attrs" v-on="on" href="#tab-8"
              :class="{ 'my-selected-configuraciones': tab === 'tab-8' || tab === 'tab-9' || tab === 'tab-10' || tab === 'tab-11' || tab === 'tab-12' }">
              Configuraciones
              <v-icon>mdi mdi-cog</v-icon>
            </v-tab>
          </template>

          <v-list>
            <v-list-item>
              <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.configuraciones.transporte" href="#tab-8"
                @click="initTransporte()">
                Transporte
                <v-icon> mdi mdi-truck-delivery</v-icon>
              </v-tab>
            </v-list-item>
            <v-list-item>
              <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.configuraciones.frecuencias" href="#tab-9"
                @click="initFrecuencia()">
                Frecuencias
                <v-icon>mdi mdi-clipboard-text-clock</v-icon>
              </v-tab>
            </v-list-item>
            <v-list-item>
              <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.configuraciones.mermas" href="#tab-10"
                @click="initMermas()">
                Mermas
                <v-icon>mdi-delete-variant</v-icon>
              </v-tab>
            </v-list-item>
            <v-list-item>
              <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.configuraciones.tiempos" href="#tab-11"
                @click="initTiempos()">
                Tiempos
                <v-icon>mdi-clock-time-eight</v-icon>
              </v-tab>
            </v-list-item>
            <v-list-item>
              <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.configuraciones.maquinas" href="#tab-12" @click="initMaquinas()">
                Maquinas
                <v-icon>mdi-robot-industrial</v-icon>
              </v-tab>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.costos" href="#tab-13" @click="initCostos()">
          Costos
          <v-icon>mdi-currency-usd</v-icon>
        </v-tab>

        <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.secados" href="#tab-14" @click="initSecados()">
          Secados
          <v-icon>mdi-ceiling-light</v-icon>
        </v-tab>

        <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.ordenes" href="#tab-15" @click="initOrdenes()">
          Ordenes
          <v-icon>mdi-order-bool-ascending-variant</v-icon>
        </v-tab>

        <v-tab v-tooltip.bottom="modoAyuda && $help.cotizacion.remisiones" href="#tab-16" @click="initRemisiones()">
          Remisiones
          <v-icon>mdi-wallet</v-icon>
        </v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item :key="1" :value="'tab-1'">
            <v-card>
              <Avisos ref="Avisos" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'">
            <v-card flat>
              <PreCotizacion ref="PreCotizacion" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" :value="'tab-3'">
            <v-card>
              <Papeles ref="Papeles" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="'tab-4'">
            <v-card>
              <Tintas />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5" :value="'tab-5'">
            <v-card>
              <Barniz ref="Barniz" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="6" :value="'tab-6'">
            <v-card>
              <Vasos ref="Vasos" class="my-tab"/>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="7" :value="'tab-7'">
            <v-card>
              <InsumosMaquinas ref="InsumosMaquinas" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="8" :value="'tab-8'">
            <v-card>
              <Transporte ref="Transporte" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="9" :value="'tab-9'">
            <v-card>
              <Frecuencia ref="Frecuencia" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="10" :value="'tab-10'">
            <v-card>
              <Mermas ref="Mermas" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="11" :value="'tab-11'">
            <v-card>
              <Tiempos ref="Tiempos" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="12" :value="'tab-12'">
            <v-card>
              <Maquinas ref="Maquinas" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="13" :value="'tab-13'">
            <v-card>
              <Costos ref="Costos" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="14" :value="'tab-14'">
            <v-card>
              <Secados ref="Secados" class="my-tab" />
            </v-card>
          </v-tab-item>

          <v-tab-item :key="15" :value="'tab-15'">
            <v-card>
              <Ordenes ref="Ordenes" class="my-tab" />
            </v-card>
          </v-tab-item>
          <v-tab-item :key="16" :value="'tab-16'">
            <v-card>
              <Remisiones ref="Remisiones" class="my-tab" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import PreCotizacion from './Cotizacion/PreCotizacion.vue'
import Papeles from './Cotizacion/Papeles.vue'
import Tintas from './Cotizacion/Tintas.vue'
import Barniz from './Cotizacion/Barniz.vue'
import Vasos from './Cotizacion/Vasos.vue'
import InsumosMaquinas from './Cotizacion/Insumos/InsumosMaquinas.vue'
import Transporte from './Cotizacion/Transporte.vue'
import Frecuencia from './Cotizacion/Frecuencia.vue'
import Mermas from './Cotizacion/Procesos/Mermas.vue'
import Tiempos from './Cotizacion/Procesos/Tiempos.vue';
import Procesos from './Cotizacion/Procesos.vue'
import Maquinas from './Cotizacion/Maquinas.vue'
import Avisos from './Cotizacion/Avisos.vue'
import Costos from './Cotizacion/Costos.vue'
import Secados from './Cotizacion/Secados.vue';
import Ordenes from './Cotizacion/Ordenes/Ordenes.vue'
import Remisiones from './Cotizacion/Remision/Remisiones.vue';

export default {
  data() {
    return {
      tab: null,
    }
  },
  methods: {
    // initAvisos() {
    //   this.$refs.Avisos?.initialize();
    // },
    initPreCotizacion() {
      this.$refs.PreCotizacion?.initialize();
    },
    initPapeles() {
      this.$refs.Papeles?.initialize();
    },
    initVasos() {
      this.$refs.Vasos?.initialize();
    },
    initInsumos() {
      this.$refs.InsumosMaquinas?.initialize();
    },
    initTransporte() {
      this.$refs.Transporte?.initialize();
    },
    initFrecuencia() {
      this.$refs.Frecuencia?.initialize();
    },
    initMermas(){
      this.$refs.Mermas?.initialize();
    },
    initTiempos(){
      this.$refs.Tiempos?.initialize();
    },
    initMaquinas() {
      this.$refs.Maquinas?.initialize();
    },
    initCostos() {
      this.$refs.Costos?.initialize();
    },
    initSecados() {
      this.$refs.Secados?.initialize();
    },
    initOrdenes(){
      this.$refs.Ordenes?.initialize();
    },
    initRemisiones() {
      this.$refs.Remisiones?.initialize();
    }
  },
  computed: {
    ...mapState({
        nombreEmpresa :  "nombreEmpresa",
        anchoMaximo : "anchoMaximo",
        colorTabs : "colorTabs",
        modoAyuda: "modoAyuda"
      }),
  },
  components: {
    PreCotizacion,
    Papeles,
    Tintas,
    Barniz,
    Vasos,
    InsumosMaquinas,
    Transporte,
    Frecuencia,
    Mermas,
    Tiempos,
    Procesos,
    Maquinas,
    Avisos,
    Costos,
    Secados,
    Ordenes,
    Remisiones
  },
};
</script>

<style scoped>
.my-tab {
  padding: 15px 20px 0 20px;
}

.v-tabs .v-tabs-slider {
  display: none;
}

.my-selected-insumos {
  color: white !important;
}

.my-selected-insumos i {
  color: white !important;
}

.my-selected-configuraciones {
  color: white !important;
}

.my-selected-configuraciones i {
  color: white !important;
}
</style>