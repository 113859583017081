<template>
  <v-col cols="12">
    <alert-dialog @close="logout" ref="alerta"> </alert-dialog>
    <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
      <v-card>
        <v-card-title class="headline"
          >¿Está seguro que desea descontinuar este Artículo?</v-card-title
        >
        <v-card-text
          >Esta acción no se puede revertir y será permanente.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              eliminar = false;
              delItem = '';
            "
            >Cancelar</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="
              eliminar = false;
              deleteItemDB();
            "
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="avanzada"
      persistent
      max-width="500px"
      :key="avanzada"
      content-class="dialog-busqueda"
      @keydown="dialog_busqueda_keyevent_action"
    >
      <v-card class="card-busqueda">
        <v-card-title>
          <span class="headline">Búsqueda Avanzada</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col justify="center" cols="11" sm="11" md="11">
              <v-autocomplete
                v-model="busqueda.categorias"
                :items="categorias"
                label="Categoría"
                item-text="nombre"
                item-value="idArticuloCategoria"
                placeholder="Todos"
                multiple
                chips
                small-chips
                dense
                clearable
                deletable-chips
                hide-details
                :disabled="loading"
              ></v-autocomplete>
            </v-col>
            <v-icon
              class="mr-2"
              :disabled="loadingCategoria"
              @click="getCategorias()"
              >mdi-reload</v-icon
            >
          </v-row>
          <v-row>
            <v-col justify="center" cols="11" sm="11" md="11">
              <v-autocomplete
                v-model="busqueda.tipos"
                :items="tipos"
                label="Tipo"
                item-text="nombre"
                item-value="idArticuloTipo"
                placeholder="Todos"
                multiple
                chipsey
                small-chips
                dense
                clearable
                deletable-chips
                hide-details
                :disabled="loading"
              ></v-autocomplete>
            </v-col>
            <v-icon class="mr-2" :disabled="loadingTipo" @click="getTipos()"
              >mdi-reload</v-icon
            >
          </v-row>
          <v-row>
            <v-col justify="center" cols="11" sm="11" md="11">
              <v-autocomplete
                v-model="busqueda.clases"
                :items="clases"
                label="Clase"
                item-text="clase"
                item-value="idArticuloClase"
                placeholder="Todos"
                multiple
                small-chips
                dense
                clearable
                deletable-chips
                hide-details
                :disabled="loading"
              ></v-autocomplete>
            </v-col>
            <v-icon class="mr-2" :disabled="loadingClases" @click="getClases()"
              >mdi-reload</v-icon
            >
          </v-row>
          <v-row>
            <v-col justify="center" cols="11" sm="11" md="11">
              <v-autocomplete
                v-model="busqueda.unidades"
                :items="unidades"
                label="Unidad"
                item-text="nombre"
                item-value="idUnidad"
                placeholder="Todos"
                multiple
                chips
                small-chips
                dense
                clearable
                deletable-chips
                hide-details
                :disabled="loading"
              ></v-autocomplete>
            </v-col>
            <v-icon
              class="mr-2"
              :disabled="loadingUnidad"
              @click="getUnidades()"
              >mdi-reload</v-icon
            >
          </v-row>
          <v-row>
            <v-col justify="center" cols="11" sm="11" md="11">
              <v-autocomplete
                v-model="busqueda.almacenes"
                :items="almacenes"
                label="Almacén"
                item-text="nombre"
                item-value="idAlmacen"
                placeholder="Todos"
                multiple
                chips
                small-chips
                dense
                clearable
                deletable-chips
                hide-details
                :disabled="loading"
              ></v-autocomplete>
            </v-col>
            <v-icon
              class="mr-2"
              :disabled="loadingAlmacen"
              @click="getAlmacenes()"
              >mdi-reload</v-icon
            >
          </v-row>
          <v-row> <!--Para el filtro de subalmacenes-->
            <v-col justify="center" cols="11" sm="11" md="11">
              <v-autocomplete
                v-model="busqueda.subalmacenes"
                :items="subalmacenes"
                label="Subalmacén"
                item-text="subalmacen"
                item-value="idSubalmacen"
                placeholder="Todos"
                multiple
                chips
                small-chips
                dense
                clearable
                deletable-chips
                hide-details
                :disabled="loading"
              ></v-autocomplete>
            </v-col>
            <v-icon
              class="mr-2"
              :disabled="loadingSubalmacen"
              @click="getSubalmacenes()"
              >mdi-reload</v-icon
            >
          </v-row> <!--Fin de lo agregado para subalmacenes-->
          <v-row>
            <v-col justify="center" cols="11" sm="11" md="11">
              <v-autocomplete
                v-model="busqueda.estados"
                :items="estados"
                label="Estado"
                item-text="name"
                item-value="id"
                placeholder="Todos"
                multiple
                chips
                small-chips
                dense
                clearable
                deletable-chips
                hide-details
                :disabled="loading"
              ></v-autocomplete>
            </v-col>
            <v-icon class="mr-2" @click="getUnidades()">mdi-reload</v-icon>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="card-actions-busqueda d-flex justify-space-between"
          style="width: 93%"
        >
          <section>
            <v-btn
              color="primary"
              text
              @click="getArticulosAction"
              :disabled="loading"
              >Buscar</v-btn
            >
            <v-btn
              color="green darken-1"
              text
              @click="resetBusqueda()"
              :disabled="loading"
              >Restablecer</v-btn
            >
          </section>
          <v-btn color="blue darken-1" text @click="avanzada = false"
            >Salir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      v-if="permisoVista('articulos', 'r')"
      :key="`dtt-${dtabkey}`"
      :headers="headers"
      :items="articulos"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
      :server-items-length="articulos_total"
      :items-per-page="articulos_items_per_page"
      :page="articulos_actual_page"
      @update:page="articulos_update_page_action"
      @update:items-per-page="(ipp) => (articulos_items_per_page = ipp)"
      @update:sort-by="articulos_sort_by_action"
      :footer-props="{
        disableItemsPerPage: true,
        disablePagination: this.loading,
      }"
      :disable-sort="true"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <section class="d-flex flex-column pt-3" style="width: 100%">
            <div class="d-flex align-center">
              <v-dialog
                v-model="dialog"
                persistent
                max-width="500px"
                :key="dialog"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-on="on"
                    v-if="permisoVista('articulos', 'c')"
                    >Nuevo Artículo</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-container>
                        <v-row>
                          <v-col justify="center" cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="editedItem.nombre"
                              label="Nombre del Artículo"
                            ></v-text-field>
                          </v-col>
                          <v-col justify="center" cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="editedItem.codigo"
                              :counter="30"
                              :rules="codeRules"
                              label="Código del Artículo"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <p style="font-size: 16px !important">
                              Salida Automática
                            </p>
                            <v-checkbox
                              v-model="editedItem.salidaAutomatica"
                              color="success"
                              class="mt-0"
                              hide-details
                            >
                              <template v-slot:label>
                                <p class="mb-0 text--primary">
                                  {{
                                    editedItem.salidaAutomatica
                                      ? "Activada"
                                      : "Desactivada"
                                  }}
                                </p>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col justify="center" cols="11" sm="11" md="11">
                            <v-autocomplete
                              v-model="editedItem.idUnidad"
                              :items="unidades"
                              label="Unidad"
                              item-text="nombre"
                              item-value="idUnidad"
                            ></v-autocomplete>
                          </v-col>
                          <v-icon
                            class="mr-2"
                            :disabled="loadingCategoria"
                            @click="getUnidades()"
                            >mdi-reload</v-icon
                          >
                          <v-col cols="12">
                            <VSelectTags
                              v-model="editedItem.tags"
                              ref="selecTags"
                              :key="chkey"
                              :items="tags"
                              itemText="tag"
                              itemValue="idTag"
                              placeholder="Tags"
                            />
                          </v-col>
                          <v-col justify="center" cols="12" sm="12" md="12">
                            <v-file-input
                              v-model="editedItem.imagen"
                              accept="image/png, image/jpeg, image/jpg"
                              label="Imagen (Opcional)"
                            ></v-file-input>
                          </v-col>
                        </v-row>
                        <ul>
                          <li class="red--text" v-for="ex in errores" :key="ex">
                            {{ ex }}
                          </li>
                        </ul>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="
                        dialog = false;
                        errores = [];
                      "
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="red darken-1"
                      text
                      @click="save()"
                      :disabled="saving"
                      :loading="saving"
                    >
                      Guardar
                      <template v-slot:loader>
                        <v-progress-circular
                          indeterminate
                          :width="2"
                          :size="24"
                          color="red"
                        ></v-progress-circular>
                      </template>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Buscar por código o artículo"
                single-line
                hide-details
                @keyup="getArticulosOnEnterAction"
                @click:clear="clearSearchAction"
                @click:append="getArticulosAction"
                :clearable="true"
                :disabled="loading"
              ></v-text-field>
            </div>
            <div class="d-flex align-center">
              <section style="width: calc(100% - 200px)">
                <v-chip-group v-if="filtrosAplicados.length">
                  <v-chip
                    v-for="(fa, fa_indx) in filtrosAplicados"
                    :key="fa_indx"
                  >
                    <span class="overline chip-filtro">({{ fa.filtro }})</span>
                    {{ fa.multiple ? fa.valores : fa.valor }}
                  </v-chip>
                </v-chip-group>
                <v-chip v-else class="overline" :disabled="true"
                  >Sin filtros</v-chip
                >
              </section>
              <v-btn
                small
                text
                color="primary"
                @click="avanzada = true"
                style="width: 200px"
              >
                <v-icon>mdi-target</v-icon>&nbsp; Búsqueda Avanzada
              </v-btn>
            </div>
          </section>
        </v-toolbar>
      </template>
      <template v-slot:item.imagen="{ item }">
        <div v-if="item.imagen != null" >
          <TooltipThumbnail
          :lazy="false"
          :lazyUrl="''"
          :url="item.imagen"
        ></TooltipThumbnail>
        </div>        
      </template>
      <template v-slot:item.articuloClase="{ item }">
        {{ item.articuloClase ? item.articuloClase : "N/D" }}
      </template>
      <template v-slot:item.minimo="{ item }">
        {{ item.minimo != null ? item.minimo.toFixed(4) : "N/D" }}
      </template>
      <template v-slot:item.maximo="{ item }">
        {{ item.maximo != null ? item.maximo.toFixed(4) : "N/D" }}
      </template>
      <template v-slot:item.puntoReorden="{ item }">
        {{ item.puntoReorden != null ? item.puntoReorden.toFixed(4) : "N/D" }}
      </template>
      <template v-slot:item.cantidad="{ item }">
        <!-- {{ item.cantidad.toFixed(4) }} -->
        {{ (Number(10000).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
      </template>
      <template v-slot:item.costo="{ item }">
        <div v-if="item.costo.min == item.costo.max">
          ${{ item.costo.min.toFixed(2) }}
        </div>
        <div v-else>
          ${{ item.costo.min.toFixed(2) }} - ${{ item.costo.max.toFixed(2) }}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="permisoVista('articulos', 'u')"
              small
              class="mr-2"
              @click="editItem(item)"
              >edit</v-icon
            >
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="permisoVista('articulos', 'd')"
              small
              class="mr-2"
              @click="deleteItem(item)"
              >mdi-timer-off</v-icon
            >
          </template>
          <span class="white--text">Descontinuar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="permisoVista('articulos', 'r')"
              small
              @click="detailsItem(item)"
              >mdi-eye</v-icon
            >
          </template>
          <span class="white--text">Ver</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="permisoVista('articulos', 'd')"
              small
              class="mr-2"
              @click="showControlInventario(item)"
              >mdi-swap-vertical-bold</v-icon
            >
          </template>
          <span class="white--text">Control Inventario</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-row>
      <download-btn
        v-if="permisoVista('articulos', 'r')"
        color="blue darken-1"
        text
        :retryTime="20"
        @click="descargarReporte"
        label="Descargar Reporte<br/> de Inventario"
      ></download-btn>
    <download-btn
        v-if="permisoVista('articulos', 'r')"
        color="blue darken-1"
        text
        :retryTime="20"
        @click="enviarEmail"
        :waiting-text="'Enviando email...'"
        label="Enviar a email Reporte<br/> de Inventario"
      ></download-btn>
      <download-btn
        v-if="permisoVista('articulos', 'r')"
        color="blue darken-1"
        text
        :retryTime="240"
        @click="descargarReporteAlmacenes"
        label="Descargar Reporte<br/> de Almacenes"
      ></download-btn> <!-- Botón agregado para la descarga del reporte de almacenes -->
      <!-- <v-btn 
        v-if="permisoVista('articulos','r')"
        color="blue darken-1"
        text
        @click="descargarReporte">
        Descargar Reporte
        <br/> de Inventario
      </v-btn> -->
    </v-row>
    <v-snackbar v-model="alerta" top color="error">
      Existe un Artículo Activo con el mismo Nombre o Código
      <v-btn text @click="alerta = false">Cerrar</v-btn>
    </v-snackbar>
    <vista-previa
      :item="detailItemSelected"
      :show="detailItemShow"
      :toggle-show="detailItemShowAction"
    />
    <ControlInventario
      ref="controlinv"
      @refresh="articulos_update_page_action(articulos_actual_page)"
    />
  </v-col>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { debounce } from "debounce";
import VistaPrevia from "@/components/DashViews/Inventario/Articulo/VistaPrevia";
import moment from "moment";
import alertDialog from "@/components/AlertDialog.vue";
import ControlInventario from "./Articulo/ControlInventario.vue";
import VSelectTags from "@/components/VSelectTags.vue";
import DownloadBtn from "../../DownloadBtn.vue";
import TooltipThumbnail from "@/components/TooltipThumbnail.vue";

export default {
  components: {
    VistaPrevia,
    alertDialog,
    ControlInventario,
    VSelectTags,
    DownloadBtn,
    TooltipThumbnail,
  },
  data: () => ({
    clases: [],
    loadingClases: false,
    errorCargaMasivaInventario: false,
    cargaMasivaInventario: false,
    itemTag: null,
    dtkey: 0,
    chkey: 0,
    tags: [],
    saving: false,
    estados: [
      {
        name: "VIGENTE",
        id: 1,
      },
      {
        name: "DESCONTINUADO",
        id: 0,
      },
    ],
    busqueda: {
      nombre: "",
      categorias: [],
      tipos: [],
      clases: [],
      unidades: [],
      codigo: "",
      almacenes: [],
      subalmacenes: [], //Nuevo en el filtro
      estados: [1],
    },
    idArticuloTipo: [],
    avanzada: false,
    loadingTipo: false,
    tipos: [],
    loadingCategoria: false,
    categorias: [],
    loadingUnidad: false,
    loadingAlmacen: false,
    loadingSubalmacen: false, //Agregado para el filtro
    unidades: [],
    dialog: false,
    alerta: false,
    eliminar: false,
    search: "",
    headers: [
      {
        text: "Imagen",
        align: "center",
        sortable: false,
        value: "imagen",
      },
      {
        text: "Código",
        align: "left",
        sortable: false,
        value: "codigo",
      },
      {
        text: "Artículo",
        align: "left",
        sortable: false,
        value: "nombre",
      },
      {
        text: "Clase",
        align: "left",
        sortable: false,
        value: "articuloClase",
      },
      {
        text: "Categoría",
        align: "left",
        sortable: false,
        value: "articuloCategoria",
      },
      {
        text: "Tipo",
        align: "left",
        sortable: false,
        value: "articuloTipo",
      },
      {
        text: "Stock",
        align: "left",
        sortable: false,
        value: "cantidad",
      },
      {
        text: "Mínimo",
        align: "left",
        sortable: true,
        value: "minimo",
      },
      {
        text: "Máximo",
        align: "left",
        sortable: true,
        value: "maximo",
      },
      {
        text: "Punto Reorden",
        align: "left",
        sortable: true,
        value: "puntoReorden",
      },
      {
        text: "Unidad",
        align: "left",
        sortable: false,
        value: "unidad",
      },
      {
        text: "Precio",
        align: "right",
        sortable: false,
        value: "costo",
      },
      {
        text: "Estado",
        align: "center",
        sortable: false,
        value: "estado",
      },
      {
        text: "Acciones",
        value: "action",
        sortable: false,
        width: "10%",
        align: "center",
      },
    ],
    loading: false,
    articulos: [],
    //? Paginacion articulos y filtros (server side)
    articulos_total: null,
    articulos_items_per_page: 10,
    articulos_actual_page: 1,
    delItem: "",
    action: 0,
    errores: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
      codigo: "",
      idUnidad: -1,
      tags: [],
      salidaAutomatica: false,
      imagen:null
    },
    defaultItem: {
      nombre: "",
      codigo: "",
      idUnidad: -1,
      tags: [],
      salidaAutomatica: false,
      imagen:null
    },
    detailItemSelected: null,
    detailItemShow: false,
    actualizacionMasiva: false,
    loadingActualizacionMasiva: false,
    ficheroActualizacion: null,
    cargaActualizacionCompleta: false,
    selectedUpdateFile: null,
    errorActualizacionMasiva: false,
    cargaMasiva: false,
    loadingCargaMasiva: false,
    ficheroCarga: null,
    cargaCargaCompleta: false,
    selectedCreateFile: null,
    errorCargaMasiva: false,
    cargaLink: "",
    updateLink: "",
    valid: true,
    codeRules: [
      (v) => (/^.{2,30}$/.test(v)) || "El código debe tener mínimo 2 y máximo 30 caracteres"
    ],
  }),

  computed: {
    headersFiltrados() {
      return this.permisoVista("articulos", "$")
        ? this.headers
        : this.headers.filter((head) => head.value != "costo");
    },
    ...mapState({
      cargasMasivas: "cargasMasivas",
      token: "token",
    }),
    filtrosAplicados() {
      let filtros_aplicados = [];
      if (this.busqueda.categorias.length)
        filtros_aplicados.push({
          filtro: "categoría",
          valores: this.busqueda.categorias.map(
            (x) =>
              this.categorias.find((c) => c.idArticuloCategoria == x).nombre
          ),
          multiple: true,
        });
      if (this.busqueda.tipos.length)
        filtros_aplicados.push({
          filtro: "tipo",
          valores: this.busqueda.tipos.map(
            (x) => this.tipos.find((t) => t.idArticuloTipo == x).nombre
          ),
          multiple: true,
        });
      if (this.busqueda.unidades.length)
        filtros_aplicados.push({
          filtro: "unidad",
          valores: this.busqueda.unidades.map(
            (x) => this.unidades.find((u) => u.idUnidad == x).nombre
          ),
          multiple: true,
        });
      if (this.busqueda.almacenes.length)
        filtros_aplicados.push({
          filtro: "almacen",
          valores: this.busqueda.almacenes.map(
            (x) => this.almacenes.find((a) => a.idAlmacen == x).nombre
          ),
          multiple: true,
        });
      if (this.busqueda.subalmacenes.length) //Para el filtro de subalmacenes
        filtros_aplicados.push({
          filtro: "subalmacen",
          valores: this.busqueda.subalmacenes.map(
            (x) => this.subalmacenes.find((s) => s.idSubalmacen == x).subalmacen
          ),
          multiple: true,
        });
      if (this.busqueda.estados.length)
        filtros_aplicados.push({
          filtro: "estado",
          valores: this.busqueda.estados.map((x) =>
            x === 1 ? "VIGENTE" : "DESCONTINUADO"
          ),
          multiple: true,
        });
      return filtros_aplicados;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Artículo" : "Editar Artículo";
    },
    articulos_fetch_url() {
      let url = `/Articulos?paginar=true&page=${this.articulos_actual_page}&size=${this.articulos_items_per_page}`;

      if (this.search)
        url += this.search.length ? `&nombreCodigo=${this.search}` : "";

      if (this.busqueda.categorias)
        this.busqueda.categorias.forEach(
          (x) => (url += `&categorias=${x || ""}`)
        );

      if (this.busqueda.tipos)
        this.busqueda.tipos.forEach((x) => (url += `&tipos=${x || ""}`));

      if (this.busqueda.clases.length > 0)
        this.busqueda.clases.forEach((x) => (url += `&clases=${x || ""}`));

      if (this.busqueda.unidades)
        this.busqueda.unidades.forEach((x) => (url += `&unidades=${x || 0}`));

      if (this.busqueda.almacenes)
        this.busqueda.almacenes.forEach((x) => (url += `&almacenes=${x || 0}`));

      if (this.busqueda.subalmacenes)
        this.busqueda.subalmacenes.forEach((x) => (url += `&subalmacenes=${x || 0}`));  //Para el filtro
  
      if (this.busqueda.estados)
        this.busqueda.estados.forEach((x) => (url += `&estados=${x || 0}`));
      return url;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
    if (!this.permisoVista("articulos", "$")) {
      this.headers = this.headers.filter((element) => {
        return element.value != "costo";
      });
    }
  },

  methods: {
    getClases() {
      this.loadingClases = true;
      axios
        .get("/Articulos/ListarArticuloClase")
        .then((response) => {
          this.clases = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingClases = false;
        });
    },
    async getPuntosReorden(idArticulo) {
      return new Promise((resolve) => {
        const item = {};
        axios
          .get("/Inventario/ListarPuntosReorden", {
            params: { idArticulo },
          })
          .then((response) => {
            item.minimo =
              response.data.length > 0 ? response.data[0].minimo : null;
            item.maximo =
              response.data.length > 0 ? response.data[0].maximo : null;
            item.puntoReorden =
              response.data.length > 0 ? response.data[0].puntoReorden : null;
          })
          .catch((error) => {
            item.minimo = null;
            item.maximo = null;
            item.puntoReorden = null;
          })
          .finally(() => {
            resolve(item);
          });
      });
    },
    showControlInventario(item) {
      this.$refs.controlinv.mostrarDialogControl(item.idArticulo);
    },
    descargarReporte() {
      var datetime = moment().format("YYYYMMDDHHmmss");
      this.$utils.axios.downloadFile(
        "/Reportes/Almacenes/xlsx",
        "GET",
        "reporte-inventario-" + datetime + ".xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
    descargarReporteAlmacenes() { //Función creada para descargar el csv de almacenes
      var datatime = moment().format("YYYYMMDDHHmmss");
      this.$utils.axios.downloadFile(
        "/Almacenes/ImpresionQrsAlmacenes",
        "GET",
        "reporte-almacenes-" + datatime + ".xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
    enviarEmail(){
      axios
      .post("/Reportes/EnviarXlsInventario")
      .then(() => {
        
      })
      .catch(error => {
        this.saving = false;
        console.log(error);
      });
      /*  */
    },
    cambioficheroCarga(event) {
      if (typeof event === "undefined" || event == null)
        this.ficheroCarga = null;
      else this.ficheroCarga = event;
    },
    cambioficheroActualizacion(event) {
      if (typeof event === "undefined" || event == null)
        this.ficheroActualizacion = null;
      else this.ficheroActualizacion = event;
    },
    resetBusqueda() {
      this.busqueda.nombre = "";
      this.busqueda.categorias = [];
      this.busqueda.tipos = [];
      this.busqueda.clases = [];
      this.busqueda.codigo = "";
      this.busqueda.unidades = [];
      this.busqueda.estados = [1];
      this.busqueda.almacenes = [];
      this.busqueda.subalmacenes = []; //Para el filtro
      this.getArticulosAction();
    },
    getTags() {
      axios
        .get("/Articulos/ListTag")
        .then((response) => {
          this.tags = response.data.map((etiqueta) => ({
            idTag: etiqueta.idTag,
            tag: etiqueta.nombre,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async initialize() {
      this.getCategorias();
      this.getTipos();
      this.getClases();
      this.getUnidades();
      this.getAlmacenes();
      this.getSubalmacenes(); //Nuevo en el filtro
      this.getTags();
      this.loading = true;
      try {
        let response = await this.getArticulos();
        this.articulos = response.data.result;
        this.articulos_total = response.data.total;
        this.loading = false;
      } catch (exception) {
        console.error(exception);
      }
    },
    articulos_sort_by_action($sort) {},
    dialog_busqueda_keyevent_action($event) {
      if ($event.keyCode === 27) this.avanzada = false;
      if ($event.keyCode === 13) this.getArticulosAction();
    },
    clearSearchAction() {
      this.search = "";
      this.getArticulosAction();
    },
    getArticulos() {
      //* Realizamos peticion HTTP
      return new Promise((resolve, reject) => {
        axios
          .get(this.articulos_fetch_url)
          .then((response) => {
            response.data.result = response.data.result.map((articulo) => ({
              ...articulo,
              minimo: null,
              maximo: null,
              puntoReorden: null,
            }));
            response.data.result.forEach(async (articulo) => {
              const {
                minimo,
                maximo,
                puntoReorden,
              } = await this.getPuntosReorden(articulo.idArticulo);
              articulo.minimo = minimo;
              articulo.maximo = maximo;
              articulo.puntoReorden = puntoReorden;
            });
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    async getArticulosAction() {
      this.loading = true;
      this.getTags();
      this.articulos_actual_page = 1;
      try {
        let response = await this.getArticulos();
        this.articulos = response.data.result;
        this.articulos_total = response.data.total;
        this.loading = false;
      } catch (exception) {
        console.error(exception);
      }
    },
    getArticulosDebounce: debounce(async function() {
      this.loading = true;
      this.articulos_actual_page = 1;
      try {
        let response = await this.getArticulos();
        this.articulos = response.data.result;
        this.articulos_total = response.data.total;
        this.loading = false;
      } catch (exception) {
        console.error(exception);
      }
    }, 750),
    getArticulosDebounceAction($event) {
      if ($event instanceof KeyboardEvent) {
        //  Enter y Tab
        if ($event.keyCode === 13 || $event.keyCode === 9) return;
        //  Flechas teclado
        if ($event.keyCode >= 37 && $event.keyCode <= 40) return;
      }
      this.getArticulosDebounce();
    },
    getArticulosOnEnterAction($event) {
      if ($event instanceof KeyboardEvent) {
        //  Enter
        if ($event.keyCode === 13) this.getArticulosAction();
      }
    },
    getCategorias() {
      let me = this;
      me.loadingCategoria = true;
      axios
        .get("/ArticuloCategorias")
        .then((response) => {
          me.categorias = response.data;
          me.categorias.push({
            idArticuloCategoria: null,
            nombre: "SIN ASIGNAR",
            descripcion: "SIN ASIGNAR",
            condicion: true,
          });
          me.loadingCategoria = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTipos() {
      let me = this;
      me.loadingTipo = true;
      axios
        .get("/ArticuloTipos")
        .then((response) => {
          me.tipos = response.data;
          me.tipos.push({
            idArticuloTipo: null,
            nombre: "SIN ASIGNAR",
            vacio: false,
            descripcion: "SIN ASIGNAR",
            receta: false,
            condicion: true,
          });
          me.loadingTipo = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUnidades() {
      let me = this;
      me.loadingUnidad = true;
      axios
        .get("/Unidades")
        .then((response) => {
          me.unidades = response.data;
          me.loadingUnidad = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAlmacenes() {
      let me = this;
      me.loadingAlmacen = true;
      axios
        .get("/Almacenes")
        .then((response) => {
          me.almacenes = response.data;
          me.loadingAlmacen = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSubalmacenes() {  //Se agregó para el filtro
      let me = this;
      me.loadingSubalmacen = true;
      axios
        .get("/Subalmacenes")
        .then((response) => {
          me.subalmacenes = response.data;
          me.loadingSubalmacen = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editItem(item) {
      this.action = 1;
      this.editedIndex = this.articulos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.chkey += 1;
    },

    deleteItem(item) {
      this.eliminar = true;
      this.delItem = item;
    },
    deleteItemDB() {
      let me = this;
      let item = this.delItem;
      this.delItem = "";
      axios
        .put("/Articulos/Desactivar/" + item.idArticulo)
        .then((response) => {
          me.initialize();
        })
        .catch((error) => {
          if (error.response.status == 409) {
            this.$refs.alerta.show(
              "Este Artículo es usado en al menos una Receta."
            );
          } else console.log(error);
        });
    },
    detailsItem(item) {
      this.detailItemSelected = item;
      this.detailItemShowAction();
    },
    detailItemShowAction() {
      this.detailItemShow = !this.detailItemShow;
    },
    close() {
      (this.action = 0), (this.dialog = false);
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    makeValidation(string, min, max, msgError) {
      if (string.length < min || string.length > max) {
        return msgError;
      } else return null;
    },
    validate() {
      this.errores = [];

      var val = null;
      val = this.makeValidation(
        this.editedItem.nombre,
        2,
        200,
        "El Nombre debe tener al menos 2 caracteres y menos de 200."
      );
      if (val != null) this.errores.push(val);

      val = this.makeValidation(
        this.editedItem.codigo,
        2,
        30,
        "El Código debe tener al menos 2 caracteres y máximo 30."
      );
      if (val != null) this.errores.push(val);

      if (this.editedItem.idUnidad == -1) {
        this.errores.push("Debe de seleccionar una Unidad para el Artículo");
      }

      if (this.errores.length == 0) return true;
      else return false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.action = 0;
        if (this.validate()) {
          let me = this;
          me.saving = true;
          let ids = [];
          let tags = [];
          this.editedItem.tags.forEach((etiqueta) => {
            if (etiqueta.idTag != undefined) ids.push(etiqueta.idTag);
            else tags.push(etiqueta.tag);
          });
          

          var formData = new FormData();
			        formData.append("Nombre", me.editedItem.nombre);
			        formData.append("Codigo", me.editedItem.codigo);
			        formData.append("IdUnidad", me.editedItem.idUnidad);
              formData.append("SalidaAutomatica", me.editedItem.salidaAutomatica == null ? false : me.editedItem.salidaAutomatica);
              formData.append("imagen", me.editedItem.imagen);

            
              if(ids.length > 0) ids.forEach(element => {
                  if(element > 0) formData.append("IdTags", element);
                });

                if(tags.length > 0) tags.forEach(element => {
                  if(element != '') formData.append("Tags", element);
                });
                

          if (this.editedIndex > -1) {
              axios
              .put("/Articulos/Actualizar/" +me.articulos[me.editedIndex].idArticulo,
                formData,{
                  headers: {
				           		"Content-Type": "multipart/form-data",
				           	},
                }
              )
              .then((response) => {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch((error) => {
                me.saving = false;
                if (error.response) {
                  if (error.response.status == 409) {
                    me.alerta = true;
                    me.errores.push(
                      "Existe un Artículo Activo con el Mismo Nombre o Código"
                    );
                  }
                }
              });
          } else {
            axios
              .post("/Articulos/Crear", formData, {
				              	headers: {
				              		"Content-Type": "multipart/form-data",
				              	},
				              })
              .then((response) => {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch((error) => {
                me.saving = false;
                if (error.response) {
                  if (error.response.status == 409) {
                    me.alerta = true;
                    me.errores.push(
                      "Existe un Artículo Activo con el Mismo Nombre o Código"
                    );
                  }
                }
              });
          }
        }
      }
    },
    async articulos_update_page_action(page) {
      this.articulos_actual_page = page;
      this.loading = true;
      try {
        let response = await this.getArticulos();
        this.articulos = response.data.result;
        this.articulos_total = response.data.total;
        this.loading = false;
      } catch (exception) {
        console.log(exception);
      }
    },
  },
};
</script>

<style>
.dialog-busqueda {
  border-radius: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  max-height: 100% !important;
  position: absolute !important;
  overflow-y: auto !important;
  top: 0 !important;
  right: 0 !important;
}
.card-busqueda {
  max-height: 100% !important;
  height: 100% !important;
}

.card-actions-busqueda {
  position: absolute;
  bottom: 0;
  right: 0;
}

.chip-filtro {
  color: #2962ff;
}
</style>
