<template>
  <div v-if="permisoVista('configuraciones','r')">
    <v-data-table v-model="idsSelected" show-select :single-select="false" item-key="posicion" :headers="headers"
      :search="search" :items="dataImpresoras" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex align-center">
          <v-text-field v-model="search" v-bind="propsSearch" />
          <v-btn v-if="permisoVista('configuraciones','u')" color="primary" class="ml-3" @click="show_dialogMultiples()">Editar Multiples</v-btn>
        </div>
      </template>
      <template v-slot:[`item.proceso`]="{ item }">
        {{ item.proceso }}
      </template>
      <template v-slot:[`item.gramaje`]="{ item }">
        {{ item.gramajeInferior }} gms - {{ item.gramajeSuperior }} gms
      </template>
      <template v-slot:[`item.medidasMaxPapel`]="{ item }">
        {{ item.medidaMaxPapelBase }} mts X {{ item.medidaMaxPapelAltura }} mts
      </template>
      <template v-slot:[`item.medidasMinPapel`]="{ item }">
        {{ item.medidaMinPapelBase }} mts X {{ item.medidaMinPapelAltura }} mts
      </template>
      <template v-slot:[`item.medidasMinPapel`]="{ item }">
        {{ item.medidaMinPapelBase }} mts X {{ item.medidaMinPapelAltura }} mts
      </template>
      <template v-slot:[`item.MedidasMaxImagen`]="{ item }">
        {{ item.medidaMaxImagenBase }} mts X {{ item.medidaMaxImagenAltura }} mts
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip v-if="permisoVista('configuraciones','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="900" persistent>
      <v-card>
        <v-card-title>Editar {{ tituloMaquina }}: "{{ maquinaNombre }}"</v-card-title>
        <br>
        <v-card-text>
          <v-form ref="form">
            <v-col>
              <v-row>
                <v-col cols="12">
                  <h4>Configuraciones de la Máquina</h4>
                </v-col>
                <v-col class="col-6" style="margin-top: -10px;">
                  <v-checkbox style="margin-top: 0;" v-model="requiereTintas" label="N° Tintas" />
                  <v-checkbox style="margin-top: 0;" v-model="requiereMedidasPapel" label="Máximos y Mínimos de Papel" />
                  <v-checkbox style="margin-top: 0;" v-model="maquinaSecado" label="Sistema de secado" />
                </v-col>
                <v-col class="col-6">
                  <v-checkbox style="margin-top: 0;" v-model="requiereGramajes" label="Limite de Gramajes" />
                  <v-checkbox style="margin-top: 0;" v-model="requiereMedidasImagen" label="Máximos de Imagen" />
                  <v-checkbox style="margin-top: 0;" v-model="costos" label="Costo y Costo Mínimo" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field v-model="porcentajeMaquina" label="Porcentaje por Máquina" :loading="loadingDialog"
                    suffix="%" v-bind="propsDecimales0" />
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="costoHora" label="Costo Energía Electrica/Hra" :loading="loadingDialog"
                    suffix="$/Hra" v-bind="propsDecimales0" />
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="costoOperadores" label="MOD/Hra" :loading="loadingDialog"
                    v-bind="propsDecimales0" suffix="$/Hra" readonly />
                </v-col>
                <v-col cols="12">
                  <v-data-table :headers="headersOperadores" :items="dataOperadores" hide-default-footer>
                    <template v-slot:[`item.idOperador`]="{ item }">
                      <v-autocomplete v-model="item.idOperador" :items="dataPersonas" item-value="idPersona"
                        label="Trabajador" v-bind="propsAutocomplete">
                        <template v-slot:selection="data">
                          <span>{{ data.item.nombre }} {{ data.item.paterno }} {{ data.item.materno }}</span> &nbsp; -
                          &nbsp;
                          <span>Costo Por Hora ${{ data.item.costoHora }}</span>
                        </template>

                        <template v-slot:item="data">
                          <span>{{ data.item.nombre }} {{ data.item.paterno }} {{ data.item.materno }}</span> &nbsp; -
                          &nbsp;
                          <span>Costo Por Hora ${{ data.item.costoHora }}</span>
                        </template>
                      </v-autocomplete>
                    </template>
                    <template v-slot:[`item.funcion`]="{ item }">
                      <v-text-field v-model="item.funcion" label="Función" v-bind="propsTexto" />
                    </template>
                    <template v-slot:[`item.porcentaje`]="{ item }">
                      <v-text-field v-model="item.porcentaje" suffix="%" label="Porcentaje" v-bind="propsDecimales1" />
                    </template>
                    <template v-slot:[`item.acciones`]="{ item }">
                      <v-tooltip bottom color="black">
                        <template v-slot:activator="{ attrs, on }">
                          <v-icon v-bind="attrs" v-on="on" medium class="mr-1"
                            @click="deleteOperador(item)">mdi-trash-can-outline</v-icon>
                        </template>
                        <span class="white--text">Eliminar</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:footer>
                      <v-btn color="primary" @click="addOperador()">Agregar</v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-row v-if="requiereTintas">
                <v-col cols="12">
                  <v-text-field v-model="impresoraTintas" label="Número de Tintas" :loading="loadingDialog"
                    v-bind="propsDecimales1" />
                </v-col>
              </v-row>

              <v-row v-if="requiereGramajes">
                <v-col>
                  <h4 class="my-h4">Gramajes Permitidos:</h4>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field v-model="gramajeInferior" label="Límite Inferior" :loading="loadingDialog"
                        v-bind="propsEnteros1" suffix="Grm" />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="gramajeSuperior" label="Límite Superior" :loading="loadingDialog"
                        v-bind="propsEnteros1" suffix="Grm" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field v-model="pinza" label="Pinza" :loading="loadingDialog" v-bind="propsDecimales0"
                    suffix="Mts" />
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="cola" label="Cola" :loading="loadingDialog" v-bind="propsDecimales0"
                    suffix="Mts" />
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="margenLado" label="Margen por Lado" :loading="loadingDialog"
                    v-bind="propsDecimales0" suffix="Mts" />
                </v-col>
              </v-row>

              <v-row v-if="requiereMedidasPapel">
                <v-col>
                  <h4 class="my-h4">Medida Máxima del Papel:</h4>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field v-model="medidaMaxPapelBase" label="Base" :loading="loadingDialog"
                        v-bind="propsDecimales1" suffix="Mts" />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="medidaMaxPapelAltura" label="Altura" :loading="loadingDialog"
                        v-bind="propsDecimales1" suffix="Mts" />
                    </v-col>
                  </v-row>

                  <h4 class="my-h4">Medida Mímina del Papel:</h4>

                  <v-row>
                    <v-col cols="4">
                      <v-text-field v-model="medidaMinPapelBase" label="Base" :loading="loadingDialog"
                        v-bind="propsDecimales1" suffix="Mts" />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="medidaMinPapelAltura" label="Altura" :loading="loadingDialog"
                        v-bind="propsDecimales1" suffix="Mts" />
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>

              <v-row v-if="requiereMedidasImagen">
                <v-col>
                  <h4 class="my-h4">Medida Máxima de la Imagen:</h4>

                  <v-row>
                    <v-col cols="4">
                      <v-text-field v-model="medidaMaxImagenBase" label="Base" :loading="loadingDialog"
                        v-bind="propsDecimales1" suffix="Mts" />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="medidaMaxImagenAltura" label="Altura" :loading="loadingDialog"
                        v-bind="propsDecimales1" suffix="Mts" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-data-table v-if="maquinaSecado" :headers="headersSecados" :items="dataSecados" hide-default-footer>
                <template v-slot:[`item.secado`]="{ item }">
                  <v-autocomplete v-model="item.idSecadoTipo" :items="dataSecadoTipo" item-value="idSecadoTipo"
                    item-text="nombre" v-bind="propsAutocomplete" />
                </template>
                <template v-slot:[`item.costo`]="{ item }">
                  <v-text-field v-model="item.costo" label="Costo" v-bind="propsDecimales0" prefix="$" />
                </template>
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" small class="mr-1"
                        @click="deleteSecado(item)">mdi-trash-can-outline</v-icon>
                    </template>
                    <span class="white--text">Eliminar</span>
                  </v-tooltip>
                </template>
                <template v-slot:footer>
                  <v-btn color="primary" @click="addSecado()">
                    Agregar
                  </v-btn>
                </template>
              </v-data-table>

              <v-row v-if="costos">
                <v-col>
                  <h4 class="my-h4">Costo y Costo Mínimo</h4>

                  <v-row>
                    <v-col cols="3">
                      <v-text-field v-model="costoMaquina" label="Costo" :loading="loadingDialog" v-bind="propsDecimales1"
                        prefix="$" />
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete v-model="idUnidadCosto" label="Unidad" :loading="loadingDialog"
                        :items="dataUnidades" :item-text="nombreUnidades" item-value="idUnidad"
                        v-bind="propsAutocomplete" />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field v-model="costoMinimoMaquina" label="Costo Mínimo" :loading="loadingDialog"
                        v-bind="propsDecimales1" prefix="$" />
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete v-model="idUnidadCostoMin" label="Unidad" :loading="loadingDialog"
                        :items="dataUnidades" :item-text="nombreUnidades" item-value="idUnidad"
                        v-bind="propsAutocomplete" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text @click="hide_dialog()">Cancelar</v-btn>
          <v-btn :loading="loadingDialog" color="error" text @click="validate()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500">
      <v-card style="padding: 5px 20px 0px 20px">
        <v-card-title>Eliminar Impresora</v-card-title>
        <v-card-actions>
          <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text
            @click="hide_dialogDelete()">Cancelar</v-btn>
          <v-btn :loading="loadingDialog" color="error" text @click="deleteImpresora()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>
  
<script>
import axios from 'axios';
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {
    AlertSnackbar,
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
      },
      propsDecimales0: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) < 0) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },
      propsDecimales1: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) <= 0) {
              return "Ingrese un número mayor a 0";
            }
            return true;
          },
        ],
      },
      propsEnteros1: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            }
            if (!Number.isInteger(Number(v))) {
              return "Ingrese solo números enteros";
            }
            if (Number(v) <= 0) {
              return "Ingrese un número mayor a 0";
            }
            return true;
          },
        ],
      },
      propsTexto: {
        outlined: false,
        dense: false,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },
      propsAutocomplete: {
        outlined: false,
        dense: false,
        required: true,
        rules: [
          (v) => !!v || "Selección Requerida"
        ],
      },

      dialog: false,
      tituloMaquina: '',
      dialogDelete: false,
      optionDialog: null,

      requiereTintas: false,
      requiereGramajes: false,
      requiereMedidasPapel: false,
      requiereMedidasImagen: false,
      costos: false,
      costoMaquina: null,
      costoMinimoMaquina: null,
      maquinaId: false,
      maquinaNombre: null,
      porcentajeMaquina: null,
      costoHora: null,
      manoObraHora: 0,
      gramajeInferior: null,
      gramajeSuperior: null,
      pinza: null,
      cola: null,
      margenLado: null,
      medidaMaxPapelBase: null,
      medidaMaxPapelAltura: null,
      medidaMinPapelBase: null,
      medidaMinPapelAltura: null,
      medidaMaxImagenBase: null,
      medidaMaxImagenAltura: null,
      impresoraTintas: null,
      maquinaSecado: false,
      idUnidadCosto: null,
      idUnidadCostoMin: null,

      search: '',

      idsSelected: [],
      idsMaquinas: [],
      headers: [
        {
          text: "Nombre",
          value: "nombre",
          align: "center",
          sortable: false,
        }, {
          text: "Proceso",
          value: "proceso",
          align: "center",
          sortable: false,
        }, {
          text: "Gramaje",
          value: "gramaje",
          align: "center",
          sortable: false,
        }, {
          text: "Medidas Máximas de Papel",
          value: "medidasMaxPapel",
          align: "center",
          sortable: false,
        }, {
          text: "Medidas Mínimas de Papel",
          value: "medidasMinPapel",
          align: "center",
          sortable: false,
        }, {
          text: "Medidas Máximas de Imagen",
          value: "MedidasMaxImagen",
          align: "center",
          sortable: false,
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        },
      ],
      headersOperadores: [
        {
          text: 'Operador',
          value: 'idOperador',
          align: 'center',
          sortable: false,
        }, {
          text: 'Función',
          value: 'funcion',
          align: 'center',
          sortable: false,
        }, {
          text: 'Porcentaje',
          value: 'porcentaje',
          align: 'center',
          sortable: false,
        }, {
          text: 'Acciones',
          value: 'acciones',
          align: 'center',
          sortable: false,
        }
      ],
      headersSecados: [
        {
          text: 'Secado',
          value: 'secado',
          align: 'center',
          sortable: false,
        }, {
          text: 'Costo',
          value: 'costo',
          align: 'center',
          sortable: false,
        }, {
          text: 'Acciones',
          value: 'acciones',
          align: 'center',
          sortable: false,
        }
      ],

      dataMaquinas: [],
      dataImpresoras: [],
      dataOperadores: [],
      dataPersonas: [],
      dataSecados: [],
      dataSecadoTipo: [],
      dataUnidades: [],

      loadingTable: false,
      loadingDialog: false,
    }
  },
  methods: {
    nombreMaquina: (item) => `[${item.modelo}] - ${item.marca}`,
    nombreUnidades: (item) => `[${item.abreviatura}] - ${item.nombre}`,

    initialize() {
      this.getPuestos();
      this.getTipoSecados();
      this.getMaquinas();
      this.getUnidades();
    },

    validate() {
      if (this.$refs.form.validate() && this.validacionDatos()) this.optionDialog == 'Crear' ? this.postImpresora() : this.putImpresora()
    },

    getPuestos() {
      axios
        .get("/Personas")
        .then((response) => {
          this.dataPersonas = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getTipoSecados() {
      axios
        .get("/SecadoTipo/Listar")
        .then((response) => {
          this.dataSecadoTipo = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getMaquinas() {
      this.loadingTable = true;
      axios
        .get("/GKMaquina/Listar")
        .then((response) => {
          this.dataImpresoras = response.data
          for (let i in this.dataImpresoras) {
            this.dataImpresoras[i].posicion = i
          }
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    getUnidades() {
      axios
        .get("/unidades")
        .then((response) => {
          this.dataUnidades = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
        })
    },
    putImpresora() {
      this.loadingDialog = true;
      axios
        .put("/GKMaquina/Actualizar", {
          maquinas: this.idsMaquinas,
          tintas: this.requiereTintas,
          numTintas: this.impresoraTintas,
          maquinaOperadores: this.dataOperadores,
          limiteGramajes: this.requiereGramajes,
          gramajeInferior: this.gramajeInferior,
          porcentajeMaquina: this.porcentajeMaquina,
          costoHora: this.costoHora,
          manoObraHora: this.manoObraHora,
          gramajeSuperior: this.gramajeSuperior,
          pinza: this.pinza,
          cola: this.cola,
          margenLado: this.margenLado,
          maxMinPapel: this.requiereMedidasPapel,
          costos: this.costos,
          costoMaquina: this.costoMaquina,
          costoMinimoMaquina: this.costoMinimoMaquina,
          medidaMaxPapelBase: this.medidaMaxPapelBase,
          medidaMaxPapelAltura: this.medidaMaxPapelAltura,
          medidaMinPapelBase: this.medidaMinPapelBase,
          medidaMinPapelAltura: this.medidaMinPapelAltura,
          minImagen: this.requiereMedidasImagen,
          medidaMaxImagenBase: this.medidaMaxImagenBase,
          medidaMaxImagenAltura: this.medidaMaxImagenAltura,
          secado: this.maquinaSecado,
          secadoTipos: this.dataSecados,
          idUnidadCosto: this.idUnidadCosto,
          idUnidadCostoMin: this.idUnidadCostoMin
        })
        .then((response) => {
          this.getMaquinas();
          this.hide_dialog()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },

    show_dialog(item) {
      this.tituloMaquina = "Máquina"
      this.dialog = true;
      this.optionDialog = item ? "Editar" : "Agregar";
      if (item) {
        this.idsMaquinas.push({ idMaquina: item.idMaquina, idProceso: item.idProceso })
        this.maquinaNombre = item.nombre;
        this.porcentajeMaquina = item.porcentajeMaquina;
        this.costoHora = item.costoHora;
        this.manoObraHora = item.manoObraHora;
        this.requiereTintas = item.tintas;
        this.impresoraTintas = item.numTintas;
        this.dataOperadores = JSON.parse(JSON.stringify(item.maquinaOperadores));
        this.requiereGramajes = item.limiteGramajes
        this.gramajeInferior = item.gramajeInferior;
        this.gramajeSuperior = item.gramajeSuperior;
        this.pinza = item.pinza;
        this.cola = item.cola;
        this.margenLado = item.margenLado;
        this.requiereMedidasPapel = item.maxMinPapel;
        this.costos = item.costos;
        this.costoMaquina = item.costoMaquina;
        this.costoMinimoMaquina = item.costoMinimoMaquina
        this.medidaMaxPapelBase = item.medidaMaxPapelBase;
        this.medidaMaxPapelAltura = item.medidaMaxPapelAltura;
        this.medidaMinPapelBase = item.medidaMinPapelBase;
        this.medidaMinPapelAltura = item.medidaMinPapelAltura;
        this.requiereMedidasImagen = item.minImagen;
        this.medidaMaxImagenBase = item.medidaMaxImagenBase;
        this.medidaMaxImagenAltura = item.medidaMaxImagenAltura;
        this.maquinaSecado = JSON.parse(JSON.stringify(item.secado));
        this.dataSecados = JSON.parse(JSON.stringify(item.secadoTipos));
        this.idUnidadCosto = item.idUnidadCosto;
        this.idUnidadCostoMin = item.idUnidadCostoMin;
      }
    },
    hide_dialog() {
      this.dialog = false;
      this.tituloMaquina = '';
      this.idsSelected = JSON.parse(JSON.stringify([]));
      this.idsMaquinas = JSON.parse(JSON.stringify([]));
      this.dataSecados = JSON.parse(JSON.stringify([]));
      this.dataOperadores = JSON.parse(JSON.stringify([]));
      this.$refs.form.reset();
    },

    addOperador() {
      this.dataOperadores.push(
        {
          idOperador: "",
          funcion: "",
          porcentaje: "",
        }
      )
    },
    deleteOperador(item) {
      var idx = this.dataOperadores.indexOf(item);
      if (idx < 0) return;
      this.dataOperadores.splice(idx, 1);
    },

    addSecado() {
      this.dataSecados.push({
        secado: null,
        costo: null,
      })
    },
    deleteSecado(item) {
      var idx = this.dataSecados.indexOf(item);
      if (idx < 0) return;
      this.dataSecados.splice(idx, 1);
    },

    validacionDatos() {
      if (this.gramajeInferior > this.gramajeSuperior || this.gramajeSuperior < this.gramajeInferior) {
        this.alerta(null, "warning", "El Gramaje Inferior No Puede Ser Mayor Al Gramaje Superior", "mdi-alert-circle-outline")
        return false
      } else if (this.medidaMinPapelBase > this.medidaMaxPapelBase || this.medidaMinPapelAltura > this.medidaMaxPapelAltura) {
        this.alerta(null, "warning", "Las Medidas Maximas Del Papel No Pueden Ser Menores A Las Minimas", "mdi-alert-circle-outline")
        return false
      }
      return true
    },

    show_dialogDelete() {
      this.dialogDelete = true;
    },
    hide_dialogDelete() {
      this.dialogDelete = false;
    },

    show_dialogMultiples() {
      if (this.idsSelected.length <= 1) {
        this.alerta(null, "warning", "Debe Seleccionar Más De Una Máquina", "mdi-alert-circle-outline");
      } else {
        this.maquinaNombre = this.idsSelected.map(item => item.nombre).join(", ");
        this.idsMaquinas = this.idsSelected.map((item) => ({
          idMaquina: item.idMaquina,
          idProceso: item.idProceso
        }))
        this.tituloMaquina = "Máquinas"
        this.dialog = true;
      }

    },

    alerta(code, color, mensaje, icono) {
      if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
      } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
      }
    },
  },
  computed: {
    costoOperadores() {
      let costoTotal = 0;

      for (let i = 0; i < this.dataOperadores.length; i++) {

        const costo = this.dataPersonas.find(item => item.idPersona === this.dataOperadores[i].idOperador)?.costoHora
        const porcentaje = this.dataOperadores[i].porcentaje;

        if (costo && porcentaje) {
          costoTotal += (costo * porcentaje) / 100;
        }
      }
      this.manoObraHora = costoTotal;
      return this.manoObraHora;
    }
  },
  mounted() {
    this.initialize();
  },
  watch: {
    'maquinaSecado'(value) {
      if (!value) {
        this.dataSecados = JSON.parse(JSON.stringify([]))
      }
    }
  }
}
</script>

<style scoped>
.my-h4 {
  font-weight: bold;
  color: #161616 !important;
  margin-top: 20px;
  margin-bottom: 5px;
}
</style>