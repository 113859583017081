<template>
  <v-col cols="12" v-if="permisoVista('insumos','r')">
    <v-row>
      <v-col cols="12">
        <v-btn v-if="permisoVista('insumos','c')" color="primary" @click="dialog = true">Nuevo Vaso</v-btn>

        <v-data-table :headers="headers" :search="search" :items="vasos" :loading="loadingTable">
          <template v-slot:top>
            <div class="d-flex justify-center">
              <v-text-field v-model="search" v-bind="propsSearch" />
            </div>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip v-if="permisoVista('insumos','u')" bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="editItem(item)">mdi-pen</v-icon>
              </template>
              <span class="white--text">Editar</span>
            </v-tooltip>
            <v-tooltip v-if="permisoVista('insumos','d')" bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" small class="mr-1"
                  @click="show_dialogDelete(item)">mdi-trash-can</v-icon>
              </template>
              <span class="white--text">Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formValido">
            <v-text-field v-model="vasoData.codigo" label="Código"
            :error-messages="errores.codigo"
            @input="limpiarError('codigo')" />
            <v-text-field v-model="vasoData.nombre" label="Nombre"
            :error-messages="errores.nombre"
            @input="limpiarError('nombre')"/>
            <v-autocomplete v-model="vasoData.idUnidad" label="Unidad" :items="unidades"
              :item-text="nombreUnidad" item-value="idUnidad" 
              :error-messages="errores.idUnidad"
              @input="limpiarError('idUnidad')" />
            <v-text-field v-model="vasoData.costo" label="Costo por pieza" prefix="$"
            :error-messages="errores.costo"
            @input="limpiarError('costo')" />
            <datePicker v-model="vasoData.fechaCotizacion" label="Fecha de cotización" format="YYYY-MM-DD" maxToday 
            :error-messages="errores.fechaCotizacion"
            @input="limpiarError('fechaCotizacion')" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" class="ml-auto" text @click="hide_dialog()">Cancelar</v-btn>
          <v-btn color="error" text @click="save()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500">
      <v-card>
        <v-card-title>Eliminar Vaso</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "[{{ this.vasoData.codigo }}] - {{ this.vasoData.nombre
          }}"?</v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialogDelet" color="blue darken-1" class="ml-auto" text
            @click="hide_dialogDelete()">Cancelar</v-btn>
          <v-btn :loading="loadingDialogDelet" color="error" text @click="deleteVaso()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AlertSnackbar ref="AlertSnackbar" />
  </v-col>
</template>

<script>
import axios from 'axios';
import AlertSnackbar from '@/components/AlertSnackbar.vue'
import datePicker from "@/components/DatePicker";
import { VueDatePicker } from '@mathieustan/vue-datepicker';

export default {
  components: {
    VueDatePicker,
    AlertSnackbar
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      optionDialog: undefined,

      search: "",

      headers: [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false,
        }, {
          text: "Nombre",
          value: "nombre",
          align: "center",
          sortable: false,
        }, {
          text: "Costo",
          value: "costo",
          align: "center",
          sortable: false,
        }, {
          text: "Fecha de Cotización",
          value: "fechaCotizacion",
          align: "center",
          sortable: false,
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        }
      ],

      unidades: [],
      vasos: [],
      up: 0,
      vasoData: { //Agregado para el crud de vasos
      idArticulo: null,
      codigo: null,
      nombre: null,
      idUnidad: null,
      costo: null,
      fechaCotizacion: null,
      errorCodigoEstatus: false,
      errorCodigo: null,
      errorNombreEstatus: false,
      errorNombre: null,
      errorIdUnidadEstatus: false,
      errorIdUnidad: null,
      errorCostoEstatus: false,
      errorCostos: null,
      errorFechaEstatus: false,
      errorFecha: null,
      },
      vasoDataDefault: {
      idArticulo: null,
      codigo: null,
      nombre: null,
      idUnidad: null,
      costo: null,
      fechaCotizacion: null,
      errorCodigoEstatus: false,
      errorCodigo: null,
      errorNombreEstatus: false,
      errorNombre: null,
      errorIdUnidadEstatus: false,
      errorIdUnidad: null,
      errorCostoEstatus: false,
      errorCostos: null,
      errorFechaEstatus: false,
      errorFecha: null,
      },
      formValido: false,
      errores: {
        codigo: [],
        nombre: [],
        fechaCotizacion: [],
        costo: [],
        idUnidad: []
      },
      editedIndex: -1,
      vasoDeletData: {},

      loadingTable: false,
      loadingDialog: false,
      loadingDialogDelet: false,
    }
  },
  computed: {
    formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Vaso' : 'Editar Vaso'
      },
  },
  methods: {
    nombreUnidad: (lista) => `[${lista.abreviatura}] - ${lista.nombre}`,

    initialize() {
      this.getVasos();
      this.getUnidades();
    },
    editItem(item) { //Función para edición de vasos
      this.editedIndex = this.vasos.indexOf(item);
      this.vasoData = Object.assign({}, item);
      this.dialog = true;
    },
    validate() {
      let hayErrores = false;

      // Validación del código
      if (!this.vasoData.codigo) {
        this.errores.codigo = ["Dato requerido"];
        hayErrores = true;
      } else {
        this.errores.codigo = [];
      }

      // Validación del nombre
      if (!this.vasoData.nombre) {
        this.errores.nombre = ["Dato requerido"];
        hayErrores = true;
      } else {
        this.errores.nombre = [];
      }

      // Validación de fecha de cotización
      if (!this.vasoData.fechaCotizacion) {
        this.errores.fechaCotizacion = ["Dato requerido"];
        hayErrores = true;
      } else {
        this.errores.fechaCotizacion = [];
      }

      // Validación del costo
      if (!this.vasoData.costo) {
        this.errores.costo = ["Dato requerido"];
        hayErrores = true;
      } else if (isNaN(this.vasoData.costo)) {
        this.errores.costo = ["Solo acepta números"];
        hayErrores = true;
      } else if (parseFloat(this.vasoData.costo) <= 0) {
        this.errores.costo = ["Debe ser mayor a 0"];
        hayErrores = true;
      } else {
        this.errores.costo = [];
      }

      // Validación de ID de unidad
      if (this.vasoData.idUnidad == null) {
        this.errores.idUnidad = ["Dato requerido"];
        hayErrores = true;
      } else {
        this.errores.idUnidad = [];
      }

      return hayErrores;
    },
    limpiarError(campo) {
      this.errores[campo] = [];
    },

    getUnidades() {
      axios
        .get("/Unidades")
        .then(response => {
          this.unidades = response.data
        })
        .catch(error => { console.log(error.response) })
    },
    getVasos() {
      this.loadingTable = true;
      axios
        .get("/Vasos/Listar")
        .then((response) => {
          this.vasos = response.data
        })
        .catch((error) => { console.log(error.response) })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    save() { //Función que se encarga de los métodos 'post' y 'put'
      if (this.validate()) return;
      var obj = {
        codigo: this.vasoData.codigo,
        nombre: this.vasoData.nombre,
        idUnidad: this.vasoData.idUnidad,
        costo: parseFloat(this.vasoData.costo),
        fechaCotizacion: this.vasoData.fechaCotizacion
      };
      this.loadingDialog = true;
      if (this.editedIndex > -1) {
        axios
        .put("/Vasos/Actualizar" , {idArticulo: this.vasoData.idArticulo,
          codigo: this.vasoData.codigo,
          nombre: this.vasoData.nombre,
          idUnidad: this.vasoData.idUnidad,
          costo: parseFloat(this.vasoData.costo),
          fechaCotizacion: this.vasoData.fechaCotizacion,}
        )
        .then((response) => {
          this.hide_dialog();
          this.initialize();
          this.loadingDialog = true;
        })
        .catch((error) => { 
          console.log(error.response);
        });
      } else {
        axios
        .post("/Vasos/Crear", obj)
        .then(() => {
          this.loadingDialog = true;
          this.hide_dialog();
          this.initialize();
          //this.alerta(200)

        })
        .catch((error) => { 
          //this.alerta(error.response.status)
          console.log(error);
        })
      }
      //this.hide_dialog()
    },
    deleteVaso() { //Función pa eliminar vasos
      this.loadingDialogDelet = true;
      axios
        .put("/Vasos/Desactivar/" + this.vasoData.idArticulo)
        .then((response) => {
          this.dialog = false;
          this.hide_dialogDelete();
          this.initialize();

        })
        .catch((error) => { console.log(error.response)
         })
        .finally(()=> {
          this.loadingDialogDelet = false;
        })    
        
    },
    hide_dialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.vasoData = Object.assign({}, this.vasoDataDefault);
        this.editedIndex = -1;
      });
    },
    alerta(code) {
      this.$refs.AlertSnackbar.state_alert(code);
    },
    show_dialogDelete(item) {
      this.editedIndex = this.vasos.indexOf(item);
      this.vasoData = Object.assign({}, item);
      this.dialogDelete = true;
    },
    hide_dialogDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.vasoData = Object.assign({}, this.vasoDataDefault);
        this.editedIndex = -1;
      });
    },
  },
  mounted() {
    this.initialize();
  },
}
</script>