<template>
  <div v-if="permisoVista('secados','r')">
    <v-btn v-if="permisoVista('secados','c')" class="ml-3" color="primary" @click="show_dialog()">
      Agregar Tipo de Secado
    </v-btn>
    <v-data-table :headers="headers" :items="dataSecados" :search="search" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex align-center">
          <v-text-field v-model="search" v-bind="propsSearch" />

        </div>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip v-if="permisoVista('secados','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>

        <v-tooltip v-if="permisoVista('secados','d')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialogDelete(item)">mdi-trash-can</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title>{{ optionDialog }} Tipo de Secado</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="codigoSecado" label="Código" v-bind="propsText" :loading="loadingDialog" />
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="nombreSecado" label="Nombre" v-bind="propsText" :loading="loadingDialog" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn class="ml-auto" text color="blue darken-1" @click="hide_dialog()" :loading="loadingDialog">Cancelar</v-btn>
          <v-btn text color="error" @click="validate()" :loading="loadingDialog">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="500" persistent>
      <v-card>
        <v-card-title>Eliminar Tipo de Secado</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "{{ nombreSecado }}"?</v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn color="blue darken-1" class="ml-auto" text @click="hide_dialogDelete()"
            :loading="loadingDialogDelete">Cancelar</v-btn>
          <v-btn color="error" text @click="deleteSecadoTipo()" :loading="loadingDialogDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>

<script>
import axios from 'axios'
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {
    AlertSnackbar
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
      },
      propsText: {
        outlined: false,
        dense: true,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },

      search: '',
      headers: [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false,
        }, {
          text: "Nombre",
          value: "nombre",
          align: "center",
          sortable: false,
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        }
      ],

      idSecado: null,
      codigoSecado: null,
      nombreSecado: null,

      dataSecados: [],

      optionDialog: '',

      dialog: false,
      dialogDelete: false,

      loadingTable: false,
      loadingDialog: false,
      loadingDialogDelete: false,
    }
  },
  methods: {
    initialize() {
      this.getSecadoTipo()
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.optionDialog == 'Crear' ? this.postSecadoTipo() : this.putSecadoTipo()
      } else {
        this.alerta(422)
      }
    },

    getSecadoTipo() {
      this.loadingTable = true;
      axios
        .get("/SecadoTipo/Listar")
        .then((response) => {
          this.dataSecados = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    postSecadoTipo() {
      this.loadingDialog = true;
      axios
        .post("/SecadoTipo/Crear", {
          codigo: this.codigoSecado,
          nombre: this.nombreSecado,
        })
        .then((response) => {
          //this.alerta(200)
          this.getSecadoTipo();
          this.hide_dialog();
        })
        .catch((error) => {
          console.log(error)
          //this.alerta(error.response.status);
        })
        .finally(() => {
          this.loadingDialog = false;
        })

    },
    putSecadoTipo() {
      this.loadingDialog = true;
      axios
        .put("/SecadoTipo/Actualizar", {
          idSecadoTipo: this.idSecado,
          codigo: this.codigoSecado,
          nombre: this.nombreSecado,
        })
        .then((response) => {
          //this.alerta(200)
          this.getSecadoTipo();
          this.hide_dialog();
        })
        .catch((error) => {
          console.log(error)
          //this.alerta(error.response.status);
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteSecadoTipo() {
      this.loadingDialogDelete = true;
      axios
        .put("/SecadoTipo/Desactivar/" + this.idSecado)
        .then((response) => {
          //this.alerta(200)
          this.getSecadoTipo();
          this.hide_dialogDelete();
        })
        .catch((error) => {
          console.log(error)
          //this.alerta(error.response.status);
        })
        .finally(() => {
          this.loadingDialogDelete = false;
        })
    },

    show_dialog(item) {
      this.optionDialog = item ? "Editar" : "Crear";
      if (item) {
        this.idSecado = item.idSecadoTipo;
        this.codigoSecado = item.codigo;
        this.nombreSecado = item.nombre;
      }
      this.dialog = true;
    },
    hide_dialog() {
      this.$refs.form.reset();
      this.dialog = false;
      this.optionDialog = "";
      this.idSecado = null;
      this.codigoSecado = null;
      this.nombreSecado = null;
    },

    show_dialogDelete(item) {
      this.idSecado = item.idSecadoTipo;
      this.codigoSecado = item.codigo;
      this.nombreSecado = item.nombre;
      this.dialogDelete = true;
    },
    hide_dialogDelete() {
      this.idSecado = null;
      this.codigoSecado = null;
      this.nombreSecado = null;
      this.$refs.form.reset();
      this.dialogDelete = false;
    },

    alerta(code, color, mensaje, icono) {
      if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
      } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>