<template>
  <div style="padding: 0 20px 0 20px;">
    <v-col></v-col>

    <!-- <v-row style="justify-content: center;">
      <v-col cols="3" v-for="seccion in problemasPrueba">
        <v-card>
          <v-card-title style="justify-content: center;">
            {{ seccion.apartado }}
          </v-card-title>

          <v-card-text class="my-center-content" v-bind="numTareasProps">
            {{ seccion.pendientes.length }}
          </v-card-text>
          <v-card-text class="my-center-content">
            Tareas Pendientes
          </v-card-text>

          <v-card-actions class="my-center-content">
            <v-btn v-if="seccion.pendientes.length > 0" color="error" @click="show_dialog(seccion)">
              Ver tareas
              <v-icon small> mdi mdi-arrow-right</v-icon>
            </v-btn>
            <v-btn v-else color="success" readonly>
              <v-icon> mdi mdi-check-circle </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row> -->

    <v-dialog v-model="dialog" width="750">
      <v-card>
        <v-card-title>
          Tareas del modulo '{{ apartadoProblemas }}'
        </v-card-title>

        <v-card-text>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel v-for="(pendientes, i) in pendientesProblemas" :key="i">
              <v-expansion-panel-header disable-icon-rotate>
                {{ pendientes.titulo }}
                <template v-slot:actions>
                  <v-icon color="error">
                    mdi-alert-circle
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ pendientes.descripcion }}
                <br><br>
                <!--<li v-for="item in pendientes.lista">
                  - {{ item.item }} <span v-if="item.concepto">({{ item.concepto }})</span>
                </li>-->

                <v-simple-table v-if="pendientes.descripcion">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center" width="50%">
                          TAREA PENDIENTE EN:
                        </th>
                        <th class="text-center" v-if="pendientes.lista.find(item => item.concepto != null)">
                          Concepto
                        </th>
                        <!--<th class="text-center" v-if="pendientes.lista.find(item => item.extra != null)">
                          Extra
                        </th>-->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in pendientes.lista">
                        <td>[{{ item.item }}] - {{ item.extra }}</td>

                        <td v-if="item.concepto" class="text-center">
                          {{ item.concepto }}
                        </td>

                        <!--<td v-if="item.extra" class="text-center">
                          {{ item.extra }}
                        </td>-->
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" class="ml-auto" text @click="hide_dialog()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--<v-skeleton-loader class="col-4" type="divider, list-item-three-line, image, actions" />-->
  </div>
</template>
  
<script>
import axios from 'axios'

export default {
  data() {
    return {
      numTareasProps: {
        style: {
          'font-size': "50px",
        },
      },

      dialog: false,
      apartadoProblemas: null,
      pendientesProblemas: [],
      panel: [],

      problemasPrueba: [],
    }
  },
  methods: {
    initialize() {
      this.getProblemas()
    },

    getProblemas() {
      axios
        .get("/ProcesoFaltantes/Mostrar")
        .then((response) => {
          this.problemasPrueba = response.data;
        })
        .catch((error) => { console.log(error) })
    },

    show_dialog(seccion) {
      this.panel = [];
      this.dialog = true;
      this.apartadoProblemas = seccion.apartado;
      this.pendientesProblemas = seccion.pendientes;
    },
    hide_dialog() {
      this.dialog = false;
      this.panel = [];
      this.apartadoProblemas = null;
      this.pendientesProblemas = [];
    }
  },

  mounted() {
    this.initialize()
  }
};
</script>
  
<style>
.my-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>