<template>
  <div v-if="mostrarChat" class="float-chat">
  <!-- Declaracion del chat usando el paquete de vue beautiful-chat -->
  <!-- Todo los templates declarados en beautiful-chat serviran para configurar diferentes aspectos del chat:title-image-url="titleImageUrl" -->
    <beautiful-chat
      :always-scroll-to-bottom="alwaysScrollToBottom" 
      :close="closeChat"
      :colors="colors"
      :is-open="mostrarChat" 
      :message-list="messageList"
      :message-styling="messageStyling"
      :new-messages-count="newMessagesCount"
      :on-message-was-sent="onMessageWasSent"
      :open="openChat"
      :participants="participants"
      :show-close-button="true"
      :show-launcher="true"
      :show-emoji="false" 
      :show-file="false" 
      :show-typing-indicator="showTypingIndicator"
      :show-edition="false" 
      :show-deletion="false" 
      
      :disable-user-list-toggle="true"
      :userIsTyping= "userIsTyping" 
      
      @onType="handleOnType"
      
    >
      
      <!-- Titulo del chat -->
      <template v-slot:header >
        <div style="
              font-size: 200%;
              text-align: center;
              align-items: center;
      
        ">
          <img src="/favicon.png" style="width: 12%;vertical-align: top;" alt="logo" />
          🤔 Juan Manuel
        </div>      
      </template>
      <!-- Boton desplegable para darle like a una pagina -->
      <template v-slot:text-message-toolbox="scopedProps">
        <button
          v-if="!scopedProps.me && scopedProps.message.type === 'text'"
          @click.prevent="like(scopedProps.message.id)"
        >
          👍
        </button>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <!-- Componente para insertar el mensaje, ya sea si viene en html o no -->
        <p class="sc-message--text-content"  v-html="scopedProps.message.data.text"></p> 
        
        <!-- Componentes que sirve para liker un mensaje-->      
        <p
          v-if="scopedProps.message.liked"
          class="sc-message--edited"
        >      
        <template v-if="scopedProps.message.liked">👍</template>
        </p>      
      </template>    
    </beautiful-chat>
  </div>
</template>

<script>
  import axios from 'axios';

  import Vue from 'vue';
  import BeautifulChat from 'vue-beautiful-chat';
  Vue.use(BeautifulChat);
  export default {
    components: {
      // BeautifulChat,
    },    
    data() {
      return {
        // Lista de participantes en el chat
        participants: [
          {
            id: 'user1',
            name: 'Usuario 1',
            imageUrl: '/favicon.png',
          },          
        ],
        // URL de la imagen del título del chat
        //titleImageUrl: '',//
        // Lista de mensajes iniciales
        messageList: [
          // Mensaje inicial de bienvenida del chatbot
          { id: 1,type: 'text', author: 'user1', data: { text: '<p>Hola, soy un chatbot. <br>¿En qué puedo ayudarte?</p>' } },
          
        ],
        // Contador de mensajes
        newMessagesCount: 1,
        // Estado del chat (abierto/cerrado)
        isChatOpen: false,
        alwaysScrollToBottom: true,
        messageStyling: true,
        userIsTyping: false,
        responseContent:'',
        showTypingIndicator: '',
        botResponse: '',  
        //Objeto donde ira el mensaje que devuelva el back 
        responseContent: {
          request:''
          
        },
        responseContentDefault: {
          request:''
          
        },
        // Colores personalizados
        colors: {
          header: {
            bg: '#1e1e1e',//color de managrx(negro)
            text: '#ffffff',
          },
          launcher: {
            bg: '#1565c0',//color de managrx(azul)
          },
          messageList: {
            bg: '#ffffff',
          },
          sentMessage: {
            bg: '#1565c0',//color de managrx(azul)
            text: '#ffffff',
          },
          receivedMessage: {
            bg: '#eaeaea',
            text: '#222222',           
          },
          userInput: {
            bg: '#f4f7f9',
            text: '#565867',
          },
          userList: {
            bg: '#fff',
            text: '#212121',            
          }
        },        
      };      
    },    
    mounted() {
    // console.log("FloatChat montado correctamente");
    },
    methods: {
      // Método para manejar el envío de mensajes      
      onMessageWasSent(message) {//asi se envia el mensaje introducido al chat, cabe mencionar que la variable mensaje es un objeto compatible con la lista this.messageList
        // Se valida que el mensaje no sea vacío
        // console.log(message)
        if(!this.$utils.strings.isNullOrEmpty(message.data.text)){
          // Se envía el mensaje al listado de mensajes
          this.createMessage(message);
          //se activa la animacion de respuesta
          this.showTypingIndicator ='user1';
          // Se envía el mensaje al back con la animacion de respuesta activada
          this.getResponse(message.data.text);          
        }              
      },
      createMessage(message){//Funcion para crear un nuevo mensaje 
        //Se incrementa el contador de los mensajes
        this.newMessagesCount++;

        this.messageList = [...this.messageList, Object.assign({}, message, {id: this.newMessagesCount})];
      },
      // Método para abrir el chat
      openChat() {
        this.isChatOpen = true;
        //this.newMessagesCount = 0;
      },
      // Método para cerrar el chat
      closeChat() {
        this.$store.state.mostrarChat=false;
        this.isChatOpen = false;
      },
      //Metodo para darle me gusta a un mensaje
      like(id) {
        const m = this.messageList.findIndex((m) => m.id === id)
        var msg = this.messageList[m]
        msg.liked = !msg.liked
        this.$set(this.messageList, m, msg)
      },
      
      handleTyping(text) {// se encargara de mandar la animacion que indique que se esta preparando el mensaje
        this.showTypingIndicator =
          text.length > 0 ? this.participants[this.participants.length - 1].id : ''
      },
      messageStylingToggled(e) {
        this.messageStyling = e.target.checked
      },
      handleOnType() {
        this.$root.$emit('onType')
        this.userIsTyping = true
      },
      createBotMessage(response){
        //Se detiene la animacion
        this.showTypingIndicator ='';
        //Se crea un nuevo mensaje con la respuesta del bot
        this.createMessage({
          author: 'user1',
          type: 'text',
          id: this.newMessagesCount,
          data: {text:response}
        });
        
      },
      async getResponse(textMessage){
        //Esta función se encarga de llamar al api y obtener la respuesta
        //Aqui se puede llamar a una funcion que se encargue de enviar el mensaje y obtener una respuesta this.idUser
        const session_id=13;

        await axios.post("/ChatFlotante/ObtenerRespuesta/"+session_id, {
          request: textMessage,
          idMensaje: this.newMessagesCount,
        })
        .then(response => {
          console.log(response)
					//Se asigna el valor que se recibio de la api
          this.responseContent = response.data;
          
				})
				.catch((error) => {
					console.log(error);
      
        });
        console.log(this.responseContent);
        this.botResponse=this.responseContent;
        this.responseContent=this.responseContentDefault;
        this.createBotMessage(this.botResponse);
      }      
    },
    computed: {
      mostrarChat() {
        return this.$store.state.mostrarChat;
      }
    }
    
  };
</script>

<style scoped>
  /* Estilos para el chat */
  .float-chat {
    position: absolute !important;
    
  }
</style>