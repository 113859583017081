<template>
  <div>
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab @click="initPreCoti()">
        Pre-Cotizaciones
      </v-tab>
      <v-tab @click="initPreOrden()">
        Pre-Ordenes
      </v-tab>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item eager>
            <PreCotizaciones ref="PreCotizaciones" />
        </v-tab-item>
        <v-tab-item eager>
            <PreOrdenes ref="PreOrdenes" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import PreCotizaciones from "./Cotizaciones/PreCotizaciones.vue";
import PreOrdenes from "./Cotizaciones/PreOrdenes.vue";

export default {
  components: {
    PreCotizaciones,
    PreOrdenes,
  },
  data() {
    return {
      tab: 0,
    }
  },
  methods: {
    initialize() {
      this.initPreCoti();
      this.initPreOrden();
    },
    initPreCoti() {
      if (typeof this.$refs.PreCotizaciones === "undefined") return;
      this.$refs.PreCotizaciones?.initialize();
    },
    initPreOrden() {
      if (typeof this.$refs.PreOrdenes === "undefined") return;
      this.$refs.PreOrdenes?.initialize();
    },
  },
}
</script>

<style scoped>
.my-tab {
  padding: 15px 20px 0 20px;
}
</style>