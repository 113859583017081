<template>
    <v-card id="projection" elevation="0">
      <v-card-text>
        <v-expansion-panels
        v-model="panel"
        :disabled="disabled"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Configuración de Alertas</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="3" sm="12">
                <vtextfieldnumber
                  v-model="configuracionAlerta.oee"
									decimal
									@change="sendDataConfingAlert" label="OEE"
									:min="-1"
                  :max="100"
                ></vtextfieldnumber>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <vtextfieldnumber
                  v-model="configuracionAlerta.disponibilidad"
									decimal
									@change="sendDataConfingAlert" label="Disponibilidad"
									:min="-1"
                  :max="100"
                ></vtextfieldnumber>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <!-- <v-text-field v-model="configuracionAlerta.rendimiento" @input="filtrarNumeros(configuracionAlerta, 'rendimiento')" @change="sendDataConfingAlert" label="Rendimiento"></v-text-field> -->
                <vtextfieldnumber
                  v-model="configuracionAlerta.rendimiento"
									decimal
									@change="sendDataConfingAlert" label="Rendimiento"
									:min="-1"
                  :max="100"
                ></vtextfieldnumber>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <!-- <v-text-field v-model="configuracionAlerta.calidad" @input="filtrarNumeros(configuracionAlerta, 'calidad')" @change="sendDataConfingAlert"  label="Calidad"></v-text-field> -->
                <vtextfieldnumber
                  v-model="configuracionAlerta.calidad"
									decimal
									@change="sendDataConfingAlert" label="Calidad"
									:min="-1"
                  :max="100"
                ></vtextfieldnumber>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
        <v-autocomplete
          v-model="idArea"
          :items="areas"
          label="Áreas"
          item-value="idArea"
          item-text="nombre"
          hide-details
          clearable
          @change="getDatos()"
        >
        </v-autocomplete>
        <v-item-group
          v-model="filtroValor"
          class="pa-0 ma-0 d-flex align-center justify-center"
          @change="getDatos"
        >
          <v-container class="pa-0 ma-0">
            <v-row class="pa-0 ma-0 justify-center align-center">
              <v-col
                v-for="(filtro, idx) in filtros"
                :key="'f-'+idx"
                cols="12"
                sm="6"
                md="2"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? colorCard(filtro.opcion || 0) : 'white'"
                    class="d-flex align-center"
                    :dark="active"
                    height="50"
                    @click="toggle"
                  >
                    <v-scroll-y-transition>
                      <div
                        class="text-h5 flex-grow-1 text-center"
                      >
                        {{filtro.nombre}}
                      </div>
                    </v-scroll-y-transition>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
        <v-progress-linear
          v-if="loading"
          indeterminate
        ></v-progress-linear>
        <br>
        <br>
        <v-row v-if="loading && maquinas.length == 0">
          <v-col cols="12" class="text-center">
            <br>
            <br>
            <br>
            <div class="display-2">Cargando</div>
            <br>
            <br>
            <v-progress-circular
              :size="250"
              :width="15"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <br>
            <br>
            <br>
          </v-col>
        </v-row>
        
          <v-row v-if="value">
            <v-col
              v-for="maquina in maquinas"
              :key="'maq-'+maquina.idMaquinaIns"
              cols="6"
              sm="6"
              md="3"
              class="flex d-flex"
            >
              <CardMaquina
                :item="maquina"
                @status="cambioStatus($event,maquina)"
              >              
              </CardMaquina>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
</template>

<script>
import CardMaquina from "./MaquinaEstado/CardMaquina.vue"
import vtextfieldnumber from "@/components/VTextFieldNumber.vue";
import axios from "axios"

export default {
  components:{
    CardMaquina,
    vtextfieldnumber
  },
  props:{
    value:{
      type: Boolean,
      default: true,
    }
	},
  data: () => ({
    numeroRules: [
        value => value === null || value.trim() === '' || /^[0-9]+(\.[0-9]+)?$/.test(value) || 'Ingrese un número válido'
      ],
    panel: 1,
    disabled: false,
    configuracionAlerta:{
      oee: undefined,
      disponibilidad: undefined,
      rendimiento: undefined,
      calidad: undefined
    },
    filtros: [
      { opcion: null, nombre: "Todas" },
      { opcion: 1, nombre: "Activas" },
      { opcion: 2, nombre: "Sin Actividad" },
      { opcion: 3, nombre: "Paros" },
      { opcion: 4, nombre: "Alertas" }
    ],
    filtroValor: 0,
    loading: false,
    maquinas: [],
    interval: null,
    idArea: -1,
    areas: [],
    loadingAreas: false
  }),
  watch:{
    value(){
      if(this.value) this.iniciar();
    }
  },
  mounted() {
    //this.getDatos();
    this.getAreas();
    this.getDataConfingAlert();
  },

  methods:{
    getAreas() {
      axios
      axios
      .get('/Areas')
      .then(response => {
        this.areas = response.data.filter(area => area.idArea > 1);
      })
      .finally(() => {
        this.loadingAreas = false;
      })
    },
    getDatos(){
      this.loading = true;
      
      axios
      .get("/Reportes/ReporteActividadMaquinaIns",{
				params: {
					Busqueda: (this.filtros[this.filtroValor] != null && this.filtros[this.filtroValor].opcion != null) ? this.filtros[this.filtroValor].opcion : null,
					IdArea: (this.idArea > 1) ? this.idArea : null
				}
			})
      .then(response => {
        this.maquinas = response.data.map(maquina => ({
            ...maquina,
            disponibilidad: maquina.disponibilidad * 100,
            rendimiento: maquina.rendimiento * 100,
            calidad: maquina.calidad * 100,
            oee: maquina.oee * 100            
          }));
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false;
      })
    },
    iniciar(){
      this.getDatos();
      this.getAreas();
      this.getDataConfingAlert();
      if(this.interval != null) return;
      this.interval = setInterval(() => {
        this.getDatos();
      },60000);
    },
    detener(){
      if(this.interval == null || typeof this.interval == 'undefined') return;
      clearInterval(this.interval);
      this.interval = null;
    },
    cerrar(){
      this.detener();
      this.$emit('input',false);
    },
    cambioStatus(value, maquina){
      maquina.status = value;
    },
    colorCard(status){
      switch (status) {
        case 0:
          return "#673ab7";
        case 1:
          return "#009e0f";
        case 2:
          return "#2b78e4";
        case 3:
          return "#cf2a27";
      }
    },
    sendDataConfingAlert(){
      var obj = new Object();
      obj.rendimiento = isNaN(parseFloat(this.configuracionAlerta.rendimiento)) ? null :parseFloat(this.configuracionAlerta.rendimiento);
      obj.calidad = isNaN(parseFloat(this.configuracionAlerta.calidad)) ? null : parseFloat(this.configuracionAlerta.calidad);
      obj.disponibilidad = isNaN(parseFloat(this.configuracionAlerta.disponibilidad)) ? null :parseFloat(this.configuracionAlerta.disponibilidad);
      obj.oEE = isNaN(parseFloat(this.configuracionAlerta.oee)) ? null : parseFloat(this.configuracionAlerta.oee);

      axios
        .post("/OEEAlertas/GuardarIndicadoresOEEAlerta", obj)
        .then((response) => {              
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
    getDataConfingAlert(){
      axios
      .get('/OEEAlertas/IndicadoresOEEAlerta')
      .then(response => {
        
        if(response.data.calidad != null)
          this.configuracionAlerta.calidad = response.data.calidad
        if(response.data.disponibilidad != null)
          this.configuracionAlerta.disponibilidad = response.data.disponibilidad
        if(response.data.oee != null)
          this.configuracionAlerta.oee = response.data.oee
        if(response.data.rendimiento != null)
          this.configuracionAlerta.rendimiento = response.data.rendimiento
        
      })
      .catch(error => {
        console.log(error);
      })
    }
  }
}
</script>