<template>
  <div>
    <v-dialog v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="primary">
          <v-btn icon @click="hide_dialog()"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title class="white--text">{{ dialogOption }} {{ tipoPreCotizacionText }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-form ref="form" class="my-form" :readonly="readOnlyData">
          <v-list-item-title class="my-title">Datos generales:</v-list-item-title>
          <v-card v-bind="cardStyle">
            <v-card-text>
              <v-row class="col-12">
                <v-autocomplete v-model="dataPostAPI.idCliente" :items="dataClientes" :item-text="nombreCliente"
                  item-value="idCliente" label="Cliente" :loading="loadingClientes" v-bind="autoCompleteProps"
                  class="col-6 my-col"/>
                <v-text-field v-model="dataPostAPI.cantidadPza" label="Cantidad piezas" v-bind="inputNumberProps"
                  :loading="loading" suffix="Pza" class="col-6 my-col"/>
                <v-text-field v-model="dataPostAPI.nombreTrabajo" label="Nombre del Trabajo" :loading="loading"
                  v-bind="inputTextProps" class="col-6 my-col"/>
                <v-autocomplete v-model="dataPostAPI.idFrecuencia" :items="dataFrecuencia" :item-text="nombreFrecuencia"
                  item-value="idFrecuencia" label="Frecuencia" v-bind="autoCompleteProps"
                  :loading="loadingFrecuancia" class="col-6 my-col"/>  
              </v-row>
            </v-card-text>
          </v-card>

          <v-list-item-title class="my-title">Medidas:</v-list-item-title>
          <v-card v-bind="cardStyle">
            <v-card-text>
              <v-col cols="12">
                <v-row v-bind="itemCenterStyle">
                  <b class="col-2">Medida del Pliego</b>
                  <v-text-field v-model="dataPostAPI.pliegoAncho" v-bind="inputNumberProps" label="Ancho"
                    class="col-3 mr-5" :loading="loading" suffix="Mts" />
                  <v-text-field v-model="dataPostAPI.pliegoHilo" v-bind="inputNumberProps" label="Hilo" class="col-3"
                    :loading="loading" suffix="Mts" />
                </v-row>
                <v-row v-bind="itemCenterStyle">
                  <b class="col-2">Medida Tamaño</b>
                  <v-text-field v-model="dataPostAPI.tamanoAncho" v-bind="inputNumberProps" label="Ancho"
                    class="col-3 mr-5" :loading="loading" suffix="Mts" />
                  <v-text-field v-model="dataPostAPI.tamanoHilo" v-bind="inputNumberProps" label="Hilo" class="col-3"
                    :loading="loading" suffix="Mts" />
                </v-row>
                <v-row v-bind="itemCenterStyle">
                  <b class="col-2">Área de Impresión</b>
                  <v-text-field v-model="dataPostAPI.impresionLargo" v-bind="inputNumberProps" label="Largo"
                    class="col-3 mr-5" :loading="loading" suffix="Mts" />
                  <v-text-field v-model="dataPostAPI.impresionAncho" v-bind="inputNumberProps" label="Ancho" class="col-3"
                    :loading="loading" suffix="Mts" />
                </v-row>
                <v-row v-bind="itemCenterStyle">
                  <b class="col-2">Pieza Individual </b>
                  <v-text-field v-model="dataPostAPI.pzIndividualLargo" v-bind="inputNumberProps" label="Largo"
                    class="col-3 mr-5" :loading="loading" suffix="Mts" />
                  <v-text-field v-model="dataPostAPI.pzIndividualAncho" v-bind="inputNumberProps" label="Ancho"
                    class="col-3" :loading="loading" suffix="Mts" />
                </v-row>
                <v-row v-bind="itemCenterStyle">
                  <b class="col-2">Piezas</b>
                  <v-text-field v-model="dataPostAPI.piezasPinza" v-bind="inputNumberProps" label="Pinza"
                    class="col-3 mr-5" :loading="loading" suffix="Pzas" />
                  <v-text-field v-model="dataPostAPI.piezasAlto" v-bind="inputNumberProps" label="Alto" class="col-3"
                    :loading="loading" suffix="Pzas" />
                </v-row>
              </v-col>
            </v-card-text>
          </v-card>

          <v-list-item-title class="my-title">Papel:</v-list-item-title>
          <v-card v-bind="cardStyle">
            <v-card-text>
              <v-row class="col-12">
                <v-col cols="6">
                  <v-row class="d-flex align-center">
                    <b class="col-4">Especialidad del Papel:</b>
                    <v-checkbox v-model="dataPostAPI.papelAlimenticio" @change="getProveedoresPapel()"
                      label="Grado Alimenticio" />
                    <v-checkbox v-model="dataPostAPI.papelResistenteHumedad" @change="getProveedoresPapel()" class=" ml-5"
                      label="Resistente a la Humedad" />
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete v-model="dataPostAPI.idPapel" :items="dataPapeles" item-value="idArticulo" label="Papel"
                    v-bind="autoCompleteProps" :loading="loading" @change="getProveedoresPapel()">
                    <template v-slot:selection="data">
                      [{{ data.item.codigo }}]&nbsp;-&nbsp;<label>{{ data.item.nombre }}</label>
                    </template>
                    <template v-slot:item="data">
                      [{{ data.item.codigo }}]&nbsp;-&nbsp;<label>{{ data.item.nombre }}</label>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete v-model="dataPostAPI.idProveedorPapel" :items="proveedoresPapel"
                    item-value="idProveedor" label="Proveedor" v-bind="autoCompleteProps" :loading="loadingProveedorPapel"
                    @change="change_gramosProveedor()">
                    <template v-slot:selection="data">
                      <label>{{ data.item.nombreProveedor }}</label> &nbsp; / &nbsp;
                      <label>{{ data.item.gramaje }} grs</label> &nbsp; / &nbsp;
                      <label>${{ data.item.precio }} MXN m2</label> &nbsp; - &nbsp;
                      <!--<v-icon color="gray">mdi mdi-clock-time-four-outline</v-icon>&nbsp;
                      label>Tiempo de Entrega: {{ data.item.tiempoEntrega }} Días</label>&nbsp; - &nbsp;-->
                      <v-icon color="gray">mdi-calendar-check</v-icon>&nbsp;
                      <label>Cotizado: {{ data.item.fechaCotizacion }}</label>&nbsp; - &nbsp;

                      <template v-if="data.item.gradoAlimenticio">
                        &nbsp;
                        <v-icon color="red">mdi-food-apple</v-icon>
                        <label>Grado Alimenticio</label>
                      </template>
                      <template v-if="data.item.resistenciaHumedad">
                        &nbsp;
                        <v-icon color="blue">mdi-water</v-icon>
                        <label>Resistente Humedad</label>
                      </template>
                    </template>

                    <template v-slot:item="data">
                      <label>{{ data.item.nombreProveedor }}</label> &nbsp; / &nbsp;
                      <label>{{ data.item.gramaje }} grs</label> &nbsp; / &nbsp;
                      <label>${{ data.item.precio }} MXN m2</label> &nbsp; - &nbsp;
                      <!--<v-icon color="gray">mdi mdi-clock-time-four-outline</v-icon>&nbsp;
                      <label>Tiempo de Entrega: {{ data.item.tiempoEntrega }} Días</label>&nbsp; - &nbsp;-->
                      <v-icon color="gray">mdi-calendar-check</v-icon>&nbsp;
                      <label>Cotizado: {{ data.item.fechaCotizacion }}</label>&nbsp; - &nbsp;

                      <template v-if="data.item.gradoAlimenticio">
                        &nbsp;
                        <v-icon color="red">mdi-food-apple</v-icon>
                        <label>Grado Alimenticio</label>
                      </template>

                      <template v-if="data.item.resistenciaHumedad">
                        &nbsp;
                        <v-icon color="blue">mdi-water</v-icon>
                        <label>Resistente Humedad</label>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete v-model="dataPostAPI.idHojeadora" :items="DataHojeadoraFiltradas" item-text="nombre"
                    item-value="idMaquina" v-bind="autocompleteMaquinas" label="Hojeadora">
                    <template v-slot:item="data">
                      <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                      <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                        data.item.problemas.join(', ') }})</label>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="3">
                  <v-text-field v-model="dataPostAPI.tamanoPliego" v-bind="inputNumberProps1"
                    label="Tamaños por Pliego" />
                </v-col>

                <v-col cols="3">
                  <v-text-field v-model="dataPostAPI.tamanoBarnizPlastificado" v-bind="inputNumberProps1"
                    label="Tamaño Barniz Plastificado" />
                </v-col>

                <v-col cols="3">
                  <v-text-field v-model="dataPostAPI.tamanoSuaje" v-bind="inputNumberProps1" label="Tamaños por Suaje" />
                </v-col>

                <v-col cols="3">
                  <v-text-field v-model="dataPostAPI.ptjeProteccionPapel" v-bind="inputNumberProps0"
                    label="Protección Papel %" suffix="%" />
                </v-col>

                <v-col cols="6">
                  <v-row class="d-flex align-center">
                    <b class="col-4" style="font-size: 15px;">Costo Millar Papel:</b>
                    <!--<b style="font-size: 15px;">$ {{ precioMillar }}</b>-->
                    <b style="font-size: 15px;">{{ costoMillarpapel }}</b>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row class="d-flex align-center">
                    <b class="col-4" style="font-size: 15px;">Referencia Kg:</b>
                    <!--<b style="font-size: 15px;">$ {{ precioMillar }}</b>-->
                    <b style="font-size: 15px;">{{ referenciaKgPapel }}</b>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-list-item-title class="my-title">Datos Generales:</v-list-item-title>
          <v-card v-bind="cardStyle">
            <v-card-text>
              <v-col cols="12">
                <v-row>
                  <v-col cols="1">
                    <b class="text-center">Ajuste Placa:</b>
                  </v-col>
                  <v-text-field v-model="dataPostAPI.placaFrente" v-bind="inputNumberProps1" label="Frente" class="col-3 my-col"/>
                  <v-text-field v-model="dataPostAPI.placaVuelta" v-bind="inputNumberProps1" label="Vuelta" class="col-3 my-col"/>
                  <v-autocomplete v-model="dataPostAPI.idPlacaDelta" :items="dataDelta" v-bind="acDelta" class="col-3 my-col"/>

                  <v-col cols="6">
                    <v-row class="d-flex align-center">
                      <v-col cols="3"> Especialidad de Tintas: </v-col>
                      <v-row style="margin: 10px 0 10px 0">
                        <v-checkbox v-model="dataPostAPI.tintaAlimenticio" @click="change_urlTintas(true)"
                          label="Grado Alimenticio" />
                        <v-checkbox v-model="dataPostAPI.tintaImpermeable" @click="change_urlTintas(true)"
                          label="Imperneable" class="ml-10" />
                      </v-row>
                    </v-row>
                  </v-col>
                  
                  <v-autocomplete v-model="dataPostAPI.idTintaDelta" :items="dataDelta" v-bind="acDelta" class="col-6 my-col"/>
                </v-row>

                <v-data-table :headers="headersImpresiones" :items="dataImpresiones" :items-per-page="-1" hide-footer
                  hide-default-footer :loading="loading">
                  <template v-slot:[`item.cara`]="{ item }">
                    <v-autocomplete v-model="item.idCara" :items="dataCaras" :item-text="nombreDefault" item-value="id"
                      label="Cara" v-bind="impresionACProps" />
                  </template>
                  <template v-slot:[`item.tipoTinta`]="{ item }">
                    <v-select v-model="item.idTintaTipo" :items="dataTintas" :item-text="nombreTipoTinta"
                      item-value="idTintaTipo" label="Tipo de Tinta" v-bind="impresionACProps"
                      @change="change_urlTintas(false, item)" :loading="loadingTintas" />
                  </template>
                  <template v-slot:[`item.nombre`]="{ item }">
                    <v-select v-model="item.idArticulo" :items="item.tintas" :item-text="nombreTinta"
                      item-value="idArticulo" label="Tinta" v-bind="impresionACProps" :loading="loadingTintas"
                      @change="change_tinta(item), autoSelectTintaProveedor(item)" />
                  </template>
                  <template v-slot:[`item.idProveedor`]="{ item }">
                    <v-select v-model="item.idProveedor" :items="item.proveedores" :item-text="nombreProveedorTinta"
                      item-value="idProveedor" single-select label="Proveedor" v-bind="impresionACProps"
                      :loading="loadingProveedorTinta">
                      <template v-slot:selection="data">
                        <label>{{ data.item.nombreProveedor }}</label> &nbsp; / &nbsp;

                        <label>${{ data.item.precio }} MXN L</label> &nbsp; - &nbsp;
                        <!--<v-icon color="gray">mdi mdi-clock-time-four-outline</v-icon>&nbsp;
                        <label>Tiempo de Entrega: {{ data.item.tiempoEntrega }} Días</label>&nbsp; - &nbsp;-->
                        <v-icon color="gray">mdi-calendar-check</v-icon>&nbsp;
                        <label>Cotizado: {{ data.item.fechaCotizacion }}</label>&nbsp; - &nbsp;
                        <template v-if="data.item.gradoAlimenticio">
                          &nbsp;
                          <v-icon color="red">mdi-food-apple</v-icon>
                          <label>Grado Alimenticio</label>
                        </template>
                        <template v-if="data.item.impermeable">
                          &nbsp;
                          <v-icon color="blue">mdi-water</v-icon>
                          <label>Impermeable</label>
                        </template>
                      </template>

                      <template v-slot:item="data">
                        <label>{{ data.item.nombreProveedor }}</label> &nbsp; / &nbsp;

                        <label>${{ data.item.precio }} MXN L</label> &nbsp; - &nbsp;
                        <!--<v-icon color="gray">mdi mdi-clock-time-four-outline</v-icon>&nbsp;
                        <label>Tiempo de Entrega: {{ data.item.tiempoEntrega }} Días</label>&nbsp; - &nbsp;-->
                        <v-icon color="gray">mdi-calendar-check</v-icon>&nbsp;
                        <label>Cotizado: {{ data.item.fechaCotizacion }}</label>&nbsp; - &nbsp;
                        <template v-if="data.item.gradoAlimenticio">
                          &nbsp;
                          <v-icon color="red">mdi-food-apple</v-icon>
                          <label>Grado Alimenticio</label>
                        </template>

                        <template v-if="data.item.impermeable">
                          &nbsp;
                          <v-icon color="blue">mdi-water</v-icon>
                          <label>Impermeable</label>
                        </template>
                      </template>
                    </v-select>
                  </template>
                  <template v-slot:[`item.percentTinta`]="{ item }">
                    <v-text-field v-model="item.porcentajeTinta" label="Porcentaje de Tinta"
                      v-bind="inputPercentTintaProps" :min="0" :max="150" suffix="%" />
                  </template>
                  <template v-slot:[`item.acciones`]="{ item }" v-if="!readOnlyData">
                    <v-tooltip bottom color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" @click="deleteTinta(item)">mdi-trash-can</v-icon>
                      </template>
                      <span class="white--text">Eliminar</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:foot>
                    <v-btn color="primary" @click="addTinta()" v-if="!readOnlyData">Nueva Tinta (Obligatorio)</v-btn>
                  </template>
                </v-data-table>

                <v-row style="margin-top: 20px;">
                  <v-col cols="12" class="d-flex justify-center align-center">
                    <v-autocomplete v-model="dataPostAPI.idImpresionMaquina" :items="dataImpresorasFiltradas"
                      item-text="nombre" item-value="idMaquina" label="Máquina" v-bind="autocompleteMaquinas"
                      @change="comprobarSecadoMaquina()">
                      <template v-slot:item="data">
                        <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                        <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                          data.item.problemas.join(', ') }})</label>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="col-6">
                    <v-checkbox v-model="dataPostAPI.barniz" label="Barniz Base Agua" @change="cambio_Barniz()"/>
                    <v-checkbox v-model="dataPostAPI.uvPlasta" label="Barniz UV Plasta" class="my-n3" @change="cambio_uvPlasta()"/>
                    <v-checkbox v-model="dataPostAPI.uvRegistro" label="Barniz UV Registro" class="my-n3" @change="cambio_uvRegistro()"/>
                    <v-checkbox v-model="dataPostAPI.plastificado" label="Plastificado" class="my-n3" @change="cambio_plastificado()"/>
                    <v-checkbox v-model="dataPostAPI.suajado" label="Suajado" class="my-n3" @change="cambio_suajado()"/>
                  </v-col>
                  <v-col class="col-6" v-if="tipoPreCotizacionId === 1">
                    <v-checkbox v-model="dataPostAPI.corte" label="Corte" @change="cambio_corte()"/>
                    <v-checkbox v-model="dataPostAPI.empalme" label="Empalme" class="my-n3" @change="cambio_empalme()"/>
                    <v-checkbox v-model="dataPostAPI.pegado" label="Pegado Lineal" class="my-n3" @change="cambio_pegado()"/>
                    <v-checkbox v-model="dataPostAPI.pegadoGolpe" label="Pegado de golpe" class="my-n3" @change="cambio_pegadoGolpe()"/>
                    <v-checkbox v-model="dataPostAPI.troquel" label="Troquel" class="my-n3" @change="cambio_troquel()"/>
                  </v-col>
                </v-row>

                <hr color="#C9CFD1" style="width: 97%; margin: 10px auto 10px auto;">

                <v-col cols="12">
                  <div v-if="dataPostAPI.barniz">
                    <h5 style="margin-bottom: 10px">Barniz Base Agua</h5>
                    <br>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-2 my-col">Frente</h5>
                          <v-autocomplete v-model="dataPostAPI.barnizFrente" :items="dataBarnizBAgua"
                            :item-text="nombreBarnizTipo" item-value="idBarnizTipo" v-bind="autoCompleteProps"
                            label="Tipo Barniz" @change="change_urlBarnizF(true); dataPostAPI.barnizFrente2 = null; cambio_BarnizFrente();" 
                            class="col-5 my-col"/>
                          <v-autocomplete v-if="dataPostAPI.barnizFrente && dataPostAPI.barnizFrente >= 0" v-model="dataPostAPI.barnizFrente2" :items="dataBarnizF"
                            item-value="idArticulo" :item-text="nombreBarniz" label="Barniz" v-bind="autoCompleteProps"
                            class="col-5 my-col">
                            <template v-slot:selection="data">
                                <label>[{{ data.item.codigo }}] - {{ data.item.nombre }}</label>
                                &nbsp; - &nbsp;
                                <v-icon color="gray">mdi-calendar-check</v-icon>&nbsp;
                                <label>Cotizado: {{ data.item.fechaCotizacion }}</label>
                            </template>
                            <template v-slot:item="data">
                                <label>[{{ data.item.codigo }}] - {{ data.item.nombre }}</label>
                                &nbsp; - &nbsp;
                                <v-icon color="gray">mdi-calendar-check</v-icon>&nbsp;
                                <label>Cotizado: {{ data.item.fechaCotizacion }}</label>
                            </template>
                          </v-autocomplete>
                          <v-col cols="12" v-if="dataPostAPI.barnizFrente && dataPostAPI.barnizFrente >= 0">
                            <v-row>
                              <v-autocomplete v-model="dataPostAPI.barnizDelta" :items="dataDelta"
                                v-bind="acDelta" class="col-6 my-col"/>  
                              <v-autocomplete v-model="dataPostAPI.idBarnizFrenteMaquina"
                                :items="DataBarnizadorasFiltradas" item-text="nombre" item-value="idMaquina"
                                label="Máquina" v-bind="autocompleteMaquinas" class="col-6 my-col">
                                <template v-slot:item="data">
                                  <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                                  <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                                    data.item.problemas.join(', ') }})</label>
                                </template>
                              </v-autocomplete>
                              <v-autocomplete v-if="dataPostAPI.barnizFrenteSecado" v-model="dataPostAPI.idSecadoFrente" :items="DataSecadoFrente"
                                item-value="idSecadoTipo" item-text="secado" label="Secado" v-bind="autoCompleteProps"
                                class="col-6 my-col">
                                <template v-slot:selection="data">
                                  <label>{{ data.item.secado }}</label> &nbsp; / &nbsp;
                                  <label>Costo: $ {{ data.item.costo }}</label> &nbsp;
                                </template>
                                <template v-slot:item="data">
                                  <label>{{ data.item.secado }}</label> &nbsp; / &nbsp;
                                  <label>Costo: $ {{ data.item.costo }}</label> &nbsp;
                                </template>
                              </v-autocomplete>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-2 my-col">Vuelta</h5>
                          <v-autocomplete v-model="dataPostAPI.barnizVuelta" :items="dataBarnizBAgua"
                            :item-text="nombreBarnizTipo" item-value="idBarnizTipo" v-bind="autoCompleteProps"
                            label="Tipo Barniz" @change="change_urlBarnizV(dataPostAPI.barnizVuelta, true); dataPostAPI.barnizVuelta2 = null; cambio_BarnizVuelta()" class="col-5 my-col"/>    
                          <v-autocomplete v-if="dataPostAPI.barnizVuelta && dataPostAPI.barnizVuelta >= 0" v-model="dataPostAPI.barnizVuelta2" :items="dataBarnizV"
                            item-value="idArticulo" :item-text="nombreBarniz" label="Barniz" v-bind="autoCompleteProps" class="col-5 my-col">
                            <template v-slot:selection="data">
                              <label>[{{ data.item.codigo }}] - {{ data.item.nombre }}</label>
                              &nbsp; - &nbsp;
                              <v-icon color="gray">mdi-calendar-check</v-icon>&nbsp;
                              <label>Cotizado: {{ data.item.fechaCotizacion }}</label>
                            </template>
                            <template v-slot:item="data">
                              <label>[{{ data.item.codigo }}] - {{ data.item.nombre }}</label>
                              &nbsp; - &nbsp;
                              <v-icon color="gray">mdi-calendar-check</v-icon>&nbsp;
                              <label>Cotizado: {{ data.item.fechaCotizacion }}</label>
                            </template>
                          </v-autocomplete>
                          <v-col cols="12" v-if="dataPostAPI.barnizVuelta && dataPostAPI.barnizVuelta >= 0">
                            <v-row>
                              <v-autocomplete v-model="dataPostAPI.barnizDelta" :items="dataDelta"
                                v-bind="acDelta" class="col-6 my-col"/>
                              <v-autocomplete v-model="dataPostAPI.idBarnizVueltaMaquina"
                                :items="DataBarnizadorasFiltradas" item-text="nombre" item-value="idMaquina"
                                label="Máquina" v-bind="autocompleteMaquinas" class="col-6 my-col">
                                <template v-slot:item="data">
                                  <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                                  <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                                    data.item.problemas.join(', ') }})</label>
                                </template>
                              </v-autocomplete>
                              <v-autocomplete v-if="dataPostAPI.barnizVueltaSecado" v-model="dataPostAPI.idSecadoVuelta" :items="DataSecadoVuelta"
                                item-value="idSecadoTipo" item-text="secado" label="Secado" v-bind="autoCompleteProps" class="col-6 my-col">
                                <template v-slot:selection="data">
                                  <label>{{ data.item.secado }}</label> &nbsp; / &nbsp;
                                  <label>Costo: $ {{ data.item.costo }}</label> &nbsp;
                                </template>
                                <template v-slot:item="data">
                                  <label>{{ data.item.secado }}</label> &nbsp; / &nbsp;
                                  <label>Costo: $ {{ data.item.costo }}</label> &nbsp;
                                </template>
                              </v-autocomplete>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="dataPostAPI.uvPlasta">
                    <h5 style="margin-bottom: 10px">Barniz UV Plasta</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="2">
                            <h5>Frente</h5>
                          </v-col>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.uvPlastaFrente" :items="dataBarnizUVPlasta"
                              :item-text="nombrePlastificado" item-value="idArticulo" v-bind="autoCompleteProps"
                              label="UV Plasta" @change="cambio_uvPlastaFrente()"/>
                          </v-col>
                          <v-col cols="5" v-if="dataPostAPI.uvPlastaFrente && dataPostAPI.uvPlastaFrente >= 0">
                            <v-autocomplete v-model="dataPostAPI.uvPlastaDelta" :items="dataDelta"
                              v-bind="acDelta" />
                          </v-col>
                          <v-col cols="12" v-if="dataPostAPI.uvPlastaFrente && dataPostAPI.uvPlastaFrente >= 0">
                            <v-autocomplete v-model="dataPostAPI.idUVPlastaFrenteMaquina" :items="DataUvPlastaFiltradas"
                              item-value="idMaquina" item-text="nombre" label="Máquina" v-bind="autocompleteMaquinas">
                              <template v-slot:item="data">
                                <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                                <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                                  data.item.problemas.join(', ') }})</label>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="2">
                            <h5>Vuelta</h5>
                          </v-col>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.uvPlastaVuelta" :items="dataBarnizUVPlasta"
                              item-value="idArticulo" :item-text="nombrePlastificado" v-bind="autoCompleteProps"
                              label="UV Plasta" @change="cambio_uvPlastaVuelta()"/>
                          </v-col>
                          <v-col cols="5" v-if="dataPostAPI.uvPlastaVuelta && dataPostAPI.uvPlastaVuelta >= 0">
                            <v-autocomplete v-model="dataPostAPI.uvPlastaDelta" :items="dataDelta"
                              v-bind="acDelta" />
                          </v-col>
                          <v-col cols="12" v-if="dataPostAPI.uvPlastaVuelta && dataPostAPI.uvPlastaVuelta >= 0"
                            justify-center>
                            <v-autocomplete v-model="dataPostAPI.idUVPlastaVueltaMaquina" :items="DataUvPlastaFiltradas"
                              item-value="idMaquina" item-text="nombre" label="Máquina" v-bind="autocompleteMaquinas">
                              <template v-slot:item="data">
                                <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                                <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                                  data.item.problemas.join(', ') }})</label>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="dataPostAPI.uvRegistro">
                    <h5 style="margin-bottom: 10px">Barniz UV Registro</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="2">
                            <h5>Frente</h5>
                          </v-col>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.uvRegistroFrente" :items="dataBarnizUVRegistro"
                              item-value="idArticulo" :item-text="nombrePlastificado" v-bind="autoCompleteProps"
                              label="UV Registro" @change="cambio_uvRegistroFrente()"/>
                          </v-col>
                          <v-col cols="5" v-if="dataPostAPI.uvRegistroFrente && dataPostAPI.uvRegistroFrente >= 0" >
                            <v-autocomplete v-model="dataPostAPI.uvRegistroDelta" :items="dataDelta"
                              v-bind="acDelta" />
                          </v-col>
                          <v-col cols="12" v-if="dataPostAPI.uvRegistroFrente && dataPostAPI.uvRegistroFrente >= 0"
                            justify-center>
                            <v-autocomplete v-model="dataPostAPI.idUVRegistroFrenteMaquina"
                              :items="DataUvRegistroFiltradas" item-value="idMaquina" item-text="nombre" label="Máquina"
                              v-bind="autocompleteMaquinas">
                              <template v-slot:item="data">
                                <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                                <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                                  data.item.problemas.join(', ') }})</label>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="2">
                            <h5>Vuelta</h5>
                          </v-col>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.uvRegistroVuelta" :items="dataBarnizUVRegistro"
                              item-value="idArticulo" :item-text="nombrePlastificado" v-bind="autoCompleteProps"
                              label="UV Registro" @change="cambio_uvRegistroVuelta()"/>
                          </v-col>
                          <v-col cols="5" v-if="dataPostAPI.uvRegistroVuelta && dataPostAPI.uvRegistroVuelta >= 0">
                            <v-autocomplete v-model="dataPostAPI.uvRegistroDelta" :items="dataDelta"
                              v-bind="acDelta" />
                          </v-col>
                          <v-col cols="12" v-if="dataPostAPI.uvRegistroVuelta && dataPostAPI.uvRegistroVuelta >= 0"
                            justify-center>
                            <v-autocomplete v-model="dataPostAPI.idUVRegistroVueltaMaquina"
                              :items="DataUvRegistroFiltradas" item-value="idMaquina" item-text="nombre" label="Máquina"
                              v-bind="autocompleteMaquinas">
                              <template v-slot:item="data">
                                <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                                <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                                  data.item.problemas.join(', ') }})</label>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="dataPostAPI.plastificado">
                    <h5 style="margin-bottom: 10px">Plastificado</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="2">
                            <h5>Frente</h5>
                          </v-col>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.plastificadoFrente" :items="dataPlastificado"
                              item-value="id" :item-text="nombrePlastificado" v-bind="autoCompleteProps"
                              label="Tipo de Plastificado" @change="cambio_plastificadoFrente()"/>
                          </v-col>
                          <v-col cols="5" v-if="dataPostAPI.plastificadoFrente && dataPostAPI.plastificadoFrente >= 0">
                            <v-autocomplete v-model="dataPostAPI.plastificadoDelta" :items="dataDelta"
                              v-bind="acDelta" />
                          </v-col>
                          <v-col cols="12" v-if="dataPostAPI.plastificadoFrente && dataPostAPI.plastificadoFrente >= 0"
                            justify-center>
                            <v-autocomplete v-model="dataPostAPI.idPlastificadoFrenteMaquina"
                              :items="DataPlastificadoFiltradas" item-value="idMaquina" item-text="nombre" label="Máquina"
                              v-bind="autocompleteMaquinas">
                              <template v-slot:item="data">
                                <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                                <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                                  data.item.problemas.join(', ') }})</label>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="2">
                            <h5>Vuelta</h5>
                          </v-col>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.plastificadoVuelta" :items="dataPlastificado"
                              item-value="id" :item-text="nombrePlastificado" v-bind="autoCompleteProps"
                              label="Tipo de Plastificado" @change="cambio_plastificadoVuelta()"/>
                          </v-col>
                          <v-col cols="5" v-if="dataPostAPI.plastificadoVuelta && dataPostAPI.plastificadoVuelta >= 0">
                            <v-autocomplete v-model="dataPostAPI.plastificadoDelta" :items="dataDelta"
                              v-bind="acDelta" />
                          </v-col>
                          <v-col cols="12" v-if="dataPostAPI.plastificadoVuelta && dataPostAPI.plastificadoVuelta >= 0"
                            justify-center>
                            <v-autocomplete v-model="dataPostAPI.idPlastificadoVueltaMaquina"
                              :items="DataPlastificadoFiltradas" item-value="idMaquina" item-text="nombre" label="Máquina"
                              v-bind="autocompleteMaquinas">
                              <template v-slot:item="data">
                                <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                                <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                                  data.item.problemas.join(', ') }})</label>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="dataPostAPI.suajado">
                    <h5 style="margin-bottom: 10px">Suajado</h5>
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="3">
                            <v-autocomplete v-model="dataPostAPI.suajadoTipo" :items="dataSuajadoTipo"
                              :item-text="nombreDefault" item-value="id" v-bind="autoCompleteProps" label="Tipo" />
                          </v-col>
                          <v-col cols="3">
                            <v-autocomplete v-model="dataPostAPI.suajadoDelta" :items="dataDelta" v-bind="acDelta" />
                          </v-col>
                          <v-col cols="3">
                            <v-autocomplete v-model="dataPostAPI.idSuajadoMaquina" :items="DataSuajadoraFiltradas"
                              item-text="nombre" item-value="idMaquina" label="Máquina" v-bind="autocompleteMaquinas">
                              <template v-slot:item="data">
                                <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                                <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                                  data.item.problemas.join(', ') }})</label>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="3" v-if="dataPostAPI.suajadoTipo === 2">
                            <v-autocomplete v-model="dataPostAPI.IdSuajadoMMaquina" :items="DataSuajadoraManualFiltradas" item-text="nombre" item-value="idMaquina" label="Máquina" v-bind="autocompleteMaquinas">
                              <template v-slot:item="data">
                                <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                                <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                                  data.item.problemas.join(', ') }})</label>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col :cols="12">
                              <v-checkbox v-model="dataPostAPI.suajadoTablaSuaje" label="Incluir Tabla de Suaje" @change="cambio_suajadoTablaSuaje()"/>
                          </v-col>

                          <v-col cols="3" v-if="dataPostAPI.suajadoTablaSuaje">
                            <v-text-field v-model="dataPostAPI.suajeTablaBase" v-bind="inputNumberProps"
                              label="Tabla Suaje-Base" />
                          </v-col>
                          <v-col cols="3" v-if="dataPostAPI.suajadoTablaSuaje">
                            <v-text-field v-model="dataPostAPI.suajeTablaAltura" v-bind="inputNumberProps"
                              label="Tabla Suaje-Altura" />
                          </v-col>
                          <v-col cols="6" />

                          <v-col cols="3" v-if="dataPostAPI.suajadoTablaSuaje">
                            <v-text-field v-model="dataPostAPI.suajeDesbarbeBase" v-bind="inputNumberProps"
                              label="Tabla Desbarbe-Base" />
                          </v-col>
                          <v-col cols="3" v-if="dataPostAPI.suajadoTablaSuaje">
                            <v-text-field v-model="dataPostAPI.suajeDesbarbeAltura" v-bind="inputNumberProps"
                              label="Tabla Desbarbe-Altura" />
                          </v-col>
                          <v-col cols="6" />

                          <v-col cols="3" v-if="dataPostAPI.suajadoTablaSuaje">
                            <v-text-field v-model="dataPostAPI.suajeCentimetros" v-bind="inputNumberProps"
                              label="Centímetros Suaje" suffix="Cms" />
                          </v-col>
                          <v-col cols="3" v-if="dataPostAPI.suajadoTablaSuaje">
                            <v-text-field v-model="dataPostAPI.suajeMatrix" v-bind="inputNumberProps"
                              label="Centímetros Matrix" suffix="Cms" />
                          </v-col>

                        </v-row>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="dataPostAPI.corte">
                    <h5 style="margin-bottom: 10px">Corte</h5>
                    <v-row>
                      <v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.corteDelta" :items="dataDelta" v-bind="acDelta" />
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.idCorteMaquina" :items="DataCorteFiltradas"
                          item-text="nombre" item-value="idMaquina" label="Máquina" v-bind="autocompleteMaquinas">
                          <template v-slot:item="data">
                            <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                            <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                              data.item.problemas.join(', ') }})</label>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="dataPostAPI.empalme">
                    <h5 style="margin-bottom: 10px">Empalme</h5>
                    <v-row>
                      <v-col cols="3">
                        <v-text-field v-model="dataPostAPI.empalmeMetros" v-bind="inputNumberProps" label="Metros Empalme"
                          suffix="Mts" />
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.empalmeDelta" :items="dataDelta" v-bind="acDelta" />
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.idEmpalmeMaquina" :items="DataEmpalemFiltradas"
                          item-text="nombre" item-value="idMaquina" label="Máquina" v-bind="autocompleteMaquinas">
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="dataPostAPI.pegado">
                    <h5 style="margin-bottom: 10px">Pegado Lineal</h5>
                    <v-row>
                      <!--<v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.idPegadoTipo" :items="dataPegado" :item-text="nombreDefault"
                          item-value="id" v-bind="autoCompleteProps" label="Tipo de Fondo" />
                      </v-col>-->
                      <v-col cols="3">
                        <v-text-field v-model="dataPostAPI.pegadoCms" v-bind="inputNumberProps" label="Cms Pegado Lineas"
                          suffix="Cms" />
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.pegadoDelta" :items="dataDelta" v-bind="acDelta" />
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.idPegadoMaquina" :items="DataPegadoLinealFiltradas"
                          item-text="nombre" item-value="idMaquina" label="Máquina" v-bind="autocompleteMaquinas">
                          <template v-slot:item="data">
                            <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                            <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                              data.item.problemas.join(', ') }})</label>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="dataPostAPI.pegadoGolpe">
                    <h5 style="margin-bottom: 10px">Pegado de Golpe</h5>
                    <v-row>
                      <v-col cols="3">
                        <v-text-field v-model="dataPostAPI.pegadoGolpeEsquinas" v-bind="inputNumberProps1"
                          label="Número de Esquinas" />
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.pegadoGolpeDelta" :items="dataDelta" v-bind="acDelta" />
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.idPegadoGolpeMaquina" :items="DataPegadoGolpeFiltradas"
                          item-text="nombre" item-value="idMaquina" label="Máquina" v-bind="autocompleteMaquinas">
                          <template v-slot:item="data">
                            <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                            <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                              data.item.problemas.join(', ') }})</label>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="dataPostAPI.troquel">
                    <h5 style="margin-bottom: 10px">Troquel</h5>
                    <v-row>
                      <v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.idTroquelTipo" :items="dataTroquelTipo"
                          :item-text="nombreDefault" item-value="id" v-bind="autoCompleteProps" label="Tipo de Troquel" @change="cambio_idTroquelTipo()"/>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete v-model="dataPostAPI.troquelDelta" :items="dataDelta" v-bind="acDelta" />
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete v-if="dataPostAPI.idTroquelTipo === 1" v-model="dataPostAPI.idTroquelMaquina"
                          :items="DataTroquelBobinaFiltradas" item-text="nombre" item-value="idMaquina" label="Máquina"
                          v-bind="autocompleteMaquinas">
                          <template v-slot:item="data">
                            <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                            <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                              data.item.problemas.join(', ') }})</label>
                          </template>
                        </v-autocomplete>

                        <v-autocomplete v-if="dataPostAPI.idTroquelTipo === 2" v-model="dataPostAPI.idTroquelMaquina"
                          :items="DataTroquelManualFiltradas" item-text="nombre" item-value="idMaquina" label="Máquina"
                          v-bind="autocompleteMaquinas">
                          <template v-slot:item="data">
                            <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                            <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                              data.item.problemas.join(', ') }})</label>
                          </template>
                        </v-autocomplete>

                        <v-autocomplete v-if="dataPostAPI.idTroquelTipo === 3" v-model="dataPostAPI.idTroquelMaquina"
                          :items="DataTroquelAutomaticoFiltradas" item-text="nombre" item-value="idMaquina"
                          label="Máquina" v-bind="autocompleteMaquinas">
                          <template v-slot:item="data">
                            <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                            <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                              data.item.problemas.join(', ') }})</label>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-col>
            </v-card-text>
          </v-card>

          <v-list-item-title class="my-title" v-if="tipoPreCotizacionId == 2"> Fondo para vaso: </v-list-item-title>
            <v-card v-bind="cardStyle" v-if="tipoPreCotizacionId == 2">
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-row class="d-flex align-center">
                      <b class="col-4">Especialidad del Papel:</b>
                      <v-checkbox v-model="dataPostAPI.papelAlimenticio" :disabled="isDisabled"
                        label="Grado Alimenticio"/>
                      <v-checkbox v-model="dataPostAPI.papelResistenteHumedad" :disabled="isDisabled" class=" ml-5"
                        label="Resistente a la Humedad" />
                    </v-row>
                  </v-col>
                  
                  <v-col cols="6">
                    <v-autocomplete v-model="dataPostAPI.idPapelVasoParedS" :items="dataPapeles" item-value="idArticulo" label="Papel"
                      v-bind="autoCompleteProps" :loading="loading" @change="getProveedoresPapelVasos()">
                      <template v-slot:selection="data">
                        [{{ data.item.codigo }}]&nbsp;-&nbsp;<label>{{ data.item.nombre }}</label>
                      </template>
                      <template v-slot:item="data">
                        [{{ data.item.codigo }}]&nbsp;-&nbsp;<label>{{ data.item.nombre }}</label>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete v-model="dataPostAPI.idProveedorPapelVasoParedS" :items="proveedoresPapelVasos"
                      item-value="idProveedor" label="Proveedor" v-bind="autoCompleteProps" :loading="loadingProveedorPapelVasosParedS"
                      @change="change_gramosProveedorVasos()">
                      <template v-slot:selection="data">
                        <label>{{ data.item.nombreProveedor }}</label> &nbsp; / &nbsp;
                        <label>{{ data.item.gramaje }} grs</label> &nbsp; / &nbsp;
                        <label>${{ data.item.precio }} MXN m2</label> &nbsp; - &nbsp;
                        <!--<v-icon color="gray">mdi mdi-clock-time-four-outline</v-icon>&nbsp;
                        label>Tiempo de Entrega: {{ data.item.tiempoEntrega }} Días</label>&nbsp; - &nbsp;-->
                        <v-icon color="gray">mdi-calendar-check</v-icon>&nbsp;
                        <label>Cotizado: {{ data.item.fechaCotizacion }}</label>&nbsp; - &nbsp;

                        <template v-if="data.item.gradoAlimenticio">
                          &nbsp;
                          <v-icon color="red">mdi-food-apple</v-icon>
                          <label>Grado Alimenticio</label>
                        </template>
                        <template v-if="data.item.resistenciaHumedad">
                          &nbsp;
                          <v-icon color="blue">mdi-water</v-icon>
                          <label>Resistente Humedad</label>
                        </template>
                      </template>

                      <template v-slot:item="data">
                        <label>{{ data.item.nombreProveedor }}</label> &nbsp; / &nbsp;
                        <label>{{ data.item.gramaje }} grs</label> &nbsp; / &nbsp;
                        <label>${{ data.item.precio }} MXN m2</label> &nbsp; - &nbsp;
                        <!--<v-icon color="gray">mdi mdi-clock-time-four-outline</v-icon>&nbsp;
                        <label>Tiempo de Entrega: {{ data.item.tiempoEntrega }} Días</label>&nbsp; - &nbsp;-->
                        <v-icon color="gray">mdi-calendar-check</v-icon>&nbsp;
                        <label>Cotizado: {{ data.item.fechaCotizacion }}</label>&nbsp; - &nbsp;

                        <template v-if="data.item.gradoAlimenticio">
                          &nbsp;
                          <v-icon color="red">mdi-food-apple</v-icon>
                          <label>Grado Alimenticio</label>
                        </template>

                        <template v-if="data.item.resistenciaHumedad">
                          &nbsp;
                          <v-icon color="blue">mdi-water</v-icon>
                          <label>Resistente Humedad</label>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field v-model="dataPostAPI.diametroVaso" label="Diametro" suffix="Mts" v-bind="inputNumberProps0"/>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="dataPostAPI.anchoPapelVaso" label="Ancho Papel Base" suffix="Mts" v-bind="inputNumberProps0"/>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="dataPostAPI.largoPapelVaso" label="Largo Papel Base" suffix="Mts" v-bind="inputNumberProps0"/>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <h5 class="col-3 my-col">Vaso Pared Sencilla</h5>
                      <v-autocomplete v-model="dataPostAPI.vasoParedSDelta" :items="dataDelta" v-bind="acDelta" class="col-6 my-col"/>
                    </v-row>
                  </v-col>
                  <v-col cols="3" class="d-flex justify-center align-center">
                    <v-autocomplete v-model="dataPostAPI.idVasoParedSMaquina" :items="dataMaquinasVasosSFiltradas"
                      item-text="nombre" item-value="idMaquina" label="Máquina" v-bind="autocompleteMaquinas">
                      <template v-slot:item="data">
                        <label>{{ data.item.nombre }}</label>&nbsp;&nbsp;
                        <label v-if="data.item.problemas && data.item.problemas.length > 0">({{
                          data.item.problemas.join(', ') }})</label>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
             </v-card-text>
            </v-card>

          <v-list-item-title class="my-title" v-if="tipoPreCotizacionId == 3">Fondo para Vasos:</v-list-item-title>
            <v-card v-bind="cardStyle" v-if="tipoPreCotizacionId == 3">
              <v-card-text>
                <v-row>
                  <v-col cols="6" class="d-flex align-center">
                    <h5 class="my-col">Vaso Pared Doble</h5>
                    <v-autocomplete v-model="dataPostAPI.vasoParedSDelta" :items="dataDelta" v-bind="acDelta" class="my-col"/>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center">
                    <v-autocomplete v-model="dataPostAPI.idVasoParedDoble" :items="this.vasos"
                      item-text="nombre" item-value="idArticulo" label=" Medida" v-bind="autocompleteMaquinas"/> 
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

          <v-list-item-title class="my-title">Extras:</v-list-item-title>
          <v-card v-bind="cardStyle">
            <v-card-text>
              <v-col class="col-12">
                <v-data-table :items="dataExtras" :headers="headersExtras" :items-per-page="-1" hide-footer
                  hide-default-footer>
                  <template v-slot:[`item.concepto`]="{ item }">
                    <v-text-field v-model="item.concepto" :outlined="false" :dense="false" v-bind="inputTextProps"
                      label="Concepto" />
                  </template>
                  <template v-slot:[`item.descripcion`]="{ item }">
                    <v-text-field v-model="item.descripcion" :outlined="false" :dense="false" v-bind="inputTextProps"
                      label="Descripción" />
                  </template>
                  <template v-slot:[`item.tipo`]="{ item }">
                    <v-autocomplete v-model="item.tipo" :items="dataSumaExtras" :item-text="nombreDefault" item-value="id"
                      :outlined="false" :dense="false" v-bind="autoCompleteProps" label="Tipo" />
                  </template>
                  <template v-slot:[`item.monto`]="{ item }">
                    <v-text-field v-model="item.monto" :outlined="false" :dense="false" v-bind="inputNumberProps"
                      label="Monto" prefix="$" />
                  </template>
                  <template v-slot:[`item.acciones`]="{ item }" v-if="!readOnlyData">
                    <v-tooltip bottom color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" small class="mr-2"
                          @click="deleteExtra(item)">mdi-trash-can</v-icon>
                      </template>
                      <span class="white--text">Eliminar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-btn color="primary" @click="addExtra()" v-if="!readOnlyData">Agregar Concepto</v-btn>
              </v-col>

              <v-col cols="12">
                <b>Observaciones</b>
                <br><br>
                <v-textarea v-model="dataPostAPI.variantes" outlined label="Observaciones..." />
              </v-col>
            </v-card-text>
          </v-card>

          <v-list-item-title class="my-title">Transporte:</v-list-item-title>
          <v-card v-bind="cardStyle">
            <v-card-text>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          <b> Requerimientos de Transporte </b>
                        </th>
                        <th class="text-center">
                          <b> Aplica: </b>
                        </th>
                        <th class="text-center">
                          <b> Monto </b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for=" item  in  dataTransporte " :key="item.pregunta">
                        <td class="text-center">{{ item.pregunta }}</td>
                        <td>
                          <v-radio-group v-model="item.respuesta"
                            @change="change_condicionTransporte(item.idTransportePregunta)">
                            <v-radio label="No" color="red" :value="false" />
                            <v-radio label="Si" color="red" :value="true" />
                          </v-radio-group>
                        </td>
                        <td>
                          <v-text-field v-if="item.respuesta" v-model="item.monto" v-bind="inputNumberMonto" prefix="$"
                            @input="change_montoTransporte" />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <br><br>
                <v-row>
                  <v-col cols="2">
                    <b class="text-center">Costo Unidad Especial:</b>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field v-model="dataPostAPI.montoTransporte" v-bind="inputNumberMonto" label="Monto $"
                      readonly prefix="$" />
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete v-model="dataPostAPI.tipoMontoTransporte" :items="dataSumaExtras"
                      :item-text="nombreDefault" item-value="id" v-bind="autoCompleteProps" label="Tipo" />
                  </v-col>
                </v-row>
                <br><br>
                <b>Observaciones / Condiciones Particulares</b>
                <br><br>
                <v-textarea v-model="dataPostAPI.observacionesTransporte" outlined
                  label="Observaciones / Condiciones Particulares..." />
              </v-col>
            </v-card-text>
          </v-card>

          <v-list-item-title class="my-title">Empaque y Envío:</v-list-item-title>
          <v-card v-bind="cardStyle">
            <v-card-text>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="3">
                        <h5>Empaque Cliente</h5>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="dataPostAPI.empaqueC" v-bind="inputNumberProps0" label="Monto $"
                          prefix="$" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="3">
                        <h5>Empaque Planta</h5>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="dataPostAPI.empaqueP" v-bind="inputNumberProps0" label="Monto $"
                          prefix="$" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="3">
                        <h5>Envío a Planta</h5>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="dataPostAPI.envioPlanta" v-bind="inputNumberProps0" label="Monto $"
                          prefix="$" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="3">
                        <h5>Tipo</h5>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete v-model="dataPostAPI.envioPlantaTipo" :items="dataSumaExtras"
                          :item-text="nombreDefault" item-value="id" v-bind="autoCompleteProps" label="Tipo" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="3">
                        <h5>Envío a Cliente</h5>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="dataPostAPI.envioCliente" v-bind="inputNumberProps0" :min="0"
                          label="Monto $" prefix="$" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="3">
                        <h5>Tipo</h5>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete v-model="dataPostAPI.envioClienteTipo" :items="dataSumaExtras"
                          :item-text="nombreDefault" item-value="id" v-bind="autoCompleteProps" label="Tipo" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field v-model="dataPostAPI.utilidad" v-bind="inputNumberProps0" label="Porcentaje de Utilidad"
                      suffix="%" />
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete v-model="dataPostAPI.idHerramental" :items="dataSumaEnvio"
                      :item-text="nombreDefault" item-value="id" v-bind="autoCompleteProps" label="Herramental" />
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete v-model="dataPostAPI.idPreprensa" :items="dataPreprensaSumaEnvio"
                      :item-text="nombreDefault" item-value="id" v-bind="autoCompleteProps" label="Preprensa" />
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete v-model="dataPostAPI.idCostosFijos" :items="dataSumaEnvio"
                      :item-text="nombreDefault" item-value="id" v-bind="autoCompleteProps" label="Costos Fijos" />
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card>
        </v-form>
        <v-btn v-if="!readOnlyData" class="my-button-form" @click="validateCliente()" :loading="loadingCalcularCostos">
          Calcular Costos
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCambioCliente" max-width="750" persistent>
      <v-card>
        <v-card-title>Cambio de cliente detectado</v-card-title>
        <v-card-text>Al guardar esta cotización se creara una copia de la precotización y cotización actual.</v-card-text>
        <v-card-actions>
          <v-btn text color="blue darken-1" @click="dialogCambioCliente = !dialogCambioCliente" class="ml-auto">Cancelar</v-btn>
          <v-btn text color="error" @click="validate()">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AlertSnackbar ref="AlertSnackbar" />
    <CalculoCosto ref="dialogCalculoCosto" v-model="dataCotizacion" @hide_dialogCalculoCosto="succes_cotizacion()"
      @error_calculoCosto="error_calculoCosto" />
  </div>
</template>

<script>
import axios from 'axios'
import AlertSnackbar from '@/components/AlertSnackbar.vue'
import CalculoCosto from './CalculoCosto.vue'

export default {
  components: {
    AlertSnackbar,
    CalculoCosto,
  },
  data() {
    return {
      cardStyle: {
        outlined: true,
        elevation: 5,
        style: {
          padding: "0px",
          width: "100%",
          border: "1px solid #C7C7C7",
        },
      },
      itemCenterStyle: {
        justify: 'center',
        'align-items': 'center',
        style: {
          height: '100%',
        },
      },
      inputTextProps: {
        outlined: true,
        dense: true,
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      inputNumberProps0: {
        outlined: true,
        dense: true,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) < 0) {
              return "Ingrese un número mayor a 0";
            }
            return true;
          },
        ],
      },
      inputNumberProps: {
        outlined: true,
        dense: true,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) <= 0) {
              return "Ingrese un número mayor a 0";
            }
            return true;
          },
        ],
      },
      inputNumberMonto: {
        outlined: true,
        dense: true,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            }
            return true;
          },
        ],
      },
      inputNumberProps1: {
        outlined: true,
        dense: true,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (!Number.isInteger(Number(v))) {
              return "Ingrese un número entero";
            }
            else if (Number(v) < 0) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },
      autoCompleteProps: {
        outlined: true,
        dense: true,
        required: true,
        rules: [
          (v) => !!v || "Selección Requerida"
        ],
      },
      autocompleteMaquinas: {
        outlined: true,
        dense: true,
        required: true,
        rules: [
          (v) => {
            if (v === null) {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },
      impresionACProps: {
        required: true,
        rules: [
          (v) => !!v || "Selección Requerida"
        ],
      },
      inputPercentTintaProps: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) <= 0) {
              return "Ingrese un número mayor a 0";
            } else if (Number(v) > 150) {
              return "Ingrese un número menor o igual a 150";
            }
            return true;
          },
        ],
      },
      acDelta: {
        outlined: true,
        dense: true,
        required: true,
        rules: [
          (v) => !!v || "Selección Requerida"
        ],
        label: "Delta",
        itemText: this.nombreDefault,
        itemValue: "id",
      },

      dialog: false,
      dialogOption: null,
      tipoPreCotizacionId: null,
      tipoPreCotizacionText: null,

      idPreCotizacion: null,
      idCotizacion: null,
      readOnlyData: false,

      idClienteBase: null,
      dialogCambioCliente:false,
      isDisabled: true,
      headersImpresiones: [
        {
          text: "Cara",
          align: "center",
          value: "cara",
          sortable: false,
          width: '10%',
        }, {
          text: "Tipo de Tinta",
          align: "center",
          value: "tipoTinta",
          sortable: false,
          width: '15%',
        }, {
          text: "Tinta",
          align: "center",
          value: "nombre",
          sortable: false,
          width: '15%',
        }, {
          text: "Proveedor",
          align: "center",
          value: "idProveedor",
          sortable: false,
          width: '40%',
        }, {
          text: "% Tinta",
          align: "center",
          value: "percentTinta",
          sortable: false,
          width: '10%',
        }, {
          text: "Acciones",
          align: "center",
          value: "acciones",
          sortable: false,
        },
      ],
      headersExtras: [
        {
          text: "Concepto",
          align: "center",
          value: "concepto",
          sortable: false,
        }, {
          text: "Descripción",
          align: "center",
          value: "descripcion",
          sortable: false,
        }, {
          text: "Tipo",
          align: "center",
          value: "tipo",
          sortable: false,
        }, {
          text: "Monto",
          align: "center",
          value: "monto",
          sortable: false,
        }, {
          text: "Acciones",
          align: "center",
          value: "acciones",
          sortable: false,
        },
      ],
      vasos: [],
      dataPostAPI: {
        idCliente: null,
        nombreTrabajo: null,
        cantidadPza: null,
        idFrecuencia: null,
        idCliente: null,

        pliegoAncho: null,
        pliegoHilo: null,
        tamanoAncho: null,
        tamanoHilo: null,
        impresionLargo: null,
        impresionAncho: null,
        pzIndividualLargo: null,
        pzIndividualAncho: null,
        piezasPinza: null,
        piezasAlto: null,

        papelAlimenticio: false,
        papelResistenteHumedad: false,
        idPapel: null,
        idPapelVasoParedS: null,
        idProveedorPapel: null,
        idProveedorPapelVasoParedS: null,
        idHojeadora: null,
        idVasoParedSMaquina: null,
        idVasoParedDoble: null,
        tamanoPliego: null,
        tamanoBarnizPlastificado: null,
        tamanoSuaje: null,
        ptjeProteccionPapel: null,
        placaFrente: null,
        placaVuelta: null,
        idPlacaDelta: null,
        tintaAlimenticio: false,
        tintaImpermeable: false,
        idTintaDelta: null,
        idImpresionMaquina: null,

        impresionInsumos: false,
        secadoImpresora: false,
        barniz: false,
        barnizFrente: -1,
        barnizFrente2: null,
        //barnizFrenteDelta: null,
        idBarnizFrenteMaquina: null,
        barnizFrenteSecado: false,
        idSecadoFrente: null,
        barnizVuelta: -1,
        barnizVuelta2: null,
        //barnizVueltaDelta: null,
        barnizDelta: null,
        idBarnizVueltaMaquina: null,
        barnizVueltaSecado: false,
        idSecadoVuelta: null,

        uvPlasta: false,
        uvPlastaFrente: null,
        uvPlastaVuelta: null,
        //uvPlastaFrenteDelta: null,
        //uvPlastaVueltaDelta: null,
        uvPlastaDelta: null,
        idUVPlastaFrenteMaquina: null,
        idUVPlastaVueltaMaquina: null,

        uvRegistro: false,
        uvRegistroFrente: null,
        //uvRegistroFrenteDelta: null,
        uvRegistroDelta: null,
        idUVRegistroFrenteMaquina: null,
        uvRegistroVuelta: null,
        //uvRegistroVueltaDelta: null,
        idUVRegistroVueltaMaquina: null,

        plastificado: false,
        plastificadoFrente: null,
        //plastificadoFrenteDelta: null,
        plastificadoDelta: null,
        idPlastificadoFrenteMaquina: null,
        plastificadoVuelta: null,
        //plastificadoVueltaDelta: null,
        idPlastificadoVueltaMaquina: null,

        suajado: false,
        suajadoTipo: null,
        suajadoDelta: null,
        idSuajadoMaquina: null,
        IdSuajadoMMaquina: null,
        suajadoTablaSuaje: false,
        suajeTablaBase: null,
        suajeTablaAltura: null,
        suajeDesbarbeBase: null,
        suajeDesbarbeAltura: null,
        suajeCentimetros: null,
        suajeMatrix: null,

        corte: false,
        corteDelta: null,
        idCorteMaquina: null,

        empalme: false,
        empalmeMetros: null,
        empalmeDelta: null,
        idEmpalmeMaquina: null,

        pegado: false,
        idPegadoTipo: null,
        pegadoCms: null,
        pegadoDelta: null,
        idPegadoMaquina: null,

        pegadoGolpe: false,
        pegadoGolpeEsquinas: null,
        pegadoGolpeDelta: null,
        idPegadoGolpeMaquina: null,

        troquel: false,
        idTroquelTipo: null,
        troquelDelta: null,
        idTroquelMaquina: null,

        diametroVaso: null, 
        anchoPapelVaso: null,
        largoPapelVaso: null,

        variantes: null,

        montoTransporte: null,
        observacionesTransporte: null,
        tipoMontoTransporte: null,

        empaqueC: null,
        empaqueP: null,

        envioPlanta: null,
        envioPlantaTipo: null,
        envioCliente: null,
        envioClienteTipo: null,
        utilidad: null,
        idHerramental: null,

        idPreprensa: null,
        idCostosFijos: null
      },
      dataPostAPIDefault: {
        idCliente: null,
        nombreTrabajo: null,
        cantidadPza: null,
        idFrecuencia: null,
        idCliente: null,

        pliegoAncho: null,
        pliegoHilo: null,
        tamanoAncho: null,
        tamanoHilo: null,
        impresionLargo: null,
        impresionAncho: null,
        pzIndividualLargo: null,
        pzIndividualAncho: null,
        piezasPinza: null,
        piezasAlto: null,

        papelAlimenticio: false,
        papelResistenteHumedad: false,
        idPapel: null,
        idPapelVasoParedS: null,
        idProveedorPapel: null,
        idProveedorPapelVasoParedS: null,
        idHojeadora: null,
        idVasoParedDoble: null,
        tamanoPliego: null,
        tamanoBarnizPlastificado: null,
        tamanoSuaje: null,
        ptjeProteccionPapel: null,
        placaFrente: null,
        placaVuelta: null,
        idPlacaDelta: null,
        tintaAlimenticio: false,
        tintaImpermeable: false,
        idTintaDelta: null,
        idImpresionMaquina: null,

        impresionInsumos: false,
        secadoImpresora: false,
        barniz: false,
        barnizFrente: -1,
        barnizFrente2: null,
        //barnizFrenteDelta: null,
        idBarnizFrenteMaquina: null,
        barnizFrenteSecado: false,
        idSecadoFrente: null,
        barnizVuelta: -1,
        barnizVuelta2: null,
        //barnizVueltaDelta: null,
        barnizDelta: null,
        idBarnizVueltaMaquina: null,
        barnizVueltaSecado: false,
        idSecadoVuelta: null,

        uvPlasta: false,
        uvPlastaFrente: null,
        uvPlastaVuelta: null,
        //uvPlastaFrenteDelta: null,
        //uvPlastaVueltaDelta: null,
        uvPlastaDelta: null,
        idUVPlastaFrenteMaquina: null,
        idUVPlastaVueltaMaquina: null,

        uvRegistro: false,
        uvRegistroFrente: null,
        //uvRegistroFrenteDelta: null,
        uvRegistroDelta: null,
        idUVRegistroFrenteMaquina: null,
        uvRegistroVuelta: null,
        //uvRegistroVueltaDelta: null,
        idUVRegistroVueltaMaquina: null,

        plastificado: false,
        plastificadoFrente: null,
        //plastificadoFrenteDelta: null,
        plastificadoDelta: null,
        idPlastificadoFrenteMaquina: null,
        plastificadoVuelta: null,
        //plastificadoVueltaDelta: null,
        idPlastificadoVueltaMaquina: null,

        suajado: false,
        suajadoTipo: null,
        suajadoDelta: null,
        idSuajadoMaquina: null,
        IdSuajadoMMaquina: null,
        suajadoTablaSuaje: false,
        suajeTablaBase: null,
        suajeTablaAltura: null,
        suajeDesbarbeBase: null,
        suajeDesbarbeAltura: null,
        suajeCentimetros: null,
        suajeMatrix: null,

        corte: false,
        corteDelta: null,
        idCorteMaquina: null,

        empalme: false,
        empalmeMetros: null,
        empalmeDelta: null,
        idEmpalmeMaquina: null,

        pegado: false,
        idPegadoTipo: null,
        pegadoCms: null,
        pegadoDelta: null,
        idPegadoMaquina: null,

        pegadoGolpe: false,
        pegadoGolpeEsquinas: null,
        pegadoGolpeDelta: null,
        idPegadoGolpeMaquina: null,

        troquel: false,
        idTroquelTipo: null,
        troquelDelta: null,
        idTroquelMaquina: null,

        diametroVaso: null,

        variantes: null,
        anchoPapelVaso: null,
        largoPapelVaso: null,

        montoTransporte: null,
        observacionesTransporte: null,
        tipoMontoTransporte: null,

        empaqueC: null,
        empaqueP: null,

        envioPlanta: null,
        envioPlantaTipo: null,
        envioCliente: null,
        envioClienteTipo: null,
        utilidad: null,
        idHerramental: null,

        idPreprensa: null,
        idCostosFijos: null
      },
      dataPreCotizacion: [],
      dataClientes: [],
      dataFrecuencia: [],
      dataPapeles: [],
      //dataPapelesVasos: [],
      dataProveedoresPapel: [],
      dataProveedoresPapelVasos: [],
      proveedoresPapel: [],
      proveedoresPapelVasos: [],
      dataImpresiones: [],
      dataTintas: [],
      dataProveedoresTinta: [],
      dataExtras: [],
      dataBarnizBAgua: [],
      dataBarnizF: [],
      dataBarnizV: [],
      dataTransporte: [],
      dataUnidades: [],
      dataHojeadora: [],
      dataMaquinaVasosS: [],
      dataDelta: [
        {
          id: 1, value: "Sencillo",
        }, {
          id: 2, value: "Complejo",
        }
      ],
      dataCaras: [
        {
          id: 1,
          value: "Frente"
        }, {
          id: 2,
          value: "Vuelta"
        }
      ],
      dataBarnizUVPlasta: [
        {
          id: -1,
          value: "No"
        }, {
          id: 1,
          value: "Brillante"
        }, {
          id: 2,
          value: "Mate"
        }
      ],
      dataBarnizUVRegistro: [
        {
          id: -1,
          value: "No"
        }, {
          id: 1,
          value: "Brillante"
        }, {
          id: 2,
          value: "Mate"
        }
      ],
      dataPlastificado: [
        {
          id: -1,
          nombre: "No"
        }, {
          id: 1,
          nombre: "Mate"
        }, {
          id: 2,
          nombre: "Brillante"
        }
      ],      dataSumaExtras: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Sumar al Costo sin Utilidad",
        }
      ],
      dataSuajadoTipo: [
        {
          id: 1,
          value: "Con Desbarbe Automático"
        }, {
          id: 2,
          value: "Con Desbarbe Manual"
        }, {
          id: 3,
          value: "Sin Desbarbe"
        }
      ],
      dataTroquelTipo: [
        {
          id: 1,
          value: "Bobina"
        }, {
          id: 2,
          value: "Manual"
        }, {
          id: 3,
          value: "Automática"
        }
      ],
      dataSumaEnvio: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Sumar al Costo Sin Utilidad",
        }, {
          id: 3,
          value: "Cobrar por Separado",
        }, {
          id: 4,
          value: "Mostrarlo y no Cobrarlo",
        }, {
          id: 5,
          value: "No Mostrarlo ni Cobrarlo",
        },
      ],
      dataPreprensaSumaEnvio: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Cobrar por Separado",
        }, {
          id: 3,
          value: "Mostrarlo y no Cobrarlo",
        }, {
          id: 4,
          value: "No Mostrarlo ni Cobrarlo",
        },
      ],
      dataMaquinasImpresoras: [],
      dataMaquinasBarniz: [],
      dataMaquinasUvPlasta: [],
      dataMaquinasUvRegistro: [],
      dataMaquinasPlastificado: [],
      dataMaquinasSuaje: [],
      dataMaquinasSuajeManual: [],
      dataMaquinasCorte: [],
      dataMaquinasEmpalme: [],
      dataMaquinasPegado: [],
      dataMaquinasPegadoGolpe: [],
      dataMaquinasTroquelBobina: [],
      dataMaquinasTroquelManual: [],
      dataMaquinasTroquelAutomatica: [],
      dataCotizacion: [],
      dataInsumos:[],
      insumoCotizaciones:[],

      mermasCartulina: false,
      precioProveedorPapel: 0,
      precioMillar: 0,

      loading: false,
      loadingClientes: false,
      loadingUnidades: false,
      loadingFrecuancia: false,
      loadingProveedorPapel: false,
      loadingProveedorPapelVasosParedS: false,
      loadingTintas: false,
      loadingProveedorTinta: false,
      loadingMaquinasImpresion: false,
      loadingBarnizTipo: false,
      loadingCalcularCostos: false,

      urlPapeles: "/papeles/listar?",
      urlTintas: "/tintas/listar?",
      urlTintasDefault: "/tintas/listar?",
      urlBarniz: "/Barniz/Listar?",
      urlBarnizDefault: "/Barniz/Listar?",

      arrayDefault: [],
    }
  },
  methods: {
    nombreCliente(lista) {
      if (lista.clave) {
        return `[${lista.clave}] - ${lista.nombre}`;
      } else {
        return lista.nombre;
      }
    },
    nombreFrecuencia: (lista) => `${lista.nombre}`,
    nombreTipoTinta: (lista) => `${lista.tipoTinta}`,
    nombreTinta: (lista) => `[${lista.codigo}] - ${lista.nombre}`,
    nombreProveedorTinta: (item) => `[${item.idProveedor}] - ${item.nombreProveedor}`,
    nombreBarnizTipo(lista) {
      if (lista.nombre === "No") {
        return lista.nombre;
      } else {
        return `[${lista.codigo}] - ${lista.nombre}`;
      }
    },
    nombreBarniz: (lista) => `[${lista.codigo}] - ${lista.nombre}`,
    nombrePlastificado(lista) {
      if (lista.nombre === "No") {
        return lista.nombre;
      } else {
        return `${lista.nombre}`;
      }
    },
    nombreDefault: (lista) => `${lista.value}`,

    initialize() {
      this.getClientes();
      this.getUnidades();
      this.getFrecuencia();
      this.getPapeles();
      this.getTintas();
      this.getMaquinas();
      this.getBarniz();
      this.getBarnizTipo();
      this.getInsumos();
      this.getVasos();
    },

    validateCliente() {
      if (this.idClienteBase != this.dataPostAPI.idCliente){
        this.dialogCambioCliente = true;
      } else {
        this.validate();
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.show_calculoCosto()
      } else {
        this.alerta(422)
      }
    },

    getPreCotizacion() {
      this.loading = true;
      axios
        .get("/Precotizaciones/Mostrar/" + this.idPreCotizacion)
        .then((response) => {
          this.dataPreCotizacion = response.data;
          this.tipoPreCotizacionId = this.dataPreCotizacion.tipoPrecotizacion
          this.dataPostAPI.idCliente = this.dataPreCotizacion.idCliente
          this.dataPostAPI.idUnidad = this.dataPreCotizacion.idUnidad
          this.dataPostAPI.nombreTrabajo = this.dataPreCotizacion.nombreTrabajo
          this.dataPostAPI.cantidadPza = this.dataPreCotizacion.cantidadPza
          this.dataPostAPI.idFrecuencia = this.dataPreCotizacion.idFrecuencia
          this.dataPostAPI.pzIndividualLargo = this.dataPreCotizacion.largoPza
          this.dataPostAPI.pzIndividualAncho = this.dataPreCotizacion.anchoPza
          this.dataPostAPI.papelAlimenticio = this.dataPreCotizacion.gradoAlimenticio
          this.dataPostAPI.papelResistenteHumedad = this.dataPreCotizacion.resistenteHumedad
          this.dataPostAPI.idPapel = this.dataPreCotizacion.idArticulo
          //this.dataPostAPI.idPapelVasoParedS = this.dataPreCotizacion.idArticulo
          this.dataPostAPI.tintaAlimenticio = this.dataPreCotizacion.gradoAlimenticioImpresion
          this.dataPostAPI.tintaImpermeable = this.dataPreCotizacion.impermeableImpresion
          this.dataPostAPI.barniz = this.dataPreCotizacion.barniz
          this.dataPostAPI.barnizFrente = this.dataPreCotizacion.idBarnizTipoFrente
          this.dataPostAPI.barnizVuelta = this.dataPreCotizacion.idBarnizTipoVuelta
          this.dataPostAPI.barnizFrente2 = this.dataPreCotizacion.idBarnizFrente
          this.dataPostAPI.barnizVuelta2 = this.dataPreCotizacion.idBarnizVuelta
          this.dataPostAPI.uvPlasta = this.dataPreCotizacion.uvPlasta
          this.dataPostAPI.uvPlastaFrente = this.dataPreCotizacion.idUVPlastaFrente
          this.dataPostAPI.uvPlastaVuelta = this.dataPreCotizacion.idUVPlastaVuelta
          this.dataPostAPI.uvRegistro = this.dataPreCotizacion.uvRegistro
          this.dataPostAPI.uvRegistroFrente = this.dataPreCotizacion.idUVRegistroFrente
          this.dataPostAPI.uvRegistroVuelta = this.dataPreCotizacion.idUVRegistroVuelta
          this.dataPostAPI.plastificado = this.dataPreCotizacion.plastificado
          this.dataPostAPI.plastificadoFrente = this.dataPreCotizacion.idPlastificadoFrente
          this.dataPostAPI.plastificadoVuelta = this.dataPreCotizacion.idPlastificadoVuelta
          this.dataPostAPI.suajado = this.dataPreCotizacion.suajado
          this.dataPostAPI.empalme = this.dataPreCotizacion.empalme
          this.dataPostAPI.pegado = this.dataPreCotizacion.pegado
          this.dataPostAPI.idPegadoTipo = this.dataPreCotizacion.idTipoPegado
          this.dataPostAPI.pegadoCms = this.dataPreCotizacion.cmsPegadoLineal
          this.dataPostAPI.pegadoGolpe = this.dataPreCotizacion.pegadoGolpe
          this.dataPostAPI.pegadoGolpeEsquinas = this.dataPreCotizacion.numEsquinas
          this.dataPostAPI.troquel = this.dataPreCotizacion.troquel
          this.dataExtras = this.dataPreCotizacion.extraPrecotizacion
          this.dataPostAPI.variantes = this.dataPreCotizacion.especificaciones
          this.dataPostAPI.montoTransporte = this.dataPreCotizacion.monto
          this.dataPostAPI.observacionesTransporte = this.dataPreCotizacion.observaciones
          this.dataPostAPI.tipoMontoTransporte = this.dataPreCotizacion.tipoSumaTransporte
          this.dataTransporte = this.dataPreCotizacion.transporte;
          this.dataImpresiones = response.data.impresionTinta,
          this.dataPostAPI.diametroVaso = parseFloat(this.dataPreCotizacion.diametroVaso)  //Dato agregado para mostrarlo en la coti.
          //this.dataPostAPI.idVasoParedDoble = this.idVasoParedD

          this.dataPostAPI.idPlacaDelta = 1;
          this.dataPostAPI.idTintaDelta = 1;
          //this.dataPostAPI.barnizFrenteDelta = 1;
          this.dataPostAPI.barnizDelta = 1;
          //this.dataPostAPI.barnizVueltaDelta = 1;
          //this.dataPostAPI.uvPlastaFrenteDelta = 1;
          this.dataPostAPI.uvPlastaDelta = 1;
          //this.dataPostAPI.uvPlastaVueltaDelta = 1;
          //this.dataPostAPI.uvRegistroFrenteDelta = 1;
          this.dataPostAPI.uvRegistroDelta = 1;
          //this.dataPostAPI.uvRegistroVueltaDelta = 1;
          //this.dataPostAPI.plastificadoFrenteDelta = 1;
          this.dataPostAPI.plastificadoDelta = 1;
          //this.dataPostAPI.plastificadoVueltaDelta = 1;
          this.dataPostAPI.suajadoDelta = 1;
          this.dataPostAPI.corteDelta = 1;
          this.dataPostAPI.empalmeDelta = 1;
          this.dataPostAPI.pegadoDelta = 1;
          this.dataPostAPI.pegadoGolpeDelta = 1;
          this.dataPostAPI.troquelDelta = 1;

          this.idClienteBase = this.dataPostAPI.idCliente;
        })
        .catch((error) => {
          console.log(error);
          this.$emit("error_Cotizacion", error.response.status)
          this.hide_dialog()
        })
        .finally(() => {
          switch (this.tipoPreCotizacionId) {
            case 1:
              this.tipoPreCotizacionText = "General";
              break;
            case 2:
              this.tipoPreCotizacionText = "Vaso Pared Senc BF";
              break;
            case 3:
              this.tipoPreCotizacionText = "Vaso Pared Senc BC";
              break;
            case 4:
              this.tipoPreCotizacionText = "Vaso Pared Dob BC";
              break;
          }

          this.change_urlBarnizF(false);
          this.change_urlBarnizV(this.dataPostAPI.barnizVuelta, false);

          this.getProveedoresPapel();
          this.getProveedoresPapelVasos();

          for (const idx in this.dataImpresiones) {
            this.comprobarTinta(idx)
            this.autoSelectTintaProveedor(this.dataImpresiones[idx])
          }

          this.autoseleccionMaquinas();
          this.loading = false;
        })
    },
    getCotizacion() {
      //this.getVasos();
      this.loading = true;
      axios
        .get("/Cotizacion/Mostrar/" + this.idCotizacion)
        .then((response) => {
          this.dataPostAPI = response.data;
          this.dataImpresiones = response.data.impresionTinta;
          this.dataExtras = response.data.extraCotizaciones;
          this.tipoPreCotizacionId = response.data.tipoCotizacion;
          this.dataTransporte = response.data.transporteCotizaciones;

          this.dataPostAPI.barnizDelta =response.data.barnizFrenteDelta;
          this.dataPostAPI.uvPlastaDelta=response.data.uvPlastaFrenteDelta;
          this.dataPostAPI.uvRegistroDelta =response.data.uvRegistroFrenteDelta;
          this.dataPostAPI.plastificadoDelta=response.data.plastificadoFrenteDelta;
          this.idClienteBase = this.dataPostAPI.idCliente;
          //console.log(this.dataPostAPI);
        })
        .catch((error) => {
          console.log(error)
          this.$emit("error_Cotizacion", error.response.status)
          this.hide_dialog()
        })
        .finally(() => {
          switch (this.dataPostAPI.tipoPreCotizacionId) {
            case 1:
              this.tipoPreCotizacionText = "General";
              break;
            case 2:
              this.tipoPreCotizacionText = "Vaso Pared Senc BF";
              break;
            case 3:
              this.tipoPreCotizacionText = "Vaso Pared Senc BC";
              break;
            case 4:
              this.tipoPreCotizacionText = "Vaso Pared Dob BC";
              break;
          }

          this.change_urlBarnizF(false);
          this.change_urlBarnizV(this.dataPostAPI.barnizVuelta, false);


          this.precioMillar = this.dataPostAPI.costoMillar;
          //this.costoMillarpapel = this.dataPostAPI.costoMillar;
          this.mermasCartulina = this.dataPostAPI.mermasCartulina;

          const proveedorPapel = this.dataPostAPI.idProveedorPapel
          this.getProveedoresPapel();
          this.dataPostAPI.idProveedorPapel = proveedorPapel
          const proveedorPapelV = this.dataPostAPI.idProveedorPapelVasoParedS
          this.getProveedoresPapelVasos();
          this.dataPostAPI.idProveedorPapelVasoParedS = proveedorPapelV

          for (const idx in this.dataImpresiones) {
            this.comprobarTinta(idx)
            this.autoSelectTintaProveedor(this.dataImpresiones[idx])
          }

          this.insumoCotizaciones = this.dataPostAPI.insumoCotizaciones

          this.loading = false;
        })
    },

    getClientes() {
      this.loadingClientes = true;
      axios
        .get("/Clientes")
        .then((response) => {
          this.dataClientes = response.data
        })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.loadingClientes = false;
        })
    },
    getUnidades() {
      this.loadingUnidades = true;
      axios
        .get("/Unidades")
        .then((response) => {
          this.dataUnidades = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingUnidades = false;
        })
    },
    getFrecuencia() {
      this.loadingFrecuancia = true;
      axios
        .get("/Frecuencia/Listar")
        .then((response) => {
          this.dataFrecuencia = response.data;
        })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.loadingFrecuancia = false;
        })
    },
    getPapeles() {
      axios
        .get(this.urlPapeles)
        .then((response) => {
          this.dataPapeles = response.data
        })
        .catch((error) => { console.log(error) })
    },
    // getPapeleVasos() {
    //   axios
    //     .get(this.urlPapeles)
    //     .then((response) => {
    //       this.dataPapelesVasos = response.data
    //     })
    //     .catch((error) => { console.log(error) })
    // },
    getProveedoresPapel() {
      this.loadingProveedorPapel = true;
      this.dataPostAPI.idProveedorPapel = null;
      //this.dataPostAPI.idProveedorPapelVasoParedS = null;
      axios
        .get("/PapelProveedores/Listar/" + this.dataPostAPI.idPapel)
        .then((response) => {
          this.dataProveedoresPapel = response.data;
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingProveedorPapel = false;
          this.change_proveedorPapel()
        })
    },
    getProveedoresPapelVasos() {
      this.loadingProveedorPapelVasosParedS = true;
      this.dataPostAPI.idProveedorPapelVasoParedS = null;
      axios
        .get("/PapelProveedores/Listar/" + this.dataPostAPI.idPapelVasoParedS)
        .then((response) => {
          this.dataProveedoresPapelVasos = response.data;
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingProveedorPapelVasosParedS = false;
          this.change_proveedorPapelVasos()
        })
    },
    getTintas() {
      this.loadingTintas = true;
      axios
        .get(this.urlTintas)
        .then((response) => {
          this.dataTintas = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTintas = false;
        })
    },
    getMaquinas() {
      axios
        .get("/GKMaquina/Listar")
        .then((response) => {
          this.dataHojeadora = response.data.filter(item => item.proceso === "Hojeado");
          this.dataHojeadora?.unshift({ "idMaquina": -1, "nombre": "No" });
          this.dataMaquinasImpresoras = response.data.filter(item => item.proceso === "Impresión");
          this.dataMaquinasBarniz = response.data.filter(item => item.proceso === "Barnizado");
          this.dataMaquinasUvPlasta = response.data.filter(item => item.proceso === "UV Plasta");
          this.dataMaquinasUvRegistro = response.data.filter(item => item.proceso === "UV Registro");
          this.dataMaquinasPlastificado = response.data.filter(item => item.proceso === "Plastificado");
          this.dataMaquinasSuaje = response.data.filter(item => item.proceso === "Suajado");
          this.dataMaquinasSuajeManual = response.data.filter(item => item.proceso === "Suajado")
          this.dataMaquinasCorte = response.data.filter(item => item.proceso === "Corte");
          this.dataMaquinasEmpalme = response.data.filter(item => item.proceso === "Empalme");
          this.dataMaquinasPegado = response.data.filter(item => item.proceso === "Pegado Lineal");
          this.dataMaquinasPegadoGolpe = response.data.filter(item => item.proceso === "Pegado de Golpe");
          this.dataMaquinasTroquelBobina = response.data.filter(item => item.proceso === "Troquelado Bobina");
          this.dataMaquinasTroquelManual = response.data.filter(item => item.proceso === "Troquelado Manual");
          this.dataMaquinasTroquelAutomatica = response.data.filter(item => item.proceso === "Troquelado Automatica");
          this.dataMaquinaVasosS = response.data.filter(item => item.proceso === "Formadora Vaso Pared Sencilla");
        })
        .catch((error) => { console.log(error) })
    },
    getBarniz(){
      axios
      .get("/Barniz/Listar")
      .then((response) => {
        this.dataBarnizUVPlasta = response.data.filter((item) => item.grupoBarniz === "Barniz Uv Plasta");
        this.dataBarnizUVPlasta.unshift({ codigo: "N", nombre: "No", idArticulo: -1 });
        this.dataBarnizUVRegistro = response.data.filter((item) => item.grupoBarniz === "Barniz Uv Registro");
        this.dataBarnizUVRegistro.unshift({ codigo: "N", nombre: "No", idArticulo: -1 });

      })
      .catch((error) => {
        console.log(error)
      })
    },
    getBarnizTipo() {
      this.loadingBarnizTipo = true;
      axios
        .get("/BarnizTipo/Listar")
        .then((response) => {
          this.dataBarnizBAgua = response.data.filter((item) => item.barnizGrupo === "Barniz Base Agua");
          this.dataBarnizBAgua.unshift({ codigo: "N", nombre: "No", idBarnizTipo: -1 });
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingBarnizTipo = false;
        })
    },
    getTransportePreguntas() {
      axios
        .get("/TransportePreguntas/Listar")
        .then((response) => {
          this.dataTransporte = response.data
        })
        .catch((error) => { console.log(error); });
    },
    getInsumos(){
      axios
      .get("/GKMaquinaInsumos/Listar")
      .then((response)=>{
        this.dataInsumos = response.data;
      })
      .catch((error)=>{
        console.log(error)
      })

    },

    getVasos() {
      //this.loadingTable = true;
      axios
        .get("/Vasos/Listar")
        .then((response) => {
          this.vasos = response.data;
          //console.log(this.idVasoParedD);
        })
        .catch((error) => { console.log(error.response) })
    },

    comprobarSecadoMaquina() {
      if (this.dataPostAPI.idImpresionMaquina) {
        this.dataPostAPI.secadoImpresora = this.dataImpresorasFiltradas.filter((item) => item.idMaquina === this.dataPostAPI.idImpresionMaquina)[0].secado
      } else {
        this.dataPostAPI.idImpresionMaquina = null;
        this.dataPostAPI.secadoImpresora = false;
      }
    },
    
    change_urlBarnizF(cambioBarnizadora) {
      this.urlBarniz = this.urlBarnizDefault;
      if (this.dataPostAPI.barnizFrente && this.dataPostAPI.barnizFrente >= 0) {
        this.urlBarniz += `idTipoBarniz=${this.dataPostAPI.barnizFrente}&idBarnizGrupo=4`;
        axios
          .get(this.urlBarniz)
          .then((response) => {
            this.dataBarnizF = response.data;
          })
          .catch((error) => {
            console.log(error) 
          })
          .finally(() => {
            this.dataPostAPI.barnizFrenteSecado = this.dataBarnizBAgua.filter((item) => item.idBarnizTipo === this.dataPostAPI.barnizFrente)[0].secado ? this.dataBarnizBAgua.filter((item) => item.idBarnizTipo === this.dataPostAPI.barnizFrente)[0].secado : null;
            if (cambioBarnizadora) {
              if (this.dataPostAPI.secadoImpresora && this.dataPostAPI.barnizFrenteSecado && this.dataPostAPI.idImpresionMaquina) {
                let barnizadoras = this.DataBarnizadorasFiltradas.filter((item) => item.idMaquina === this.dataPostAPI.idImpresionMaquina);
                this.dataPostAPI.idBarnizFrenteMaquina = barnizadoras[0].idMaquina ? barnizadoras[0].idMaquina : null;
              } else {
                this.dataPostAPI.idBarnizFrenteMaquina = null;
              }
            }
          })
      }
    },
    change_urlBarnizV(selecTipoBarniz, cambioBarnizadora) {
      this.urlBarniz = this.urlBarnizDefault;
      if (selecTipoBarniz && selecTipoBarniz >= 0) {
        this.urlBarniz += `idTipoBarniz=${selecTipoBarniz}&idBarnizGrupo=4`;
        axios
          .get(this.urlBarniz)
          .then((response) => {
            this.dataBarnizV = response.data;
          })
          .catch((error) => { 
            console.log(error)
          })
          .finally(() => {
            this.dataPostAPI.barnizVueltaSecado = this.dataBarnizBAgua.filter((item) => item.idBarnizTipo === selecTipoBarniz)[0].secado ? this.dataBarnizBAgua.filter((item) => item.idBarnizTipo === selecTipoBarniz)[0].secado : null;
            if (cambioBarnizadora) {
              if (this.dataPostAPI.secadoImpresora && this.dataPostAPI.barnizVueltaSecado && this.dataPostAPI.idImpresionMaquina) {
                let barnizadoras = this.DataBarnizadorasFiltradas.filter((item) => item.idMaquina === this.dataPostAPI.idImpresionMaquina);
                this.dataPostAPI.idBarnizVueltaMaquina = barnizadoras[0].idMaquina ? barnizadoras[0].idMaquina : null;
              } else {
                this.dataPostAPI.idBarnizVueltaMaquina = null;
              }
            }
          })
      }
    },

    addTinta() {
      this.dataImpresiones.push({ idCara: null, tipoTinta: null, idTintaTipo: null, tintas: [], tinta: null, proveedores: [], idProveedor: [], percentTinta: null })
    },
    deleteTinta(item) {
      var idx = this.dataImpresiones.indexOf(item);
      if (idx < 0) return;
      this.dataImpresiones.splice(idx, 1);
    },

    addExtra() {
      this.dataExtras.push({ concepto: null, descripcion: null, tipo: null, monto: null });
    },
    deleteExtra(item) {
      var idx = this.dataExtras.indexOf(item);
      if (idx < 0) return;
      this.dataExtras.splice(idx, 1);
    },

    change_gramosProveedor() {
      const gramaje = this.dataProveedoresPapel.find((item) => item.idProveedor === this.dataPostAPI.idProveedorPapel).gramaje
      if (gramaje < 151) {
        this.mermasCartulina = false;
      } else {
        this.mermasCartulina = true;
      }
      this.dataPostAPI.idImpresionMaquina = null;
      this.reset_maquinas();
    },
    change_gramosProveedorVasos() {
      const gramaje = this.dataProveedoresPapelVasos.find((item) => item.idProveedor === this.dataPostAPI.idProveedorPapelVasoParedS).gramaje
      if (gramaje < 151) {
        this.mermasCartulina = false;
      } else {
        this.mermasCartulina = true;
      }
      //this.dataPostAPI.idImpresionMaquina = null;
      //this.reset_maquinas_vasos();
    },
    reset_maquinas() {
      this.dataPostAPI.idHojeadora = null;
      //this.dataPostAPI.idHojeeadora = null,
      //this.dataPostAPI.idVasoParedSMaquina = null;
      this.dataPostAPI.idImpresionMaquina = null;
      this.dataPostAPI.idBarnizFrenteMaquina = null;
      this.dataPostAPI.idBarnizVueltaMaquina = null;
      this.dataPostAPI.idUVPlastaFrenteMaquina = null;
      this.dataPostAPI.idUVPlastaVueltaMaquina = null;
      this.dataPostAPI.idUVRegistroFrenteMaquina = null;
      this.dataPostAPI.idUVRegistroVueltaMaquina = null;
      this.dataPostAPI.idPlastificadoFrenteMaquina = null;
      this.dataPostAPI.idPlastificadoVueltaMaquina = null;
      this.dataPostAPI.idSuajadoMaquina = null;
      this.dataPostAPI.IdSuajadoMMaquina = null;
      this.dataPostAPI.idCorteMaquina = null;
      this.dataPostAPI.idEmpalmeMaquina = null;
      this.dataPostAPI.idPegadoMaquina = null;
      this.dataPostAPI.idPegadoGolpeMaquina = null;
      this.dataPostAPI.idTroquelMaquina = null;
      this.loadingMaquinasImpresion = true;
    },

    change_urlTintas(reset, item) {
      this.urlTintas = this.urlTintasDefault;
      const selectExtras = [];

      if (reset) { this.dataImpresiones = JSON.parse(JSON.stringify(this.arrayDefault)); }

      if (this.dataPostAPI.tintaAlimenticio) { selectExtras.push(1); }
      if (this.dataPostAPI.tintaImpermeable) { selectExtras.push(2); }

      if (selectExtras.length > 0) {
        this.urlTintas += this.urlTintas.endsWith("?") ? "" : "&";
        this.urlTintas += selectExtras.map(item => 'idsExtras=' + item).join('&');
      }

      this.getTintas();

      if (item) {
        const idx = this.dataImpresiones.indexOf(item);

        if (this.dataPostAPI.tintaAlimenticio && this.dataPostAPI.tintaImpermeable) {
          this.dataImpresiones[idx].tintas = this.dataTintas.filter((obj) => obj.idTintaTipo === this.dataImpresiones[idx].idTintaTipo && obj.gradoAlimenticio === this.dataPostAPI.tintaAlimenticio && obj.impermeable === this.dataPostAPI.tintaImpermeable);
        }
        else if (this.dataPostAPI.tintaAlimenticio && !this.dataPostAPI.tintaImpermeable) {
          this.dataImpresiones[idx].tintas = this.dataTintas.filter((obj) => obj.idTintaTipo === this.dataImpresiones[idx].idTintaTipo && obj.gradoAlimenticio === this.dataPostAPI.tintaAlimenticio);
        }
        else if (this.dataPostAPI.tintaImpermeable && !this.dataPostAPI.tintaAlimenticio) {
          this.dataImpresiones[idx].tintas = this.dataTintas.filter((obj) => obj.idTintaTipo === this.dataImpresiones[idx].idTintaTipo && obj.impermeable === this.dataPostAPI.tintaImpermeable);
        }
        else if (!this.dataPostAPI.tintaAlimenticio && !this.dataPostAPI.tintaImpermeable) {
          this.dataImpresiones[idx].tintas = this.dataTintas.filter((obj) => obj.idTintaTipo === this.dataImpresiones[idx].idTintaTipo);
        }
        this.dataImpresiones[idx].tintas = this.dataTintas.filter((obj) => obj.idTintaTipo === this.dataImpresiones[idx].idTintaTipo);

        this.dataImpresiones[idx].idArticulo = null;
        this.dataImpresiones[idx].idProveedor = null;
        this.dataImpresiones[idx].proveedores = [];
      }
    },
    comprobarTinta(idx) {
      if (!this.dataImpresiones[idx].tintas.find((element => element.idArticulo === this.dataImpresiones[idx].idArticulo))) {
        this.dataImpresiones[idx].idArticulo = null;
        this.dataImpresiones[idx].proveedores = [];
        this.dataImpresiones[idx].idProveedor = null;
      }
      if (!this.dataImpresiones[idx].proveedores.find((element => element.idProveedor === this.dataImpresiones[idx].idProveedor))) {
        this.dataImpresiones[idx].idProveedor = null
      }
    },
    autoSelectTintaProveedor(item) {
      if (item.proveedores.length === 1) {
        item.idProveedor = item.proveedores[0].idProveedor
      }
    },
    change_tinta(item) {
      let dataProveedoresTinta = [];
      const idx = this.dataImpresiones.indexOf(item);
      item.idProveedor = null

      this.loadingProveedorTinta = true;
      axios
        .get("/TintaProveedores/Listar/" + parseInt(item.idArticulo))
        .then((response) => {
          dataProveedoresTinta = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          if (this.dataPostAPI.tintaAlimenticio && this.dataPostAPI.tintaImpermeable) {
            this.dataImpresiones[idx].proveedores = dataProveedoresTinta.filter((obj) => obj.gradoAlimenticio === this.dataPostAPI.tintaAlimenticio && obj.impermeable === this.dataPostAPI.tintaImpermeable);
          }
          else if (this.dataPostAPI.tintaAlimenticio && !this.dataPostAPI.tintaImpermeable) {
            this.dataImpresiones[idx].proveedores = dataProveedoresTinta.filter((obj) => obj.gradoAlimenticio === this.dataPostAPI.tintaAlimenticio);
          }
          else if (this.dataPostAPI.tintaImpermeable && !this.dataPostAPI.tintaAlimenticio) {
            this.dataImpresiones[idx].proveedores = dataProveedoresTinta.filter((obj) => obj.impermeable === this.dataPostAPI.tintaImpermeable);
          }
          else if (!this.dataPostAPI.tintaAlimenticio && !this.dataPostAPI.tintaImpermeable) {
            this.dataImpresiones[idx].proveedores = dataProveedoresTinta;
          }

          if (this.dataImpresiones[idx].proveedores.length === 1) {
            this.dataImpresiones[idx].idProveedor = this.dataImpresiones[idx].proveedores[0].idProveedor
          } else {
            this.dataImpresiones[idx].idProveedor = null;
          }
          this.loadingProveedorTinta = false;
        })
    },
    change_proveedorPapel() {
      if (this.dataPostAPI.papelAlimenticio && this.dataPostAPI.papelResistenteHumedad) {
        this.proveedoresPapel = this.dataProveedoresPapel.filter((obj) => obj.gradoAlimenticio && obj.resistenciaHumedad);
      } else if (this.dataPostAPI.papelAlimenticio && !this.dataPostAPI.papelResistenteHumedad) {
        this.proveedoresPapel = this.dataProveedoresPapel.filter((obj) => obj.gradoAlimenticio)
      } else if (this.dataPostAPI.papelResistenteHumedad && !this.dataPostAPI.papelAlimenticio) {
        this.proveedoresPapel = this.dataProveedoresPapel.filter((obj) => obj.resistenciaHumedad)
      } else {
        this.proveedoresPapel = this.dataProveedoresPapel
      }
    },
    change_proveedorPapelVasos() {
      if (this.papelAlimenticio && this.papelResistenteHumedad) {
        this.proveedoresPapelVasos = this.dataProveedoresPapelVasos.filter((obj) => obj.gradoAlimenticio && obj.resistenciaHumedad);
      } else if (this.papelAlimenticio && !this.papelResistenteHumedad) {
        this.proveedoresPapelVasos = this.dataProveedoresPapelVasos.filter((obj) => obj.gradoAlimenticio)
      } else if (this.papelResistenteHumedad && !this.papelAlimenticio) {
        this.proveedoresPapelVasos = this.dataProveedoresPapelVasos.filter((obj) => obj.resistenciaHumedad)
      } else {
        this.proveedoresPapelVasos = this.dataProveedoresPapelVasos
      }
    },

    change_condicionTransporte(index) {
      let pregunta = this.dataTransporte.find((item) => item.idTransportePregunta === index);
      if (!pregunta.respuesta) {
        pregunta.monto = 0;
        this.change_montoTransporte();
      }
    },
    change_montoTransporte() {
      this.dataPostAPI.montoTransporte = this.dataTransporte.reduce((total, item) => total + parseFloat(item.monto || 0), 0);
    },

    show_dialog(idpreCotizacion) {
      this.initialize();
      this.dataPostAPI = JSON.parse(JSON.stringify(this.dataPostAPIDefault));
      this.idPreCotizacion = idpreCotizacion;
      this.dialogOption = "Nueva Cotización - "
      this.dialog = true;

      this.getPreCotizacion();
      this.readOnlyData = false;
      this.autoseleccionMaquinas();
    },
    show_Cotizacion(idCotizacion) {
      this.initialize()

      this.idCotizacion = idCotizacion;
      this.dialogOption = "Visualizar Cotización"
      this.dialog = true;
      this.readOnlyData = true;

      this.getCotizacion();
    },
    show_editCotizacion(idPreCotizacion, idCotizacion) {
      this.initialize();

      this.idPreCotizacion = idPreCotizacion;
      this.idCotizacion = idCotizacion;
      this.dialogOption = "Editar Cotización";
      this.dialog = true;
      this.readOnlyData = false;

      this.getCotizacion();
    },

    show_calculoCosto() {
      const copiaJSON = new Promise((resolve, reject) => {
        try {
          this.dataCotizacion = this.jsonDataApi;
          resolve();
        } catch (error) {
          reject(error);
        }
      })
      copiaJSON
        .then(() => {
          this.$refs.dialogCalculoCosto.postCalculoCostos();
        })
        .catch((error) => {
          console.log(error)
        })
    },
    error_calculoCosto(code) {
      this.alerta(code)
    },

    filtrarMaquinas(data) {
      let gramajePapel = this.dataProveedoresPapel.find(item => item.idProveedor === this.dataPostAPI.idProveedorPapel)?.gramaje;
      for (let i in data) {
        let limiteGramajes = data[i].limiteGramajes;
        let MinGramaje = data[i].gramajeInferior;
        let MaxGramaje = data[i].gramajeSuperior;

        let maxMinPapel = data[i].maxMinPapel;
        let MaxPapelBase = data[i].medidaMaxPapelBase;
        let MinPapelBase = data[i].medidaMinPapelBase;

        let MaxPapelAltura = data[i].medidaMaxPapelAltura;
        let MinPapelAltura = data[i].medidaMinPapelAltura;

        let minImagen = data[i].minImagen;
        let MaxImagenBase = data[i].medidaMaxImagenBase;
        let MaxImagenAltura = data[i].medidaMaxImagenAltura;

        data[i].problemas = []
        data[i].disabled = false;

        if (limiteGramajes && MinGramaje > gramajePapel) {
          data[i].problemas.push('Papel muy delgado')
          data[i].disabled = true;
        }
        if (limiteGramajes && MaxGramaje < gramajePapel) {
          data[i].problemas.push('Papel muy grueso')
          data[i].disabled = true;
        }
        if (maxMinPapel && MaxPapelBase < parseFloat(this.dataPostAPI.tamanoAncho)) {
          data[i].problemas.push('Papel muy ancho')
          data[i].disabled = true;
        }
        if (maxMinPapel && MaxPapelAltura < parseFloat(this.dataPostAPI.tamanoHilo)) {
          data[i].problemas.push('Papel muy largo')
          data[i].disabled = true;
        }
        if (maxMinPapel && MinPapelBase > parseFloat(this.dataPostAPI.tamanoAncho)) {
          data[i].problemas.push('Papel poco ancho')
          data[i].disabled = true;
        }
        if (maxMinPapel && MinPapelAltura > parseFloat(this.dataPostAPI.tamanoHilo)) {
          data[i].problemas.push('Papel poco largo')
          data[i].disabled = true;
        }
        if (minImagen && MaxImagenBase < parseFloat(this.dataPostAPI.impresionLargo)) {
          data[i].problemas.push('Imagen muy ancha')
          data[i].disabled = true;
        }
        if (minImagen && MaxImagenAltura < parseFloat(this.dataPostAPI.impresionAncho)) {
          data[i].problemas.push('Imagen muy larga')
          data[i].disabled = true;
        }
      }
      return data;
    },

    hide_dialog() {
      this.dataProveedoresPapel = JSON.parse(JSON.stringify(this.arrayDefault));
      this.dataProveedoresPapelVasos = JSON.parse(JSON.stringify(this.arrayDefault));
      this.dataImpresiones = JSON.parse(JSON.stringify(this.arrayDefault));
      this.dataExtras = JSON.parse(JSON.stringify(this.arrayDefault));
      this.$refs.form.reset();
      this.dataPostAPI = JSON.parse(JSON.stringify(this.dataPostAPIDefault));
      this.tipoPreCotizacionId = null;
      this.tipoPreCotizacionText = null;

      this.dialog = false;
    },
    succes_cotizacion() {
      this.dataProveedoresPapel = JSON.parse(JSON.stringify(this.arrayDefault));
      this.dataProveedoresPapelVasos = JSON.parse(JSON.stringify(this.arrayDefault));
      this.dataImpresiones = JSON.parse(JSON.stringify(this.arrayDefault));
      this.dataExtras = JSON.parse(JSON.stringify(this.arrayDefault));
      this.$refs.form.reset();
      this.dataPostAPI = JSON.parse(JSON.stringify(this.dataPostAPIDefault));
      this.tipoPreCotizacionId = this.tipoPreCotizacionText = null;
      this.$emit("succes_cotizacion");
      this.dialog = false;
    },

    cambio_Barniz(){
      if(!this.dataPostAPI.barniz){
        this.dataPostAPI.barnizFrente = -1;
        this.dataPostAPI.barnizFrente2 = null;
        //this.dataPostAPI.barnizFrenteDelta = null;
        this.dataPostAPI.barnizDelta = null;
        this.dataPostAPI.barnizVuelta = -1;
        this.dataPostAPI.barnizVuelta2 = null;
        //this.dataPostAPI.barnizVueltaDelta = null;
      }
    },
    cambio_BarnizFrente(){
      if(this.dataPostAPI.barnizFrente < 0){
        this.dataPostAPI.barnizFrente2 = null;
        //this.dataPostAPI.barnizFrenteDelta = null;
        this.dataPostAPI.barnizDelta = null;
        this.dataPostAPI.idBarnizFrenteMaquina = null;
      }
    },
    cambio_BarnizVuelta(){
      if(this.dataPostAPI.barnizVuelta < 0) {
        this.dataPostAPI.barnizVuelta2 = null;
        this.dataPostAPI.barnizDelta = null;
        //this.dataPostAPI.barnizVueltaDelta = null;
        this.dataPostAPI.idBarnizVueltaMaquina = null;
      }
    },
    cambio_uvPlasta(){
      if (!this.dataPostAPI.uvPlasta) {
        this.dataPostAPI.uvPlastaFrente = null;
        this.dataPostAPI.uvPlastaVuelta = null;
        //this.dataPostAPI.uvPlastaFrenteDelta = null;
        //this.dataPostAPI.uvPlastaVueltaDelta = null;
        this.dataPostAPI.uvPlastaDelta = null;
        this.dataPostAPI.idUVPlastaFrenteMaquina = null;
        this.dataPostAPI.idUVPlastaVueltaMaquina = null;
      }
    },
    cambio_uvPlastaFrente(){
      if(this.dataPostAPI.uvPlastaFrente < 0){
        
      } else {
        //this.dataPostAPI.uvPlastaFrenteDelta = null;
        this.dataPostAPI.uvPlastaDelta = null;
        this.dataPostAPI.idUVPlastaFrenteMaquina = null;
      }
    },
    cambio_uvPlastaVuelta(){
      if (this.dataPostAPI.uvPlastaVuelta < 0) {
        console.log("cambio")
        //this.dataPostAPI.uvPlastaVueltaDelta = null;
        this.dataPostAPI.uvPlastaDelta = null;
        this.dataPostAPI.idUVPlastaVueltaMaquina = null;
      }
    },
    cambio_uvRegistro(){
      if(!this.dataPostAPI.uvRegistro){
        this.dataPostAPI.uvRegistroFrente = null;
        //this.dataPostAPI.uvRegistroFrenteDelta = null;
        this.dataPostAPI.uvRegistroDelta = null;
        this.dataPostAPI.idUVRegistroFrenteMaquina = null;
        this.dataPostAPI.uvRegistroVuelta = null;
        //this.dataPostAPI.uvRegistroVueltaDelta = null;
        this.dataPostAPI.idUVRegistroVueltaMaquina = null;
      }
    },
    cambio_uvRegistroFrente(){
      if(this.dataPostAPI.uvRegistroFrente < 0){
        //this.dataPostAPI.uvRegistroFrenteDelta = null;
        this.dataPostAPI.uvRegistroDelta = null;
        this.dataPostAPI.idUVRegistroFrenteMaquina = null;
      }
    },
    cambio_uvRegistroVuelta(){
      if (this.dataPostAPI.uvRegistroVuelta < 0) {
        //this.dataPostAPI.uvRegistroVueltaDelta = null;
        this.dataPostAPI.uvRegistroDelta = null;
        this.dataPostAPI.idUVRegistroVueltaMaquina = null;
      }
    },
    cambio_plastificado(){
      if (!this.dataPostAPI.plastificado) {
        this.dataPostAPI.plastificadoFrente = null;
        //this.dataPostAPI.plastificadoFrenteDelta = null;
        this.dataPostAPI.plastificadoDelta = null;
        this.dataPostAPI.idPlastificadoFrenteMaquina = null;
        this.dataPostAPI.plastificadoVuelta = null;
        //this.dataPostAPI.plastificadoVueltaDelta = null;
        this.dataPostAPI.idPlastificadoVueltaMaquina = null;
      }
    },
    cambio_plastificadoFrente(){
      if (this.dataPostAPI.plastificadoFrente < 0) {
        //this.dataPostAPI.plastificadoFrenteDelta = null;
        this.dataPostAPI.plastificadoDelta = null;
        this.dataPostAPI.idPlastificadoFrenteMaquina = null;
      }
    },
    cambio_plastificadoVuelta(){
      if (this.dataPostAPI.plastificadoVuelta < 0) {
        //this.dataPostAPI.plastificadoVueltaDelta = null;
        this.dataPostAPI.plastificadoDelta = null;
        this.dataPostAPI.idPlastificadoVueltaMaquina = null;
      }
    },
    cambio_suajado(){
      if(!this.dataPostAPI.suajado){
        this.dataPostAPI.suajadoTipo = null;
        this.dataPostAPI.suajadoDelta = null;
        this.dataPostAPI.idSuajadoMaquina = null;
        this.dataPostAPI.IdSuajadoMMaquina = null;
        this.dataPostAPI.suajadoTablaSuaje = false;

        this.dataPostAPI.suajeTablaBase = null;
        this.dataPostAPI.suajeTablaAltura = null;
        this.dataPostAPI.suajeDesbarbeBase = null;
        this.dataPostAPI.suajeDesbarbeAltura = null;
        this.dataPostAPI.suajeCentimetros = null;
        this.dataPostAPI.suajeMatrix = null;
      }
    },
    cambio_suajadoTablaSuaje(){
      if (!this.dataPostAPI.suajadoTablaSuaje) {
        this.dataPostAPI.suajeTablaBase = null;
        this.dataPostAPI.suajeTablaAltura = null;
        this.dataPostAPI.suajeDesbarbeBase = null;
        this.dataPostAPI.suajeDesbarbeAltura = null;
        this.dataPostAPI.suajeCentimetros = null;
        this.dataPostAPI.suajeMatrix = null;
      }
    },
    cambio_corte(){
      if (!this.dataPostAPI.corte) {
        this.dataPostAPI.corteDelta = null;
        this.dataPostAPI.idCorteMaquina = null;
      }
    },
    cambio_empalme(){
      if (!this.dataPostAPI.empalme) {
        this.dataPostAPI.empalmeMetros = null;
        this.dataPostAPI.empalmeDelta = null;
        this.dataPostAPI.idEmpalmeMaquina = null;
      }
    },
    cambio_pegado(){
      if (!this.dataPostAPI.pegado) {
        this.dataPostAPI.idPegadoTipo = null;
        this.dataPostAPI.pegadoCms = null;
        this.dataPostAPI.pegadoDelta = null;
        this.dataPostAPI.idPegadoMaquina = null;
      } else {
        this.dataPostAPI.pegadoGolpe = false;
        this.cambio_pegadoGolpe();
      }
    },
    cambio_pegadoGolpe(){
      if (!this.dataPostAPI.pegadoGolpe) {
        this.dataPostAPI.pegadoGolpeEsquinas = null;
        this.dataPostAPI.pegadoGolpeDelta = null;
        this.dataPostAPI.idPegadoGolpeMaquina = null;
      } else {
        this.dataPostAPI.pegado = false;
        this.cambio_pegado();
      }
    },
    cambio_troquel(){
      if (!this.dataPostAPI.troquel) {
        this.dataPostAPI.idTroquelTipo = null;
        this.dataPostAPI.troquelDelta = null;
        this.dataPostAPI.idTroquelMaquina = null;
      }
    },
    cambio_idTroquelTipo(){
      if (this.dataPostAPI.idTroquelTipo) {
        this.dataPostAPI.idTroquelMaquina = null;
      }
    },

    autoseleccionMaquinas(){
          if(this.DataHojeadoraFiltradas.length === 1){this.dataPostAPI.idHojeadora = this.DataHojeadoraFiltradas[0].idMaquina}
          if(this.dataImpresorasFiltradas.length === 1){this.dataPostAPI.idImpresionMaquina = this.dataImpresorasFiltradas[0].idMaquina}
          if(this.DataBarnizadorasFiltradas.length === 1){this.dataPostAPI.idBarnizFrenteMaquina = this.DataBarnizadorasFiltradas[0].idMaquina}
          if(this.DataBarnizadorasFiltradas.length === 1){this.dataPostAPI.idBarnizVueltaMaquina = this.DataBarnizadorasFiltradas[0].idMaquina}
          if(this.DataUvPlastaFiltradas.length === 1){this.dataPostAPI.idUVPlastaFrenteMaquina = this.DataUvPlastaFiltradas[0].idMaquina}
          if(this.DataUvPlastaFiltradas.length === 1){this.dataPostAPI.idUVPlastaVueltaMaquina = this.DataUvPlastaFiltradas[0].idMaquina}
          if(this.DataUvRegistroFiltradas.length === 1){this.dataPostAPI.idUVRegistroFrenteMaquina = this.DataUvRegistroFiltradas[0].idMaquina}
          if(this.DataUvRegistroFiltradas.length === 1){this.dataPostAPI.idUVRegistroVueltaMaquina = this.DataUvRegistroFiltradas[0].idMaquina}
          if(this.DataPlastificadoFiltradas.length === 1){this.dataPostAPI.idPlastificadoFrenteMaquina = this.DataPlastificadoFiltradas[0].idMaquina}
          if(this.DataPlastificadoFiltradas.length === 1){this.dataPostAPI.idPlastificadoVueltaMaquina = this.DataPlastificadoFiltradas[0].idMaquina}
          if(this.DataSuajadoraFiltradas.length === 1){this.dataPostAPI.idSuajadoMaquina = this.DataSuajadoraFiltradas[0].idMaquina}
          if(this.DataSuajadoraManualFiltradas.length === 1){this.dataPostAPI.IdSuajadoMMaquina = this.DataSuajadoraManualFiltradas[0].idMaquina}
          if(this.DataCorteFiltradas.length === 1){this.dataPostAPI.idCorteMaquina = this.DataCorteFiltradas[0].idMaquina}
          if(this.DataEmpalemFiltradas.length === 1){this.dataPostAPI.idEmpalmeMaquina = this.DataEmpalemFiltradas[0].idMaquina}
          if(this.DataPegadoLinealFiltradas.length === 1){this.dataPostAPI.idPegadoMaquina = this.DataPegadoLinealFiltradas[0].idMaquina}
          if(this.DataPegadoGolpeFiltradas.length === 1){this.dataPostAPI.idPegadoGolpeMaquina = this.DataPegadoGolpeFiltradas[0].idMaquina}
          if(this.DataTroquelBobinaFiltradas.length === 1){this.dataPostAPI.idTroquelMaquina = this.DataTroquelBobinaFiltradas[0].idMaquina}
          if(this.DataTroquelManualFiltradas.length === 1){this.dataPostAPI.idTroquelMaquina = this.DataTroquelManualFiltradas[0].idMaquina}
          if(this.DataTroquelAutomaticoFiltradas.length === 1){this.dataPostAPI.idTroquelMaquina = this.DataTroquelAutomaticoFiltradas[0].idMaquina}
          if(this.dataMaquinasVasosSFiltradas.length === 1){this.dataPostAPI.idVasoParedSMaquina = this.dataMaquinasVasosSFiltradas[0].idMaquina}
    },


    alerta(code) {
      this.$refs.AlertSnackbar.state_alert(code);
    },
  },
  /*watch: {
    'dataPostAPI.idProveedorPapel'(newValue) {
      let proveedor = null;
      if (newValue) {
        proveedor = this.dataProveedoresPapel.find((item) => item.idProveedor === newValue);
      }
      if (proveedor) {
        this.precioProveedorPapel = proveedor.precio;
      } else {
        this.precioProveedorPapel = 0;
      }
      if (this.dataPostAPI.pliegoAncho && this.dataPostAPI.pliegoHilo && this.precioProveedorPapel) {
        let costo = 0;
        let areaPliego = (this.dataPostAPI.pliegoAncho) * (this.dataPostAPI.pliegoHilo);
        costo = (areaPliego) * (this.precioProveedorPapel) * (1000);

        this.precioMillar = costo.toFixed(2);
      } else {
        this.precioMillar = 0;
      }
    },
  },*/
  computed: {
    costoMillarpapel() {
      if(this.precioMillar > 0){
        return this.precioMillar
      } else{
        
        let proveedor = null;
      if (this.dataPostAPI.idProveedorPapel) {
        
        proveedor = this.dataProveedoresPapel.find((item) => item.idProveedor === this.dataPostAPI.idProveedorPapel);
      }
      if (proveedor) {
        
        this.precioProveedorPapel = proveedor.precio;
      } else {
        
        this.precioProveedorPapel = 0;
      }

      let ancho = this.dataPostAPI.pliegoAncho;
      let hilo = this.dataPostAPI.pliegoHilo;

      if (ancho && hilo && this.precioProveedorPapel) {
        let costo = 0;
        let areaPliego = (this.dataPostAPI.pliegoAncho) * (this.dataPostAPI.pliegoHilo);
        costo = (areaPliego) * (this.precioProveedorPapel) * (1000);
        return costo.toFixed(2);
      } else {
        
        return 0;
      }
      }
    },
    referenciaKgPapel(){
      let proveedor = null;
      let gramaje = null;

      if (this.dataPostAPI.idProveedorPapel) {
        proveedor = this.dataProveedoresPapel.find((item) => item.idProveedor === this.dataPostAPI.idProveedorPapel);
      }

      gramaje = proveedor ? proveedor.gramaje : 0;
      let ancho = this.dataPostAPI.pzIndividualLargo;
      let hilo = this.dataPostAPI.pzIndividualAncho;
      let piezas = this.dataPostAPI.cantidadPza;

      if (ancho && hilo && gramaje) {
        let kilos = 0;
        let area = ancho * hilo * this.dataPostAPI.cantidadPza;
        kilos = (area) * (gramaje / 1000);
        return kilos.toFixed(2);
      } else {
        return 0;
      }
    },
    jsonImpresiones() {
      const dataImpresiones = this.dataImpresiones.map(item => {
        return {
          idCara: item.idCara,
          idTintaTipo: item.idTintaTipo,
          idArticulo: item.idArticulo,
          idProveedor: item.idProveedor,
          porcentajeTinta: parseFloat(item.porcentajeTinta)
        }
      })
      return dataImpresiones
    },
    jsonTransporte() {
      const dataTransporte = this.dataTransporte.map(item => ({
        idTransportePregunta: item.idTransportePregunta,
        respuesta: item.respuesta,
        monto: parseFloat(item.monto)
      }));
      return dataTransporte;
    },
    jsonConceptos() {
      const dataConceptos = this.dataExtras.map(item => {
        return {
          concepto: item.concepto,
          tipo: parseInt(item.tipo),
          monto: parseFloat(item.monto),
          descripcion: item.descripcion,
        }
      })
      return dataConceptos
    },
    jsonMermas() {
      const mermas = [];
      if (this.dataImpresiones.length > 0) {
        mermas.push({
          idProceso: this.dataMaquinasImpresoras.find(item => item.proceso === "Impresión").idProceso,
        })
      }
      if (this.dataPostAPI.barniz) {
        mermas.push({
          idProceso: this.dataMaquinasBarniz.find(item => item.proceso === "Barnizado").idProceso,
        })
      }
      if (this.dataPostAPI.uvPlasta) {
        mermas.push({
          idProceso: this.dataMaquinasUvPlasta.find(item => item.proceso === "UV Plasta").idProceso,
        })
      }
      if (this.dataPostAPI.uvRegistro) {
        mermas.push({
          idProceso: this.dataMaquinasUvRegistro.find(item => item.proceso === "UV Registro").idProceso,
        })
      }
      if (this.dataPostAPI.plastificado) {
        mermas.push({
          idProceso: this.dataMaquinasPlastificado.find(item => item.proceso === "Plastificado").idProceso,
        })
      }
      if (this.dataPostAPI.suajado) {
        mermas.push({
          idProceso: this.dataMaquinasSuaje.find(item => item.proceso === "Suajado").idProceso,
        })
      }
      if (this.dataPostAPI.corte) {
        mermas.push({
          idProceso: this.dataMaquinasCorte.find(item => item.proceso === "Corte").idProceso,
        })
      }
      if (this.dataPostAPI.empalme) {
        mermas.push({
          idProceso: this.dataMaquinasEmpalme.find(item => item.proceso === "Empalme").idProceso,
        })
      }
      if (this.dataPostAPI.pegado) {
        mermas.push({
          idProceso: this.dataMaquinasPegado.find(item => item.proceso === "Pegado Lineal").idProceso,
        })
      }
      if (this.dataPostAPI.pegadoGolpe) {
        mermas.push({
          idProceso: this.dataMaquinasPegadoGolpe.find(item => item.proceso === "Pegado de Golpe").idProceso,
        })
      }
      if (this.dataPostAPI.troquel) {
        switch (this.dataPostAPI.idTroquelTipo) {
          case 1:
            mermas.push(
              {
                idProceso: this.dataMaquinasTroquelBobina.find(item => item.proceso === "Troquelado Bobina").idProceso,
              })
            break
          case 2:
            mermas.push(
              {
                idProceso: this.dataMaquinasTroquelManual.find(item => item.proceso === "Troquelado Manual").idProceso,
              })
            break
          case 3:
            mermas.push(
              {
                idProceso: this.dataMaquinasTroquelAutomatica.find(item => item.proceso === "Troquelado Automatica").idProceso,
              })
            break
        }
      }
      return mermas;
    },
    jsonDataApi() {
      return {
        idCliente: parseInt(this.dataPostAPI.idCliente),
        idPrecotizacion: this.idPreCotizacion,
        idUnidad: 1,
        tipoCotizacion: this.tipoPreCotizacionId,
        nombreTrabajo: this.dataPostAPI.nombreTrabajo,
        cantidadPza: this.dataPostAPI.cantidadPza,
        idFrecuencia: parseInt(this.dataPostAPI.idFrecuencia),

        pliegoAncho: parseFloat(this.dataPostAPI.pliegoAncho),
        pliegoHilo: parseFloat(this.dataPostAPI.pliegoHilo),
        tamanoAncho: parseFloat(this.dataPostAPI.tamanoAncho),
        tamanoHilo: parseFloat(this.dataPostAPI.tamanoHilo),
        impresionLargo: parseFloat(this.dataPostAPI.impresionLargo),
        impresionAncho: parseFloat(this.dataPostAPI.impresionAncho),
        pzIndividualLargo: parseFloat(this.dataPostAPI.pzIndividualLargo),
        pzIndividualAncho: parseFloat(this.dataPostAPI.pzIndividualAncho),
        piezasPinza: parseFloat(this.dataPostAPI.piezasPinza),
        piezasAlto: parseFloat(this.dataPostAPI.piezasAlto),

        papelAlimenticio: this.dataPostAPI.papelAlimenticio,
        papelResistenteHumedad: this.dataPostAPI.papelResistenteHumedad,
        //papelResistenteHumedad: this.papelResistenteHumedad,
        //papelAlimenticio: this.papelAlimenticio,
        idPapel: parseInt(this.dataPostAPI.idPapel),
        idPapelVasoParedS: parseInt(this.dataPostAPI.idPapelVasoParedS),
        idProveedorPapel: parseInt(this.dataPostAPI.idProveedorPapel),
        idProveedorPapelVasoParedS: parseInt(this.dataPostAPI.idProveedorPapelVasoParedS),
        idHojeadora: this.dataPostAPI.idHojeadora,
        costoMillar: parseFloat(this.costoMillarpapel),
        tamanoPliego: parseFloat(this.dataPostAPI.tamanoPliego),
        tamanoBarnizPlastificado: parseFloat(this.dataPostAPI.tamanoBarnizPlastificado),
        tamanoSuaje: parseFloat(this.dataPostAPI.tamanoSuaje),
        ptjeProteccionPapel: parseFloat(this.dataPostAPI.ptjeProteccionPapel),

        placaFrente: parseFloat(this.dataPostAPI.placaFrente),
        placaVuelta: parseFloat(this.dataPostAPI.placaVuelta),
        idPlacaDelta: parseInt(this.dataPostAPI.idPlacaDelta),
        tintaAlimenticio: this.dataPostAPI.tintaAlimenticio,
        tintaImpermeable: this.dataPostAPI.tintaImpermeable,
        idTintaDelta: parseInt(this.dataPostAPI.idPlacaDelta),
        idImpresionMaquina: parseInt(this.dataPostAPI.idImpresionMaquina),

        impresionInsumos: this.dataPostAPI.impresionInsumos,
        maquinaInsumos: this.dataInsumosImpresion,
        insumosCotizaciones: this.dataInsumosImpresion,
        secadoImpresora: this.dataPostAPI.secadoImpresora,
        barniz: this.dataPostAPI.barniz,
        barnizFrente: parseInt(this.dataPostAPI.barnizFrente),
        barnizFrente2: parseInt(this.dataPostAPI.barnizFrente2),
        //barnizFrenteDelta: parseInt(this.dataPostAPI.barnizFrenteDelta),
        barnizFrenteDelta: parseInt(this.dataPostAPI.barnizDelta),
        idBarnizFrenteMaquina: parseInt(this.dataPostAPI.idBarnizFrenteMaquina),
        barnizFrenteSecado: this.dataPostAPI.barnizFrenteSecado,
        idSecadoFrente: this.dataPostAPI.idSecadoFrente ? this.dataPostAPI.idSecadoFrente : null,
        barnizVuelta: parseInt(this.dataPostAPI.barnizVuelta),
        barnizVuelta2: parseInt(this.dataPostAPI.barnizVuelta2),
        //barnizVueltaDelta: parseInt(this.dataPostAPI.barnizVueltaDelta),
        barnizVueltaDelta: parseInt(this.dataPostAPI.barnizDelta),
        idBarnizVueltaMaquina: parseInt(this.dataPostAPI.idBarnizVueltaMaquina),
        barnizVueltaSecado: this.dataPostAPI.barnizVueltaSecado,
        idSecadoVuelta: this.dataPostAPI.idSecadoVuelta ? this.dataPostAPI.idSecadoVuelta : null,

        uvPlasta: this.dataPostAPI.uvPlasta,
        uvPlastaFrente: parseInt(this.dataPostAPI.uvPlastaFrente),
        //uvPlastaFrenteDelta: parseInt(this.dataPostAPI.uvPlastaFrenteDelta),
        idUVPlastaFrenteMaquina: parseInt(this.dataPostAPI.idUVPlastaFrenteMaquina),
        uvPlastaVuelta: parseInt(this.dataPostAPI.uvPlastaVuelta),
        //uvPlastaVueltaDelta: parseInt(this.dataPostAPI.uvPlastaVueltaDelta),
        uvPlastaFrenteDelta: parseInt(this.dataPostAPI.uvPlastaDelta),
        idUVPlastaVueltaMaquina: parseInt(this.dataPostAPI.idUVPlastaVueltaMaquina),

        uvRegistro: this.dataPostAPI.uvRegistro,
        uvRegistroFrente: parseInt(this.dataPostAPI.uvRegistroFrente),
        //uvRegistroFrenteDelta: parseInt(this.dataPostAPI.uvRegistroFrenteDelta),
        uvRegistroFrenteDelta: parseInt(this.dataPostAPI.uvRegistroDelta),
        idUVRegistroFrenteMaquina: parseInt(this.dataPostAPI.idUVRegistroFrenteMaquina),
        uvRegistroVuelta: parseInt(this.dataPostAPI.uvRegistroVuelta),
        //uvRegistroVueltaDelta: this.dataPostAPI.uvRegistroVueltaDelta,
        uvRegistroVueltaDelta: parseInt(this.dataPostAPI.uvRegistroDelta),
        idUVRegistroVueltaMaquina: this.dataPostAPI.idUVRegistroVueltaMaquina,

        plastificado: this.dataPostAPI.plastificado,
        plastificadoFrente: parseInt(this.dataPostAPI.plastificadoFrente),
        //plastificadoFrenteDelta: parseInt(this.dataPostAPI.plastificadoFrenteDelta),
        plastificadoFrenteDelta: parseInt(this.dataPostAPI.plastificadoDelta),
        idPlastificadoFrenteMaquina: parseInt(this.dataPostAPI.idPlastificadoFrenteMaquina),
        plastificadoVuelta: parseInt(this.dataPostAPI.plastificadoVuelta),
        //plastificadoVueltaDelta: parseInt(this.dataPostAPI.plastificadoVueltaDelta),
        plastificadoVueltaDelta: parseInt(this.dataPostAPI.plastificadoDelta),
        idPlastificadoVueltaMaquina: parseInt(this.dataPostAPI.idPlastificadoVueltaMaquina),

        suajado: this.dataPostAPI.suajado,
        suajadoTipo: this.dataPostAPI.suajadoTipo,
        suajadoDelta: this.dataPostAPI.suajadoDelta,
        idSuajadoMaquina: this.dataPostAPI.idSuajadoMaquina,
        IdSuajadoMMaquina: this.dataPostAPI.IdSuajadoMMaquina,
        suajadoTablaSuaje: this.dataPostAPI.suajadoTablaSuaje,
        suajeTablaBase: this.dataPostAPI.suajeTablaBase,
        suajeTablaAltura: this.dataPostAPI.suajeTablaAltura,
        suajeDesbarbeBase: this.dataPostAPI.suajeDesbarbeBase,
        suajeDesbarbeAltura: this.dataPostAPI.suajeDesbarbeAltura,
        suajeCentimetros: this.dataPostAPI.suajeCentimetros,
        suajeMatrix: this.dataPostAPI.suajeMatrix,

        corte: this.dataPostAPI.corte,
        corteDelta: this.dataPostAPI.corteDelta,
        idCorteMaquina: this.dataPostAPI.idCorteMaquina,

        empalme: this.dataPostAPI.empalme,
        empalmeMetros: parseFloat(this.dataPostAPI.empalmeMetros),
        empalmeDelta: parseInt(this.dataPostAPI.empalmeDelta),
        idEmpalmeMaquina: parseInt(this.dataPostAPI.idEmpalmeMaquina),

        pegado: this.dataPostAPI.pegado,
        idPegadoTipo: parseInt(this.dataPostAPI.idPegadoTipo),
        pegadoCms: parseFloat(this.dataPostAPI.pegadoCms),
        pegadoDelta: parseInt(this.dataPostAPI.pegadoDelta),
        idPegadoMaquina: parseInt(this.dataPostAPI.idPegadoMaquina),

        pegadoGolpe: this.dataPostAPI.pegadoGolpe,
        pegadoGolpeEsquinas: parseInt(this.dataPostAPI.pegadoGolpeEsquinas),
        pegadoGolpeDelta: parseInt(this.dataPostAPI.pegadoGolpeDelta),
        idPegadoGolpeMaquina: parseInt(this.dataPostAPI.idPegadoGolpeMaquina),

        troquel: this.dataPostAPI.troquel,
        idTroquelTipo: parseInt(this.dataPostAPI.idTroquelTipo),
        troquelDelta: this.dataPostAPI.troquelDelta,
        idTroquelMaquina: this.dataPostAPI.idTroquelMaquina,

        montoTransporte: this.dataPostAPI.montoTransporte,
        tipoMontoTransporte: this.dataPostAPI.tipoMontoTransporte,

        empaqueC: parseFloat(this.dataPostAPI.empaqueC),
        empaqueP: parseFloat(this.dataPostAPI.empaqueP),
        envioPlanta: parseFloat(this.dataPostAPI.envioPlanta),
        envioPlantaTipo: parseInt(this.dataPostAPI.envioPlantaTipo),
        envioCliente: parseFloat(this.dataPostAPI.envioCliente),
        envioClienteTipo: parseInt(this.dataPostAPI.envioClienteTipo),
        utilidad: parseFloat(this.dataPostAPI.utilidad),
        idHerramental: parseInt(this.dataPostAPI.idHerramental),
        idPreprensa: parseInt(this.dataPostAPI.idPreprensa),
        idCostosFijos: parseInt(this.dataPostAPI.idCostosFijos),

        diametroVaso: parseFloat(this.dataPostAPI.diametroVaso),
        anchoPapelVaso: parseFloat(this.dataPostAPI.anchoPapelVaso),
        largoPapelVaso: parseFloat(this.dataPostAPI.largoPapelVaso), 
        vasoParedSDelta: parseInt(this.dataPostAPI.vasoParedSDelta),
        idVasoParedSMaquina: parseInt(this.dataPostAPI.idVasoParedSMaquina), 
        idVasoParedDoble: parseInt(this.dataPostAPI.idVasoParedDoble),
        //IdPapelVasoParedS: parseInt(this.dataPostAPI.vasoParedSDelta),

        variantes: this.dataPostAPI.variantes,
        observacionesTransporte: this.dataPostAPI.observacionesTransporte,

        mermasCartulina: this.mermasCartulina,

        impresionTintaCotizaciones: this.jsonImpresiones,
        transporte: this.jsonTransporte,
        extrasCotizaciones: this.jsonConceptos,
        maquinaProcesos: this.jsonMermas,

        comentario: "string",
      }
    },

    DataHojeadoraFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataHojeadora);

      let idxMaquina = this.dataPostAPI.idHojeadora != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idHojeadora)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idHojeadora = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idHojeadora = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    dataImpresorasFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasImpresoras);

      let idxMaquina = this.dataPostAPI.idImpresionMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idImpresionMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idImpresionMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idImpresionMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    dataMaquinasVasosSFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinaVasosS);

      let idxMaquina = this.dataPostAPI.idVasoParedSMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idVasoParedSMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idVasoParedSMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idVasoParedSMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataBarnizadorasFiltradas() {
      let idx = 0;
      let maquinas = this.filtrarMaquinas(this.dataMaquinasBarniz);

      let idxMaquina = this.dataPostAPI.idBarnizFrenteMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idBarnizFrenteMaquina)) : null
      let idxMaquina1 = this.dataPostAPI.idBarnizVueltaMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idBarnizVueltaMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idBarnizFrenteMaquina = null;
      }
      if (idxMaquina1 && idxMaquina1 >= 0 && maquinas[idxMaquina1]?.disabled) {
        this.dataPostAPI.idBarnizVueltaMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idBarnizFrenteMaquina = unicaMaquina[0].idMaquina
      }
      if (idxMaquina1 && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idBarnizVueltaMaquina = unicaMaquina[0].idMaquina
      }

      maquinas.forEach(function (objeto) {
        objeto.idPosicion = idx++;
      });
      return maquinas
    },
    DataUvPlastaFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasUvPlasta);

      let idxMaquina = this.dataPostAPI.idUVPlastaFrenteMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idUVPlastaFrenteMaquina)) : null
      let idxMaquina1 = this.dataPostAPI.idUVPlastaVueltaMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idUVPlastaVueltaMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idUVPlastaFrenteMaquina = null;
      }
      if (idxMaquina1 && idxMaquina1 >= 0 && maquinas[idxMaquina1]?.disabled) {
        this.dataPostAPI.idUVPlastaVueltaMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idUVPlastaFrenteMaquina = unicaMaquina[0].idMaquina
      }
      if (idxMaquina1 && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idUVPlastaVueltaMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataUvRegistroFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasUvRegistro);

      let idxMaquina = this.dataPostAPI.idUVRegistroFrenteMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idUVRegistroFrenteMaquina)) : null
      let idxMaquina1 = this.dataPostAPI.idUVRegistroVueltaMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idUVRegistroVueltaMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idUVRegistroFrenteMaquina = null;
      }
      if (idxMaquina1 && idxMaquina1 >= 0 && maquinas[idxMaquina1]?.disabled) {
        this.dataPostAPI.idUVRegistroVueltaMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idUVRegistroFrenteMaquina = unicaMaquina[0].idMaquina
      }
      if (idxMaquina1 && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idUVRegistroVueltaMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataPlastificadoFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasPlastificado);

      let idxMaquina = this.dataPostAPI.idPlastificadoFrenteMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idPlastificadoFrenteMaquina)) : null
      let idxMaquina1 = this.dataPostAPI.idPlastificadoVueltaMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idPlastificadoVueltaMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idPlastificadoFrenteMaquina = null;
      }
      if (idxMaquina1 && idxMaquina1 >= 0 && maquinas[idxMaquina1]?.disabled) {
        this.dataPostAPI.idPlastificadoVueltaMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idPlastificadoFrenteMaquina = unicaMaquina[0].idMaquina
      }
      if (idxMaquina1 && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idPlastificadoVueltaMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataSuajadoraFiltradas() {
      //let maquinas = this.filtrarMaquinas(this.dataMaquinasSuaje);
      let filtradas = this.filtrarMaquinas(this.dataMaquinasSuaje);
      let maquinas = filtradas.filter(item => {
        return item.subprocesos.some(item => item.subproceso !== "Desbarbe Manual")
      })

      let idxMaquina = this.dataPostAPI.idSuajadoMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idSuajadoMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idSuajadoMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idSuajadoMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataSuajadoraManualFiltradas(){
      let filtradas = this.filtrarMaquinas(this.dataMaquinasSuajeManual);
      let maquinas = filtradas.filter(item => {
        return item.subprocesos.some(item => item.subproceso === "Desbarbe Manual")
      })

      let idxMaquina = this.dataPostAPI.IdSuajadoMMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.IdSuajadoMMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.IdSuajadoMMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.IdSuajadoMMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataCorteFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasCorte);

      let idxMaquina = this.dataPostAPI.idCorteMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idCorteMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idCorteMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idCorteMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataEmpalemFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasEmpalme);

      let idxMaquina = this.dataPostAPI.idEmpalmeMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idEmpalmeMaquina)) : null


      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idEmpalmeMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idEmpalmeMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataPegadoLinealFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasPegado);

      let idxMaquina = this.dataPostAPI.idPegadoMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idPegadoMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idPegadoMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idPegadoMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataPegadoGolpeFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasPegadoGolpe);

      let idxMaquina = this.dataPostAPI.idPegadoGolpeMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idPegadoGolpeMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idPegadoGolpeMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idPegadoGolpeMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataTroquelBobinaFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasTroquelBobina);

      let idxMaquina = this.dataPostAPI.idTroquelMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idTroquelMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idTroquelMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idTroquelMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataTroquelManualFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasTroquelManual);

      let idxMaquina = this.dataPostAPI.idTroquelMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idTroquelMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idTroquelMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idTroquelMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataTroquelAutomaticoFiltradas() {
      let maquinas = this.filtrarMaquinas(this.dataMaquinasTroquelAutomatica);

      let idxMaquina = this.dataPostAPI.idTroquelMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idTroquelMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idTroquelMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idTroquelMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    DataSecados() {
      if (this.dataPostAPI.secadoImpresora) {
        return this.dataImpresorasFiltradas.filter((item) => item.idMaquina === this.dataPostAPI.idImpresionMaquina)[0].secadoTipos
      } else {
        this.dataPostAPI.idSecadoFrente = null;
        this.dataPostAPI.idSecadoVuelta = null;
        return [];
      }
    },
    DataSecadoFrente(){
      if (this.dataPostAPI.idBarnizFrenteMaquina) {
        let maquinas = this.DataBarnizadorasFiltradas.filter((item) => item.idMaquina === this.dataPostAPI.idBarnizFrenteMaquina);
        return maquinas.flatMap(item => item.secadoTipos)
      }
    },
    DataSecadoVuelta(){
      if (this.dataPostAPI.idBarnizVueltaMaquina) {
        let maquinas = this.DataBarnizadorasFiltradas.filter((item) => item.idMaquina === this.dataPostAPI.idBarnizVueltaMaquina);
        return maquinas.flatMap(item => item.secadoTipos)
      }
    },

    dataInsumosImpresion() {
      let insumos = [];
      if (this.dataPostAPI.impresionInsumos){

        insumos = this.dataInsumos.filter(item => item.idMaquina === this.dataPostAPI.idImpresionMaquina)

        return insumos.map(item => {
          const insumoN = this.insumoCotizaciones.find(aplicaInsumo => aplicaInsumo.idMaquinaInsumos === item.idMaquinaInsumos);
          return {
            idMaquinaInsumos: item.idMaquinaInsumos,
            idMaquina: item.idMaquina,
            insumo: item.insumo,
            tipoInsumo: item.tipoInsumo,
            costo: item.costo,
            aplicaInsumo: insumoN ? insumoN.aplicaInsumo : false,
          };
        });
      }
      return insumos;
    },
  },
  mounted(){
    this.getMaquinas();
  }
}
</script>

<style>
.my-title {
  color: #5c5c5c;
  margin-top: 50px;
  padding-bottom: 10px;
  width: 100%;
}

.my-col{
  padding: 12px 12px 12px 12px !important;
}

.my-form {
  padding: 0 40px 20px 40px !important;
}

.my-button-form {
  height: 50px !important;
  width: calc(100% - 40px);
  margin: 10px 20px 20px 20px !important;
  background-color: #03A60E !important;
}

.my-card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 15px;
  width: 100%;
}

.text-center {
  line-height: 40px;
}
</style>