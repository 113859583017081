<template>
  <v-col cols="12" class="px-8">
    
    <v-row class="mt-0">
      <v-col cols="3" md="3" sm="3">
                <v-autocomplete
                  v-model="filtros.idsOTS"
                  :items="ots"
                  item-value="idTrabajoOrden"
                  item-text="clave"
                  label="Orden de Trabajo (Opcional)"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(3)"                  
                >
                </v-autocomplete>
              </v-col> 
      <v-col cols="3" class="align-self-end">
        <datePicker
          v-model="filtros.desde"
					format="YYYY-MM-DD"
					label="Desde (Obligatorio)"
					clearable
					maxToday
					@input="obtenerDatos"
					:disabled="cargando"
          :maxDate="filtros.hasta"
				></datePicker>
      </v-col> 
      <v-col cols="3" class="align-self-end">
        <datePicker
        v-model="filtros.hasta"
					format="YYYY-MM-DD"
					label="Hasta (Obligatorio)"
					clearable
					maxToday
					@input="obtenerDatos"
					:disabled="cargando"
          :minDate="filtros.desde"
				></datePicker>
      </v-col> 
      <v-col cols="3">
        <v-autocomplete
          v-model="filtros.idsTurnos"
          :items="turnos"
          item-value="idTurno"
          item-text="nombre"
          label="Turnos (Opcional)"
          hide-details
          clearable
          multiple
          chips
          deletable-chips
          :disabled="cargando"
          @input="obtenerDatos"
          @click:clear="resetCampo(1)"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="filtros.eficiencia"
          label="Eficiencia (Opcional)"
          :items="tiposEficiencia"
          :disabled="cargando"
          clearable
          hide-details
          style="padding-top: 22px"
          @change="obtenerDatos"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>
              <v-icon>mdi-target</v-icon> Filtros Avanzados (Opcionales)
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsAreas"
                  :items="areas"
                  item-value="idArea"
                  item-text="nombre"
                  label="Áreas"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(2)"
                >
                </v-autocomplete>
              </v-col> 
              
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsArticulos"
                  :items="articulos"
                  item-value="idArticulo"
                  :item-text="nombreArticulo"
                  label="Artículos"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(4)"
                >
                </v-autocomplete>
              </v-col> 
            </v-row>
            <v-row>
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsMaquinaInstancias"
                  :items="maquinasIns"
                  item-value="idMaquinaIns"
                  :item-text="nombreMaquina"
                  label="Maquinas Instancias"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(5)"
                >
                </v-autocomplete>
              </v-col> 
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsPersonas"
                  :items="operadores"
					        item-value="idPersona"
					        :item-text="nombrePersona"
                  label="Personas"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(6)"
                >
                </v-autocomplete>
              </v-col> 
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsClientes"
                  :items="clientes"
                  item-text="nombre"
                  item-value="idCliente"
                  label="Clientes"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(7)"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="4" md="4" sm="4">
                <v-select
                  v-model="filtros.extemporaneo"
                  :items="valoresExtemporaneo"
                  label="Extemporáneo"
                  hide-details
                  clearable
                  :disabled="cargando"
                  @change="obtenerDatos"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row>
      <download-btn
        className="my-4"
        color="blue darken-1"
        text
        @click="descargarReporte"
        label="Descargar Reporte de Calidad<br>y Eficiencia por Operador"
      />
    </v-row>
    <br>
    <v-dialog
      v-model="dialogEditHour"
      max-width="410"
      persistent
    >
    <v-card>
      <v-card-title class="text-h5">
        Editar fecha de inicio y finalización de la operación
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
        <VueCtkDateTimePicker
									id="fechaM1"
									v-model="inicio"
									label="Inicio"
									:max-date="fin"
									format="YYYY-MM-DD HH:mm"
									formatted="YYYY-MM-DD HH:mm"
									noHeader
									overlay
									buttonNowTranslation="Ahora"
                  :error="errores.errorInicio"
                  :error-messages="errores.errorInicioTexto"
								/>
          </v-col>
          <v-col cols="12" md="12">
        <VueCtkDateTimePicker
									id="fechaM2"
									v-model="fin"
									label="Fin"
									:min-date="inicio"
									format="YYYY-MM-DD HH:mm"
									formatted="YYYY-MM-DD HH:mm"
									noHeader
									overlay
									buttonNowTranslation="Ahora"
                  :error="errores.errorFin"
                  :error-messages="errores.errorFinTexto"
								/>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
        color="red darken-1"
          text
          @click="dialogEditHour = false"
          :loading="loadSave"
          :disabled="loadSave"
        >
          cancelar
        </v-btn>

        <v-btn
        color="green darken-1"
          text
          @click="sendDataDate()"
          :loading="loadSave"
          :disabled="loadSave"
        >
          guardar
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="reportes"
      :loading="cargandoTable"
      loading-text="Cargando... Espere por favor"
    >
      <template v-slot:[`item.extemporaneo`]="{ item }">
        <v-tooltip v-if="item.extemporaneo" bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="error"
            >
              mdi-clock-outline
            </v-icon>
          </template>
          <span class="white--text">Extemporáneo</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.tipoOperacion`]="{ item }">
        <div v-if="item.tipoOperacion == 1">Normal</div>
        <div v-if="item.tipoOperacion == 2">Liberación</div>
      </template>
    <template v-slot:[`item.proceso`]="{ item }">
				<div>
					<!-- Artículo: {{item.articuloProducir}} -->
					<!-- <br>
					Fase: {{item.articuloFase}} -->
          <div v-if="item.codigoArticulo != null">
            [{{item.codigoArticulo}}] - {{item.articuloProducir}}
          </div>
          <div v-else>{{item.articuloProducir}}</div>
				</div>
			</template>
      <template v-slot:[`item.paso`]="{ item }">
        <div v-if="item.codigoArticuloFase != null">
           [{{item.codigoArticuloFase}}] - {{item.paso}}
        </div>
        <div v-else >{{item.paso}}</div>
      </template>
      <template v-slot:[`item.operadores`]="{ item }">
        <ul>
          <li v-for="(op,idx) in item.operadores" :key="'op'-idx">{{op}}</li>
        </ul>
      </template>
      <template  v-slot:[`item.cantidad`]="{ item }">
        {{item.cantidad.toFixed(2)}} {{item.unidad}}
      </template>
      <template v-if="nombreEmpresa == 'MYC'" v-slot:[`item.auxiliar`]="{ item }">
        <p v-if="item.unidadAuxiliar == null || item.unidadAuxiliar == '' " >N/D</p>
        <p v-else-if="item.cantidadAuxiliar === null || item.cantidadAuxiliar === undefined || /^\s*$/.test(item.cantidadAuxiliar) === ''" > {{  0}} {{item.unidadAuxiliar}}</p>
        <p v-else >{{ item.cantidadAuxiliar.toFixed(2)}} {{item.unidadAuxiliar}}</p>
      </template>
      <template v-slot:[`item.cantidadNoOk`]="{ item }">
        {{item.cantidadNoOk.toFixed(2)}} {{item.unidad}}
      </template>
      <template v-slot:[`item.costoTotal`]="{ item }">
        ${{item.costoTotal.toFixed(2)}}
      </template>
      <template v-slot:[`item.eficiencia`]="{ item }">
        {{item.eficiencia.toFixed(2)}}%
      </template>
      <template v-slot:[`item.pagoDestajo`]="{ item }">
        ${{item.pagoDestajo.toFixed(2)}}
      </template>
      <template v-if="nombreEmpresa == 'MYC'" v-slot:[`item.tiempoParo`]="{ item }">
        {{item.tiempoParo == null ? 'N/D': item.tiempoParo}}
      </template>
      <template v-slot:[`item.causaTiempoParo`]="{ item }">
        {{ item.causaTiempoParo }}
        <p v-if="item.causaTiempoParo == null">N/D</p>
        <ul v-else>
          <li v-for="(op,idxs) in item.causaTiempoParo" :key="'osp'-idxs">{{op}}</li>
        </ul>
      </template>
      <template v-if="nombreEmpresa == 'MYC'" v-slot:[`item.merma`]="{ item }">
        {{ item.merma}}
      </template>
      <template v-if="nombreEmpresa == 'MYC'" v-slot:[`item.scrap`]="{ item }">
        {{ item.scrap}}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
					small
					class="mr-2"
					@click="showDialogEdit(item.idOperacionReal, item.inicio, item.fin)"
					>
						edit
					</v-icon>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import axios from "axios";
import ICountUp from "vue-countup-v2";
import moment from "moment";
import DownloadBtn from '../../DownloadBtn.vue';

export default {
	components: {
		ICountUp,
    DownloadBtn,
	},
  props: {
		active: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
    ...mapState({
       nombreEmpresa: 'nombreEmpresa',
     }),
		realizarPeticion() {
			return this.$utils.isValid(this.filtros.desde) && this.$utils.isValid(this.filtros.hasta);
		},
		tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    },
    sameDay(){
      var desde = this.$utils.isValid(this.filtros.desde) ? moment(this.filtros.desde, "YYYY-MM-DD") : moment(1);
      var hasta = this.$utils.isValid(this.filtros.hasta) ? moment(this.filtros.hasta, "YYYY-MM-DD") : moment(1);
      return desde.isSameOrAfter(moment(),'day') || hasta.isSameOrAfter(moment(),'day');
    }
	},
	data: () => ({
    valoresExtemporaneo: [
      {
        text: "SI",
        value: true
      },
      {
        text: "NO",
        value: false
      },
    ],
    tiposEficiencia: [
      {
        text: "Igual a 0%",
        value: 1
      },
      {
        text: "Entre 0% y 50%",
        value: 2
      },
      {
        text: "Entre 50% y 100%",
        value: 3
      },
      {
        text: "Más de 100%",
        value: 4
      }
    ],
    inicio: null,
    fin:null,
    cargandoTable:false,
		cargando: false,
    dialogEditHour: false,
    idOperacionReal: null,
    loadSave: false,
    errores:{
      errorInicio:false,
      errorInicioTexto: null,
      errorFin:false,
      errorFinTexto: null
    },
    filtros: {
      desde: null,
      hasta: null,
      idsTurnos: [],
      idsAreas: [],
      idsOTS: [],
      idsArticulos: [],
      idsMaquinaInstancias: [],
      idsPersonas: [],
      idsClientes: [],
      eficiencia: null,
      extemporaneo: null
    },
    turnos:[],
    areas:[],
    ots:[],
    articulos:[],
    maquinasIns:[],
    operadores:[],
    clientes:[],
    reportes: [],
    headers:[
      {
        text: "",
        align: "center",
        value: "extemporaneo"
      },
      {
        text: "Tipo de Operación",
        align: "center",
        sortable: true,
        value: "tipoOperacion",
      },
      { 
        text: "OT",
        align: "center",
        sortable: true,
        value: "ot",
      },
      { 
        text: "Área",
        align: "center",
        sortable: true,
        value: "area",
      },
      {
					text: "Proceso",
					align: "center",
					sortable: false,
					value: "proceso",
					width: '20%'
				},
      { 
        text: "Fase",
        align: "center",
        sortable: true,
        value: "paso",
      },
      { 
        text: "SubPaso",
        align: "center",
        sortable: true,
        value: "subpaso",
      },
      { 
        text: "Operadores",
        align: "center",
        sortable: true,
        value: "operadores",
      },
      { 
        text: "Inicio",
        align: "center",
        sortable: true,
        value: "inicio",
      },
      { 
        text: "Fin",
        align: "center",
        sortable: true,
        value: "fin",
      },
      { 
        text: "Cantidad Reportada",
        align: "center",
        sortable: true,
        value: "cantidad",
      },
      { 
        text: "Cantidad Auxiliar",
        align: "center",
        sortable: true,
        value: "auxiliar",
      },
      { 
        text: "Eficiencia",
        align: "center",
        sortable: true,
        value: "eficiencia",
      },
      { 
        text: "Pago Destajo",
        align: "center",
        sortable: true,
        value: "pagoDestajo",
      },
      { 
        text: "STD",
        align: "center",
        sortable: true,
        value: "std",
      },
      { 
        text: "Cantidad No Ok",
        align: "center",
        sortable: true,
        value: "cantidadNoOk",
      },
      { 
        text: "Tiempo Paro",
        align: "center",
        sortable: true,
        value: "tiempoParo",
      },
      { 
        text: "Causa Tiempo Paro",
        align: "center",
        sortable: true,
        value: "causaTiempoParo",
      },
      { 
        text: "Costo No Calidad",
        align: "center",
        sortable: true,
        value: "costoTotal",
      },
      { 
        text: "Merma",
        align: "center",
        sortable: true,
        value: "merma",
      },
      { 
        text: "Scrap",
        align: "center",
        sortable: true,
        value: "scrap",
      },
      { 
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "actions",
      }
    ],
  }),
	methods: {
    nombreArticulo:(articulo) => `[${articulo.codigo}] - ${articulo.nombre}`,
    nombreMaquina: maquina => ` [${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
    nombrePersona: (persona) => persona.nombre + " " + persona.paterno + " " + persona.materno,
    deleteHeaders(){ 
      if (this.nombreEmpresa.toLowerCase() != 'myc') {
          this.headers = this.headers.filter(function(header) {
          return header.text !== "Tiempo Paro"  && header.text !== "Cantidad Auxiliar" &&
           header.text !== "Merma"&&
           header.text !== "Scrap";
      });
    }
    },
    initialize() {
      this.getTurnos();
      this.getAreas();
      this.getOTS();
      this.getArticulos();
      this.getMaquinasIns();
      this.getOperadores();
      this.getClientes();
		},
    resetCampo(posicion){
      switch (posicion) {
        case 1:
          this.filtros.idsTurnos = [];
          break;
        case 2:
          this.filtros.idsAreas = [];
          break;
        case 3:
          this.filtros.idsOTS = [];
          break;
        case 4:
          this.filtros.idsArticulos = [];
          break;
        case 5:
          this.filtros.idsMaquinaInstancias = [];
          break;
        case 6:
          this.filtros.idsPersonas = [];
          break;
        case 7:
          this.filtros.idsClientes = [];
          break;
        default:
          break;
      }
    },
    url(path) {
      var url = `${path}?Desde=${this.filtros.desde}&Hasta=${this.filtros.hasta}`;
      url += this.$utils.arrays.urlQuery(this.filtros.idsTurnos,"IdsTurnos",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsAreas,"IdsAreas",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsOTS,"IdsOTS",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsArticulos,"IdsArticulos",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsMaquinaInstancias,"IdsMaquinasInstancias",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsPersonas,"IdsPersonas",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsClientes,"IdsClientes",false);
      if(this.filtros.eficiencia != null ) url += `&IdEdiciencia=${this.filtros.eficiencia}`
      
      return url;
		},
		obtenerDatos() {
    //if (!this.realizarPeticion || this.cargando) return;      
      //Se consideran los casos en los que la funcion que manda a llamer el back nos regresaria un mensaje de error
      if((this.filtros.desde == null && this.filtros.hasta == null && this.filtros.idsOTS.length == 0 ) ||
        (this.filtros.desde == null && this.filtros.hasta != null && this.filtros.idsOTS.length == 0) || //
        (this.filtros.desde != null && this.filtros.hasta == null && this.filtros.idsOTS.length == 0) ){ //
          //Si llegase a pasar alguno de esos casos, no se llama al back y se vacia la lista
          this.reportes=[];
          
      }else{
        //Animacion de carga, si cargando y cargando table estan como true se habilita la animación de carga
        this.cargando = true;
        this.cargandoTable=true;

        //Funcion axios para obtener el listado de la base de datos
        axios
        .get(this.url("/Reportes/ReporteCalidadSubpasos"), {
          params: {
            extemporaneo: this.filtros.extemporaneo
          }
        })
        .then(response => { //Se procede a cargar los datos que se reciben del back a this.reportes para poder visualizarlos
          this.reportes = response.data;
          this.cargando = false;
          this.cargandoTable=false;
        })
        .catch(error => {
          console.log(error);
          this.cargando = false;          
        })
      }      
		},
    descargarReporte(){
      var datetime = moment().format('YYYYMMDDHHmmss');
      this.$utils.axios.downloadFile(this.url("/Reportes/XlsxReporteCalidadSubpasos"),'GET','reporte-suboperaciones-'+datetime+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    },
    getAreas() {
			axios
			.get("/Areas")
			.then((response) => {
				this.areas = response.data.filter(a => a.idArea > 1);
			})
			.catch((error) => {
				console.log(error);
			});
		},
		getTurnos() {
			axios
			.get("/Turnos")
			.then((response) => {
				this.turnos = response.data;
			})
			.catch((error) => {
				console.log(error);
			});
		},
    getOTS() {
      var url = `/TrabajoOrdenes/TrabajoOrdenHeaders?soloEnProgreso=${false}`;
			axios
				.get(url)
				.then((response) => {
					this.ots = response.data;
          idsOTS = this.idsOTS;
				})
				.catch((error) => {
					console.log(error);
				});
		},
    getArticulos() {
			axios
				.get("/Articulos/OTS")
				.then((response) => {
					this.articulos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
    getMaquinasIns(){
      axios
      .get("/MaquinaInstancias")
      .then(response => {
        this.maquinasIns = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getOperadores() {
			axios
			.get("/Personas/Operadores")
			.then((response) => {
				this.operadores = response.data;
			})
			.catch((error) => {
				console.log(error);
			});
		},
    getClientes(){
      axios
      .get("/Clientes")
      .then(response => {
        response.data.unshift({
          idCliente: -1,
          nombre: this.nombreEmpresa,
        });
        this.clientes = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    showDialogEdit(item, inicio, fin){
      this.inicio = new Date(inicio.replace(' ', 'T') + ':00');
      this.fin = new Date(fin.replace(' ', 'T') + ':00');
      this.idOperacionReal = item;
      this.dialogEditHour = true;
    },
    sendDataDate(){
      if(!this.validar()) return;

      this.loadSave = true;
      axios
          .post("/Operadores/ModificarFehaHoraOMO/" + this.idOperacionReal, {
            Inicio: this.inicio,
            Fin: this.fin,
          })
          .then(() => {
            this.obtenerDatos();
            this.inicio = null;
            this.fin = null;
            this.errores.errorInicio = false;
            this.errores.errorInicioTexto = null;
            this.errores.errorFin = false;
            this.errores.errorFinTexto = null;
            this.idOperacionReal = null;
            this.dialogEditHour = false;
            this.loadSave = false;
          })
          .catch((error) => {
            this.loadSave = false;
            console.log(error);
          });
    },
    validar(){
      if(this.inicio == null && this.fin == null){
        this.errores.errorInicio = true;
        this.errores.errorInicioTexto = "La fecha de Inicio es obligatorio";
        this.errores.errorFin = true;
        this.errores.errorFinTexto = "La fecha de Fin es obligatorio";

        return false;
        
      }else if(this.inicio == null && this.fin != null) {
        this.errores.errorInicio = true;
        this.errores.errorFin = false;
        this.errores.errorInicioTexto = "La fecha de Inicio es obligatorio";
        return false;
      }else if(this.inicio != null && this.fin == null) {
        this.errores.errorInicio = false;
        this.errores.errorFin = true;
        this.errores.errorFinTexto = "La fecha de Fin es obligatorio";
        return false;
      }else{
        this.errores.errorInicio = false;
        this.errores.errorInicioTexto = null;
        this.errores.errorFin = false;
        this.errores.errorFinTexto = null;
        return true;
      }
    }
	},
  mounted() {
		this.initialize();
    this.deleteHeaders()
	},
};
</script>