<template>
  <div v-if="permisoVista('insumos','r')">
    <v-btn v-if="permisoVista('insumos','c')" color="primary" @click="show_dialog()">Crear Nuevo Tipo de Barniz</v-btn>
    <v-data-table :headers="headers" :search="search" :items="dataBarnizTipo" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex justify-center">
          <v-text-field v-model="search" v-bind="propsSearch" class="mr-4" />
          <v-autocomplete v-model="searchIdBarnizGrupo" :items="dataBarnizGrupo" item-text="nombre"
            item-value="idBarnizGrupo" label="Grupo de Barniz" v-bind="propsAutocomplete"
            style="width: 40% !important;" @change="change_url()" />
        </div>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip v-if="permisoVista('insumos','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip v-if="permisoVista('insumos','d')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1"
              @click="show_dialogDelete(item)">mdi-trash-can-outline</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>{{ optionDialog }} Tipo de Barniz</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="barnizTipoCodigo" label="Código" v-bind="propsText" />
            <v-autocomplete v-model="idBarnizGrupo" label="Grupo de Barniz" :items="dataBarnizGrupo" item-text="nombre"
              item-value="idBarnizGrupo" v-bind="propsAutocomplete" />
            <v-text-field v-model="barnizTipoNombre" label="Nombre" v-bind="propsText" />
            <v-checkbox v-model="barnizSecado" label="¿Requiere Secado?" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text @click="hide_dialog()">Cancelar</v-btn>
          <v-btn :loading="loadingDialog" color="error" text @click="validate()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500" persistent>
      <v-card>
        <v-card-title>Eliminar Tipo de Barniz</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "[{{ barnizTipoCodigo }}] -
          {{ barnizTipoNombre }}"?</v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialogDelete" color="blue darken-1" class="ml-auto" text
            @click="hide_dialogDelete()">Cancelar</v-btn>
          <v-btn :loading="loadingDialogDelete" color="error" text @click="deleteBarnizTipo()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>

<script>
import axios from 'axios';
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {
    AlertSnackbar,
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: true,
      },
      propsText: {
        outlined: false,
        dense: false,
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      propsAutocomplete: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },


      dialog: false,
      dialogDelete: false,
      optionDialog: null,

      barnizTipoId: null,
      barnizTipoCodigo: null,
      idBarnizGrupo: null,
      barnizTipoNombre: null,
      barnizSecado: false,

      search: '',
      searchIdBarnizGrupo: null,

      headers: [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false
        }, {
          text: "Grupo",
          value: "barnizGrupo",
          align: "center",
          sortable: false
        }, {
          text: "Nombre",
          value: "nombre",
          align: "center",
          sortable: false
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false
        },
      ],

      dataBarnizTipo: [],
      dataBarnizGrupo: [],

      urlBarnizTipo: "/BarnizTipo/Listar",
      urlBarnizTipoBase: "/BarnizTipo/Listar",

      loadingTable: false,
      loadingDialog: false,
      loadingDialogDelete: false,
    }
  },
  methods: {
    initialize() {
      this.getBarnizTipo();
      this.getBarnizGrupo();
    },

    validate() {
      if (this.$refs.form.validate()) this.optionDialog == 'Crear' ? this.postBarnizTipo() : this.putBarnizTipo()
    },

    getBarnizTipo() {
      this.loadingTable = true;
      axios
        .get(this.urlBarnizTipo)
        .then((response) => {
          this.dataBarnizTipo = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    getBarnizGrupo() {
      axios
        .get("/BarnizGrupo/Listar")
        .then((response) => {
          this.dataBarnizGrupo = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    postBarnizTipo() {
      this.loadingDialog = true;
      axios
        .post("/BarnizTipo/Crear", {
          idBarnizGrupo: this.idBarnizGrupo,
          codigo: this.barnizTipoCodigo,
          nombre: this.barnizTipoNombre,
          secado: this.barnizSecado,
        })
        .then((response) => {
          this.hide_dialog();
          this.getBarnizTipo()
          //this.alerta(200);
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putBarnizTipo() {
      this.loadingDialog = true;
      axios
        .put("/BarnizTipo/Actualizar", {
          idBarnizGrupo: this.idBarnizGrupo,
          idBarnizTipo: this.barnizTipoId,
          codigo: this.barnizTipoCodigo,
          nombre: this.barnizTipoNombre,
          secado: this.barnizSecado,
        })
        .then((response) => {
          this.hide_dialog();
          this.getBarnizTipo()
          //this.alerta(200);
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteBarnizTipo() {
      this.loadingDialogDelete = true;
      axios
        .put("/BarnizTipo/Desactivar/" + this.barnizTipoId)
        .then((response) => {
          this.hide_dialogDelete();
          this.getBarnizTipo()
          //this.alerta(200);
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialogDelete = false;
        })
    },

    show_dialog(item) {
      this.dialog = true;
      this.optionDialog = item ? "Editar" : "Crear";
      if (item) {
        this.idBarnizGrupo = item.idBarnizGrupo;
        this.barnizTipoId = item.idBarnizTipo;
        this.barnizTipoCodigo = item.codigo;
        this.barnizTipoNombre = item.nombre;
        this.barnizSecado = item.secado
      }
    },
    hide_dialog() {
      this.$refs.form?.reset();
      this.idBarnizGrupo = null;
      this.barnizTipoId = null;
      this.barnizTipoCodigo = null;
      this.barnizTipoNombre = null;
      this.barnizSecado = false;
      this.dialog = false;
    },

    show_dialogDelete(item) {
      this.barnizTipoId = item.idBarnizTipo;
      this.barnizTipoCodigo = item.codigo;
      this.barnizTipoNombre = item.nombre;
      this.dialogDelete = true;
    },
    hide_dialogDelete() {
      this.barnizTipoId = null;
      this.barnizTipoCodigo = null;
      this.barnizTipoNombre = null;
      this.$refs.form.reset();
      this.dialogDelete = false;
    },

    change_url() {
      this.urlBarnizTipo = this.searchIdBarnizGrupo ? this.urlBarnizTipo + "?idBarnizGrupo=" + parseInt(this.searchIdBarnizGrupo) : this.urlBarnizTipoBase
      this.getBarnizTipo()
    },

    alerta(code) {
      this.$refs.AlertSnackbar.state_alert(code);
    },
  },
  mounted() {
    this.initialize();
  },
}
</script>