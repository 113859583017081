<template>
  <div v-if="permisoVista('configuraciones','r')">
    <v-col cols="6">
      <v-btn v-if="permisoVista('configuraciones','c')" color="primary" @click="show_dialog()">Nueva Pregunta</v-btn>
    </v-col>
    <v-data-table :headers="headers" :items="dataTransportePreguntas" :search="search" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex justify-center">
          <v-text-field v-model="search" v-bind="propsSearch" />
        </div>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip v-if="permisoVista('configuraciones','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class=" mr-1" @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip v-if="permisoVista('configuraciones','d')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-1"
              @click="show_dialogDelet(item)">mdi-trash-can-outline</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-form ref="form">
          <v-card-title>{{ optionDialog }} Pregunta</v-card-title>
          <v-card-text>
            <v-textarea v-model="transportePregunta" label="Pregunta" v-bind="propsTextArea" />
          </v-card-text>
          <v-card-actions>
            <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text @click="hide_dialog()">Cancelar</v-btn>
            <v-btn :loading="loadingDialog" color="error" text @click="validate()">Guardar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelet" max-width="500px" persistent>
      <v-card>
        <v-card-title>Eliminar Pregunta</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "[{{ transporteId }}] {{ transportePregunta }}"?
        </v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialogDelet" color="blue darken-1" @click="hide_dialogDelet()" background="primary"
            class="ml-auto" text>Cancelar</v-btn>
          <v-btn :loading="loadingDialogDelet" color="error" @click="deleteTransportePreguntas()" text>Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>

<script>
import AlertSnackbar from '@/components/AlertSnackbar.vue'
import axios from "axios"

export default {
  components: {
    AlertSnackbar
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: true,
      },
      propsTextArea: {
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },

      dialog: false,
      dialogDelet: false,
      optionDialog: null,

      transporteId: null,
      transportePregunta: null,

      search: '',

      headers: [
        {
          text: "Código",
          value: "idTransportePregunta",
          align: "center",
          sortable: false,
        },
        {
          text: "Pregunta",
          value: "pregunta",
          align: "center",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        },
      ],

      dataTransportePreguntas: [],

      loadingTable: false,
      loadingDialog: false,
      loadingDialogDelet: false,
    }
  },
  methods: {
    initialize() {
      this.getTransportePreguntas();
    },

    validate() {
      if (this.$refs.form.validate()) this.optionDialog == 'Crear' ? this.postTransportePreguntas() : this.putTransportePreguntas()
    },

    getTransportePreguntas() {
      this.loadingTable = true;
      axios
        .get("/TransportePreguntas/Listar")
        .then((response) => {
          this.dataTransportePreguntas = response.data;
          console.log("Ya jalo las preguntas aquí en Managrx");
          this.loadingTable = false;
        })
        .catch((error) => { 
          console.log(error);
        this.loadingTable = false; })
    },
    postTransportePreguntas() {
      this.loadingDialog = true;
      axios
        .post("/TransportePreguntas/Crear", {
          pregunta: this.transportePregunta
        })
        .then((response) => {
          this.hide_dialog()
          this.getTransportePreguntas()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putTransportePreguntas() {
      this.loadingDialog = true;
      axios
        .put("/TransportePreguntas/Actualizar/" + this.transporteId, {
          pregunta: this.transportePregunta
        })
        .then((response) => {
          this.hide_dialog()
          this.getTransportePreguntas()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteTransportePreguntas() {
      this.loadingDialogDelet = true;
      axios
        .put("/TransportePreguntas/Desactivar/" + this.transporteId)
        .then((response) => {
          this.hide_dialogDelet(false)
          this.getTransportePreguntas()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialogDelet = false;
        })
    },

    show_dialog(item) {
      this.dialog = true;
      this.optionDialog = item ? "Editar" : "Crear";

      if (item) {
        this.transporteId = item.idTransportePregunta;
        this.transportePregunta = item.pregunta;
        console.log(this.transporteId)
      }
    },
    hide_dialog() {
      this.$refs.form?.reset();
      this.transportePregunta = null;
      this.transporteId = null;
      this.dialog = false;
    },

    show_dialogDelet(item) {
      this.dialogDelet = true
      if (item) {
        this.transporteId = item.idTransportePregunta;
        this.transportePregunta = item.pregunta;
        console.log(this.transporteId)
      }
    },
    hide_dialogDelet() {
      this.tipoTintaId = null;
      this.tipoTintaNombre = null;
      this.tipoTintaCodigo = null;
      this.$refs.form?.reset();
      this.dialogDelet = false
    },

    alerta(code, color, mensaje, icono) {
      if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
      } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>