<template>
  <div v-if="permisoVista('insumos','r')">
    <v-col cols="6">
      <v-btn v-if="permisoVista('insumos','c')" color="primary" @click="show_dialog()">Nuevo Tipo de Tinta</v-btn>
    </v-col>

    <v-data-table :headers="headers" :items="dataTipoTintas" :search="search" :loading="loadingTable">
      <template v-slot:top>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="search" v-bind="propsSearch" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip v-if="permisoVista('insumos','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip v-if="permisoVista('insumos','d')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small @click="show_dialogDelete(item)">mdi-trash-can-outline</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>{{ optionDialog }} Tipo de Tinta</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="tipoTintaCodigo" label="Código" v-bind="propsText" />
            <v-text-field v-model="tipoTintaNombre" label="Nombre" v-bind="propsText" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text @click="hide_dialog()">Cancelar</v-btn>
          <v-btn :loading="loadingDialog" color="error" text @click="validate">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
      <v-card>
        <v-card-title>Eliminar Tipo de Tinta</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "[{{ tipoTintaCodigo }}] - {{ tipoTintaNombre
        }}"?</v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialogDelet" color="blue darken-1" class="ml-auto" @click="hide_dialogDelet()"
            text>Cancelar</v-btn>
          <v-btn :loading="loadingDialogDelet" color="error" @click="deleteTipotintas()" text>Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>

<script>
import axios from "axios"
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {
    AlertSnackbar
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: true,
      },
      propsText: {
				outlined: false,
				dense: false,
				required: true,
				rules: [
					(v) => {
						if (v === null || v === "") {
							return "Dato requerido";
						}
						return true;
					},
				],
			},

      dialog: false,
      dialogDelete: false,
      optionDialog: null,

      tipoTintaId: null,
      tipoTintaCodigo: null,
      tipoTintaNombre: null,

      search: '',

      headers: [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false
        }, {
          text: "Nombre",
          value: "nombre",
          align: "center",
          sortable: false
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false
        }
      ],

      dataTipoTintas: [],

      loadingTable: false,
      loadingDialog: false,
      loadingDialogDelet: false,

      urlTipoTintas: "/tintatipos/listar",
      urlTipoTintasDefault: "/tintatipos/listar",
    }
  },
  methods: {
    initialize() {
      this.getTipoTintas()
    },

    validate() {
      if (this.$refs.form.validate()) this.optionDialog == 'Crear' ? this.postTipoTintas() : this.putTipoTintas()
    },

    getTipoTintas() {
      this.loadingTable = true;
      axios
        .get(this.urlTipoTintas)
        .then((response) => {
          this.dataTipoTintas = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    postTipoTintas() {
      this.loadingDialog = true;
      axios
        .post("/tintatipos/crear", {
          codigo: this.tipoTintaCodigo,
          nombre: this.tipoTintaNombre
        })
        .then((response) => {
          this.hide_dialog()
          this.getTipoTintas()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putTipoTintas() {
      this.loadingDialog = true;
      axios
        .put("/tintatipos/actualizar", {
          idTintaTipo: this.tipoTintaId,
          codigo: this.tipoTintaCodigo,
          nombre: this.tipoTintaNombre
        })
        .then((response) => {
          this.hide_dialog()
          this.getTipoTintas()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteTipotintas() {
      this.loadingDialogDelet = true;
      axios
        .put("/tintatipos/desactivar/" + this.tipoTintaId)
        .then((response) => {
          this.hide_dialogDelet()
          this.getTipoTintas()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialogDelet = false;
        })
    },

    show_dialog(item) {
      this.dialog = true
      this.optionDialog = item ? "Editar" : "Crear"

      if (item) {
        this.tipoTintaId = item.idTintaTipo
        this.tipoTintaCodigo = item.codigo
        this.tipoTintaNombre = item.nombre
      }
    },
    hide_dialog() {
      this.$refs.form?.reset();
      this.tipoTintaId = null;
      this.tipoTintaCodigo = null;
      this.tipoTintaNombre = null;
      this.dialog = false
    },

    show_dialogDelete(item) {
      this.dialogDelete = true
      if (item) {
        this.tipoTintaId = item.idTintaTipo
        this.tipoTintaNombre = item.nombre
        this.tipoTintaCodigo = item.codigo
      }
    },
    hide_dialogDelet() {
      this.tipoTintaId = null;
      this.tipoTintaNombre = null;
      this.tipoTintaCodigo = null;
      this.dialogDelete = false;
      this.$refs.form?.reset();
    },

    alerta(code, color, mensaje, icono) {
      if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
      } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>