<template>
  <v-container fluid>
    <v-dialog v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="primary">
          <v-btn icon @click="hide_dialog()"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title class="white--text">Nueva Preorden</v-toolbar-title>
          <v-spacer />
          <v-btn :loading="saving" text @click="savePreOrden()">Guardar</v-btn>
        </v-toolbar>

        <v-list-item style="width: 98%; margin: auto" v-if="dialog">
          <v-list-item-content>
            <v-list-item-title class="my-title font-weight-bold">Cotización:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-row class="col-12">
                  <v-col cols="6">
                    <h6 class="font-weight-medium d-inline">Folio de cotización: </h6>
                    {{ this.idOrden }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-medium d-inline">Fecha de cotización: </h6>
                    {{ dataCotizacion.fechaCreacion }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title font-weight-bold">Datos del cliente:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-row class="col-12">
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Cliente: </h6>
                    {{ dataCotizacion.cliente }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Contacto: </h6>
                    {{ dataCostos.clienteMostrar && dataCostos.clienteMostrar.contacto ? dataCostos.clienteMostrar.contacto : ' ' }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Teléfono: </h6>
                    {{ dataCostos.clienteMostrar && dataCostos.clienteMostrar.telefono ? dataCostos.clienteMostrar.telefono : ' ' }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title font-weight-bold">Datos del trabajo:</v-list-item-title> <!--Diálogo info trabajo-->
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-row class="d-flex align-center">
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Trabajo: </h6>
                    {{ dataCotizacion.nombreTrabajo }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Cantidad: </h6>
                    {{ dataCotizacion.cantidadPza }} Pza(s)
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                    v-model="dataPreOrden.orden.piezasExtra"
                    outlined 
                    dense 
                    label="Piezas extra" 
                    suffix="Pza(s)" 
                    />
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Tintas: </h6>
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Frente: </h6>
                    {{ (dataCostos && dataCostos.calculoCostos && dataCostos.calculoCostos.materiales && Array.isArray(dataCostos.calculoCostos.materiales.tinta) 
                          ? dataCostos.calculoCostos.materiales.tinta.map(item => item.frente.toFixed(2)).join(', ') 
                          : ' ')   }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Vuelta: </h6>
                    {{  (dataCostos && dataCostos.calculoCostos && dataCostos.calculoCostos.materiales && Array.isArray(dataCostos.calculoCostos.materiales.tinta) 
                          ? dataCostos.calculoCostos.materiales.tinta.map(item => item.vuelta).join(', ') 
                          : ' ')  }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Colores: </h6>
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Frente: </h6>
                    {{ (dataCotizacion && dataCotizacion.impresionTintaCotizacionesString && Array.isArray(dataCotizacion.impresionTintaCotizacionesString)
                          ? dataCotizacion.impresionTintaCotizacionesString.map(item => item.articulo).join(', ')
                          : ' ') }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Vuelta: </h6>
                    {{ 'No' }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Tamaño Final: </h6>
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Largo: </h6>
                    {{ dataCotizacion.tamanoHilo }} Mts.
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Ancho: </h6>
                    {{ dataCotizacion.tamanoAncho }} Mts.
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Papel: </h6>
                    {{ dataCotizacion.papel }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Gramaje: </h6>
                    {{ dataCostos.trabajoMostrar && dataCostos.trabajoMostrar.pesoPapel ? dataCostos.trabajoMostrar.pesoPapel : ' ' }} Grs.
                  </v-col>
                  <v-col cols="12">
                    <h6 class="font-weight-medium d-inline">Barniz Frente: </h6>
                    {{ dataCotizacion.barnizFrente }}
                  </v-col>
                  <v-col cols="12">
                    <h6 class="font-weight-medium d-inline">Suajado: </h6>
                    {{ dataCotizacion.suajado ? 'Si' : 'No'}}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Tabla de Suaje: </h6>
                    {{ dataCotizacion.suajadoTablaSuaje ? 'Si' : 'No' }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Base: </h6>
                    {{ dataCotizacion.suajeTablaBase }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Altura: </h6>
                    {{ dataCotizacion.suajeTablaAltura }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Tabla Desbarbe: </h6>
                    {{ '' }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Base: </h6>
                    {{ dataCotizacion.suajeDesbarbeBase }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Altura: </h6>
                    {{ dataCotizacion.suajeDesbarbeAltura }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline"></h6>
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Centimetros Suaje: </h6>
                    {{ dataCotizacion.suajeCentimetros }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-medium d-inline">Centimetros Matrix: </h6>
                    {{ dataCotizacion.suajeMatrix }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Empalme: </h6>
                    {{ dataCotizacion.empalme ? 'Si' : 'No' }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-medium d-inline">Metros: </h6>
                    {{ dataCotizacion.empalmeMetros}}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Pegado Lineal: </h6>
                    {{ dataCotizacion.pegado ? 'Si' : 'No' }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-medium d-inline">Cms: </h6>
                    {{ dataCotizacion.pegadoCms }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-medium d-inline">Pegado de Golpes: </h6>
                    {{ dataCotizacion.pegadoGolpe ? 'Si' : 'No' }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-medium d-inline">Esquinas: </h6>
                    {{ dataCotizacion.pegadoGolpeEsquinas }}
                  </v-col>
                  <v-col cols="6">
                    <v-text-field 
                    v-model="dataPreOrden.orden.entregarEn" 
                    outlined 
                    dense
                    label="Entregar en "/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field 
                    v-model="dataPreOrden.orden.ordenCompra"
                    outlined 
                    dense
                    :error-messages="dataPreOrden.orden.errorOrdenCompra" 
                    label="Orden de Compra "/>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea 
                    v-model="dataPreOrden.orden.trabajoObservaciones"
                    outlined 
                    rows="2"
                    dense
                    auto-grow
                    label="Observaciones" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-list-item-title class="my-title font-weight-bold">Datos Económicos:</v-list-item-title> <!--Diálogo info económico-->
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-row cols="12" no-gutters>
                  <v-col cols="6" class="px-2 mt-2">
                    <v-text-field 
                    v-model="dataPreOrden.orden.precio"
                    v-bind="propsDecimales"
                    label="$ Precio"/>
                    <span>(Incluir desglosado si aplica)</span>
                  </v-col>
                  <v-col cols="6" class="px-2 mt-2">
                    <v-text-field 
                    v-model="dataPreOrden.orden.anticipo"
                    outlined 
                    dense
                    :error-messages="dataPreOrden.orden.errorAnticipo" 
                    label="$ Anticipo "/>
                  </v-col>
                  <v-col cols="6" class="px-2 mt-2">
                      <v-autocomplete 
                      v-model="dataPreOrden.orden.desglosado"
                      :items="dataDesglosado"
                      item-text="text"
                      item-value="value"
                      outlined 
                      dense
                      clearable 
                      label="Desglosado"/>
                    </v-col>
                    <v-col cols="6" class="px-2 mt-2">
                      <v-autocomplete 
                      v-model="dataPreOrden.orden.formaPago"
                      :items="dataFormaPago"
                      item-text="text"
                      item-value="value"
                      outlined 
                      dense
                      clearable
                      :error-messages="dataPreOrden.orden.errorFormaPago" 
                      label="Forma de Pago"/>
                    </v-col>
                    <v-col cols="12" class="px-2 mt-2">
                      <v-textarea
                      v-model="dataPreOrden.orden.referenciaPago" 
                      outlined 
                      dense
                      rows="2"    
                      label="Referencia del Pago" />
                    </v-col>
                  <v-col cols="12" class="px-2 mt-2">
                    <v-textarea
                    v-model="dataPreOrden.orden.economicoObservaciones"
                    dense
                    auto-grow
                    rows="2" 
                    outlined 
                    label="Observaciones" />
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <datePicker
                      v-model="dataPreOrden.orden.fechaPromesa"
                      label="Fecha Prometida"
                      clearable
                      format="YYYY-MM-DD"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            
            <!--Adaptación de pre-orden geom a ODT mana-->
            <v-list-item-title class="my-title font-weight-bold">Pedido:</v-list-item-title> 
               <v-card v-bind="cardStyle">
                <v-card-text>
                  <v-row no-gutters>
                  <v-col cols="4" class="px-2 mt-2">
                    <v-autocomplete
                    v-model="lineaComercial"
                    label="Línea Comercial"
                    :items="lineasComerciales"
                    item-text="nombre"
                    item-value="idComercialLinea"
                    outlined
                    dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <v-autocomplete
                      v-model="selectCliente"
                      :items="dataClientes"
                      :item-text="nombreCliente"
                      item-value="idCliente"
                      outlined 
                      dense
                      clearable
                      label="Cliente" 
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <v-text-field
                    label="Clave de la Orden de Trabajo"
                    outlined
                    dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <datePicker
                      v-model="dataArtiExtra.fechaCompromiso"
                      label="Fecha de Compromiso de Entrega"
                      clearable
                      outlined
                      dense
                      format="YYYY-MM-DD"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <v-autocomplete
                    v-model="vendedor"
                    label="Vendedores"
                    :items="vendedores"
                    :item-text="nombrePersona"
                    item-value="idVendedor"
                    outlined
                    dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <datePicker
                      v-model="dataArtiExtra.fechaInicio"
                      label="Fecha de Inicio de producción"
                      clearable
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <v-autocomplete
                    v-model="almacen"
                    label="Almacén"
                    :items="almacenes"
                    item-text="nombre"
                    item-value="idAlmacen"
                    outlined
                    dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-card-actions>
                    <v-btn class="mb-2" text color="blue darken-1" @click="agregarArticulo()"
                    :disabled="dataArtiExtra.articulos.length >= articulos.length"
                    >Agregar Artículo </v-btn>
                  </v-card-actions>
                </v-row>
              
                <v-data-table
                  :items="dataArtiExtra.articulos"
                  hide-default-footer
                  :items-per-page="-1"
                  :headers="extras"
                >
                  <template v-slot:item="{item}">
                    <tr>
                      <td class="text-center">
                        <v-autocomplete
                          v-model="item.articulo"
                          :items="dataArticulos"
                          item-value="idArticulo"
                          :item-text="codigoNombre"
                          dense
                          hide-details
                          single-line
                        ></v-autocomplete>
                      </td>
                      <td class="text-center pa-0">
                        <v-col cols="10" class="pa-0">
                          <v-text-field
                            v-model="item.cantidad"
                            dense
                            hide-details
                            single-line
                            type="number"
                            min="0"
                            @change="validarCantidad(item)"
                            @keyup="validarCantidad(item)"
                          ></v-text-field>
                        </v-col>
                      </td>
                      <td class="text-center">
                        $
                        {{
                          `${precioUnitario.toFixed(2)} x Pza`
                        }}
                      </td>
                      <td class="text-center">
                        ${{ Math.min((precioUnitario * item.cantidad), precioDivision).toFixed(2) }}
                      </td>
                      <td class="text-center pa-0">
                        <v-col cols="10" class="pa-0">
                          <v-textarea
                            v-model="item.nota"
                            dense
                            outlined
                            rows="2"
                            hide-details
                            auto-grow
                            single-line
                          ></v-textarea>
                        </v-col>
                      </td>
                      <td> 
                        <div class="d-flex align-center justify-center">
                          <v-tooltip bottom color="black" class="white--text">
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                small
                                class="mr-2"
                                @click="deleteItem(item)"
                              >
                                delete
                              </v-icon>
                            </template>
                            <span class="white--text">Eliminar</span>
                          </v-tooltip>
                        </div>
                      </td>    
                    </tr>
                  </template>
                </v-data-table>
                <v-col cols="12" class="text-end">
                  <h3 class="ma-0 pa-0">
                    Total: ${{ Math.min(costoTotal, dataPreOrden.orden.precio).toFixed(2) }}
                  </h3>
                </v-col>
                </v-card-text>
              </v-card>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-dialog>

  </v-container>  
</template>
<script>
import axios from "axios"
import datePicker from "@/components/DatePicker";
import Moldes from "@/components/DashViews/Mantenimiento/Moldes/Moldes.vue"

export default {
  components: {
    datePicker,
    Moldes
  },
  props: {
    idOrden: {
      type: Number,
      default: 0,
    },
    articles: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      cardStyle: {
        outlined: true,
        elevation: 3,
        style: {
          padding: "0px",
          width: "100%",
          border: "1px solid #C7C7C7",
        },
      },
      nombreCliente(lista) {
        if (lista.clave) {
        return `[${lista.clave}] - ${lista.nombre}`;
        } else {
        return lista.nombre;
        }
      },
      nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
      codigoNombre: (element) => "[" + element.codigo + "] - " + element.nombre,

      propsDecimales: {
				outlined: true,
				dense: true,
				type: "number",
				inputmode: "numeric",
				required: true,
				rules: [
					(v) => {
						if (v === null || v === "") {
							return "Dato requerido";
						} else if (!Number.isFinite(Number(v))) {
							return "Ingrese solo números";
						} else if (Number(v) < 0) {
							return "Dato no valido";
						}
						return true;
					},
				],
			},
      // editedItem: {
      //   idMolde: null,
      //   observaciones: null,
      //   formaPago: null,
      //   fecha: null,
      //   precio: null,
      //   ordenCompra: null,
      // },
      dataPre: [],  //Datos pa la tabla de suaje
      dialog: false,
      artiExtra: false,
      selectCliente: null,
      dataCotizacion: [],
      dataPostAPI: {
        suajado: false,
        suajadoTipo: null,
        suajadoDelta: null,
        idSuajadoMaquina: null,
        IdSuajadoMMaquina: null,
        suajadoTablaSuaje: false,
        suajeTablaBase: null,
        suajeTablaAltura: null,
        suajeDesbarbeBase: null,
        suajeDesbarbeAltura: null,
        suajeCentimetros: null,
        suajeMatrix: null,
      },
      dataPostAPIDefault: {
        suajado: false,
        suajadoTipo: null,
        suajadoDelta: null,
        idSuajadoMaquina: null,
        IdSuajadoMMaquina: null,
        suajadoTablaSuaje: false,
        suajeTablaBase: null,
        suajeTablaAltura: null,
        suajeDesbarbeBase: null,
        suajeDesbarbeAltura: null,
        suajeCentimetros: null,
        suajeMatrix: null,
      },
      dialogSuaje: false,
      loadingData: false,
      siTabla: false,
      dataMoldes: [],
      parametros: {
        idArticulo: null,
        idMaquina: null,
        codigo: null,
      },
      dataDesglosado: [
        { text: 'Si', value: true}, 
        { text: 'No', value: false},
      ],
      dataFormaPago: [
        { text: 'Efectivo', value: 1}, 
        { text: 'Tarjeta', value: 2},
        //{ text: 'Otro', value: 3},
      ],
      dataMonedas: [
        { text: 'Pesos Mexicanos', value: 1}, 
        { text: 'Dólares', value: 2},
        { text: 'Euros', value: 3},
      ],
      saving: false,
      areas: [],
      articulos: [],
      maquinas: [],
      recetas: [],
      dataProveedores: [],
      dataClientes: [],
      dataUnidades: [],
      dataCostos: [],
      dataPreOrden: {
        idPreOrden: null,
        idCotizacion: null,
        idCliente: null,
        fechaCreacion: new Date(),
        estatus: null,
        orden: {
          folioCotizacion: null,
          fechaCotizacion: null,  // Obligatorio
          errorFechaCotizacion: null,
          telefono: null,
          nombreTrabajo: null,
          cantidadPza: null,
          piezasExtra: null,
          tintasFrente: null,  // Obligatorio
          errorTintasFrente: null,
          tintasVuelta: null,  // Obligatorio
          errorTintasVuelta: null,
          largo: null,  // Obligatorio
          errorLargo: null,
          ancho: null,  // Obligatorio
          errorAncho: null,
          idArticuloPapel: null,  // Obligatorio
          errorIdArticuloPapel: null,
          barnizFrente: null,  // Obligatorio
          errorBarnizFrente: null,
          suajado: null,  // Obligatorio
          errorSuajado: null,
          tablaSuaje: null,
          tablaSuajeBase: null,
          tablaSuajeAltura: null,
          suajeCms: null,
          tablaDesbarbe: null,
          tablaDesbarbeBase: null,
          tablaDesbarbeAltura: null,
          matrixCms: null,
          empalme: null,
          empalmeMts: null,
          pegadoLineal: null,
          pegadoCms: null,
          pegadoGolpe: null,
          pegadoEsquinas: null,
          entregarEn: null,
          ordenCompra: null,  // Obligatorio
          errorOrdenCompra: null,
          trabajoObservaciones: null,
          precio: null,  // Obligatorio
          errorPrecio: null,
          anticipo: null,  // Obligatorio
          errorAnticipo: null,
          desglosado: null,
          formaPago: null,  // Obligatorio
          errorFormaPago: null,
          referenciaPago: null,
          economicoObservaciones: null,  // Obligatorio
          errorEconomicoObservaciones: null,
          fechaPromesa: null,
          tipoTablaSuaje: null,  // Obligatorio
          errorTipoTablaSuaje: null,
        },

        unidad: null, //Pa probar agregar artículo
      },
      defaultDataPreOrden: {
        idPreOrden: null,
        idCotizacion: null,
        idCliente: null,
        fechaCreacion: new Date(), 
        estatus: null,
        orden: {
          folioCotizacion: null,
          fechaCotizacion: null,  // Obligatorio
          errorFechaCotizacion: null,
          telefono: null,
          nombreTrabajo: null,
          cantidadPza: null,
          piezasExtra: null,
          tintasFrente: null,  // Obligatorio
          errorTintasFrente: null,
          tintasVuelta: null,  // Obligatorio
          errorTintasVuelta: null,
          largo: null,  // Obligatorio
          errorLargo: null,
          ancho: null,  // Obligatorio
          errorAncho: null,
          idArticuloPapel: null,  // Obligatorio
          errorIdArticuloPapel: null,
          barnizFrente: null,  // Obligatorio
          errorBarnizFrente: null,
          suajado: null,  // Obligatorio
          errorSuajado: null,
          tablaSuaje: null,
          tablaSuajeBase: null,
          tablaSuajeAltura: null,
          suajeCms: null,
          tablaDesbarbe: null,
          tablaDesbarbeBase: null,
          tablaDesbarbeAltura: null,
          matrixCms: null,
          empalme: null,
          empalmeMts: null,
          pegadoLineal: null,
          pegadoCms: null,
          pegadoGolpe: null,
          pegadoEsquinas: null,
          entregarEn: null,
          ordenCompra: null,  // Obligatorio
          errorOrdenCompra: null,
          trabajoObservaciones: null,
          precio: null,  // Obligatorio
          errorPrecio: null,
          anticipo: null,  // Obligatorio
          errorAnticipo: null,
          desglosado: null,
          formaPago: null,  // Obligatorio
          errorFormaPago: null,
          referenciaPago: null,
          economicoObservaciones: null,  // Obligatorio
          errorEconomicoObservaciones: null,
          fechaPromesa: null,
          tipoTablaSuaje: null,  // Obligatorio
          errorTipoTablaSuaje: null,
        }
      },
      dataArtiExtra: {  //Datos usados al momento de agregar artículos (tabla)
        articulos: [],
        cantidad: null,
        fechaCompromiso: null,
        fechaInicio: null,
        observaciones: null,
        precio: null,
        iniciarAuto: false,
      },
      dataArtiExtraDefault: {  //Lo mismo de arriba, pero para reiniciar tabla 
        articulos: [],
        cantidad: null,
        fechaCompromiso: null,
        fechaInicio: null,
        observaciones: null,
        precio: null,
        iniciarAuto: false,
      },
      parametros: {
        idArticulo: null,
        idMaquina: null,
        codigo: null,
      },
      acDelta: {
        outlined: true,
        dense: true,
        required: true,
        rules: [
          (v) => !!v || "Selección Requerida"
        ],
        label: "Delta",
        itemText: this.nombreDefault,
        itemValue: "id",
      },
      lineasComerciales: [],
      lineaComercial: null,
      almacenes: [],
      almacen: null,
      vendedores: [],
      vendedor: null,
      pos: 0,
      precioUnitario: 0,
      precioTotal: null,
      precioDivision: 0,
      costoTotal: 0.0,
      piezasTotal: 0,
      cantidadRestante: 0,
      extras: [  //usados en la tabla para añadir artículos
        {
          text: "Artículo",
          align:"center",
          sortable: true,
          value: "nombre",
        },{
          text: "Cantidad",
          align:"center",
          sortable: true,
          value:"cantidad",
        }, {
          text: "Precio",
          align:"center",
          sortable: true,
          value:"costo",
        },{
          text: "Precio Total",
          align: "center",
          sortable: true,
          value: "precioTotal"
        },{
          text: "Observaciones",
          align: "center",
          sortable: true,
          value: "nota"
        },{
          text: "Acciones",
          value: "action",
          sortable: false,
          align: "center",
        }
      ],
      startAuto: false,
      dataArticulos: [],
      dataMaquinasImpresoras: [],
      dataMaquinasBarniz: [],
      dataMaquinasUvPlasta: [],
      dataMaquinasUvRegistro: [],
      dataPreOrdenes: [],
      dataMaquinasPlastificado: [],
      dataMaquinasSuaje: [],
      dataMaquinasSuajeManual: [],
      dataMaquinasCorte: [],
      dataMaquinasEmpalme: [],
      dataMaquinasPegado: [],
      dataMaquinasPegadoGolpe: [],
      dataMaquinasTroquelBobina: [],
      dataMaquinasTroquelManual: [],
      dataMaquinasTroquelAutomatica: [],
      dataCotizacion: [],
      dataInsumos:[],
      insumoCotizaciones:[],
      dataSuajadoTipo: [
        {
          id: 1,
          value: "Con Desbarbe Automático"
        }, {
          id: 2,
          value: "Con Desbarbe Manual"
        }, {
          id: 3,
          value: "Sin Desbarbe"
        }
      ],
      dataDelta: [
        {
          id: 1, value: "Sencillo",
        }, {
          id: 2, value: "Complejo",
        }
      ],
    }   
  },
  computed: {
    readOnly(){
      return this.editedItem.idMolde != null;
    },
    articulos(){
      return this.articles
    },

    observacionesValue: {
      get() {
        return this.editedItem.observaciones || '';
      },
      set(value) {
        this.editedItem.observaciones = value;
      }
    },
    jsonMermas() {
      const mermas = [];
      if (this.dataImpresiones.length > 0) {
        mermas.push({
          idProceso: this.dataMaquinasImpresoras.find(item => item.proceso === "Impresión").idProceso,
        })
      }
      if (this.dataPostAPI.barniz) {
        mermas.push({
          idProceso: this.dataMaquinasBarniz.find(item => item.proceso === "Barnizado").idProceso,
        })
      }
      if (this.dataPostAPI.uvPlasta) {
        mermas.push({
          idProceso: this.dataMaquinasUvPlasta.find(item => item.proceso === "UV Plasta").idProceso,
        })
      }
      if (this.dataPostAPI.uvRegistro) {
        mermas.push({
          idProceso: this.dataMaquinasUvRegistro.find(item => item.proceso === "UV Registro").idProceso,
        })
      }
      if (this.dataPostAPI.plastificado) {
        mermas.push({
          idProceso: this.dataMaquinasPlastificado.find(item => item.proceso === "Plastificado").idProceso,
        })
      }
      if (this.dataPostAPI.suajado) {
        mermas.push({
          idProceso: this.dataMaquinasSuaje.find(item => item.proceso === "Suajado").idProceso,
        })
      }
      if (this.dataPostAPI.corte) {
        mermas.push({
          idProceso: this.dataMaquinasCorte.find(item => item.proceso === "Corte").idProceso,
        })
      }
      if (this.dataPostAPI.empalme) {
        mermas.push({
          idProceso: this.dataMaquinasEmpalme.find(item => item.proceso === "Empalme").idProceso,
        })
      }
      if (this.dataPostAPI.pegado) {
        mermas.push({
          idProceso: this.dataMaquinasPegado.find(item => item.proceso === "Pegado Lineal").idProceso,
        })
      }
      if (this.dataPostAPI.pegadoGolpe) {
        mermas.push({
          idProceso: this.dataMaquinasPegadoGolpe.find(item => item.proceso === "Pegado de Golpe").idProceso,
        })
      }
      if (this.dataPostAPI.troquel) {
        switch (this.dataPostAPI.idTroquelTipo) {
          case 1:
            mermas.push(
              {
                idProceso: this.dataMaquinasTroquelBobina.find(item => item.proceso === "Troquelado Bobina").idProceso,
              })
            break
          case 2:
            mermas.push(
              {
                idProceso: this.dataMaquinasTroquelManual.find(item => item.proceso === "Troquelado Manual").idProceso,
              })
            break
          case 3:
            mermas.push(
              {
                idProceso: this.dataMaquinasTroquelAutomatica.find(item => item.proceso === "Troquelado Automatica").idProceso,
              })
            break
        }
      }
      return mermas;
    },
  },
  mounted() {
    this.initialize();
    this.getMaquinas();
  },
  
  methods: {
    nombreUnidad: (lista) => `[${lista.abreviatura}] - ${lista.nombre}`,

    initialize() {
      this.getArticulos();
      this.getMaquinasIns();
      this.getProveedores();
      this.getMaquinas();
    },  

    abrirPreorden() {  //Función llamada desde el componente "PreCotizaciones.vue" para abrir el diálogo "asociado al v-model=dialog".
      this.getClientes();
      this.getUnidades();
      this.getLineasComercial();
      this.getAlmacenes();
      this.getOtrosArticulos();
      this.obtenerVendedores();
      //this.obtenerPrecioUnitario();
      this.dialog = true;
      //this.getMoldes();
    },
    obtenerPrecioUnitario() {  //Se hace este cálculo porque todas las pzas van a valer lo mismo (su costo) sin importar el artículo
      this.precioUnitario = this.precioDivision / this.dataCotizacion.cantidadPza;
      //console.log("Precio desde operación: ", this.precioDivision);
      //console.log(this.precioUnitario);
    },
    actualizarCostoTotal() { //Control de pzas y costo total al añadir artículos en preorden para mandar a orden.
      let costo = 0.0;
      let piezas = 0;
      let resto = 0;

      this.dataArtiExtra.articulos.forEach((element) => {

        let cantidad = parseInt(element.cantidad);
        if(isNaN(cantidad)){
            cantidad = 0;
            element.cantidad = 0;
        }
        //console.log("cantidad por fila: ", cantidad);
        if (element.cantidad > this.dataCotizacion.cantidadPza) {
          element.cantidad = 0;
          //console.log("superé el max de pzas", element.cantidad);
        }
        costo += parseFloat(cantidad * this.precioUnitario);
        piezas += parseInt(cantidad);
        resto = Math.max(0, parseInt(this.dataCotizacion.cantidadPza - piezas));
        if(isNaN(resto)){
          element.cantidad = 0;
        }
        //console.log("cuento las pzas: ", piezas);
        //console.log("resto original: ", resto);
        if(cantidad > resto && piezas > this.dataCotizacion.cantidadPza ) {
          piezas -= cantidad;
         //console.log("soy las piezas ajustadas: ", piezas)
          resto = parseInt(this.dataCotizacion.cantidadPza - piezas);
          //console.log("soy el resto modificado", resto);
          element.cantidad = resto;
        }
      });

      this.piezasTotal = piezas;
      this.costoTotal = costo;
      //this.cantidadRestante = resto;
      //console.log("es o no cero: ", this.cantidadRestante);
    },
    validarCantidad(item) { //Controlar que las piezas por artículo no superen el máximo
      // let totalCantidad = parseInt(this.piezasTotal);
      // // console.log("Otro contéo: ", totalCantidad);
      // // // Calculamos la cantidad máxima restante
      // let maxPiezas = this.dataCotizacion.cantidadPza;
      // let cantidadRestante = maxPiezas - totalCantidad;
       //let resto = 0
      // console.log("resto de piezas disponibles", cantidadRestante);
    
      if (this.piezasTotal > this.dataCotizacion.cantidadPza) {
        item.cantidad = this.cantidadRestante;
        //("cantidad ajustada en validar", item.cantidad);
      }
      this.actualizarCostoTotal();
    },
    crearTablaSuaje() {
      this.dialogSuaje = true;
    },

    nombreProveedor: (proveedor) => `${proveedor.nombre}`,
    //nombreCliente: (cliente) => `${cliente.nombre}`,
    nombreDefault: (lista) => `${lista.value}`,


    validatePreOrden() {  //Validaciones de campos al crear una pre-orden.
      let error = false;

      if (!this.dataPreOrden.orden.ordenCompra && this.dataPreOrden.orden.ordenCompra !== 0) {
        this.dataPreOrden.orden.errorOrdenCompra = "Requerido";
        error = true;
      } else if (isNaN(parseInt(this.dataPreOrden.orden.ordenCompra))) {
        this.dataPreOrden.orden.errorOrdenCompra = "Debe ser un número entero";
        error = true;
       } else if (parseInt(this.dataPreOrden.orden.ordenCompra) <= 0) {
        this.dataPreOrden.orden.errorOrdenCompra = "Debe ser mayor a 0";
        error = true; 
      } else {
        this.dataPreOrden.orden.errorOrdenCompra = null;
      }

      if (!this.dataPreOrden.orden.precio && this.dataPreOrden.orden.precio !== 0) {
        this.dataPreOrden.orden.errorPrecio = "Requerido";
        error = true;
      } else if (isNaN(parseFloat(this.dataPreOrden.orden.precio))) {
        this.dataPreOrden.orden.errorPrecio = "Debe ser un número decimal";
        error = true;
      } else if (parseFloat(this.dataPreOrden.orden.precio) <= 0) {
        this.dataPreOrden.orden.errorPrecio = "Debe ser mayor a 0";
        error = true;
      } else {
        this.dataPreOrden.orden.errorPrecio = null;
      }

      if (!this.dataPreOrden.orden.anticipo && this.dataPreOrden.orden.anticipo !== 0) {
        this.dataPreOrden.orden.errorAnticipo = "Requerido";
        error = true;
      } else if (isNaN(parseFloat(this.dataPreOrden.orden.anticipo))) {
        this.dataPreOrden.orden.errorAnticipo = "Debe ser un número decimal";
        error = true;
      } else if (parseFloat(this.dataPreOrden.orden.anticipo) < 0) {
        this.dataPreOrden.orden.errorAnticipo = "Debe ser mayor o igual a 0";
        error = true;
      } else if (parseFloat(this.dataPreOrden.orden.anticipo) > parseFloat(this.dataPreOrden.orden.precio)) {
        this.dataPreOrden.orden.errorAnticipo = "El anticipo no puede ser mayor al precio";
        error = true;
      } else {
        this.dataPreOrden.orden.errorAnticipo = null;
      }

      if (!this.dataPreOrden.orden.formaPago && this.dataPreOrden.orden.formaPago !== 0) {
        this.dataPreOrden.orden.errorFormaPago = "Requerido";
        error = true;
      } else if (isNaN(parseInt(this.dataPreOrden.orden.formaPago))) {
        this.dataPreOrden.orden.errorFormaPago = "Debe ser un número entero";
        error = true;
      } else {
        this.dataPreOrden.orden.errorFormaPago = null;
      }

      if (!this.dataPreOrden.orden.economicoObservaciones) {
        this.dataPreOrden.orden.errorEconomicoObservaciones = "Requerido";
        error = true;
      } else {
        this.dataPreOrden.orden.errorEconomicoObservaciones = null;
      }

      return !error;
    },

    savePreOrden() {  //Función pa guardar y editar una pre-orden.
      if (!this.validatePreOrden()) return ;

      const data = {
          idCotizacion: this.idOrden,  
          idCliente: 1,
          orden: {
              ancho: 6,      
              anticipo: parseFloat(this.dataPreOrden.orden.anticipo),   
              barnizFrente: "Soy un barniz :P",  //this.dataCotizacion.barnizFrente, 
              cantidadPza: this.dataCotizacion.cantidadPza,
              desglosado: this.dataPreOrden.orden.desglosado, 
              economicoObservaciones: this.dataPreOrden.orden.economicoObservaciones, 
              empalme: true,  //this.dataCotizacion.empalme,  
              empalmeMts: 4.8,//parseFloat(this.dataCotizacion.empalmeMetros), 
              entregarEn: this.dataPreOrden.orden.entregarEn, 
              fechaCotizacion: this.dataCotizacion.fechaCreacion,//"2024-10-14",
              fechaPromesa: this.dataPreOrden.orden.fechaPromesa,//"2024-11-10",  
              folioCotizacion: this.idOrden,    
              formaPago: this.dataPreOrden.orden.formaPago,             
              idArticuloPapel: this.dataCostos.idPapel,       
              largo: 4,              
              matrixCms: parseFloat(this.dataCotizacion.suajeMatrix),              
              nombreTrabajo: this.dataCostos.trabajoMostrar.nombreTrabajo,          
              ordenCompra: parseInt(this.dataPreOrden.orden.ordenCompra),            
              pegadoCms: 12.2, //this.dataCotizacion.pegadoCms,              
              pegadoEsquinas: 8, //this.dataCotizacion.pegadoGolpeEsquinas,        
              pegadoGolpe: this.dataCotizacion.pegadoGolpe,        
              pegadoLineal: this.dataCotizacion.pegado,      
              piezasExtra: parseInt(this.dataPreOrden.orden.piezasExtra),            
              precio: parseFloat(this.dataPreOrden.orden.precio),                 
              referenciaPago: this.dataPreOrden.orden.referenciaPago, 
              suajeCms: parseFloat(this.dataCotizacion.suajeCentimetros),               
              suajado: 1, //this.dataCotizacion.suajado,                
              tablaDesbarbe: true,      
              tablaDesbarbeAltura: parseFloat(this.dataCotizacion.suajeDesbarbeAltura),    
              tablaDesbarbeBase: parseFloat(this.dataCotizacion.suajeDesbarbeBase),      
              tablaSuaje: this.dataCotizacion.suajadoTablaSuaje,          
              tablaSuajeAltura: parseFloat(this.dataCotizacion.suajeTablaAltura),      
              tablaSuajeBase: parseFloat(this.dataCotizacion.suajeTablaBase),         
              telefono: this.dataCostos.clienteMostrar.telefono,    
              tintasFrente: 1,          
              tintasVuelta: 1,           
              tipoTablaSuaje: this.dataCotizacion.suajadoTipo,         
              trabajoObservaciones: this.dataPreOrden.orden.trabajoObservaciones
          },
          fechaCreacion: this.dataPreOrden.fechaCreacion, 
          estatus: 0,    
      };       
      axios({
        url: this.dataPreOrden.idPreOrden == null
          ? "/PreOrden/Crear"
          : `/PreOrden/Actualizar/${this.dataPreOrden.idPreOrden}`,
        method: this.dataPreOrden.idPreOrden == null
          ? "POST"
          : "PUT",
        data  
      })
        .then(() => {
          this.hide_dialog();
          //console.log("Todo salió bien :D", data)
          this.saving = false;
        })
        .catch(error => {
          console.log(error);
          this.saving = false;
          //console.log(data);
        });
    },

    getClientes() {
    axios
        .get("/Clientes")
        .then(response => { this.dataClientes = response.data 
        //console.log("Soy de Geometrika");
        })
        .catch(error => { console.log(error.response) })
    },

    getLineasComercial() {
      axios
        .get("/ComercialLineas")
        .then(response => {
          this.lineasComerciales = response.data;
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAlmacenes() {
      axios
        .get("/Almacenes")
        .then(response => {
          this.almacenes = response.data;
        })
        .catch(error => {
          console.log(error)
        })
    },

    getOtrosArticulos() {  //Obtención de artículos para seleccionar en la tabla de "Pedidos"
      axios
        .get("/Articulos/OTS")
        .then(response => {
          this.dataArticulos = response.data;
          //console.log("Holi :D");
        })
        .catch(error => {
          console.log(error);
        })
    },

    obtenerVendedores() {
      axios
        .get("/vendedores")
        .then((response) => {
          this.vendedores = response.data
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    agregarArticulo() {  //Pa agregar múltiples artículos en la tabla de la sección "Pedido"
      this.obtenerPrecioUnitario();
      var detalle = Object.assign({}, this.dataArtiExtraDefault);
      this.pos += 1;
      detalle.id = this.pos;
      this.dataArtiExtra.articulos.push(detalle);
    },
    deleteItem(item) {  //Elimina filas en la tabla de la sección "Pedido" y recalcula cantidad pzas y costo
      let deleteIndex = this.dataArtiExtra.articulos.indexOf(item);
      if (deleteIndex !== -1) {
        const cantidadEliminada = this.dataArtiExtra.articulos[deleteIndex].cantidad;
        this.dataArtiExtra.articulos.splice(deleteIndex, 1);
        //this.actualizarCostoTotal();

        this.piezasTotal -= parseInt(cantidadEliminada);
        this.costoTotal -= (cantidadEliminada * this.precioUnitario);
        this.actualizarCostoTotal();
      }
    },


    getUnidades() {
      axios
        .get("/Unidades")
        .then(response => {
          this.dataUnidades = response.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },

    hide_dialog() {
      this.dialog = false;
      //this.precioUnitario = 0;
      this.dataArtiExtra.articulos = [];
      this.costoTotal = 0;
      this.$nextTick(() => {
        this.dataPreOrden = Object.assign({}, this.defaultDataPreOrden);
        //console.log(this.dataPreOrden);
        //this.editedIndex = -1;
      });
    },

    closeExtra(){ //Función usada en el diálogo de agregar artículos
      this.artiExtra = false;
      this.$nextTick(() => {
        this.dataArtiExtra = Object.assign({}, this.dataArtiExtraDefault);
      });
    },

    cambio_suajado(){
      if(!this.dataPostAPI.suajado){
        this.dataPostAPI.suajadoTipo = null;
        this.dataPostAPI.suajadoDelta = null;
        this.dataPostAPI.idSuajadoMaquina = null;
        this.dataPostAPI.IdSuajadoMMaquina = null;
        this.dataPostAPI.suajadoTablaSuaje = false;

        this.dataPostAPI.suajeTablaBase = null;
        this.dataPostAPI.suajeTablaAltura = null;
        this.dataPostAPI.suajeDesbarbeBase = null;
        this.dataPostAPI.suajeDesbarbeAltura = null;
        this.dataPostAPI.suajeCentimetros = null;
        this.dataPostAPI.suajeMatrix = null;
      }
    },

    cambio_suajadoTablaSuaje(){
      if (!this.dataPostAPI.suajadoTablaSuaje) {
        this.dataPostAPI.suajeTablaBase = null;
        this.dataPostAPI.suajeTablaAltura = null;
        this.dataPostAPI.suajeDesbarbeBase = null;
        this.dataPostAPI.suajeDesbarbeAltura = null;
        this.dataPostAPI.suajeCentimetros = null;
        this.dataPostAPI.suajeMatrix = null;
      }
    },

    DataSuajadoraManualFiltradas(){
      let filtradas = this.filtrarMaquinas(this.dataMaquinasSuajeManual);
      let maquinas = filtradas.filter(item => {
        return item.subprocesos.some(item => item.subproceso === "Desbarbe Manual")
      })

      let idxMaquina = this.dataPostAPI.IdSuajadoMMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.IdSuajadoMMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.IdSuajadoMMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.IdSuajadoMMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },

    getMaquinas() {  //Pa la tabla de suaje
      axios
        .get("/GKMaquina/Listar")
        .then((response) => {
          this.dataHojeadora = response.data.filter(item => item.proceso === "Hojeado");
          this.dataHojeadora?.unshift({ "idMaquina": -1, "nombre": "No" });
          this.dataMaquinasImpresoras = response.data.filter(item => item.proceso === "Impresión");
          this.dataMaquinasBarniz = response.data.filter(item => item.proceso === "Barnizado");
          this.dataMaquinasUvPlasta = response.data.filter(item => item.proceso === "UV Plasta");
          this.dataMaquinasUvRegistro = response.data.filter(item => item.proceso === "UV Registro");
          this.dataMaquinasPlastificado = response.data.filter(item => item.proceso === "Plastificado");
          this.dataMaquinasSuaje = response.data.filter(item => item.proceso === "Suajado");
          this.dataMaquinasSuajeManual = response.data.filter(item => item.proceso === "Suajado")
          this.dataMaquinasCorte = response.data.filter(item => item.proceso === "Corte");
          this.dataMaquinasEmpalme = response.data.filter(item => item.proceso === "Empalme");
          this.dataMaquinasPegado = response.data.filter(item => item.proceso === "Pegado Lineal");
          this.dataMaquinasPegadoGolpe = response.data.filter(item => item.proceso === "Pegado de Golpe");
          this.dataMaquinasTroquelBobina = response.data.filter(item => item.proceso === "Troquelado Bobina");
          this.dataMaquinasTroquelManual = response.data.filter(item => item.proceso === "Troquelado Manual");
          this.dataMaquinasTroquelAutomatica = response.data.filter(item => item.proceso === "Troquelado Automatica");
          this.dataMaquinaVasosS = response.data.filter(item => item.proceso === "Formadora Vaso Pared Sencilla");
        })
        .catch((error) => { console.log(error) })
    },

    autoseleccionMaquinas(){
          if(this.DataHojeadoraFiltradas.length === 1){this.dataPostAPI.idHojeadora = this.DataHojeadoraFiltradas[0].idMaquina}
          if(this.dataImpresorasFiltradas.length === 1){this.dataPostAPI.idImpresionMaquina = this.dataImpresorasFiltradas[0].idMaquina}
          if(this.DataBarnizadorasFiltradas.length === 1){this.dataPostAPI.idBarnizFrenteMaquina = this.DataBarnizadorasFiltradas[0].idMaquina}
          if(this.DataBarnizadorasFiltradas.length === 1){this.dataPostAPI.idBarnizVueltaMaquina = this.DataBarnizadorasFiltradas[0].idMaquina}
          if(this.DataUvPlastaFiltradas.length === 1){this.dataPostAPI.idUVPlastaFrenteMaquina = this.DataUvPlastaFiltradas[0].idMaquina}
          if(this.DataUvPlastaFiltradas.length === 1){this.dataPostAPI.idUVPlastaVueltaMaquina = this.DataUvPlastaFiltradas[0].idMaquina}
          if(this.DataUvRegistroFiltradas.length === 1){this.dataPostAPI.idUVRegistroFrenteMaquina = this.DataUvRegistroFiltradas[0].idMaquina}
          if(this.DataUvRegistroFiltradas.length === 1){this.dataPostAPI.idUVRegistroVueltaMaquina = this.DataUvRegistroFiltradas[0].idMaquina}
          if(this.DataPlastificadoFiltradas.length === 1){this.dataPostAPI.idPlastificadoFrenteMaquina = this.DataPlastificadoFiltradas[0].idMaquina}
          if(this.DataPlastificadoFiltradas.length === 1){this.dataPostAPI.idPlastificadoVueltaMaquina = this.DataPlastificadoFiltradas[0].idMaquina}
          if(this.DataSuajadoraFiltradas.length === 1){this.dataPostAPI.idSuajadoMaquina = this.DataSuajadoraFiltradas[0].idMaquina}
          if(this.DataSuajadoraManualFiltradas.length === 1){this.dataPostAPI.IdSuajadoMMaquina = this.DataSuajadoraManualFiltradas[0].idMaquina}
          if(this.DataCorteFiltradas.length === 1){this.dataPostAPI.idCorteMaquina = this.DataCorteFiltradas[0].idMaquina}
          if(this.DataEmpalemFiltradas.length === 1){this.dataPostAPI.idEmpalmeMaquina = this.DataEmpalemFiltradas[0].idMaquina}
          if(this.DataPegadoLinealFiltradas.length === 1){this.dataPostAPI.idPegadoMaquina = this.DataPegadoLinealFiltradas[0].idMaquina}
          if(this.DataPegadoGolpeFiltradas.length === 1){this.dataPostAPI.idPegadoGolpeMaquina = this.DataPegadoGolpeFiltradas[0].idMaquina}
          if(this.DataTroquelBobinaFiltradas.length === 1){this.dataPostAPI.idTroquelMaquina = this.DataTroquelBobinaFiltradas[0].idMaquina}
          if(this.DataTroquelManualFiltradas.length === 1){this.dataPostAPI.idTroquelMaquina = this.DataTroquelManualFiltradas[0].idMaquina}
          if(this.DataTroquelAutomaticoFiltradas.length === 1){this.dataPostAPI.idTroquelMaquina = this.DataTroquelAutomaticoFiltradas[0].idMaquina}
          if(this.dataMaquinasVasosSFiltradas.length === 1){this.dataPostAPI.idVasoParedSMaquina = this.dataMaquinasVasosSFiltradas[0].idMaquina}
    },

    DataSuajadoraFiltradas() {
      //let maquinas = this.filtrarMaquinas(this.dataMaquinasSuaje);
      let filtradas = this.filtrarMaquinas(this.dataMaquinasSuaje);
      let maquinas = filtradas.filter(item => {
        return item.subprocesos.some(item => item.subproceso !== "Desbarbe Manual")
      })

      let idxMaquina = this.dataPostAPI.idSuajadoMaquina != null ? maquinas?.findIndex(item => item.idMaquina === Number(this.dataPostAPI.idSuajadoMaquina)) : null

      if (idxMaquina >= 0 && maquinas[idxMaquina]?.disabled) {
        this.dataPostAPI.idSuajadoMaquina = null;
      }
      if (idxMaquina && maquinas.filter(item => !item.disabled).length === 1) {
        let unicaMaquina = maquinas.filter(item => !item.disabled)
        this.dataPostAPI.idSuajadoMaquina = unicaMaquina[0].idMaquina
      }
      return maquinas
    },
    getProveedores() {  //Se obtienen proveedores parea el crud de suaje.
      this.loadingProveedores = true;
      axios
        .get("/Proveedores")
        .then((response) => {
          this.dataProveedores = response.data;
          this.loadingProveedores = false;
        })
        .catch((error) => { 
          console.log(error);
          this.loadingProveedores = false; 
        })
    },
    getAreas() {
      this.loadingAreas = true;
      axios.get("/Areas").then((response) => {
        this.loadingAreas = false;
        this.areas = response.data.filter((a) => a.idArea > 1);
      });
    },
    getArticulos() {
      this.loadingArticulos = true;
      axios
        .get("/Articulos/ArticulosNombres?componentes=true")
        .then((response) => {
          this.articulos = response.data;
          this.loadingArticulos = false;
          //console.log(this.articulos);
        })
        .catch((error) => {
          this.loadingArticulos = false;
          console.log(error);
        });
    },
    getMaquinasIns() {
      this.loadingMaquinas = true;
      axios
        .get("/Maquinas")
        .then((response) => {
          this.maquinas = response.data;
          this.loadingMaquinas = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingMaquinas = false;
        });
    },
    getRecetasAreas(paso) {
      if (paso.idArticulo == null || this.editedItem.idMaquina == null) return;
      
      this.$set(paso,'loadingRecetas',true);
      
      axios
        .get('/Moldes/ListarRecetasAreas',{
            params: {
              idArticulo: paso.idArticulo,
              idMaquina: this.editedItem.idMaquina
            }
          }
        )
        .then((response) => {
          this.$set(paso,'recetas',response.data);
        })
        .catch((error) => {
          this.$set(paso,'recetas',[]);
          console.log(error);
        })
        .finally( () => {
          this.$set(paso,'loadingRecetas',false);
        });
    },
    getTransporte() {
      axios
        .get("/TransportePreguntas/Listar")
        .then((response) => {
          this.dataTransporte = response.data
        })
        .catch((error) => { console.log(error); });
    },
    getMostrarCotizacionPre() {  //Obtención de datos para formulario principal
      axios
        .get(`/Orden/MostrarDatos/${this.idOrden}`)
        .then((response) => {
          if (response.status == 204) {
            this.$emit("error_mostrarCotizacion", 204);
          }
          else {
            this.dataCotizacion = response.data;
            this.getTransporte();
            this.dialog = true;
            //console.log(this.dataCotizacion);
          }
        })
        .catch((error) => {
          this.$emit("error_mostrarCotizacion", error);
          this.dialog = false;
        })
    },

    getMostrarCalculosPaPre() { //Se obtienen datos para mostrar en el formualario principal
      axios
        .get(`/Orden/MostrarCalculos/${this.idOrden}`)
        .then((response) => {
          if (response.status == 204 || !response.data) {
            this.$emit("error_mostrarCalculos", 204);
          }
          else {
            this.dataCostos = response.data;
            this.dataPreOrden.orden.precio = this.dataCostos.precioVenta.total.toFixed(2); //Dato colocado en el campo "precio"
            this.precioDivision = this.dataPreOrden.orden.precio; //Se manda para cálculo de costo por pieza individual.
            //this.dialog = true;
            //console.log("Info", this.dataCostos);
          }
        })
        .catch((error) => {
          this.$emit("error_mostrarCalculos", error.response.status);
          //this.dialog = false;
        })
    },
  }
}
</script>

<style>
.my-title {
  color: #5c5c5c;
  margin-top: 50px;
  padding-bottom: 10px;
  width: 100%;
}

.padding-hr {
  padding: 0 0 15px 0;
}
</style>