<template>
	<v-col cols="12">
		<variante
			:show="showReceta"
			:idArticulo="idArticulo"
			@change="obtenerVariantes"
		></variante>
		<especial
			:show="showEspecial"
			:mensaje="mensaje"
			@change="changeEspecial"
		>
		</especial>
		<v-dialog v-model="dialogBusquedaTag" persistent max-width="60%">
			<v-card>
				<v-card-title>Búsqueda por Tags</v-card-title>
				<v-card-text>
					<v-col cols="12">
						<VSelectTags
							v-model="itemTags"
							ref="selecTags"
							:key="chkey"
							:items="tags"
							itemText="tag"
							itemValue="idTag"
							placeholder="Búsqueda por Tags"
							:disabled="loadingTagsOTS"
							@input="changeTableOTS"
						/>
					</v-col>
					<v-col cols="12">
						<v-data-table
							:items="articulosxTags"
							:headers="headersTagsOTS"
							:loading="loadingTagsOTS"
							loading-text="Cargando..."
						>
							<template v-slot:[`item.nombre`]="{ item }">
								<span>
									<v-icon color="green" v-if="item.completo"
										>mdi-check</v-icon
									>
									<v-icon color="red" v-else
										>mdi-alert-outline</v-icon
									>
									{{ item.nombre }}
								</span>
							</template>
							<template v-slot:[`item.tags`]="{ item }">
								<v-chip-group class="text-center" column>
									<v-chip
										v-for="etiqueta in item.tags"
										:key="etiqueta.idTag"
										style="pointer-events: none"
									>
										{{ etiqueta.tag }}
									</v-chip>
								</v-chip-group>
							</template>
							<template v-slot:[`item.actions`]="{ item }">
								<v-btn
									color="red"
									@click="
										seleccionarArticuloTag(item.idArticulo)
									"
									>Seleccionar</v-btn
								>
							</template>
						</v-data-table>
					</v-col>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						text
						@click="cerrarDialogBusquedaTags"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="pantalla2"
			max-width="500px"
			persistent
			key="pantalla2"
		>
			<v-card>
				<v-card-text class="text-center">
					<lottie
						:options="checkOptions"
						:height="200"
						:width="200"
					/>
					<h3>
						{{
							multiple
								? "Ordenes de Trabajo Guardadas Correctamente"
								: "Orden de Trabajo Guardada Correctamente"
						}}
					</h3>
					<template v-if="!multiple">
						<div v-if="!clavePersonalizada">
							La Orden de Trabajo se guardo con el código
							<h3>ODT-{{ numOT }}</h3>
						</div>
						<div v-else>
							La Orden de Trabajo con clave
							<h3>{{ claveOT }}</h3>
						</div>
					</template>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red darken-1" text @click="close()"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="error" top color="error">
			<ul>
				<li v-for="(e, i) in errores" :key="i">{{ e }}</li>
			</ul>
			<v-btn text @click="error = false"> Cerrar </v-btn>
		</v-snackbar>
		<v-row>
			<v-col cols="6" sm="6" md="6">
				<v-select
					v-model="editedItem.idComercialLinea"
					label="Línea Comercial"
					:items="lineascomerciales"
					:loading="loadingLineasComerciales"
					item-text="nombre"
					item-value="idComercialLinea"
					hide-details
					:disabled="!newODT"
					@change="getClientes"
				></v-select>
			</v-col>
			<v-col cols="6" sm="6" md="6">
				<v-autocomplete
					v-model="editedItem.idCliente"
					:items="clientes"
					item-text="nombre"
					item-value="idCliente"
					label="Cliente"
					:disabled="!newODT"
					hide-details
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="1">
				<v-switch
					v-model="newODT"
					hide-details
					@change="editedItem.clave = ''"
				>
					<template v-slot:label>
						<p class="mb-0" style="font-size: 14px !important">
							{{ newODT ? "Nueva ODT" : "ODT Existente" }}
						</p>
					</template>
				</v-switch>
			</v-col>
			<template v-if="newODT">
				<v-col v-show="clavePersonalizada" cols="5" sm="5" md="5">
					<v-text-field
						v-model="editedItem.clave"
						label="Clave de la Orden de Trabajo"
						hide-details
					>
					</v-text-field>
				</v-col>
				<v-col v-show="!clavePersonalizada" cols="5" sm="5" md="5">
					<v-text-field
						v-model="editedItem.numOrden"
						readonly
						label="Número de Orden de Trabajo"
						hide-details
					>
					</v-text-field>
				</v-col>
			</template>
			<template v-else>
				<v-col cols="5">
					<v-autocomplete
						v-model="editedItem.clave"
						:items="odtsExistentes"
						label="Orden de Trabajo"
						placeholder="Indique la Orden de Trabajo"
						item-text="nombre"
						item-value="idTrabajoOrden"
						hide-details
						clearable
					></v-autocomplete>
				</v-col>
			</template>
			<v-col
				:cols="newODT ? 3 : 6"
				:sm="newODT ? 3 : 6"
				:md="newODT ? 3 : 6"
			>
				<VueDatePicker
					v-model="editedItem.fechaCompromiso"
					no-header
					format="DD-MM-YYYY"
					@onChange="cambioFecha"
				>
					<template #activator>
						<v-text-field
							v-model="editedItem.fechaCompromisoLabel"
							label="Fecha de Compromiso de Entrega"
							readonly
							ref="activator"
							hide-details
						></v-text-field>
					</template>
				</VueDatePicker>
			</v-col>
			<v-col cols="3" sm="3" md="3" v-if="newODT">
				<v-autocomplete
					v-model="editedItem.idVendedor"
					:items="vendedores"
					label="Vendedores"
					:item-text="nombrePersona"
					item-value="idVendedor"
					hide-details
				></v-autocomplete>
			</v-col>
			<v-col cols="6" sm="6" md="6" v-if="maquila && inventario">
				<switchx
					v-model="editedItem.maquila"
					label="¿Es una ODT de Maquila?"
				></switchx>
			</v-col>
			<v-col
				cols="5"
				md="5"
				sm="5"
				class="align-self-end"
				v-if="inventario"
			>
				<v-autocomplete
					v-model="editedItem.idAlmacen"
					label="Almacén"
					:items="almacenesFiltrados"
					hide-details
					:item-text="almacenText"
					item-value="idAlmacen"
					:disabled="!newODT"
				>
				</v-autocomplete>
			</v-col>
			<v-col cols="5" sm="5" md="5">
				<VueDatePicker
					v-model="fechainicioProduccion"
					no-header
					format="DD-MM-YYYY"
					@onChange="cambioFechaProduccion"
					:disabled="!newODT"
				>
					<template #activator>
						<v-text-field
							v-model="fechainicioProduccionLabel"
							label="Fecha de inicio de producción"
							readonly
							ref="activator"
							hide-details
							:disabled="!newODT"
						></v-text-field>
					</template>
				</VueDatePicker>
			</v-col>
			<v-col cols="2" sm="2" md="2">
				<v-switch
					v-model="sinArti"
					hide-details
					label="Sin Artículos"
				></v-switch>
			</v-col>
		</v-row>
		<template v-if="!sinArti">
			<v-row  
				v-for="(group, groupIndex) in editedItem.grupos"
				:key="`group-${groupIndex}`"
				style="border-radius: 4px; border: 1px solid #e0e0e0"
				class="mt-8 elevation-2"
			>
				<v-col
					cols="12"
					class="py-0 d-flex align-center justify-space-between"
				>
					<v-col cols="4" class="px-0 py-2" v-if="!sinArti">
						<v-text-field 
							v-model="group.codigo"
							label="Identificador Único de Parte: # de Serie"
							clearable
							:error-messages="group.errorNoCodigo"
							@focus="$set(group, 'errorNoCodigo', null)"
						></v-text-field>
					</v-col>
					<v-col cols="4" class="px-0 py-2" v-if="!sinArti">
						<v-autocomplete v-if="!sinArti"
							v-model="group.idArticuloFamilia"
							:label="
								group.idArticuloFamilia
									? 'Familia'
									: 'Seleccione una Familia'
							"
							:items="familias"
							:item-text="familiaNombre"
							item-value="idArticuloFamilia"
							:disabled="group.loadingArticulos || loadingFamilias"
							:loading="loadingFamilias"
							:error-messages="group.errorFamilia"
							@focus="$set(group, 'errorFamilia', null)"
							@change="onChangeFamilia(group)"
						>	
							<template v-slot:append-outer>
								<v-tooltip bottom color="black" class="white--text">
									<template v-slot:activator="{ on }">
										<v-icon v-if="!sinArti"
											v-on="on"
											:disabled="
												group.loadingArticulos ||
												loadingFamilias
											"
											@click="deleteGroup(groupIndex)"
											>mdi-delete</v-icon
										>
									</template>
									<span class="white--text">Eliminar grupo</span>
								</v-tooltip>
							</template>	
						</v-autocomplete>
					</v-col>
				</v-col>
				<v-col cols="12" sm="12" md="12" class="pt-0" v-if="!sinArti">
					<v-data-table v-if="!sinArti"
						:key="`table-${groupIndex}`"
						:items="group.articulos"
						hide-default-footer
						:items-per-page="-1"
						:headers="headers"
						:loading="group.loadingArticulos"
						:no-data-text="
							group.idArticuloFamilia == null
								? 'Seleccione una familia'
								: 'Agregue al menos un procedimiento'
						"
					>
						<template v-slot:item="{ item, index }" >
							<tr>
								<td class="text-center">
									<v-autocomplete
										v-model="item.idArticulo"
										:items="group.articulosSet"
										:item-text="codigoNombre"
										:items-icons="'mdi-account-cowboy-hat-outline'"
										item-value="idArticulo"
										@change="
											cargarPropiedades(
												item,
												item.idArticulo,
												group,
												groupIndex
											);
											getSufijoUnidad(item, group);
										"
										:error="errorArticulo(item.idArticulo)"
										:class="
											errorArticulo(item.idArticulo)
												? 'v-input--is-focused'
												: ''
										"
										dense
										hide-details
										single-line
										append-outer-icon="mdi-magnify"
										@click:append-outer="
											showDialogBusquedaTag(index, groupIndex)
										"
										@focus="actualizarArticulo(item)"
										@blur="actualizarArticulo(item)"
									>
										<template v-slot:item="data">
											<v-list-item-avatar>
												<v-tooltip bottom>
													<template
														v-slot:activator="{
															on,
															attrs,
														}"
													>
														<span
															v-bind="attrs"
															v-on="on"
															><v-icon
																color="green"
																v-if="
																	data.item
																		.completo
																"
																>mdi-check</v-icon
															>
															<v-icon
																color="red"
																v-else
																>mdi-alert-outline</v-icon
															></span
														>
													</template>
													<span v-if="data.item.completo"
														>Completo</span
													>
													<span v-else>Incompleto</span>
												</v-tooltip>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title>{{
													codigoNombre(data.item)
												}}</v-list-item-title>
											</v-list-item-content>
										</template>
									</v-autocomplete>
								</td>
								<td class="text-center pa-0">
									<v-col cols="12" class="pa-0"> 
										<v-text-field
											v-model="item.cantidadSwitch"
											dense
											hide-details
											single-line
											class="custom-cantidad-field"
											type="number"
											min="1"
											:error="
												errorCantidad(item.cantidadSwitch)
											"
											:class="
												errorCantidad(item.cantidadSwitch)
													? 'v-input--is-focused'
													: ''
											"
											@change="
												getFactorConversion(item, group);
												actualizarArticulo(item);
											"
											@keyup="
												getFactorConversion(item, group);
												actualizarArticulo(item);
											"
										>
											<template
												v-slot:append
												v-if="item.itemsUnidades != null"
											>
												<v-select
													v-if="
														item.itemsUnidades.length >
														1
													"
													v-model="item.sufijo"
													:items="item.itemsUnidades"
													item-text="value"
													item-value="id"
													hide-details
													dense
													style="width: 68px !important"
													@change="
														getFactorConversion(
															item,
															group
														);
														actualizarArticulo(item);
													"
												></v-select>
												<span
													v-if="
														item.itemsUnidades.length ==
														1
													"
												>
													{{
														item.itemsUnidades[0].value
													}}
												</span>
											</template>
										</v-text-field>
									</v-col>
									<v-col cols="12" class="pa-0">
										<p
											:key="`p${index}-${phkey}`"
											class="text-left mb-0 pt-2"
										>
											{{ item.factor }}
										</p>
									</v-col>
								</td>
								<td
									class="text-center"
									v-if="permisoVista('listas', '$')"
								>
									$
									{{
										item.unidad != null
											? `${item.precio.toFixed(2)} x ${
													item.unidad
											}`
											: item.precio.toFixed(2)
									}}
								</td>
								<td
									class="text-center"
									v-if="permisoVista('listas', '$')"
								>
									$
									{{
										item.unidad != null
											? `${item.precioTotal.toFixed(2)} x ${
													item.unidad
											}`
											: item.precioTotal.toFixed(2)
									}}
								</td>
								<td
									:class="
										'text-center ' +
										statusClass(item.statusArticulo)
									"
								>
									<div v-if="item.statusArticulo != 0">
										{{ estadoLabel(item.statusArticulo) }}
									</div>
									<div v-else>
										<v-progress-circular
											indeterminate
											color="primary"
											size="24"
											width="3"
										></v-progress-circular>
									</div>
								</td>
								<td
									:class="
										'text-center ' + statusClass(item.statusMP)
									"
								>
									<div
										v-if="
											item.statusMP != 0 && item.statusMP != 2
										"
									>
										{{ estadoLabel(item.statusMP) }}
									</div>
									<div v-else-if="item.statusMP == 2">
										<v-tooltip
											bottom
											color="black"
											class="white--text"
										>
											<template v-slot:activator="{ on }">
												<span v-on="on">{{
													estadoLabel(item.statusMP)
												}}</span>
											</template>
											<span class="white--text text-left">
												<ul>
													<li
														v-for="(
															msg, idx
														) in item.faltantesMP"
														:key="
															'faltante' +
															item.idArticulo +
															'-' +
															idx
														"
													>
														{{ msg }}
													</li>
												</ul>
											</span>
										</v-tooltip>
									</div>
									<div v-else>
										<v-progress-circular
											indeterminate
											color="primary"
											size="24"
											width="3"
										></v-progress-circular>
									</div>
								</td>
								<td>
									<div class="d-flex align-center justify-center">
										<v-tooltip
											bottom
											color="black"
											class="white--text"
										>
											<template v-slot:activator="{ on }">
												<v-icon
													v-on="on"
													small
													class="mr-2"
													@click="
														deleteItem(group, index)
													"
												>
													delete
												</v-icon>
											</template>
											<span class="white--text"
												>Eliminar</span
											>
										</v-tooltip>
									</div>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-col>
				<v-col v-if="!sinArti"
					cols="12"
					class="pa-0 ma-0 d-flex align-center justify-space-between"
				>
					<v-btn
						color="blue darken-1"
						text
						:disabled="
							group.articulos.length >= group.articulosSet.length ||
							group.loadingArticulos
						"
						@click="agregarArticulo(group)"
					>
						<v-progress-circular
							v-if="group.loadingArticulos"
							indeterminate
							color="grey lighten-1"
							size="20"
							width="2"
							class="ma-0 mr-1"
						></v-progress-circular>
						<v-icon v-else>mdi-plus</v-icon> Agregar
						Procedimiento</v-btn
					>
					<v-slide-y-transition>
						<p
							v-if="group.errorNoArticulos"
							class="pr-4 mb-0 error--text"
						>
							{{ group.errorNoArticulos }}
						</p>
					</v-slide-y-transition>
				</v-col>
			</v-row>
		</template>
		
		<v-row>
			<v-col v-show="!sinArti">
				<v-row class="pa-0 ma-0">
					<v-col cols="6" md="6" sm="6" class="pa-0 ma-0">
						<v-btn
							color="blue darken-1"
							text
							@click="agregarGrupo()"
							class="pl-1"
						>
							<v-icon>mdi-plus</v-icon> Agregar Elemento</v-btn
						>
					</v-col>
					<v-col cols="6" md="6" sm="6" class="px-0 text-right">
						<h3
							class="ma-0 pa-0"
							v-if="permisoVista('listas', '$')"
						>
							Total: ${{ costoTotal.toFixed(2) }}
						</h3>
						<h4 class="ma-0 pa-0 text--secondary">
							<span
								class="font-weight-medium"
								style="font-size: 17px !important"
							>
								Fecha Aproximada de Finalización: </span
							>{{ editedItem.fechaEntrega }}
						</h4>
						<div class="d-flex justify-end">
							<v-progress-linear
								v-if="loadingFechaEntrega"
								indeterminate
								color="primary"
								height="3"
								style="width: 367px"
							></v-progress-linear>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<div v-if="!multiple" class="d-flex justify-end">
			<v-checkbox v-show="!sinArti"
				v-model="editedItem.iniciarAuto"
				label="Iniciar automáticamente"
				color="primary"
				class="mt-0"
				hide-details
			></v-checkbox>
		</div>
		<div class="d-flex justify-end">
			<v-btn
				class="mr-4"
				color="blue darken-1"
				:disabled="saving"
				@click="close()"
				>Limpiar</v-btn
			>
			<v-btn
				color="red darken-1"
				:loading="saving"
				:disabled="saving"
				@click="save()"
			>
				Guardar
			</v-btn>
		</div>
	</v-col>
</template>

<script>
import Lottie from "vue-lottie";
import * as checkData from "@/assets/check.json";
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import switchx from "@/components/Switchx";
import variante from "./Variante";
import especial from "./Especial";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import VSelectTags from "@/components/VSelectTags.vue";
import qs from "qs";

export default {
	components: {
		lottie: Lottie,
		variante: variante,
		especial: especial,
		VueDatePicker,
		switchx,
		VSelectTags,
	},
	props: {
		comercialLines: {
			type: Array,
			default: () => [],
		},
		warehouse: {
			type: Array,
			default: () => [],
		},
		articTags: {
			type: Array,
			default: () => [],
		},
		iniciarAutoNuevaODT: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		loadingFamilias: false,
		familias: [],
		vendedores: [],
		odtsExistentes: [],
		newODT: true,
		saving: false,
		sinArti: false, //Para ocultar o mostrar
		phkey: 0,
		chkey: 0,
		itemIndex: -1,
		itemGroupIndex: -1,
		articuloTagSeleccionado: [],
		articulosxTags: [],
		loadingTagsOTS: false,
		headersTagsOTS: [
			{
				text: "Artículo",
				align: "center",
				sortable: true,
				value: "nombre",
			},
			{
				text: "",
				align: "center",
				sortable: true,
				value: "tags",
			},
			{
				text: "Acciones",
				sortable: false,
				align: "center",
				value: "actions",
				width: "15%",
			},
		],
		itemTags: [],
		dialogBusquedaTag: false,
		costoTotal: 0.0,
		headers: [
			{
				text: "Procedimiento",
				align: "center",
				sortable: true,
				value: "nombre",
				width: "30%",
			},
			{
				text: "Cantidad",
				align: "center",
				sortable: true,
				value: "cantidad",
				width: "15%",
			},
			{
				text: "Precio Unitario",
				align: "center",
				sortable: true,
				value: "costo",
				width: "13%",
			},
			{
				text: "Precio Total",
				align: "center",
				sortable: true,
				value: "precioTotal",
				width: "13%",
			},
			{
				text: "Disponibilidad de Recursos",
				align: "center",
				sortable: true,
				value: "disponibilidadProducto",
				width: "14%",
			},
			{
				text: "Disponibilidad de M.P.",
				align: "center",
				sortable: true,
				value: "disponibilidadMateriaPrima",
				width: "13%",
			},
			{
				text: "Acciones",
				value: "action",
				width: "9%",
				sortable: false,
				align: "center",
			},
		],
		clientes: [],
		loadingCliente: false,
		editedItem: {
			idCliente: -1,
			idComercialLinea: -1,
			fechaCreacion: moment().format("DD-MM-YYYY h:mm a"),
			fechaEntrega: "Información Insuficiente",
			numOrden: null,
			grupos: [],
			fechaCompromiso: new Date(),
			fechaCompromisoLabel: "",
			clave: "",
			maquila: false,
			idAlmacen: 1,
			idVendedor: null,
			iniciarAuto: false,
		},
		loadingFechaEntrega: false,
		defaultEditedItem: {
			idCliente: -1,
			idComercialLinea: -1,
			fechaCreacion: moment().format("DD-MM-YYYY h:mm a"),
			fechaEntrega: "Información Insuficiente",
			numOrden: null,
			grupos: [],
			fechaCompromiso: new Date(),
			fechaCompromisoLabel: "",
			clave: "",
			maquila: false,
			idAlmacen: 1,
			idVendedor: null,
			iniciarAuto: false,
		},
		pos: 0,
		error: false,
		errores: [],
		pantalla1: false,
		pantalla2: false,
		numOT: -1,
		claveOT: "",
		checkOptions: {
			animationData: checkData.default,
			loop: false,
			speeed: 0.5,
		},
		showReceta: false,
		idArticulo: -1,
		showEspecial: false,
		posEspecial: -1,
		recetaItemIndex: -1,
		recetaGroupIndex: -1,
		mensaje: "",
		loadingComercialLineas: false,
		defaultElemento: {
			codigo: null,
			idArticuloFamilia: null,
			loadingArticulos: false,
			otFamilias: [],
			articulos: [],
			articulosSet: [],
			errorNoArticulos: null,
			errorNoCodigo: null,
			errorFamilia: null,
		},
		defaultDetalle: {
			id: -1,
			claveODT: "",
			idArticulo: -1,
			nombre: "",
			codigo: "",
			cantidad: "",
			precio: 0,
			precioTotal: 0,
			statusArticulo: 0,
			statusMP: 0,
			especial: false,
			faltantesMP: [],
			mensaje: "",
			recetas: [],
			cambios: [],
			excedentesDisponibles: [],
			loadingExcedentesDisponibles: false,
			tiempo: 0,
			excedentes: false,
			iniciarAuto: false,
		},
		tiempo: 0.0,
		cuentaAtras: false,
		calcularItem: null,
		fechainicioProduccionLabel: "",
		fechainicioProduccion: null,
	}),

	computed: {
		...mapState({
			nombreEmpresa: "nombreEmpresa",
			clavePersonalizada: "clavePersonalizada",
			maquila: "maquila",
			inventario: "inventario",
		}),
		lineascomerciales() {
			return this.comercialLines;
		},
		almacenes() {
			return this.warehouse;
		},
		tags() {
			return this.articTags;
		},
		almacenesFiltrados() {
			if (this.editedItem.maquila) {
				return this.almacenes.filter((a) => {
					return (
						a.idCliente == this.editedItem.idCliente &&
						a.idCliente != null
					);
				});
			} else {
				return this.almacenes.filter((a) => {
					return a.idCliente == null;
				});
			}
		},
	},
	mounted() {
		this.initialize();
		if (!this.permisoVista("listas", "$")) {
			this.headers = this.headers.filter((element) => {
				return (
					element.value != "costo" && element.value != "precioTotal"
				);
			});
		}
		this.editedItem.fechaCompromisoLabel = this.formatoFecha(new Date());
		this.defaultEditedItem.fechaCompromisoLabel = this.formatoFecha(
			new Date()
		);
	},
	methods: {
		errorClaveODT: (claveODT) => claveODT == "" || claveODT == null,
		familiaNombre: ({ codigo, nombre }) =>
			`${codigo ? `[${codigo}] -` : ""} ${nombre}`.trim(),
		deleteGroup(groupIndex) {
			this.editedItem.grupos.splice(groupIndex, 1);
			if (this.editedItem.grupos.length == 0) this.agregarGrupo();

			this.actualizarCostoTotal();
			this.obtenerTiempoEstimadoEntrega();
		},
		seleccionarArticuloTag(idArticulo) {
			this.editedItem.grupos[this.itemGroupIndex].articulos[
				this.itemIndex
			].idArticulo = idArticulo;
			this.cargarPropiedades(
				this.editedItem.grupos[this.itemGroupIndex].articulos[
					this.itemIndex
				],
				idArticulo,
				this.editedItem.grupos[this.itemGroupIndex],
				this.itemGroupIndex
			);
			this.cerrarDialogBusquedaTags();
		},
		changeTableOTS() {
			this.loadingTagsOTS = true;
			axios
				.get("/Articulos/OTS", {
					params: {
						idTags: this.itemTags.reduce((acc, tag) => {
							if (tag != null) acc.push(tag.idTag);
							return acc;
						}, []),
						idArticuloFamilia:
							this.editedItem.grupos[this.itemGroupIndex]
								.idArticuloFamilia,
					},
					paramsSerializer: (params) =>
						qs.stringify(params, {
							indices: false,
							skipNulls: true,
						}),
				})
				.then((response) => {
					this.articulosxTags = response.data;
					this.loadingTagsOTS = false;
				})
				.catch((error) => {
					this.loadingTagsOTS = false;
					console.log(error);
				});
		},
		cerrarDialogBusquedaTags() {
			this.dialogBusquedaTag = false;
			this.chkey += 1;
			this.articuloTagSeleccionado = [];
			this.itemTags = [];
			this.itemIndex = -1;
			this.itemGroupIndex = -1;
			this.articulosxTags = [];
		},
		showDialogBusquedaTag(index, groupIndex) {
			this.itemIndex = index;
			this.itemGroupIndex = groupIndex;

			this.changeTableOTS();

			this.dialogBusquedaTag = true;
		},
		getFactorConversion(item, group) {
			const articulo = group.articulosSet.find(
				(articulo) => articulo.idArticulo == item.idArticulo
			);
			if (articulo != undefined) {
				if (
					articulo.unidad != null &&
					articulo.factorConversion != null &&
					item.cantidadSwitch != null &&
					item.cantidadSwitch != undefined &&
					item.cantidadSwitch > 0 &&
					!isNaN(item.cantidadSwitch)
				) {
					if (item.sufijo == 1 && articulo.unidadAuxiliar != null) {
						// console.log("Caso E");
						this.$set(item, "cantidad", item.cantidadSwitch);
						this.$set(
							item,
							"factor",
							`~ ${(
								articulo.factorConversion * item.cantidadSwitch
							).toFixed(2)} ${articulo.unidadAuxiliar}`
						);
					} else if (item.sufijo == 2 && articulo.unidad != null) {
						// console.log("Caso D");
						this.$set(
							item,
							"cantidad",
							item.cantidadSwitch / articulo.factorConversion
						);
						this.$set(
							item,
							"factor",
							`~ ${(
								item.cantidadSwitch / articulo.factorConversion
							).toFixed(2)} ${articulo.unidad}`
						);
					} else {
						// console.log("Caso C");
						this.$set(item, "cantidad", item.cantidadSwitch);
						this.$set(item, "factor", "");
					}
				} else {
					// console.log("Caso B");
					this.$set(item, "cantidad", item.cantidadSwitch);
					this.$set(item, "factor", "");
				}
			} else {
				// console.log("Caso A");
				this.$set(item, "cantidad", item.cantidadSwitch);
				this.$set(item, "factor", "");
			}
		},
		getSufijoUnidad(item, group) {
			const articulo = group.articulosSet.find(
				(articulo) => articulo.idArticulo == item.idArticulo
			);

			if (articulo != undefined) {
				let unidades = [];
				if (articulo.unidad != null) {
					unidades.push({ id: 1, value: articulo.unidad });
					this.$set(item, "unidad", articulo.unidad);
					this.$set(item, "sufijo", 1);
				}
				if (articulo.unidadAuxiliar != null)
					unidades.push({ id: 2, value: articulo.unidadAuxiliar });
				this.$set(item, "itemsUnidades", unidades);
			} else {
				this.$set(item, "itemsUnidades", []);
			}
		},
		almacenText(item) {
			return (
				"[" +
				item.nombre +
				"] - " +
				(item.cliente == null ? this.nombreEmpresa : item.cliente)
			);
		},
		codigoNombre: (element) =>
			"[" + element.codigo + "] - " + element.nombre,
		validFloat(value) {
			return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
		},
		cambioFecha() {
			if (typeof this.editedItem.fechaCompromiso === "string") {
				this.editedItem.fechaCompromisoLabel =
					this.editedItem.fechaCompromiso
						.split("-")
						.reverse()
						.join("-");
			} else {
				this.editedItem.fechaCompromisoLabel = this.formatoFecha(
					this.editedItem.fechaCompromiso
				);
			}
		},
		cambioFechaProduccion() {
			//if (this.fechainicioProduccionLabel == "")
			this.fechainicioProduccionLabel = this.fechainicioProduccion
				.split("-")
				.reverse()
				.join("-");
		},
		formatoFecha(date) {
			var d = ("0" + date.getDate()).slice(-2);
			var m = ("0" + (date.getMonth() + 1)).slice(-2);
			var y = date.getFullYear();
			return d + "-" + m + "-" + y;
		},
		actualizarCostoTotal() {
			this.costoTotal = this.editedItem.grupos.reduce(
				(accGroup, grupo) =>
					accGroup +
					grupo.articulos.reduce(
						(accArticulo, articulo) =>
							accArticulo + articulo.precioTotal,
						0
					),
				0
			);
		},
		predefinirInicioAutomatico() {
			this.editedItem.iniciarAuto = this.iniciarAutoNuevaODT;
			this.defaultEditedItem.iniciarAuto = this.iniciarAutoNuevaODT;
		},
		initialize() {
			// if(!this.show) return;
			this.editedItem.fechaCreacion =
				moment().format("DD-MM-YYYY h:mm a");
			this.editedItem.fechaEntrega = "Información Insuficiente";
			if (this.editedItem.grupos.length == 0) this.agregarGrupo();
			this.predefinirInicioAutomatico();
			this.getFamilias();
			this.getNumOrden();
			this.getODTExistentes();
			this.obtenerVendedores();
		},
		getFamilias() {
			this.loadingFamilias = true;

			axios
				.get(`/ArticuloFamilias`)
				.then((response) => {
					this.familias = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingFamilias = false;
				});
		},
		getODTExistentes() {
			axios
				.get("/TrabajoOrdenes/ListarOT", {
					params: {
						otsCerradas: true,
						noIniciada: true,
					},
				})
				.then((response) => {
					this.odtsExistentes = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getNumOrden() {
			this.loadingOrden = true;
			axios.get("/TrabajoOrdenes/NumeroOrden").then((response) => {
				this.editedItem.numOrden = "ODT-" + response.data;
				this.loadingOrden = false;
			});
		},
		getClientes(idComercialLinea) {
			this.editedItem.idCliente = -1;
			let me = this;
			me.loadingCliente = true;
			axios
				.get("/Clientes/ComercialLinea/" + idComercialLinea)
				.then((response) => {
					response.data.unshift({
						idCliente: null,
						rfc: "",
						nombre: me.nombreEmpresa,
					});
					me.clientes = response.data;
					me.loadingCliente = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		especial(item) {
			this.posEspecial = this.editedItem.articulos.indexOf(item);
			this.showEspecial = true;
			this.mensaje = this.editedItem.articulos[this.posEspecial].mensaje;
		},
		changeEspecial(event) {
			this.editedItem.articulos[this.posEspecial].mensaje = event;
			this.showEspecial = false;
			this.mensaje = "";
			if (event == "") {
				this.editedItem.articulos[this.posEspecial].especial = false;
			} else {
				this.editedItem.articulos[this.posEspecial].especial = true;
			}
		},
		onChangeFamilia(group) {
			this.$set(group, "errorNoArticulos", null);
			this.$set(group, "articulos", []);
			if (group.idArticuloFamilia == null) return;

			this.$set(group, "loadingArticulos", true);

			axios
				.get("/Articulos/OTS", {
					params: {
						idArticuloFamilia: group.idArticuloFamilia,
					},
				})
				.then((response) => {
					this.$set(group, "articulosSet", response.data);
				})
				.catch((error) => {
					this.$set(group, "articulosSet", []);
					console.log(error);
				})
				.finally(() => {
					this.$set(group, "loadingArticulos", false);
				});
		},
		agregarGrupo() {
			this.editedItem.grupos.push(
				JSON.parse(JSON.stringify(this.defaultElemento))
			);
		},
		agregarArticulo(group) {
			this.$set(group, "errorNoArticulos", null);
			var detalle = JSON.parse(JSON.stringify(this.defaultDetalle));
			detalle.id = group.articulos.length + 1;
			group.articulos.push(detalle);
			this.predefinirInicioAutomatico();
		},
		cargarPropiedades(item, idArticulo, group, groupIndex) {
			if (idArticulo <= 0) return;
			let articulo = group.articulosSet.find(
				(element) => element.idArticulo == idArticulo
			);
			if (typeof articulo !== "object") return;
			this.recetaGroupIndex = groupIndex;
			this.recetaItemIndex = group.articulos.indexOf(item);
			this.$set(item, "precio", articulo.costo.max);
			this.idArticulo = item.idArticulo;
			this.showReceta = true;
		},
		actualizarPrecioTotal(item) {
			this.$set(
				item,
				"precioTotal",
				this.validFloat(item.cantidad) * item.precio
			);
		},
		actualizarEstadoArticulo(item, idArticulo) {
			if (idArticulo <= 0) this.$set(item, "statusArticulo", 0);
			else this.$set(item, "statusArticulo", 1);
		},
		actualizarEstadoMP(item) {
			this.$set(item, "statusMP", 0);
			var obj = {
				IdArticulo: item.idArticulo,
				Cantidad: this.validFloat(item.cantidad),
				Mensaje:
					item.especial && !item.excedentes
						? item.mensaje == ""
							? null
							: item.mensaje
						: null,
				Recetas: item.recetas,
				Cambios: item.cambios,
				Tiempo: item.tiempo,
				Excedentes: item.excedentes,
			};

			axios
				.post(
					"/TrabajoOrdenes/FaltantesMP?IdAlmacen=" +
						this.editedItem.idAlmacen,
					obj
				)
				.then((response) => {
					if (response.data.length > 0) {
						this.$set(item, "statusMP", 2);
						this.$set(item, "faltantesMP", response.data);
					} else {
						this.$set(item, "statusMP", 1);
						this.$set(item, "faltantesMP", []);
					}
				})
				.catch((error) => {
					this.$set(item, "statusMP", 3);
					console.log(error);
				});
		},
		statusClass(code) {
			/*
			 * Codigo 0: Calculando
			 * Codigo 1: Ok
			 * Codigo 2: Insuficiente
			 * Codigo 3: No Disponible
			 */
			switch (code) {
				case 1:
					return "green white--text";
					break;
				case 2:
					return "yellow black--text";
					break;
				case 3:
					return "red white--text";
					break;
				default:
					return;
			}
		},
		estadoLabel(code) {
			/*
			 * Codigo 0: Calculando
			 * Codigo 1: Ok
			 * Codigo 2: Insuficiente
			 * Codigo 3: No Disponible
			 */
			switch (code) {
				case 1:
					return "Disponible";
					break;
				case 2:
					return "Insuficiente";
					break;
				case 3:
					return "No Disponible";
					break;
				default:
					return "Cargando ...";
			}
		},
		actualizarArticulo(item) {
			this.actualizarPrecioTotal(item);
			this.actualizarEstadoArticulo(item, item.idArticulo);
			this.actualizarCostoTotal();
			this.calcularItem = item;
			this.editedItem.fechaEntrega = "Cargando ...";
			this.loadingFechaEntrega = true;
			this.delayCalculos();
			this.$set(item, "statusMP", 0);
		},
		delayCalculos() {
			this.tiempo = 3.0;
			if (!this.cuentaAtras) {
				this.cuentaAtras = true;
				this.delayFunction();
			}
		},
		delayFunction() {
			setTimeout(() => {
				this.tiempo -= 0.5;
				if (this.tiempo > 0.0) {
					this.delayFunction();
				} else {
					this.tiempo = 0.0;
					this.cuentaAtras = false;
					this.obtenerTiempoEstimadoEntrega();
					this.actualizarEstadoMP(
						this.calcularItem,
						this.calcularItem.idArticulo
					);
				}
			}, 500);
		},
		validate() {
			this.errores = [];
			let hayErroresGrupo = false;

			if (this.clavePersonalizada) {
				if (this.newODT) {
					if (
						this.editedItem.clave.length < 5 ||
						this.editedItem.clave.length > 50
					) {
						this.errores.push(
							"La Clave debe tener al menos 5 caracteres y menos de 50."
						);
					}
				} else {
					if (
						this.editedItem.clave == null ||
						this.editedItem.clave == ""
					) {
						this.errores.push(
							"Debe seleccionar una Orden de Trabajo"
						);
					}
				}
			}

			if (this.editedItem.idComercialLinea <= 0 && this.newODT) {
				this.errores.push("Falta seleccionar una Línea Comercial.");
			}

			if (this.editedItem.idCliente != null && this.newODT) {
				if (this.editedItem.idCliente <= 0) {
					this.errores.push("Falta seleccionar un Cliente.");
				}
			}

			this.editedItem.grupos.forEach((grupo, grupoIndex) => {
				this.$set(grupo, "errorNoArticulos", null);
				this.$set(grupo, "errorNoCodigo", null);
				this.$set(grupo, "errorFamilia", null);

				if (grupo.articulos.length == 0 && !this.sinArti) {
					this.$set(
						grupo,
						"errorNoArticulos",
						"Debe agregar al menos un procedimiento al grupo"
					);
					hayErroresGrupo = true;
				}

				

				if (!grupo.codigo && !this.sinArti) {
					this.$set(grupo, "errorNoCodigo", "Requerido");
					hayErroresGrupo = true;
				}

				if (grupo.idArticuloFamilia == null && !this.sinArti) {
					this.$set(grupo, "errorFamilia", "Requerido");
					hayErroresGrupo = true;
				}

				grupo.articulos.forEach((artic, idx) => {
					if (artic.idArticulo <= 0 && !this.sinArti) {
						this.errores.push(
							`Grupo #${grupoIndex + 1}, Fila #${
								idx + 1
							}: Falta seleccionar un procedimiento.`
						);
					}

					if (isNaN(parseFloat(artic.cantidad)&& !this.sinArti)) {
						this.errores.push(
							`Grupo #${grupoIndex + 1}, Fila #${
								idx + 1
							}: La cantidad ingresada es incorrecta.`
						);
					} else {
						if (parseFloat(artic.cantidad) <= 0.0 && !this.sinArti) {
							this.errores.push(
								`Grupo #${grupoIndex + 1}, Fila #${
									idx + 1
								}: La cantidad ingresada es inferior o igual a 0.`
							);
						}
					}
				});
			});

			//Validacion de que la fecha de inicio de produccion no vaya vacia
			if (this.$utils.strings.isNullOrEmpty(this.fechainicioProduccionLabel) && this.newODT) {
        		this.errores.push("Falta agragar la fecha de inicio de produccion.");
      		}

			if (hayErroresGrupo) this.errores.push("Otros errores");

			this.error = !(this.errores.length == 0 && !hayErroresGrupo);
			return this.errores.length == 0 && !hayErroresGrupo;
		},
		errorArticulo(idArticulo) {
			return idArticulo <= 0;
		},
		errorCantidad(cantidad) {
			return isNaN(parseFloat(cantidad)) || parseFloat(cantidad) <= 0;
		},
		obtenerVariantes(event) {
			this.editedItem.grupos[this.recetaGroupIndex].articulos[
				this.recetaItemIndex
			].recetas = event.recetas;
			this.editedItem.grupos[this.recetaGroupIndex].articulos[
				this.recetaItemIndex
			].cambios = event.cambios;
			this.editedItem.grupos[this.recetaGroupIndex].articulos[
				this.recetaItemIndex
			].tiempo = event.tiempo;
			this.obtenerPrecio(this.recetaItemIndex, this.recetaGroupIndex);
			this.showReceta = false;

			this.obtenerExcedentesDisponibles(
				{
					IdArticulo:
						this.editedItem.grupos[this.recetaGroupIndex].articulos[
							this.recetaItemIndex
						].idArticulo,
					Cantidad: 0,
					Mensaje: null,
					Recetas:
						this.editedItem.grupos[this.recetaGroupIndex].articulos[
							this.recetaItemIndex
						].recetas,
					Cambios:
						this.editedItem.grupos[this.recetaGroupIndex].articulos[
							this.recetaItemIndex
						].cambios,
					Tiempo: 0,
					Excedentes: false,
				},
				this.editedItem.idAlmacen
			);
		},
		obtenerPrecio(recetaItemIndex, recetaGroupIndex) {
			let obj = {
				IdArticulo:
					this.editedItem.grupos[recetaGroupIndex].articulos[
						recetaItemIndex
					].idArticulo,
				Recetas:
					this.editedItem.grupos[recetaGroupIndex].articulos[
						recetaItemIndex
					].recetas,
				Cambios:
					this.editedItem.grupos[recetaGroupIndex].articulos[
						recetaItemIndex
					].cambios,
			};

			axios
				.post("/Articulos/ObtenerPrecio", obj)
				.then((response) => {
					this.editedItem.grupos[recetaGroupIndex].articulos[
						recetaItemIndex
					].precio = response.data.max;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		obtenerVendedores() {
			axios
				.get("/vendedores")
				.then((response) => {
					this.vendedores = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		obtenerExcedentesDisponibles(obj, idAlmacen) {
			this.editedItem.grupos[this.recetaGroupIndex].articulos[
				this.recetaItemIndex
			].loadingExcedentesDisponibles = true;
			axios
				.post(
					"/TrabajoOrdenes/ExcedentesDisponibles?IdAlmacen=" +
						idAlmacen,
					obj
				)
				.then((response) => {
					this.editedItem.grupos[this.recetaGroupIndex].articulos[
						this.recetaItemIndex
					].excedentesDisponibles = response.data;
					this.editedItem.grupos[this.recetaGroupIndex].articulos[
						this.recetaItemIndex
					].loadingExcedentesDisponibles = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		obtenerTiempoEstimadoEntrega() {
			this.loadingFechaEntrega = true;
			this.editedItem.fechaEntrega = "Cargando ...";

			const articulos = this.editedItem.grupos.reduce(
				(accGroup, grupo) => {
					const articulosReduced = grupo.articulos.reduce(
						(accArticulo, articulo) => {
							if (
								articulo.idArticulo > 0 &&
								articulo.cantidad > 0
							) {
								accArticulo.push({
									IdArticulo: articulo.idArticulo,
									Cantidad: this.validFloat(
										articulo.cantidad
									),
									Mensaje:
										articulo.especial &&
										!articulo.excedentes
											? articulo.mensaje == ""
												? null
												: articulo.mensaje
											: null,
									Recetas: articulo.recetas,
									Cambios: articulo.cambios,
									Tiempo: articulo.tiempo,
									Excedentes: articulo.excedentes,
								});
							}
							return accArticulo;
						},
						[]
					);
					accGroup.push(...articulosReduced);
					return accGroup;
				},
				[]
			);

			if (articulos.length == 0) {
				this.editedItem.fechaEntrega = "Información Insuficiente";
				this.loadingFechaEntrega = false;
				return;
			}

			let obj = {
				IdCliente: this.editedItem.idCliente,
				FechaEntrega: this.editedItem.fechaCompromisoLabel,
				articulos,
			};

			axios
				.post("/TrabajoOrdenes/EstimacionProduccion", obj)
				.then((response) => {
					this.editedItem.fechaEntrega = response.data;
					this.loadingFechaEntrega = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		save() {
			if (!this.validate()) return;

			const { articulos, otFamilias } = this.editedItem.grupos.reduce(
				(accGroup, grupo, grupoIndex) => {
					const articulosReduced = grupo.articulos.reduce(
						(accArticulo, articulo) => {
							accArticulo.push({
								ClaveODT: this.multiple
									? articulo.claveODT
									: null,
								IdArticulo: articulo.idArticulo,
								Cantidad: this.validFloat(articulo.cantidad),
								Mensaje:
									articulo.especial && !articulo.excedentes
										? articulo.mensaje == ""
											? null
											: articulo.mensaje
										: null,
								Recetas: articulo.recetas,
								Cambios: articulo.cambios,
								Tiempo: articulo.tiempo,
								Excedentes: articulo.excedentes,
								iniciarAuto: articulo.iniciarAuto,
								posicionFamilia: grupoIndex,
							});
							return accArticulo;
						},
						[]
					);
	
					accGroup.articulos.push(...articulosReduced);
					accGroup.otFamilias.push({
						idArticuloFamilia: grupo.idArticuloFamilia,
						codigo: grupo.codigo,
						posicionFamilia: grupoIndex,
					});
					return accGroup;
				},
				{ articulos: [], otFamilias: [] }
			);

			let obj = {
				IdComercialLinea: this.editedItem.idComercialLinea,
				idTrabajoOrden: this.newODT ? null : this.editedItem.clave,
				idVendedor: this.multiple
					? this.editedItem.idVendedor
					: this.newODT
					? this.editedItem.idVendedor
					: null,
				IdCliente: this.editedItem.idCliente,
				IdAlmacen: this.editedItem.idAlmacen,
				Maquila: this.editedItem.maquila,
				FechaEntrega: this.editedItem.fechaCompromisoLabel,
				Clave:
					this.clavePersonalizada && this.newODT
						? this.editedItem.clave
						: null,
				articulos,
				otFamilias: this.sinArti ? [] : otFamilias,
				FechaInicioDeProduccion: this.fechainicioProduccion,
				Adicionar: !this.newODT,
				iniciarAuto: this.editedItem.iniciarAuto,
			};
			console.log(obj);
			this.saving = true;
			axios
				.post("/TrabajoOrdenes/Crear", obj)
				.then((response) => {
					this.pantalla1 = false;
					this.pantalla2 = true;
					this.saving = false;
					this.fechainicioProduccionLabel = null;
					this.fechainicioProduccion = null;
					this.numOT = response.data.numOT;
					this.claveOT = response.data.clave;
					this.newODT = true;
					this.sinArti = false;
					this.getODTExistentes();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
					if (error.response) {
						if (error.response.status == 409) {
							this.errores = [];
							this.error = true;
							this.errores.push(
								"Existe una Orden de Trabajo con la Misma Clave"
							);
						}
					}
				});
		},
		deleteItem(group, index) {
			group.articulos.splice(index, 1);

			this.actualizarCostoTotal();
			this.obtenerTiempoEstimadoEntrega();
		},
		close() {
			this.costoTotal = 0.0;
			this.loadingFechaEntrega = false;
			this.editedItem = JSON.parse(
				JSON.stringify(this.defaultEditedItem)
			);
			this.agregarGrupo();
			this.predefinirInicioAutomatico();
			this.pantalla1 = false;
			this.pantalla2 = false;
			// this.$emit('close', false);
		},
	},
};
</script>

<style scoped>
.v-input__slot {
	justify-content: center;
}

.custom-cantidad-field >>> .v-input__append-inner {
	display: flex;
	align-self: center;
}
</style>