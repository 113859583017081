<template>
  <div v-if="permisoVista('insumos','r')">
    <v-btn v-if="permisoVista('insumos','c')" color="primary" @click="show_dialog()">Crear Nuevo Barniz</v-btn>
    <v-data-table :headers="headers" :items="dataBarniz" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex justify-center">
          <v-text-field v-model="search" v-bind="propsSearch" @keyup.enter="change_urlBarniz()"
            @click:append="change_urlBarniz()" @click:clear="reset_urlBarniz()" class="mr-3" />
          <v-autocomplete v-model="selecTipoBarniz" :items="dataBarnizTipo" :item-text="nombreBarnizTipo"
            item-value="idBarnizTipo" label="Tipo de Barniz" v-bind="propsSearchAutocomplete" @change="change_urlBarniz()"
            class="mr-3" style="width: 40% !important;" />
          <v-autocomplete v-model="searchIdBarnizGrupo" label="Grupo de Barniz" :items="dataBarnizGrupo" item-value="idBarnizGrupo" item-text="nombre"
            v-bind="propsSearchAutocomplete" style="width: 40% !important;" @change="change_urlBarniz()"/>
        </div>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip v-if="permisoVista('insumos','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip v-if="permisoVista('insumos','d')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1"
              @click="show_dialogDelete(item)">mdi-trash-can-outline</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>{{ optionDialog }} Barniz</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="barnizCodigo" label="Código" v-bind="propsText" />
            <v-text-field v-model="barnizNombre" label="Nombre" v-bind="propsText" />
            <v-autocomplete v-model="barnizUnidad" :items="dataUnidades" item-value="idUnidad" :item-text="nombreUnidades"
              label="Unidades" v-bind="propsAutocomplete" :multiple ="false" :loading="loadingUnidades" />
            <v-autocomplete v-model="idBarnizGrupo" label="Grupo de Barniz" :items="dataBarnizGrupo"
              item-value="idBarnizGrupo" item-text="nombre" v-bind="propsAutocomplete" :multiple="false"
              @change="change_urlGrupoBarniz()" />
            <v-autocomplete v-if="idBarnizGrupo" v-model="barnizBarnizTipo" :items="dataBarnizTipo"
              item-value="idBarnizTipo" :item-text="nombreBarnizTipo" label="Tipo de Barniz" v-bind="propsAutocomplete"
              :multiple="false" :loading="loadingBarnizTipo" />
            <v-text-field v-model="barnizCosto" label="Costo" v-bind="propsDecimales" prefix="$" />
            <v-text-field v-model="barnizAporte" label="Aporte Gr/m2" v-bind="propsDecimales" suffix="Gr/m2" />
            <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="barnizFechaCotizacion" label="Fecha Cotización" prepend-icon="mdi-calendar"
                  readonly v-bind="attrs" v-on="on" />
              </template>
              <v-date-picker color="primary" dark v-model="barnizFechaCotizacion"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                @change="save()" />
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text @click="hide_dialog()">Cancelar</v-btn>
          <v-btn :loading="loadingDialog" color="error" text @click="validate()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500" persistent>
      <v-card>
        <v-card-title>Eliminar Barniz</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "[{{ barnizCodigo }}] - {{ barnizNombre }}"?</v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialogDelete" color="blue darken-1" class="ml-auto" text
            @click="hide_dialogDelete()">Cancelar</v-btn>
          <v-btn :loading="loadingDialogDelete" color="error" text @click="deleteBarniz()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>
  
<script>
import axios from 'axios'
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {
    AlertSnackbar,
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: true,
      },
      propsSearchAutocomplete: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
      },
      propsText: {
        outlined: false,
        dense: false,
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      propsDecimales: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) < 0) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },
      propsAutocomplete: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },


      dialog: false,
      dialogDelete: false,
      optionDialog: null,

      barnizId: null,
      barnizUnidad: null,
      idBarnizGrupo: null,
      barnizBarnizTipo: null,
      barnizNombre: null,
      barnizCodigo: null,
      barnizRendimiento: null,
      barnizPliegoMaximo: null,
      barnizCosto: null,
      barnizAporte: null,
      barnizFechaCotizacion: null,

      search: '',
      selecTipoBarniz: null,
      searchIdBarnizGrupo: null,

      headers: [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false,
        },
        {
          text: "Nombre",
          value: "nombre",
          align: "center",
          sortable: false,
        },
        {
          text: "Grupo de Barniz",
          value: "grupoBarniz",
          align: "center",
          sortable: false,
        },
        {
          text: "Tipo de Barniz",
          value: "tipoBarniz",
          align: "center",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        },
      ],

      dataUnidades: [],
      dataBarnizTipo: [],
      dataBarniz: [],
      dataBarnizGrupo: [],

      loadingTable: false,
      loadingDialog: false,
      loadingDialogDelete: false,
      loadingBarnizTipo: false,
      loadingUnidades: false,

      urlBarniz: "/Barniz/Listar?",
      urlBarnizDefault: "/Barniz/Listar?",

      urlBarnizTipo: "/BarnizTipo/Listar",
      urlBarnizTipoBase: "/BarnizTipo/Listar",

      activePicker: null,
    }
  },
  methods: {
    nombreUnidades: (item) => `[${item.abreviatura}] - ${item.nombre}`,
    nombreBarnizTipo: (item) => `[${item.codigo}] - ${item.nombre}`,

    initialize() {
      this.getBarniz();
      this.getBarnizGrupo();
      this.getBarnizTipo();
      this.getUnidades();
    },

    validate() {
      if (this.$refs.form.validate()) this.optionDialog == 'Crear' ? this.postBarniz() : this.putBarniz()
    },

    getBarniz() {
      this.loadingTable = true;
      axios
        .get(this.urlBarniz)
        .then((response) => {
          this.dataBarniz = response.data;
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    getBarnizGrupo() {
      axios
        .get("/BarnizGrupo/Listar")
        .then((response) => {
          this.dataBarnizGrupo = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getBarnizTipo() {
      this.loadingBarnizTipo = true;
      axios
        .get(this.urlBarnizTipo)
        .then((response) => {
          this.dataBarnizTipo = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingBarnizTipo = false;
        })
    },
    getUnidades() {
      this.loadingUnidades = true;
      axios
        .get("/unidades")
        .then((response) => {
          this.dataUnidades = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingUnidades = false;
        })
    },

    postBarniz() {
      this.loadingDialog = true;
      axios
        .post("/Barniz/Crear", {
          idUnidad: this.barnizUnidad,
          idBarnizTipo: this.barnizBarnizTipo,
          idBarnizGrupo: this.idBarnizGrupo,
          nombre: this.barnizNombre,
          codigo: this.barnizCodigo,
          rendimiento: this.barnizRendimiento,
          pliegosMaximos: this.barnizPliegoMaximo,
          costo: this.barnizCosto,
          aporte: this.barnizAporte,
          fechaCotizacion: this.barnizFechaCotizacion,
        })
        .then((response) => {
          this.hide_dialog();
          this.getBarniz()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putBarniz() {
      this.loadingDialog = true;
      axios
        .put("/Barniz/Actualizar", {
          idArticulo: this.barnizId,
          idUnidad: this.barnizUnidad,
          idBarnizTipo: this.barnizBarnizTipo,
          idBarnizGrupo: this.idBarnizGrupo,
          nombre: this.barnizNombre,
          codigo: this.barnizCodigo,
          rendimiento: this.barnizRendimiento,
          pliegosMaximos: this.barnizPliegoMaximo,
          costo: this.barnizCosto,
          aporte: this.barnizAporte,
          fechaCotizacion: this.barnizFechaCotizacion,
        })
        .then((response) => {
          this.hide_dialog();
          this.getBarniz();
          this.getBarnizTipo();
          //this.alerta(200);
        })
        .catch((error) => {
          //this.alerta(error.response.status);
          console.log(error);
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteBarniz() {
      this.loadingDialogDelete = true;
      axios
        .put("/Barniz/Desactivar/" + this.barnizId)
        .then((response) => {
          this.hide_dialogDelete();
          this.getBarniz();
          this.getBarnizTipo();
          //this.alerta(200);
        })
        .catch((error) => {
          //this.alerta(error.response.status);
          console.log(error);
        })
        .finally(() => {
          this.loadingDialogDelete = false;
        })
    },

    show_dialog(item) {
      this.dialog = true;
      this.optionDialog = item ? "Editar" : "Crear";
      if (item) {
        this.barnizId = item.idArticulo;
        this.barnizUnidad = item.idUnidad;
        this.barnizBarnizTipo = item.idBarnizTipo;
        this.idBarnizGrupo = item.idBarnizGrupo;
        this.barnizNombre = item.nombre;
        this.barnizCodigo = item.codigo;
        this.barnizRendimiento = item.rendimiento;
        this.barnizCosto = item.costo
        this.barnizAporte = item.aporte;
        this.barnizPliegoMaximo = item.pliegosMaximos;
        this.barnizFechaCotizacion = item.fechaCotizacion
      }
      if (this.optionDialog === "Crear") {
        this.barnizFechaCotizacion = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
      }
    },
    hide_dialog() {
      this.$refs.form?.reset();
      this.barnizUnidad = null;
      this.barnizBarnizTipo = null;
      this.idBarnizGrupo = null;
      this.barnizNombre = null;
      this.barnizCodigo = null;
      this.barnizRendimiento = null;
      this.barnizPliegoMaximo = null;
      this.dialog = false;
    },

    show_dialogDelete(item) {
      this.dialogDelete = true;
      if (item) {
        this.barnizId = item.idArticulo;
        this.barnizNombre = item.nombre;
        this.barnizCodigo = item.codigo;
      }
    },
    hide_dialogDelete() {
      this.barnizId = null;
      this.barnizNombre = null;
      this.barnizCodigo = null;
      this.$refs.form?.reset();
      this.dialogDelete = false;
    },

    change_urlGrupoBarniz() {
      this.urlBarnizTipo = this.urlBarnizTipoBase;
      if (this.idBarnizGrupo) {
        this.barnizBarnizTipo = null;
        this.urlBarnizTipo = this.urlBarnizTipo + "?idBarnizGrupo=" + parseInt(this.idBarnizGrupo);
        this.getBarnizTipo()
      }
    },

    change_urlBarniz() {
      this.urlBarniz = this.urlBarnizDefault;

      if (this.search) {
        this.urlBarniz += `buscar=${this.search}`;
      }

      if (this.selecTipoBarniz) {
        this.urlBarniz += this.urlBarniz.endsWith("?") ? "" : "&";
        this.urlBarniz += `idTipoBarniz=${this.selecTipoBarniz}`;
      }

      if (this.searchIdBarnizGrupo) {
        this.urlBarniz += this.urlBarniz.endsWith("?") ? "" : "&";
        this.urlBarniz += `idBarnizGrupo=${this.searchIdBarnizGrupo}`;
      }

      this.getBarniz()
    },
    reset_urlBarniz() {
      this.selecTipoBarniz = null;
      this.searchIdBarnizGrupo = null;
      this.urlBarniz = this.urlBarnizDefault;
      this.getBarniz()
    },

    alerta(code) {
      this.$refs.AlertSnackbar.state_alert(code);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  mounted() {
    this.initialize();
  },
}
</script>