<template>
	<div :class="['orden-trabajo-card', { bloqueada: isBloqueada }]" :style="bloqueoStyle">
		<v-card
		:color="colorCarta"
		:class="{'card-selected': selected}"
		@dragstart="onDragStart"
		@dragend="onDragEnd"
	>
		<v-card-text>
			<v-container fluid>
				<v-row>
					<v-icon v-if="isBloqueada" class="bloqueo-icon" style="color: #FF0000">mdi-lock</v-icon>
					<v-col :cols="computedCols" class="pa-0" >
						<v-checkbox
						class="mb-0 ml-8"
						v-model="operacion.fijo"
						:label="`Operación fija`"
						:disabled="modoEdicion"
						@change="mandarCheckbox"
						></v-checkbox>
						<p 
							class="mb-0 text-body-2 font-weight-medium"
							:class="`${getColorText} ${ selected && 'orange--text text--darken-4' }`"
						>
							{{
								`ODT: ${operacion.claveOT}`
							}}
						</p>
						<v-divider class="mx-0" :class="{ 'grey lighten-1': !esDependencia, 'white': esDependencia }"/>
						<p
							:class="'mb-0 text-body-2 '+ getColorText"
						>
							{{
								`[${operacion.articulo.codigo}] - ${operacion.articulo.nombre}`
							}}
						</p>
						<template v-if="operacion.subpaso.length > 0">
							<p
								v-for="(subpaso, idx) in operacion.subpaso"
								:key="idx"
								:class="'mb-0 text-body-2 '+ getColorText"
							>
								{{
									`• [${subpaso.codigo}] - ${subpaso.descripcion}`
								}}
							</p>
						</template>
						<v-divider class="mx-0" :class="{ 'grey lighten-1': !esDependencia, 'white': esDependencia }"/>
						<p
							:class="'mb-0 text-subtitle-2 font-weight-regular '+ getColorText"
						>
							Duración: {{operacion.duracion}}
						</p>
						<v-btn x-small @click="editArea(operacion.areaCyM)" color="primary">Asignar operador</v-btn>
						<p v-if="operacion.faltaMP"><v-btn
							color="primary"
							x-small
							@click="viewInfo(operacion.listMPFaltante)"
						  >MP incompleta</v-btn></p>
						<p v-else>MP Completa</p>
						<p v-if="operacion.operacionRealEnCurso" style="color:#32CD32 ">
							<v-icon style="color:#32CD32 " >mdi-adjust</v-icon>
							Operación en curso
						</p>
						<p v-else > <v-icon>mdi-octagon</v-icon> Sin iniciar</p>
						<div>
							<ul>
								<li v-for="persona in operacion.operadoresAsignados" :key="persona.idPersona">
								  <span :style="persona.enCurso ?'color:#32CD32' : ''" >{{ persona.nombre }}</span>
								</li>
							  </ul>
						</div>
						<!-- <p
							:class="'mb-0 text-subtitle-2 font-weight-regular '+ getColorText"
						>
							Fin: {{operacion.fechaFin}}
						</p> -->
					</v-col>
					<v-col v-if="operacion.idsDependientes.length > 0" cols="2" class="align-self-center">
						<v-tooltip
							bottom
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									@click="setDependencias"
									:color="getColor"
									:disabled="inhabilitar"

								>mdi-graph-outline</v-icon>
							</template>
							<span class="white--text">
								Mostrar {{ this.operacion.idsDependientes.length }} Operaciones Dependientes
							</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
	<area-control
      v-model="showArea"
      :area="area"
    ></area-control>
	<v-dialog
      v-model="dialogMP"
      persistent
      max-width="590"
    >
      <v-card>
        <v-card-title class="text-h5">
          Materia prima faltante
        </v-card-title>
        <v-card-text>
			<v-data-table
			:headers="headers"
			:items="faltantes"
			:items-per-page="5"
			hide-default-footer
			class="dense-table"
		></v-data-table>
		</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogMP = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
	</div>
</template>

<script>
import Area from '../../Control/Area.vue'
export default {
	components:{
		'area-control':Area,
	},
	props: {
		operacion: {
			type: Object,
			default: () => ({}),
		},
		dependencias: {
			type: Array,
			default: () => ([]),
		},
		modoEdicion:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dialogMP: false,
			checkbox: this.operacion.fijo, 
			seleccionado: false,
			selected: false,
			showArea: false,
			area: {
				area:'',
				idArea:-1,
				operacionesCurso: 0,
			},
			headers: [
				{
					text: 'Articulo',
					align: 'center',
					sortable: false,
					value: 'articulo',
				},
				{
					text: 'Cantidad',
					align: 'center',
					sortable: false,
					value: 'cantidad',
				},
				{
					text: 'Unidad',
					align: 'center',
					sortable: false,
					value: 'unidadAbreviatura',
				}
			],
			faltantes:[]
		};
	},
	computed:{
		computedCols(){
			return this.operacion.idsDependientes.length > 0 ? 10 : 12;
		},
		getColor(){
			return this.seleccionado ? 'red' : 'blue';
		},
		esDependencia(){
			return this.dependencias.includes(this.operacion.idOperacion);
		},
		getColorText(){
			return this.esDependencia ? 'white--text' : 'black--text';
		},
		colorCarta(){
			return this.esDependencia ? '#D50000' : 'white';
		},
		isBloqueada() {
			return this.operacion.fijo || this.operacion.operacionRealEnCurso;
		},
		bloqueoStyle() {
			return this.isBloqueada ? 'cursor: default' : 'cursor: move';
		},
		inhabilitar(){
			if(this.dependencias != null && this.dependencias.length > 0){
				return !this.seleccionado;
			}
			else return false;
		}
	},	
	methods:{
		// mandarCheckbox() {
		// 	this.$emit('bloquearEdicion', this.operacion.fijo, this.operacion);
		// 	console.log("soy la op en el hijo: ", this.operacion);
		// },
		mandarCheckbox() {  //pa mandar y controlar el valor (true o false) del checkbox permitiendo o bloqueando mover las tarjetas
			if (!this.operacion) {
				console.error("Error: 'operacion' no está definido");
				return;
			}
			this.$emit('bloquearEdicion', this.checkbox, this.operacion);
			//console.log("Estado del checkbox en hijo:", this.checkbox, "Operación:", this.operacion);
		},

		onDragEnd(){
			setTimeout(() => {
				this.selected = false;
			}, 100);
		},
		onDragStart(){
			this.selected = true;
		},
		setDependencias(){
			/* Este código es una declaración condicional que revisa el valor de
			`this.seleccionado` y, dependiendo de esto, emite un evento 'change'. */
			if(!this.seleccionado) this.$emit('change',this.operacion.idsDependientes);
			else this.$emit('change',[]);
			this.seleccionado = !this.seleccionado;
		},
		editArea(area){
			this.area = area;
			this.showArea = true;
		},
		viewInfo(value){
			/*
			Este fragmento de código utiliza el método flatMap para iterar sobre un arreglo 
			de objetos llamado value. Por cada item en el arreglo value, se realiza una iteración
			adicional sobre el arreglo detalles dentro de ese item. Posteriormente, para cada
			detalle dentro del arreglo detalles, se lleva a cabo otra iteración sobre el arreglo
			faltantesDetalles contenido en ese detalle.
			*/
			this.dialogMP = true;
			 this.faltantes = value.flatMap(item => item.detalles.flatMap(detalle =>
			 detalle.faltantesDetalles.map(faltante => ({
				articulo: faltante.articulo,
				cantidad: faltante.cantidad,
				unidadAbreviatura: faltante.unidadAbreviatura
			}))
		)
	);
		}
	}
};
</script>

<style scoped>
.card-selected {
	/* border: 1px solid #FF6D00 !important; */
	box-shadow: 0 1px 10px 1px #FF6D00 !important;
}
.bloqueo-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 24px;
}
.orden-trabajo-card.bloqueada {
  opacity: 0.7;
}

.small-checkbox {
	font-size: 12px; /* Tamaño del texto */
	height: 24px; /* Altura del checkbox */
  }
  .small-checkbox .v-input__control {
	min-height: 24px; /* Ajustar el espacio interno */
  }
  .small-checkbox .v-label {
	font-size: 12px; /* Ajustar el tamaño del texto de la etiqueta */
  }

  .dense-table .v-data-table__wrapper {
	font-size: 12px; /* Reduce font size */
  }
  
  .dense-table .v-data-table__row {
	height: 32px; /* Reduce row height */
  }
  
  .dense-table .v-data-table__cell {
	padding: 4px 8px; /* Reduce padding inside cells */
  }
</style>