<template>
  <div>
    <v-btn color="primary" @click="show_dialog()">Crear nuevo Grupo de Barniz</v-btn>
    <v-data-table :headers="headers" :search="search" :items="dataGrupoBarniz" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex justify-center">
          <v-text-field v-model="search" v-bind="propsSearch" class="mr-3" />
        </div>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="show_dialog(item)">mdi-eye</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialogDelete(item)">mdi-trash-can</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>{{ optionDialog }} Grupo de Barniz</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="grupoBarnizCodigo" label="Código" v-bind="propsText" />
            <v-text-field v-model="grupoBarnizNombre" label="Nombre" v-bind="propsText" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text @click="hide_dialog()">Cancelar</v-btn>
          <v-btn :loading="loadingDialog" color="error" text @click="validate()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500">
      <v-card>
        <v-card-title>Eliminar Grupo de Barniz</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "[{{ grupoBarnizCodigo }}] - {{ grupoBarnizNombre
        }}"?</v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text
            @click="hide_dialogDelete()">Cancelar</v-btn>
          <v-btn :loading="loadingDialog" color="error" text @click="deleteGrupoBarniz()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>
  
<script>
import axios from 'axios'
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {
    AlertSnackbar,
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: true,
      },

      propsText: {
        outlined: false,
        dense: true,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },
      propsAutocomplete: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },

      dialog: false,
      dialogDelete: false,
      optionDialog: undefined,

      search: "",

      headers: [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false,
        }, {
          text: "Nombre",
          value: "nombre",
          align: "center",
          sortable: false,
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        }
      ],

      grupoBarnizId: null,
      grupoBarnizCodigo: null,
      grupoBarnizNombre: null,

      dataGrupoBarniz: [],

      loadingTable: false,
      loadingDialog: false,
    }
  },
  methods: {
    initialize() {
      this.getBarnizGrupo()
    },

    validate() {
      if (this.$refs.form.validate()) this.optionDialog == 'Crear' ? this.postGrupoBarniz() : this.putGrupoBarniz()
    },

    getBarnizGrupo() {
      this.loadingTable = true;
      axios
        .get("/BarnizGrupo/Listar")
        .then((response) => {
          this.dataGrupoBarniz = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    postGrupoBarniz() {
      this.loadingDialog = true;
      axios
        .post("/BarnizGrupo/Crear", {
          codigo: this.grupoBarnizCodigo,
          nombre: this.grupoBarnizNombre,
        })
        .then((response) => {
          this.alerta(200);
          this.hide_dialog();
          this.getBarnizGrupo();
        })
        .catch((error) => {
          this.alerta(error.response.status);
          console.log(error);
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putGrupoBarniz() {
      this.loadingDialog = true;
      axios
        .put("/BarnizGrupo/Actualizar", {
          idBarnizGrupo: this.grupoBarnizId,
          codigo: this.grupoBarnizCodigo,
          nombre: this.grupoBarnizNombre,
        })
        .then((response) => {
          this.alerta(200);
          this.hide_dialog();
          this.getBarnizGrupo();
        })
        .catch((error) => {
          this.alerta(error.response.status);
          console.log(error);
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteGrupoBarniz() {
      this.dialogDelete = true;
      axios
        .put("/BarnizGrupo/Desactivar/" + this.grupoBarnizId)
        .then((response) => {
          this.alerta(200);
          this.hide_dialogDelete();
          this.getBarnizGrupo();
        })
        .catch((error) => {
          this.alerta(error.response.status);
          console.log(error);
        })
        .finally(() => {
          this.dialogDelete = false;
        })
    },

    show_dialog(item) {
      this.dialog = true;
      this.optionDialog = item ? "Editar" : "Crear";
      if (item) {
        this.grupoBarnizId = item.idBarnizGrupo;
        this.grupoBarnizCodigo = item.codigo;
        this.grupoBarnizNombre = item.nombre;
      }
    },
    hide_dialog() {
      this.$refs.form?.reset();
      this.grupoBarnizId = null;
      this.grupoBarnizCodigo = null;
      this.grupoBarnizNombre = null;
      this.dialog = false;
    },

    show_dialogDelete(item) {
      this.dialogDelete = true;
      if (item) {
        this.grupoBarnizId = item.idBarnizGrupo;
        this.grupoBarnizCodigo = item.codigo;
        this.grupoBarnizNombre = item.nombre;
      }
    },
    hide_dialogDelete() {
      this.grupoBarnizId = null;
      this.grupoBarnizCodigo = null;
      this.grupoBarnizNombre = null;
      this.dialogDelete = false;
    },
    alerta(code) {
      this.$refs.AlertSnackbar.state_alert(code);
    },
  },
  mounted() {
    this.initialize();
  },
}
</script>