<template>
  <div  v-if="permisoVista('insumos','r')">
    <v-col cols="6">
      <v-btn  v-if="permisoVista('insumos','c')" color="primary" @click="show_dialog()">Nueva Tinta</v-btn>
    </v-col>

    <v-data-table :headers="headers" :items="dataTintas" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex align-center">
          <v-text-field v-model="search" @keyup.enter="change_urlTintas()" @click:append="change_urlTintas()"
            @click:clear="change_urlTintas(false)" v-bind="propsSearch"  dense="true"/>
          <v-autocomplete v-model="selectExtras" :items="tintaExtras" :item-text="nombreExtras" item-value="id"
            label="Extras" @change="change_urlTintas()" v-bind="propsSelect" :multiple="true" class="col-3"/>
        </div>
      </template>
      <template v-slot:[`item.extras`]="{ item }">
        <ul>
          <li v-for="extra in item.extras" style="text-align: center; list-style-position: inside;">{{ extra }}
          </li>
        </ul>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip  v-if="permisoVista('insumos','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialog(item)">mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip  v-if="permisoVista('insumos','d')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1"
              @click="show_dialogDelete(item)">mdi-trash-can-outline</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small @click="show_dialogProveedor(item)">mdi-storefront-outline</v-icon>
          </template>
          <span class="white--text">Proveedores</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>{{ optionDialog }} Tinta</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="tintaCodigo" label="Código" v-bind="propsText" />
            <v-text-field v-model="tintaNombre" label="Nombre" v-bind="propsText" />
            <v-autocomplete :loading="loadingTipoTintas" v-model="tintaTipo" :items="dataTipoTintas"
              item-value="idTintaTipo" :item-text="nombreTipoTinta" label="Tipo de Tinta" v-bind="propsAutocomplete" />
            <v-row>
              <v-col class="col-5"><v-checkbox v-model="tintaAlimenticio" label="Grado Alimenticio" />
              </v-col>
              <v-col class="col-5"><v-checkbox v-model="tintaImpermeable" label="Impermeable" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingDialog" color="blue darken-1" class="ml-auto" text
            @click="hide_dialog(false)">Cancelar</v-btn>
          <v-btn :loading="loadingDialog" color="error" text @click="validate()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
      <v-card>
        <v-card-title>Eliminar Tinta</v-card-title>
        <v-card-text class="text--primary">¿Desea Eliminar "[{{ tintaCodigo }}] - {{ tintaNombre }}"?</v-card-text>
        <v-card-subtitle>Esta acción es permanente y no se puede deshacer</v-card-subtitle>
        <v-card-actions>
          <v-btn :loading="loadingDialogDelet" color="blue darken-1" class="ml-auto" @click="hide_dialogDelete()"
            text>Cancelar</v-btn>
          <v-btn :loading="loadingDialogDelet" color="error" @click="deleteTintas()" text>Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogProveedor" persistent max-width="1000px">
      <v-card>
        <v-card-title>Proveedores de Tinta</v-card-title>
        <v-card-subtitle>{{ tintaNombre }}</v-card-subtitle>
        <v-card-text>
          <v-form ref="formProveedores">
            <v-data-table :headers="headersProveedores" :items="dataProveedoresTinta" :items-per-page="-1" hide-footer
              hide-default-footer :loading="loadingTableProveedores">
              <template v-slot:[`item.nombreProveedor`]="{ item }">
                <v-autocomplete v-model="item.idProveedor" :items="dataProveedores" :item-text="nombreProveedorAC"
                  item-value="idProveedor" label="Proveedor" v-bind="propsAutocomplete" />
              </template>
              <template v-slot:[`item.extras`]="{ item }">
                <v-checkbox v-model="item.gradoAlimenticio" label="Grado Alimenticio" />
                <v-checkbox v-model="item.impermeable" label="Impermeable" style="margin-top: -10px;" />
              </template>
              <template v-slot:[`item.precio`]="{ item }">
                <v-text-field v-model="item.precio" label="Costo por Litro" v-bind="propsDecimales1" prefix="$" />
              </template>
              <template v-slot:[`item.tiempoEntrega`]="{ item }">
                <v-text-field v-model="item.tiempoEntrega" label="Días para entrega" v-bind="propsDecimales1" />
              </template>
              <template v-slot:[`item.fechaCotizacion`]="{ item }">
                <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="item.fechaCotizacion" label="Fecha Cotización" prepend-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on" />
                  </template>
                  <v-date-picker color="primary" dark v-model="item.fechaCotizacion"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                    @change="save()" />
                </v-menu>
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-icon @click="removeProveedor(item)">mdi-trash-can-outline</v-icon>
              </template>
            </v-data-table>
          </v-form>
          <v-btn color="primary" style="margin: 15px 0 15px 15px;" @click="addProveedor">Agregar Proveedor</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingTableProveedores" color="blue darken-1" class="ml-auto" text
            @click="hide_dialogProveedor()">Cancelar</v-btn>
          <v-btn :loading="loadingTableProveedores" color="error" text @click="putProveedoresTinta()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>

<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import axios from 'axios'
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {
    VueDatePicker,
    AlertSnackbar
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: false,
      },
      propsSelect: {
        multiple: false,
        chips: false,
        dense: false,
        clearable: true,
        'clear-icon': "mdi-close-circle",
      },
      propsText: {
        outlined: false,
        dense: false,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },
      propsAutocomplete: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      propsDecimales1: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) < 1) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },

      dialog: false,
      dialogDelete: false,
      optionDialog: null,
      dialogProveedor: false,

      tintaId: null,
      tintaCodigo: null,
      tintaNombre: null,
      tintaUnidad: null,
      tintaTipo: null,
      tintaAlimenticio: false,
      tintaImpermeable: false,

      search: '',
      seleccion: [],
      selectExtras: [],
      selectTipoTinta: null,

      headers: [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false
        }, {
          text: "Tinta",
          value: "nombre",
          align: "center",
          sortable: false
        }, {
          text: "Tipo",
          value: "tipoTinta",
          align: "center",
          sortable: false
        }, {
          text: "Extras",
          value: "extras",
          align: "center",
          sortable: false
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false
        },
      ],
      headersProveedores: [
        {
          text: "Proveedor",
          value: "nombreProveedor",
          align: "center",
          sortable: false,
        }, {
          text: "Extras",
          value: "extras",
          align: "center",
          sortable: false,
        }, {
          text: "Costo por Litro",
          value: "precio",
          align: "center",
          sortable: false,
        }, {
          text: "Tiempo de Entrega",
          value: "tiempoEntrega",
          align: "center",
          sortable: false
        }, {
          text: "Fecha Cotización",
          value: "fechaCotizacion",
          align: "center",
          sortable: false
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        },
      ],

      dataTintas: [],
      dataTipoTintas: [],
      dataUnidades: [],
      dataProveedores: [],
      dataProveedoresTinta: [],
      tintaExtras: [
        {
          id: 1,
          value: 'Grado Alimenticio',
        }, {
          id: 2,
          value: 'Impermeable',
        }
      ],

      loadingTable: false,
      loadingDialog: false,
      loadingUnidades: false,
      loadingTipoTintas: false,
      loadingDialogDelet: false,
      loadingTableProveedores: false,

      urlTintas: "/tintas/listar?",
      urlTintasDefault: "/tintas/listar?",

      activePicker: null,
    }
  },
  methods: {
    nombreExtras: (item) => `${item.value}`,
    nombreUnidad: (lista) => `[${lista.abreviatura}] - ${lista.nombre}`,
    nombreTipoTinta: (lista) => `[${lista.codigo}] - ${lista.nombre}`,
    nombreProveedorAC: (item) => `[${item.codigo}] - ${item.nombre}`,

    initialize() {
      this.getTintas()
      this.getTipoTintas()
    },

    validate() {
      if (this.$refs.form.validate()) this.optionDialog == 'Crear' ? this.postTintas() : this.putTintas()
    },

    getTintas() {
      this.loadingTable = true;
      axios
        .get(this.urlTintas)
        .then((response) => {
          this.dataTintas = response.data
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loadingTable = false;
        })
    },
    getUnidades() {
      this.loadingUnidades = true;
      axios
        .get("/unidades")
        .then((response) => {
          this.dataUnidades = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingUnidades = false;
        })
    },
    getTipoTintas() {
      this.loadingTipoTintas = true;
      axios
        .get("/tintatipos/listar")
        .then((response) => {
          this.dataTipoTintas = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTipoTintas = false;
        })
    },
    getProveedores() {
      axios
        .get("/Proveedores")
        .then((response) => {
          this.dataProveedores = response.data
        })
        .catch((error) => { console.log(error) })
    },
    getProveedoresTinta() {
      this.loadingTableProveedores = true;
      axios
        .get("/TintaProveedores/Listar/" + this.tintaId)
        .then((response) => {
          this.dataProveedoresTinta = response.data;
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTableProveedores = false;
        })
    },
    postTintas() {
      this.loadingDialog = true;
      axios
        .post("/tintas/crear",
          {
            idUnidad: 3,
            idTintaTipo: this.tintaTipo,
            nombre: this.tintaNombre,
            codigo: this.tintaCodigo,
            gradoAlimenticio: this.tintaAlimenticio,
            impermeable: this.tintaImpermeable
          })
        .then((response) => {
          this.hide_dialog()
          this.getTintas()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putProveedoresTinta() {
      if (this.$refs.formProveedores.validate()) {
        this.loadingTableProveedores = true;
        axios
          .put("/TintaProveedores/CrearActualizarDesactivar/" + this.tintaId, this.jsonProveedoresTinta)
          .then((response) => {
            this.hide_dialogProveedor();
            this.getTintas();
            //this.alerta(200)
          })
          .catch((error) => {
            //this.alerta(error.response.status)
            console.log(error)
          })
          .finally(() => {
            this.loadingTableProveedores = false;
          })
      }
    },
    putTintas() {
      this.loadingDialog = true;
      axios
        .put("/tintas/actualizar", {
          idArticulo: this.tintaId,
          idUnidad: 3,
          idTintaTipo: this.tintaTipo,
          codigo: this.tintaCodigo,
          nombre: this.tintaNombre,
          gradoAlimenticio: this.tintaAlimenticio,
          impermeable: this.tintaImpermeable
        })
        .then((response) => {
          this.hide_dialog()
          this.getTintas()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteTintas() {
      this.loadingDialogDelet = true;
      axios
        .put("/tintas/desactivar/" + this.tintaId)
        .then((response) => {
          this.hide_dialogDelete()
          this.getTintas()
          //this.alerta(200)
        })
        .catch((error) => {
          //this.alerta(error.response.status)
          console.log(error)
        })
        .finally(() => {
          this.loadingDialogDelet = false;
        })
    },

    show_dialogProveedor(item) {
      this.dialogProveedor = true;
      this.tintaId = item.idArticulo;
      this.tintaNombre = item.nombre;
      this.getProveedores();
      this.getProveedoresTinta();
    },
    hide_dialogProveedor() {
      this.idArticulo = null;
      this.dataProveedoresTinta = JSON.parse(JSON.stringify([]));
      this.dialogProveedor = false
    },

    addProveedor() {
      this.dataProveedoresTinta.push({ idProveedor: null, nombreProveedor: null, gradoAlimenticio: false, impermeable: false, precio: null, tiempoEntrega: null, fechaCotizacion: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10), });
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    removeProveedor(item) {
      var idx = this.dataProveedoresTinta.indexOf(item);
      if (idx < 0) return;
      this.dataProveedoresTinta.splice(idx, 1);
    },

    show_dialog(item) {
      this.dialog = true;
      this.optionDialog = item ? "Editar" : "Crear"
      if (item) {
        this.tintaId = item.idArticulo
        this.tintaCodigo = item.codigo
        this.tintaNombre = item.nombre
        this.tintaUnidad = item.idUnidad
        this.tintaTipo = item.idTintaTipo
        this.tintaAlimenticio = item.gradoAlimenticio
        this.tintaImpermeable = item.impermeable
      }
      this.getTipoTintas()
      this.getUnidades()
    },
    hide_dialog() {
      this.$refs.form?.reset();
      this.tintaAlimenticio = null;
      this.tintaImpermeable = null;
      this.tintaId = null
      this.dialog = false
    },

    show_dialogDelete(item) {
      this.dialogDelete = true
      if (item) {
        this.tintaNombre = item.nombre
        this.tintaCodigo = item.codigo
        this.tintaId = item.idArticulo
      }
    },
    hide_dialogDelete() {
      this.tintaNombre = null;
      this.tintaId = null;
      this.tintaCodigo = null;
      this.$refs.form.reset();
      this.dialogDelete = false
    },

    change_urlTintas(searchValue = true) {
      this.urlTintas = this.urlTintasDefault;

      if (searchValue && this.search) {
        this.urlTintas += `buscar=${this.search}`;
      }

      if (this.selectExtras.length > 0) {
        this.urlTintas += this.urlTintas.endsWith("?") ? "" : "&";
        this.urlTintas += this.selectExtras.map(item => 'idsExtras=' + item).join('&')
      }

      if (this.selectTipoTinta) {
        this.urlTintas += this.urlTintas.endsWith("?") ? `idTipoTinta=${this.selectTipoTinta}` : `&idTipoTinta=${this.selectTipoTinta}`
      }

      this.getTintas()
    },

    alerta(code, color, mensaje, icono) {
      if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
      } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
      }
    },
  },
  computed: {
    jsonProveedoresTinta() {
      const dataProveedoresTinta = this.dataProveedoresTinta.map((item) => {
        return {
          idProveedor: item.idProveedor,
          impermeable: item.impermeable,
          gradoAlimenticio: item.gradoAlimenticio,
          costo: item.precio,
          tiempoEntrega: item.tiempoEntrega,
          fechaCotizacion: item.fechaCotizacion
        }
      })
      return dataProveedoresTinta
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  mounted() {
    this.initialize()
  }
}
</script>