import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"4","xl":"4","md":"4","lg":"4","xs":"12","sm":"12"}},[_c(VAutocomplete,{attrs:{"label":"Cliente","items":_vm.dataClientes,"item-text":_vm.nombreCliente,"item-value":"idCliente","clearable":""}})],1),_c(VCol,{attrs:{"cols":"4","xl":"4","md":"4","lg":"4","xs":"12","sm":"12"}},[_c(VAutocomplete,{attrs:{"label":"Tipo","item-text":"clave","item-value":"idTrabajoOrden","clearable":""}})],1),_c(VCol,{attrs:{"cols":"4","xl":"4","md":"4","lg":"4","xs":"12","sm":"12"}},[_c(VAutocomplete,{attrs:{"label":"Moneda","item-text":_vm.nombrePersona,"item-value":"idPersona","clearable":""}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headersDetalles,"items":_vm.dataPreOrdenes,"search":_vm.search,"loading":_vm.loadingTable},scopedSlots:_vm._u([{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(_vm.formatearCifra(item.monto))+" ")]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){item.ordenesMX <= 0 ? _vm.abrirConfirmacion(item) : null}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.ordenesMX <= 0 ? 'mdi-receipt-text-check' : 'mdi-check-circle')+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(item.ordenesMX <= 0 ? 'Crear Orden' : 'Orden Creada')+" ")])]),(item.ordenesMX > 0)?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.generarPDF(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-pdf-box ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(" Generar PDF ")])]):_vm._e()]}}],null,true)})],1)],1),_c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.confirmacion),callback:function ($$v) {_vm.confirmacion=$$v},expression:"confirmacion"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("¿Desea mandar esta Orden a Producción?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.cerrarConfirmacion()}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.postReceta()}}},[_vm._v("Aceptar")])],1)],1)],1),_c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.successDialog),callback:function ($$v) {_vm.successDialog=$$v},expression:"successDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Se ha procesado correctamente a ordenes")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":"","enabled":_vm.timer > 0},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Aceptar ("+_vm._s(_vm.timer)+")")])],1)],1)],1),_c('Cotizacion',{ref:"dialogCotizacion",attrs:{"idPreCotizacion":_vm.PCotizacionId},on:{"succes_cotizacion":function($event){return _vm.succesCotizacion()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }