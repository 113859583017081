<template>
	<div style="cursor: pointer;" @click="close_alert(false)">
		<v-snackbar v-model="alert.state" right top :color="alert.colorSB" :timeout="alert.timeout">
			<div style="display: inline-flex; align-items: center;">
				<v-icon class="mr-2"> {{ alert.icon }} </v-icon>
				<p style="font-weight: bold; height: 0;"> {{ alert.message }} </p>
			</div>
		</v-snackbar>
	</div>
</template>

<script>

export default {
	data() {
		return {
			alert: {
				state: false,
				timeout: 2000,
				colorSB: null,
				message: null,
				icon: null
			},
			alertOptions: {
				422: {
					colorSB: "warning",
					message: "Faltan datos obligatorios.",
					icon: "mdi-alert-circle-outline",
				},
				200: {
					colorSB: "success",
					message: "La operación se completó con éxito.",
					icon: "mdi-check-circle-outline",
				},
				201: {
					colorSB: "success",
					message: "La operación se completó con éxito.",
					icon: "mdi-check-circle-outline",
				},
				400: {
					colorSB: "warning",
					message: "Hubo un problema con los datos proporcionados.",
					icon: "mdi-alert-circle-outline",
				},
				401: {
					colorSB: "error",
					message: "No tienes permiso para realizar esta acción.",
					icon: "mdi-alert-octagon-outline",
				},
				404: {
					colorSB: "error",
					message: "El recurso que estás buscando no se ha encontrado.",
					icon: "mdi-alert-circle-outline",
				},
				405: {
					colorSB: "error",
					message: "El método de solicitud utilizado no está permitido.",
					icon: "mdi-alert-circle-outline",
				},
				409: {
					colorSB: "warning",
					message: "El recurso que intentas crear ya existe.",
					icon: "mdi-alert-circle-outline",
				},
				500: {
					colorSB: "error",
					message: "Hubo un error en el servidor.",
					icon: "mdi-alert-circle-outline",
				},
			},
		}
	},

	methods: {
		state_alert(code, color, mensaje, icono) {
			if (code) {
				let { colorSB, message, icon } = this.alertOptions.hasOwnProperty(code)
					? this.alertOptions[code]
					: {
						colorSB: "error",
						message: `Ocurrió un error (${code}).`,
						icon: "mdi-information-outline",
					};

				this.alert = {
					...this.alert,
					colorSB: colorSB || this.alert.colorSB,
					message: message || this.alert.message,
					icon: icon || this.alert.icon,
				};
			} else {
				this.alert.colorSB = color;
				this.alert.message = mensaje;
				this.alert.icon = icono;
			}
			this.alert.state = true;
		},
		close_alert() {
			this.alert.state = false;
		}
	}
}
</script>