<template>
  <v-dialog v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar color="primary">
        <v-btn icon @click="hide_dialog()"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title class="white--text">Nueva Pre-Cotización - {{ tipoPreCotizacionText }}</v-toolbar-title>
        <v-spacer />
        <v-btn :loading="loading" text @click="validate()">Guardar</v-btn>
      </v-toolbar>

      <v-form ref="form" lazy-validation>
        <v-list-item style="width: 98%; margin: auto">
          <v-list-item-content>
            <v-list-item-title class="my-title">Datos generales:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-row>
                <v-col>
                  <v-autocomplete v-model="dataPostAPI.idCliente" :items="dataClientes" :item-text="nombreCliente"
                    item-value="idCliente" label="Cliente" v-bind="propsAutocomplete" :loading="loadingClientes" />
                </v-col>

                <v-col cols="6">
                  <v-text-field v-model="dataPostAPI.nombreTrabajo" label="Nombre del Trabajo" v-bind="inputTextProps" />
                </v-col>

                <v-col cols="6">
                  <v-row class="d-flex align-center justify-center">
                    <v-col class="col-3"> Especialidad del papel:</v-col>
                    <v-col cols="9">
                      <v-row>
                        <v-checkbox v-model="dataPostAPI.PapelAlimenticio" label="Grado Alimenticio" class="mr-10" />
                        <v-checkbox v-model="dataPostAPI.PapelResistenteHumedad" label="Resistente a la humedad" />
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete v-model="dataPostAPI.Papel" :items="dataPapeles" :item-text="nombrePapel"
                    item-value="idArticulo" label="Tipo de Papel" v-bind="propsAutocomplete" :loading="loadingPapel" />
                </v-col>

                <v-col cols="3">
                  <v-text-field v-model="dataPostAPI.LargoPieza" label="Largo Pieza (mts)" v-bind="inputNumberProps"
                    suffix="Mts" />
                </v-col>

                <v-col cols="3">
                  <v-text-field v-model="dataPostAPI.AnchoPieza" label="Ancho Pieza (mts)" v-bind="inputNumberProps"
                    suffix="Mts" />
                </v-col>

                <v-col cols="3">
                  <v-text-field v-model="dataPostAPI.CantidadPiezas" label="Cantidad piezas" v-bind="inputNumberProps"
                    suffix="Pza" />
                </v-col>

                <!--<v-col cols="3">
                  <v-autocomplete v-model="dataPostAPI.idUnidad" :items="dataUnidades" :item-text="nombreUnidad"
                    item-value="idUnidad" label="Unidad" v-bind="propsAutocomplete" :loading="loadingUnidades" />
                </v-col>-->

                <v-col cols="3">
                  <v-autocomplete v-model="dataPostAPI.idFrecuencia" :items="dataFrecuencia"
                    :item-text="nombreFrecuencia" item-value="idFrecuencia" label="Frecuencia"
                    v-bind="propsAutocomplete" :loading="loadingFrecuancia" />
                </v-col>
              </v-row>
            </v-card>

            <v-list-item-title class="my-title">Impresión:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-row class="d-flex align-center">
                <v-col cols="2"> Especialidad de Tintas: </v-col>
                <v-row style="margin: 10px 0 10px 0">
                  <v-checkbox v-model="dataPostAPI.ImpresionAlimenticio" label="Grado Alimenticio"
                    @change="reiniciarTabla" />
                  <v-checkbox v-model="dataPostAPI.ImpresionImperneable" label="Impermeable" class="ml-10"
                    @change="reiniciarTabla" />
                </v-row>
              </v-row>
              <v-row>
                <v-col class="col-12">
                  <v-data-table :headers="headersImpresiones" :items="dataImpresiones" :items-per-page="-1" hide-footer
                    hide-default-footer>
                    <template v-slot:[`item.cara`]="{ item }">
                      <v-select v-model="item.cara" :items="dataCaras" :item-text="nombreDefault" item-value="id"
                        label="Cara" v-bind="selectProps" />
                    </template>
                    <template v-slot:[`item.tipoTinta`]="{ item }">
                      <v-select v-model="item.idTipoTinta" :items="dataTipoTintas" :item-text="nombreDefault2"
                        item-value="idTintaTipo" label="Tipo de Tinta" @change="urlTintasListar(item)"
                        v-bind="selectProps">
                      </v-select>
                    </template>
                    <template v-slot:[`item.nombre`]="{ item }">
                      <v-autocomplete v-model="item.idTinta" :items="item.tinta" :item-text="nombreDefault2"
                        item-value="idArticulo" label="Tinta" :loading="item.loadTintas" :disabled="item.loadTintas"
                        :key="chg" v-bind="selectProps">
                      </v-autocomplete>
                    </template>
                    <template v-slot:[`item.percentTinta`]="{ item }">
                      <v-text-field v-model="item.percentTinta" label="Porcentaje de Tinta" type="number"
                        v-bind="inputPercentTintaProps" suffix="%" :min="0" :max="150" />
                    </template>
                    <template v-slot:[`item.acciones`]="{ item }">
                      <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on="on" v-bind="attrs" @click="eliminarTinta(item)">mdi-trash-can</v-icon>
                        </template>
                        <span class="white--text">Eliminar</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                  <v-btn color="primary" @click="agregarTinta">Nueva Tinta</v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-list-item-title class="my-title"> Acabados: </v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-row>
                  <v-card style="width: 100%;" flat>
                    <v-row>
                      <v-col class="col-6">
                        <v-checkbox v-model="dataPostAPI.Barniz" label="Barniz Base Agua" />
                        <v-checkbox v-model="dataPostAPI.UVPlasta" label="Barniz UV Plasta" class="my-n3" />
                        <v-checkbox v-model="dataPostAPI.UVRegistro" label="Barniz UV Registro" class="my-n3" />
                        <v-checkbox v-model="dataPostAPI.Plastificado" label="Plastificado" class="my-n3" />
                        <v-checkbox v-model="dataPostAPI.Suajado" label="Suajado" class="my-n3" />
                      </v-col>
                      <v-col class="col-6" v-if="tipoPreCotizacionId < 2">
                        <v-checkbox v-model="dataPostAPI.Empalme" label="Empalme" />
                        <v-checkbox v-model="dataPostAPI.Pegado" label="Pegado Lineal" class="my-n3" />
                        <v-checkbox v-model="dataPostAPI.PegadoGolpe" label="Pegado de golpe" class="my-n3" />
                        <v-checkbox v-model="dataPostAPI.Troquel" label="Troquel" class="my-n3" />
                      </v-col>
                    </v-row>
                  </v-card>

                  <hr color="#C9CFD1" style="width: 97%; margin: 10px auto 10px auto;">

                  <v-col v-if="dataPostAPI.Barniz" cols="12">
                    <h5 style="margin-bottom: 10px">Barniz Base Agua</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-2">Frente</h5>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.BarnizFrente" :items="dataBarnizTipo"
                              item-value="idBarnizTipo" :item-text="nombreBarnizTipo" label="Tipo de Barniz"
                              v-bind="propsAutocomplete" @change="cambiarBarnizFrente(dataPostAPI.BarnizFrente)" />
                          </v-col>
                          <v-col cols="5" v-if="dataPostAPI.BarnizFrente && dataPostAPI.BarnizFrente >= 0">
                            <v-autocomplete v-model="dataPostAPI.BarnizFrente2" :items="dataBarnizF"
                              item-value="idArticulo" :item-text="nombreDefault2" label="Barniz"
                              v-bind="propsAutocomplete" />
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-2">Vuelta</h5>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.BarnizVuelta" :items="dataBarnizTipo"
                              item-value="idBarnizTipo" :item-text="nombreBarnizTipo" label="Tipo de Barniz"
                              v-bind="propsAutocomplete" @change="cambiarBarnizVuelta(dataPostAPI.BarnizVuelta)" />
                          </v-col>
                          <v-col cols="5" v-if="dataPostAPI.BarnizVuelta && dataPostAPI.BarnizVuelta >= 0">
                            <v-autocomplete v-model="dataPostAPI.BarnizVuelta2" :items="dataBarnizV"
                              item-value="idArticulo" :item-text="nombreDefault2" label="Barniz"
                              v-bind="propsAutocomplete" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="dataPostAPI.UVPlasta" cols="12">
                    <h5 style="margin-bottom: 10px">Barniz UV Plasta</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-2">Frente</h5>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.UVPlastaFrente" :items="dataBarnizUVPlasta"
                              item-value="idArticulo" :item-text="nombreBarnices" label="UV Plasta"
                              v-bind="propsAutocomplete" />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-2">Vuelta</h5>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.UVPlastaVuelta" :items="dataBarnizUVPlasta"
                              item-value="idArticulo" :item-text="nombreBarnices" label="UV Plasta"
                              v-bind="propsAutocomplete" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="dataPostAPI.UVRegistro" cols="12">
                    <h5 style="margin-bottom: 10px">Barniz UV Registro</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-2">Frenta</h5>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.UVRegistroFrente" :items="dataBarnizUVRegistro"
                              item-value="idArticulo" :item-text="nombreBarnices" label="UV Registro"
                              v-bind="propsAutocomplete" />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-2">Vuelta</h5>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.UVRegistroVuelta" :items="dataBarnizUVRegistro"
                              item-value="idArticulo" :item-text="nombreBarnices" label="UV Registro"
                              v-bind="propsAutocomplete" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="dataPostAPI.Plastificado" cols="12">
                    <h5 style="margin-bottom: 10px">Plastificado</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-2">Frente</h5>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.PlastificadoFrente" :items="dataPlastificados"
                              item-value="id" :item-text="nombrePlastificado" label="Tipo de Plastificado"
                              v-bind="propsAutocomplete" />
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-2">Vuelta</h5>
                          <v-col cols="5">
                            <v-autocomplete v-model="dataPostAPI.PlastificadoVuelta" :items="dataPlastificados"
                              item-value="id" :item-text="nombrePlastificado" label="Tipo de Plastificado"
                              v-bind="propsAutocomplete" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="dataPostAPI.Pegado" cols="12">
                    <h5 style="margin-bottom: 10px">Pegado Lineal</h5>
                    <v-row>
                      <v-col cols="4">
                        <v-autocomplete v-model="dataPostAPI.PegadoTipo" :items="dataPegado" item-value="id"
                          :item-text="nombreFondo" label="Tipo de fondo" v-bind="propsAutocomplete" />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field v-model="dataPostAPI.CMSPegadoLineal" label="Cms Pegado Lineal"
                          v-bind="inputNumberProps" suffix="Cms" />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="dataPostAPI.PegadoGolpe" cols="12">
                    <h5 style="margin-bottom: 10px">Pegado de Golpe</h5>
                    <v-row>
                      <v-col cols="4">
                        <v-text-field v-model="dataPostAPI.PegadoGolpeEsquinas" v-bind="inputNumberIntProps"
                          label="# de Esquinas" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title" v-if="tipoPreCotizacionId == 2"> Fondo para vaso: </v-list-item-title>
            <v-card v-bind="cardStyle" v-if="tipoPreCotizacionId == 2">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="dataPostAPI.diametroVaso" label="Diametro" suffix="Mts" v-bind="propsDecimales"/>
                </v-col>
              </v-row>
            </v-card>

            <v-list-item-title class="my-title"> Extras: </v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-data-table :headers="headersConceptos" :items="dataConceptos" :items-per-page="-1" hide-footer
                  hide-default-footer>
                  <template v-slot:[`item.concepto`]="{ item }">
                    <v-textarea v-model="item.concepto" label="Concepto" v-bind="inputTextTableProps" rows="1"
                      :auto-grow="true" />
                  </template>
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" small class="mr-2"
                          @click="eliminarConcepto(item)">mdi-trash-can</v-icon>
                      </template>
                      <span class="white--text">Eliminar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>

                <v-btn color="primary" style="margin-top: 20px" @click="agregarConcepto">
                  Agregar Concepto
                </v-btn>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Transporte:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          <b> Requerimientos de Transporte </b>
                        </th>
                        <th class="text-center">
                          <b> Aplica: </b>
                        </th>
                        <th class="text-center">
                          <b> Monto </b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in dataTransporte" :key="item.pregunta">
                        <td class="text-center">{{ item.pregunta }}</td>
                        <td>
                          <v-radio-group v-model="item.respuesta"
                            @change="cambiarCondicionTransporte(item.idTransportePregunta)">
                            <v-radio label="No" color="red" :value="false" />
                            <v-radio label="Si" color="red" :value="true" />
                          </v-radio-group>
                        </td>
                        <td>
                          <v-text-field v-if="item.respuesta" v-model="item.monto" v-bind="inputNumberProps1" prefix="$"
                            @input="cambiarMontoTransporte" />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <br><br>
                <v-row>
                  <v-col cols="2">
                    <b class="text-center">Costo Unidad Especial:</b>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field v-model="dataPostAPI.transporteMonto" label="Monto Total $" :required="false"
                      v-bind="inputNumberProps1" prefix="$" readonly />
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete v-model="dataPostAPI.transporteTipo" :items="dataSumaExtras"
                      :item-text="nombreDefault" item-value="id" outlined dense label="Tipo" />
                  </v-col>
                </v-row>
                <br><br>
                <b>Observaciones / Condiciones Particulares</b>
                <br><br>
                <v-textarea v-model="dataPostAPI.transporteObservaciones"
                  label="Observaciones / Condiciones Particulares..." outlined dense />
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Variantes en el volumen:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-card-text>
                <h5 style="margin-bottom: 10px">
                  Especifique las diferentes variantes
                </h5>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-textarea v-model="dataPostAPI.variantes" label="Variantes..." outlined dense />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-list-item-content>
        </v-list-item>
      </v-form>
    </v-card>

    <div>
      <AlertSnackbar ref="AlertSnackbar" />
    </div>
  </v-dialog>
</template>

<script>
import AlertSnackbar from '@/components/AlertSnackbar.vue'
import axios from "axios";

export default {
  components: {
    AlertSnackbar,
  },
  data() {
    return {
      chg: 0,
      loadTintas: false,
      index: 0,

      cardStyle: {
        outlined: true,
        elevation: 5,
        style: {
          padding: "15px",
          width: "100%",
          border: "1px solid #C7C7C7",
        },
      },
      inputTextProps: {
        outlined: true,
        dense: true,
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      inputNumberProps: {
        outlined: true,
        dense: true,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) <= 0) {
              return "Ingrese un número mayor a 0";
            }
            return true;
          },
        ],
      },
      inputNumberProps1: {
        outlined: true,
        dense: true,
        type: "number",
        inputmode: "numeric",
        min: 0,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return true;
            }
            return Number.isFinite(Number(v)) || "Ingrese solo números";
          },
          (v) => (v === null || v === "" || Number(v) >= 0) || "Dato no válido"
        ]
      },
      inputTextTableProps: {
        outlined: false,
        dense: false,
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      inputPercentTintaProps: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) <= 0) {
              return "Ingrese un número mayor a 0";
            }
            else if (Number(v) > 150) {
              return "Ingrese un número menor o igual a 150";
            }
            return true;
          },
        ],
      },
      inputNumberIntProps: {
        outlined: true,
        dense: true,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isInteger(Number(v))) {
              return "Ingrese solo números enteros";
            } else if (Number(v) <= 0) {
              return "Ingrese un número mayor a 0";
            }
            return true;
          },
        ],
      },
      propsDecimales: {
				outlined: true,
				dense: true,
				type: "number",
				inputmode: "numeric",
				required: true,
				rules: [
					(v) => {
						if (v === null || v === "") {
							return "Dato requerido";
						} else if (!Number.isFinite(Number(v))) {
							return "Ingrese solo números";
						} else if (Number(v) < 0) {
							return "Dato no valido";
						}
						return true;
					},
				],
			},
      propsAutocomplete: {
        outlined: true,
        dense: true,
        required: true,
        rules: [
          (v) => !!v || "Selección Requerida"
        ],
      },
      selectProps: {
        dense: false,
        outlined: false,
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },

      dialog: false,
      tipoPreCotizacionId: null,
      tipoPreCotizacionText: null,

      headersImpresiones: [
        {
          text: "Cara",
          align: "center",
          value: "cara",
          sortable: false,
        }, {
          text: "Tipo de Tinta",
          align: "center",
          value: "tipoTinta",
          sortable: false,
        }, {
          text: "Tinta",
          align: "center",
          value: "nombre",
          sortable: false,
        }, {
          text: "% Tinta",
          align: "center",
          value: "percentTinta",
          sortable: false,
        }, {
          text: "Acciones",
          align: "center",
          value: "acciones",
          sortable: false,
        },
      ],
      headersConceptos: [
        {
          text: "Concepto",
          align: "center",
          value: "concepto",
          sortable: false,
        }, {
          text: "Eliminar",
          value: "acciones",
          align: "center",
          sortable: false,
          width: "20%",
        },
      ],

      dataPostAPI: {
        idCliente: null,
        idUnidad: null,
        nombreTrabajo: null,
        PapelAlimenticio: false,
        PapelResistenteHumedad: false,
        Papel: null,
        LargoPieza: null,
        AnchoPieza: null,
        CantidadPiezas: null,
        idFrecuencia: null,
        ImpresionAlimenticio: false,
        ImpresionImperneable: false,

        Barniz: false,
        BarnizFrente: null,
        BarnizFrente2: null,
        BarnizVuelta: null,
        BarnizVuelta2: null,
        UVPlasta: false,
        UVPlastaFrente: null,
        UVPlastaVuelta: null,
        UVRegistro: false,
        UVRegistroFrente: null,
        UVRegistroVuelta: null,
        Plastificado: false,
        PlastificadoFrente: null,
        PlastificadoVuelta: null,
        Suajado: false,
        Empalme: false,
        Pegado: false,
        PegadoTipo: null,
        CMSPegadoLineal: null,
        PegadoGolpe: false,
        PegadoGolpeEsquinas: null,
        Troquel: false,

        diametroVaso: 0,

        transporteMonto: 0,
        transporteObservaciones: null,
        transporteTipo: null,
        variantes: null
      },
      dataPostAPIDefault: {
        idCliente: null,
        idUnidad: null,
        nombreTrabajo: null,
        PapelAlimenticio: false,
        PapelResistenteHumedad: false,
        Papel: null,
        LargoPieza: null,
        AnchoPieza: null,
        CantidadPiezas: null,
        idFrecuencia: null,
        ImpresionAlimenticio: false,
        ImpresionImperneable: false,

        Barniz: false,
        BarnizFrente: null,
        BarnizFrente2: null,
        BarnizVuelta: null,
        BarnizVuelta2: null,
        UVPlasta: false,
        UVPlastaFrente: null,
        UVPlastaVuelta: null,
        UVRegistro: false,
        UVRegistroFrente: null,
        UVRegistroVuelta: null,
        Plastificado: false,
        PlastificadoFrente: null,
        PlastificadoVuelta: null,
        Suajado: false,
        Empalme: false,
        Pegado: false,
        PegadoTipo: null,
        CMSPegadoLineal: null,
        PegadoGolpe: false,
        PegadoGolpeEsquinas: null,
        Troquel: false,

        diametroVaso: 0,

        transporteMonto: 0,
        transporteObservaciones: null,
        transporteTipo: null,
        variantes: null
      },
      dataClientes: [],
      dataUnidades: [],
      dataTintas: [],
      dataPapeles: [],
      dataTipoTintas: [],
      dataImpresiones: [],
      dataConceptos: [],
      dataFrecuencia: [],
      dataTransporte: [],
      dataBarnizF: [],
      dataBarnizV: [],
      dataBarnizTipo: [],
      dataBarnizUVPlasta: [],
      dataBarnizUVRegistro: [],
      dataPlastificados: [
        {
          id: -1,
          value: "No"
        }, {
          id: 1,
          value: "Brillante"
        }, {
          id: 2,
          value: "Mate"
        }
      ],
      dataPegado: [
        {
          id: 1, value: "Pegado Lineal"
        }, {
          id: 2, value: "Pagado de Fondo Automático"
        }
      ],
      dataCaras: [
        {
          id: 1,
          value: "Frente"
        }, {
          id: 2,
          value: "Vuelta"
        }
      ],
      dataSumaExtras: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Sumar al Costo sin Utilidad",
        }
      ],
      impresionesTipoTinta: [],
      impresionesTinta: [],

      loadingClientes: false,
      loadingUnidades: false,
      loadingPapel: false,
      loadingFrecuancia: false,
      loading: false,

      urlTintas: "/tintas/listar?",
      urlTintasDefault: "/tintas/listar?",
      urlPapeles: "/papeles/listar?",
      urlPapelesDefault: "/papeles/listar?",
      urlBarniz: "/Barniz/Listar?",
      urlBarnizDefault: "/Barniz/Listar?",

      valueDefault: null,
      binaryDefault: false,
      arrayDefault: [],
    };
  },
  methods: {
    nombreCliente(lista) {
      if (lista.clave) {
        return `[${lista.clave}] - ${lista.nombre}`;
      } else {
        return lista.nombre;
      }
    },
    nombreUnidad: (lista) => `[${lista.abreviatura}] - ${lista.nombre}`,
    nombrePapel: (lista) => `[${lista.codigo}] - ${lista.nombre}`,
    nombreFrecuencia: (lista) => `${lista.nombre}`,
    nombreDefault: (lista) => `${lista.value}`,
    nombreDefault2: (lista) => `[${lista.codigo}] - ${lista.nombre}`,
    nombreBarnices(lista) {
      if (lista.nombre === "No") {
        return lista.nombre;
      } else {
        return `${lista.nombre}`;
      }
    },
    nombreBarnizTipo(lista) {
      if (lista.nombre === "No") {
        return lista.nombre;
      } else {
        return `[${lista.codigo}] - ${lista.nombre}`;
      }
    },
    nombrePlastificado(lista) {
      if (lista.value === "No") {
        return lista.value;
      } else {
        return `${lista.value}`;
      }
    },
    nombreFondo(lista) {
      if (lista.value === "No") {
        return lista.value;
      } else {
        return `${lista.value}`;
      }
    },

    initialize() {
      this.getClientes()
      this.getUnidades()
      this.getPapeles()
      this.getTintas()
      this.getTipoTintas()
      this.getBarnizTipo()
      this.getBarniz()
      this.getFrecuencia()
      this.getTransportePreguntas()
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.postPreCotizacion()
      }
      else {
        this.alerta(422)
      }
    },

    postPreCotizacion() {
      this.loading = true;
      axios
        .post("/Precotizaciones/Crear", {
          idFrecuencia: this.dataPostAPI.idFrecuencia,
          idArticulo: this.dataPostAPI.Papel,
          idCliente: this.dataPostAPI.idCliente,
          idUnidad: 1,
          tipoPrecotizacion: this.tipoPreCotizacionId,
          nombreTrabajo: this.dataPostAPI.nombreTrabajo,
          gradoAlimentaicio: this.dataPostAPI.PapelAlimenticio,
          resistenteHumedad: this.dataPostAPI.PapelResistenteHumedad,
          largoPza: parseFloat(this.dataPostAPI.LargoPieza),
          anchoPza: parseFloat(this.dataPostAPI.AnchoPieza),
          cantidadPza: this.dataPostAPI.CantidadPiezas,
          gradoAlimenticioImpresion: this.dataPostAPI.ImpresionAlimenticio,
          impermeableImpresion: this.dataPostAPI.ImpresionImperneable,
          monto: this.dataPostAPI.transporteMonto ? parseFloat(this.dataPostAPI.transporteMonto) : 0,
          barniz: this.dataPostAPI.Barniz,

          idBarnizFrente: this.dataPostAPI.BarnizFrente2 ? this.dataPostAPI.BarnizFrente2 : null,
          idBarnizVuelta: this.dataPostAPI.BarnizVuelta2 ? this.dataPostAPI.BarnizVuelta2 : null,

          uvPlasta: this.dataPostAPI.UVPlasta,
          idUVPlastaFrente: Number.isInteger(this.dataPostAPI.UVPlastaFrente) ? this.dataPostAPI.UVPlastaFrente : null,
          idUVPlastaVuelta: Number.isInteger(this.dataPostAPI.UVPlastaVuelta) ? this.dataPostAPI.UVPlastaVuelta : null,
          uvRegistro: this.dataPostAPI.UVRegistro,
          idUVRegistroFrente: Number.isInteger(this.dataPostAPI.UVRegistroFrente) ? this.dataPostAPI.UVRegistroFrente : null,
          idUVRegistroVuelta: Number.isInteger(this.dataPostAPI.UVRegistroVuelta) ? this.dataPostAPI.UVRegistroVuelta : null,
          plastificado: this.dataPostAPI.Plastificado,
          idPlastificadoFrente: Number.isInteger(this.dataPostAPI.PlastificadoFrente) ? this.dataPostAPI.PlastificadoFrente : null,
          idPlastificadoVuelta: Number.isInteger(this.dataPostAPI.PlastificadoVuelta) ? this.dataPostAPI.PlastificadoVuelta : null,
          suajado: this.dataPostAPI.Suajado,
          empalme: this.dataPostAPI.Empalme,
          pegado: this.dataPostAPI.Pegado,
          idTipoPegado: this.dataPostAPI.PegadoTipo ? this.dataPostAPI.PegadoTipo : null,
          cmsPegadoLineal: this.dataPostAPI.CMSPegadoLineal ? parseFloat(this.dataPostAPI.CMSPegadoLineal) : null,
          pegadoGolpe: this.dataPostAPI.PegadoGolpe,
          numEsquinas: this.dataPostAPI.PegadoGolpeEsquinas ? parseInt(this.dataPostAPI.PegadoGolpeEsquinas) : null,
          troquel: this.dataPostAPI.Troquel,
          observacion: this.dataPostAPI.transporteObservaciones != "" ? this.dataPostAPI.transporteObservaciones : null,
          especificacion: this.dataPostAPI.variantes != "" ? this.dataPostAPI.variantes : null,
          tipoSumaTransporte: this.dataPostAPI.transporteTipo ? this.dataPostAPI.transporteTipo : null,
          transporte: this.jsonTransporte.length > 0 ? this.jsonTransporte : null,
          impresionTinta: this.jsonImpresiones.length > 0 ? this.jsonImpresiones : null,
          precotizacionExtra: this.jsonConceptos.length > 0 ? this.jsonConceptos : null,

          diametroVaso: this.dataPostAPI.diametroVaso
        })
        .then((response) => {
          this.$emit("success_precotizacion")
          this.hide_dialog()
        })
        .catch((error) => {
          console.log(error.response.status)
          this.alerta(error.response.status)
        })
        .finally(() => {
          this.loading = false;
        })
    },

    getClientes() {
      this.loadingClientes = true;
      axios
        .get("/Clientes")
        .then((response) => {
          this.dataClientes = response.data
        })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.loadingClientes = false;
        })
    },
    getUnidades() {
      this.loadingUnidades = true;
      axios
        .get("/Unidades")
        .then((response) => {
          this.dataUnidades = response.data
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingUnidades = false;
        })
    },
    getPapeles() {
      this.loadingPapel = true;
      axios
        .get(this.urlPapeles)
        .then((response) => {
          this.dataPapeles = response.data
        })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.loadingPapel = false;
        })
    },
    getTintas() {
      axios
        .get(this.urlTintas)
        .then((response) => {
          this.dataTintas = response.data
        })
        .catch((error) => { console.log(error); });
    },
    getTipoTintas() {
      axios
        .get("/TintaTipos/Listar")
        .then((response) => {
          this.dataTipoTintas = response.data
        })
        .catch((error) => { console.log(error); });
    },
    getBarnizTipo() {
      this.loadingTable = true;
      axios
        .get("/BarnizTipo/Listar")
        .then((response) => {
          this.dataBarnizTipo = response.data
          this.dataBarnizTipo.unshift({ codigo: "N", nombre: "No", idBarnizTipo: -1 })
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    getBarniz() {
      axios
        .get("/Barniz/Listar")
        .then((response) => {
          this.dataBarnizUVPlasta = response.data.filter((item) => item.grupoBarniz === "Barniz Uv Plasta");
          this.dataBarnizUVPlasta.unshift({ codigo: "N", nombre: "No", idArticulo: -1 });
          this.dataBarnizUVRegistro = response.data.filter((item) => item.grupoBarniz === "Barniz Uv Registro");
          this.dataBarnizUVRegistro.unshift({ codigo: "N", nombre: "No", idArticulo: -1 });

        })
        .catch((error) => {
          console.log(error)
        })
    },
    getFrecuencia() {
      this.loadingFrecuancia = true;
      axios
        .get("/Frecuencia/Listar")
        .then((response) => {
          this.dataFrecuencia = response.data
        })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.loadingFrecuancia = false;
        })
    },
    getTransportePreguntas() {
      axios
        .get("/TransportePreguntas/Listar")
        .then((response) => {
          this.dataTransporte = response.data
        })
        .catch((error) => { console.log(error); });
    },

    agregarTinta() {
      this.dataImpresiones.push(JSON.parse(JSON.stringify({
        index: this.index,
        cara: null,
        idTipoTinta: null,
        tipoTintaNombre: null,
        tinta: null,
        idTinta: null,
        tintaNombre: null,
        loadTintas: false,
        percentTinta: null
      })))
      this.getTintas(this.index)
      this.index++;
    },
    eliminarTinta(item) {
      var idx = this.dataImpresiones.indexOf(item);
      if (idx < 0) return;
      this.dataImpresiones.splice(idx, 1);
      this.impresionesTipoTinta = this.impresionesTinta = this.arrayDefault
    },

    agregarConcepto() {
      this.dataConceptos.push({ concepto: null });
    },
    eliminarConcepto(item) {
      var idx = this.dataConceptos.indexOf(item);
      if (idx < 0) return;
      this.dataConceptos.splice(idx, 1);
    },

    urlTintasListar(item) {
      var url = `/tintas/listar?idTipoTinta=${item.idTipoTinta}`
      var pos = this.returnPost(item);
      this.dataImpresiones[pos].tinta = [];
      this.dataImpresiones[pos].idTinta = null;
      this.dataImpresiones[pos].nombreDefault2 = null;
      this.dataImpresiones[pos].loadTintas = true;
      axios
        .get(url)
        .then((response) => {
          let respuesta = response.data;

          if (!this.dataPostAPI.ImpresionAlimenticio && !this.dataPostAPI.ImpresionImperneable) {
            this.dataImpresiones[pos].tinta = respuesta
          }
          else if (this.dataPostAPI.ImpresionAlimenticio && this.dataPostAPI.ImpresionImperneable) {
            this.dataImpresiones[pos].tinta = respuesta.map(item => {
              if (item.gradoAlimenticio === this.dataPostAPI.ImpresionAlimenticio && item.impermeable === this.dataPostAPI.ImpresionImperneable) {
                return item;
              }
            });
          }
          else {
            this.dataImpresiones[pos].tinta = respuesta.map(item => {
              if (this.dataPostAPI.ImpresionAlimenticio && item.gradoAlimenticio === this.dataPostAPI.ImpresionAlimenticio) {
                return item
              }
              else if (this.dataPostAPI.ImpresionImperneable && item.impermeable === this.dataPostAPI.ImpresionImperneable) {
                return item
              }
            });
          }

          this.dataImpresiones[pos].loadTintas = false;
        })
        .catch((error) => {
          this.dataImpresiones[pos].tinta = [];
          this.dataImpresiones[pos].loadTintas = false;
          console.log(error)
        })
    },
    reiniciarTabla() {
      this.dataImpresiones = [];
    },
    returnPost(item) {
      return this.dataImpresiones.findIndex(a => a.index == item.index);
    },
    cambiarBarnizFrente(selecTipoBarniz) {
      this.urlBarniz = this.urlBarnizDefault;
      this.dataPostAPI.BarnizFrente2 = null;

      if (selecTipoBarniz != "No") {
        this.urlBarniz += `idTipoBarniz=${selecTipoBarniz}`;

        axios
          .get(this.urlBarniz)
          .then((response) => {
            this.dataBarnizF = response.data;
          })
          .catch((error) => { console.log(error.response) })
      }
    },
    cambiarBarnizVuelta(selecTipoBarniz) {
      this.urlBarniz = this.urlBarnizDefault;
      this.dataPostAPI.BarnizVuelta2 = null;

      if (selecTipoBarniz != "No") {
        this.urlBarniz += `idTipoBarniz=${selecTipoBarniz}`;

        axios
          .get(this.urlBarniz)
          .then((response) => {
            this.dataBarnizV = response.data;
          })
          .catch((error) => { console.log(error.response) })
      }
    },

    cambiarCondicionTransporte(index) {
      let pregunta = this.dataTransporte.find((item) => item.idTransportePregunta === index);
      if (!pregunta.respuesta) {
        pregunta.monto = 0;
        this.cambiarMontoTransporte();
      }
    },
    cambiarMontoTransporte() {
      this.dataPostAPI.transporteMonto = this.dataTransporte.reduce((total, item) => total + parseFloat(item.monto || 0), 0);
    },

    show_dialog(tipoPreCotizacion) {
      this.tipoPreCotizacionId = parseInt(tipoPreCotizacion);
      switch (this.tipoPreCotizacionId) {
        case 1:
          this.tipoPreCotizacionText = "General";
          break;
        case 2:
          this.tipoPreCotizacionText = "Vaso Pared Sencilla";
          break;
        case 3:
          this.tipoPreCotizacionText = "Vaso Pared Doble";
          break;
      }
      this.dialog = true;
      this.initialize()
      /*if (this.tipoPreCotizacionId > 1) {
        this.dataPostAPI.nombreTrabajo = this.tipoPreCotizacionText;
      }*/
    },
    hide_dialog() {
      this.dataConceptos = this.dataImpresiones = JSON.parse(JSON.stringify(this.arrayDefault));
      this.$refs.form.reset();
      this.dataPostAPI = JSON.parse(JSON.stringify(this.dataPostAPIDefault));
      this.tipoPreCotizacionId = this.tipoPreCotizacionText = this.valueDefault

      this.dialog = false;
    },

    alerta(code) {
      this.$refs.AlertSnackbar.state_alert(code);
    },
  },
  watch: {
    'dataPostAPI.Barniz'(newValue) {
      if (!newValue) {
        this.dataPostAPI.BarnizFrente = null;
        this.dataPostAPI.BarnizFrente2 = null;
        this.dataPostAPI.BarnizVuelta = null;
        this.dataPostAPI.BarnizVuelta2 = null;
      }
    },
    'dataPostAPI.BarnizFrente'(newValue) {
      if (newValue == -1) {
        this.dataPostAPI.BarnizFrente2 = null;
      }
    },
    'dataPostAPI.BarnizVuelta'(newValue) {
      if (newValue == -1) {
        this.dataPostAPI.BarnizVuelta2 = null;
      }
    },
    'dataPostAPI.UVPlasta'(newValue) {
      if (!newValue) {
        this.dataPostAPI.UVPlastaFrente = this.dataPostAPI.UVPlastaVuelta = this.valueDefault
      }
    },
    'dataPostAPI.UVRegistro'(newValue) {
      if (!newValue) {
        this.dataPostAPI.UVRegistroFrente = this.dataPostAPI.UVRegistroVuelta = this.valueDefault
      }
    },
    'dataPostAPI.Plastificado'(newValue) {
      if (!newValue) {
        this.dataPostAPI.PlastificadoFrente = this.dataPostAPI.PlastificadoVuelta = this.valueDefault
      }
    },
    'dataPostAPI.Pegado'(newValue) {
      if (!newValue) {
        this.dataPostAPI.PegadoTipo = this.dataPostAPI.CMSPegadoLineal = this.valueDefault
      } else {
        this.dataPostAPI.PegadoGolpe = false;
      }
    },
    'dataPostAPI.PegadoGolpe'(newValue) {
      if (!newValue) {
        this.dataPostAPI.PegadoGolpeEsquinas = this.valueDefault
      } else {
        this.dataPostAPI.Pegado = false;
      }
    },
  },
  computed: {
    jsonImpresiones() {
      const dataImpresiones = this.dataImpresiones.map(item => {
        return {
          idCara: item.cara,
          idTintaTipo: item.idTipoTinta,
          idArticulo: item.idTinta,
          porcentajeTinta: parseInt(item.percentTinta)
        }
      })
      return dataImpresiones
    },
    jsonTransporte() {
      const dataTransporte = this.dataTransporte
        .map(item => ({
          idTransportePregunta: item.idTransportePregunta,
          respuesta: item.respuesta,
          monto: item.monto
        }));
      return dataTransporte;
    },
    jsonConceptos() {
      const dataConceptos = this.dataConceptos.map(item => {
        return {
          concepto: item.concepto,
        }
      })
      return dataConceptos
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.my-title {
  color: #5c5c5c;
  margin-top: 50px;
  padding-bottom: 10px;
  width: 100%;
}
</style>