import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"elevation-0",attrs:{"id":"app-drawer","app":"","floating":"","mobile-breakpoint":"990","width":"220","mini-variant-width":"68","color":"rgb(33,33,33)","dark":"","mini-variant":_vm.miniDrawer,"permanent":!_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},[_c(VListItem,{staticClass:"px-1 py-2",on:{"click":function($event){_vm.miniDrawer=!_vm.miniDrawer}}},[_c(VListItemAvatar,{staticClass:"mx-2",attrs:{"tile":""}},[_c(VImg,{attrs:{"src":"/favicon.png"}})],1),_c(VListItemTitle,{staticClass:"font-weight-bold headline"},[_vm._v(" MANAGRX ")])],1),_c(VLayout,{attrs:{"tag":"v-list"}},[_c(VList,{attrs:{"shaped":"","nav":_vm.miniDrawer}},[_vm._l((_vm.links),function(link,i){return _c(VListItem,{key:i,staticClass:"mt-1 ml-0 mr-0 mb-0 list-item-custom",class:!_vm.miniDrawer&&'pr-10 pl-6',staticStyle:{"max-height":"44px"},attrs:{"to":link.to,"active-class":_vm.colorTabs}},[(_vm.miniDrawer)?_c(VTooltip,{attrs:{"right":"","color":"#121212"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItemAction,_vm._g(_vm._b({staticClass:"ma-3"},'v-list-item-action',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(link.icon))])],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(link.text))])]):_vm._e(),_c(VListItemIcon,{staticClass:"mr-4",staticStyle:{"margin-top":"auto","margin-bottom":"auto"}},[_c(VIcon,{domProps:{"textContent":_vm._s(link.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(link.text)}})],1)],1)}),_c(VListItem,{staticClass:"mt-1 ml-0 mr-0 mb-0 list-item-custom",class:!_vm.miniDrawer&&'pr-10 pl-6',staticStyle:{"max-height":"44px"},attrs:{"active-class":_vm.colorTabs},on:{"click":function($event){_vm.$store.state.mostrarChat=!_vm.$store.state.mostrarChat}}},[_c(VTooltip,{attrs:{"right":"","color":"#121212"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItemAction,_vm._g(_vm._b({staticClass:"ma-3"},'v-list-item-action',attrs,false),on),[(_vm.$store.state.mostrarChat)?_c(VIcon,[_vm._v("mdi-robot-off")]):_c(VIcon,[_vm._v("mdi-robot")])],1)]}}])},[(_vm.$store.state.mostrarChat)?_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s('Ocultar chat'))]):_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s('Mostrar chat'))])]),_c(VListItemContent,[(_vm.$store.state.mostrarChat)?_c(VListItemTitle,{domProps:{"textContent":_vm._s("Ocultar chat")}}):_c(VListItemTitle,{domProps:{"textContent":_vm._s("Mostrar chat")}})],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }