<template>
  <v-card>
    <v-tabs v-model="tab" style="width: 100%;">
      <v-tab href="#tab-1" class="flex-grow-1" style="max-width: 50%;" @click="initBarniz()">
        Barnices
      </v-tab>
      <v-tab href="#tab-2" class="flex-grow-1" style="max-width: 50%;" @click="initTipoBarniz()">
        Tipo de Barnices
      </v-tab>
      <!--<v-tab href="#tab-3" class="flex-grow-1" style="max-width: 0%;" @click="initTipoBarniz()">
        Grupos de Barnices
      </v-tab>-->
      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" :value="'tab-1'">
          <v-card>
            <Barniz ref="Barniz" class="my-tab" />
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" :value="'tab-2'">
          <v-card>
            <TipoBarniz ref="TipoBarniz" class="my-tab" />
          </v-card>
        </v-tab-item>
        <v-tab-item :key="3" :value="'tab-3'">
          <v-card>
            <GrupoBarniz ref="GrupoBarniz" class="my-tab" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import Barniz from './Barniz/Barniz.vue'
import TipoBarniz from './Barniz/TipoBarniz.vue'
import GrupoBarniz from './Barniz/GrupoBarniz.vue'

export default {
  components: {
    Barniz,
    TipoBarniz,
    GrupoBarniz,
  },
  data() {
    return {
      tab: null,
    }
  },
  methods: {
    initBarniz() {
      this.$refs.Barniz?.initialize();
    },
    initTipoBarniz() {
      this.$refs.TipoBarniz?.initialize();
    },
    initGrupoBarniz() {
      this.$refs.GrupoBarniz?.initialize();
    },
  },
}
</script>

<style scoped>
.my-tab {
  padding: 15px 20px 0 20px;
}
</style>