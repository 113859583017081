<template>
  <v-col cols="12" class="pt-0">
    <v-card>
      <v-toolbar :color="colorTabs" dark flat>
        <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
          <v-tab
              v-for="(item, idx) in headsToShow"
              :key="'tab-' + idx"
              :href="'#tab-' + idx"
              v-tooltip.bottom="modoAyuda && $help.reportes[item.code]"
              class="p-10"
              @click="changeSection(item.code)"
          >
            <v-icon> {{ item.icon }}</v-icon>
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <v-tabs-items v-model="currentItem">
        <v-tab-item
            v-for="(item, idx) in headsToShow"
            :key="item"
            :value="'tab-' + idx"
        >
          <v-card v-if="item.code === 'favoritos' && favs.length === 0" :elevation="0">
            <v-card-text>
              <div>
                <p class="text-h6 text-center">No tiene reportes favoritos</p>
              </div>
            </v-card-text>
          </v-card>
          <v-card v-else flat class="d-flex justify-center" color="transparent">
            <v-slide-group
                mandatory
                center-active
                show-arrows
                :key="tabsKey"
            >
              <v-slide-item
                  v-for="report in item.code==='favoritos'?listFavs():getReports(item.code)"
                  :key="'tab-' + report.idReporte"
                  v-slot="{ active, toggle }"
                  :value="report.idReporte"
              >
                <v-card
                    :color="active ? 'primary' : ''"
                    class="ma-4 mx-2 text-center d-flex align-center"
                    min-height="50"
                    max-height="100"
                    width="250"
                    @click="changeView(report.idReporte);toggle()"
                >
                  <v-scale-transition>
                    <v-col cols="12" class="ma-0 pa-0 px-1 d-flex align-center">
                      <v-col :cols="item.code==='favoritos'?10:12" class="ma-0 pa-0">
                        <p :class="active&&'white--text'"
                           class="text-subtitle-2 text-uppercase text-wrap text-center mb-0">{{ report.nombre }}</p>
                      </v-col>
                      <v-col v-if="item.code==='favoritos'" cols="2" class="ma-0 pa-0">
                        <v-btn
                            text
                            color="yellow"
                            class="ma-0 pa-0"
                            x-small
                            @click="removeFavs(report.idReporte)"
                        >
                          <v-icon>mdi-star</v-icon>
                        </v-btn>
                      </v-col>
                    </v-col>
                  </v-scale-transition>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <br/>
      <v-tabs-items v-model="currentView">
        <v-tab-item
            v-for="report in reports"
            :key="`tab-${report.idReporte}`"
            :value="'tab-' + report.idReporte"
        >
          <v-col cols="12">
            <div class="d-flex justify-center align-center">
              <div class="text-h4">{{ report.nombre }}</div>
              <v-btn
                  v-if="!isInFavs(report.idReporte)"
                  text
                  color="grey"
                  @click="addFavs(report.idReporte)"
              >
                <v-icon>mdi-star</v-icon>
              </v-btn
              >
              <v-btn
                  v-else
                  text
                  color="yellow"
                  @click="removeFavs(report.idReporte)"
              >
                <v-icon>mdi-star</v-icon>
              </v-btn
              >
            </div>

            <Grafica1 :active="`tab-${report.idReporte}` == currentView" v-if="report.codigo == 'r1'"/>
            <Grafica2 :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r2'"/>
            <Grafica3 :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r3'"/>
            <Grafica4 :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r4'"/>
            <Grafica5 :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r5'"/>
            <Grafica6 :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r6'"/>
            <Grafica7 :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r7'"/>
            <Matriz1 :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r8'"/>
            <Matriz2 :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r9'"/>
            <Matriz3 :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r10'"/>
            <ReporteOEE :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r11'"/>
            <ReporteRendimiento :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r12'"/>
            <ReporteSubOperaciones :active="`tab-${report.idReporte}` == currentView"
                                   v-else-if="report.codigo == 'r13'"/>
            <ReporteOperaciones :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r14'"/>
            <ReporteComparativaMaquinas :active="`tab-${report.idReporte}` == currentView"
                                        v-else-if="report.codigo == 'r15'"/>
            <ReporteTiempoNoProductivoMaquinas :active="`tab-${report.idReporte}` == currentView"
                                               v-else-if="report.codigo == 'r16'"/>
            <ReporteGraficaDatos :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r17'"/>
            <ReporteProduccion :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r18'"/>
            <ReporteMaquinaSensor :active="`tab-${report.idReporte}` == currentView"
                                  v-else-if="report.codigo == 'r19'"/>
            <ReporteTiempoCiclo :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r20'"/>
            <ReporteOperadoresOperacion :active="`tab-${report.idReporte}` == currentView"
                                        v-else-if="report.codigo == 'r21'"/>
            <ReporteEficienciaOperadores :active="`tab-${report.idReporte}` == currentView"
                                         v-else-if="report.codigo == 'r22'"/>
            <ReportePiezasTerminadas :active="`tab-${report.idReporte}` == currentView"
                                     v-else-if="report.codigo == 'r23'"/>
            <ReporteAsistenciasEmpleado :active="`tab-${report.idReporte}` == currentView"
                                        v-else-if="report.codigo == 'r24'"/>
            <ReporteFactibilidadOrdenTrabajo :active="`tab-${report.idReporte}` == currentView"
                                             v-else-if="report.codigo == 'r25'"/>
            <ReporteOperadorTiempoSinReportar :active="`tab-${report.idReporte}` == currentView"
                                              v-else-if="report.codigo == 'r26'"/>
            <Faltante :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r27'"/>
            <ReporteSubordinado :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r28'"/>
            <ReporteRelojChecador :active="`tab-${report.idReporte}` == currentView"
                                  v-else-if="report.codigo == 'r29'"/>
            <ReporteTendenciaRendimiento :active="`tab-${report.idReporte}` == currentView"
                                         v-else-if="report.codigo == 'r30'"/>
            <ReporteTendenciaRendimientoJefes :active="`tab-${report.idReporte}` == currentView"
                                              v-else-if="report.codigo == 'r31'"/>
            <ReporteTiempoActivoMaquinas :active="`tab-${report.idReporte}` == currentView"
                                         v-else-if="report.codigo == 'r32'"/>
            <ReporteProyeccionProduccion :active="`tab-${report.idReporte}` == currentView"
                                         v-else-if="report.codigo == 'r33'"/>

            <ReporteMPEntregar :active="`tab-${report['idReporte']}` === currentView"
                               v-else-if="report.codigo === 'r34'"/>

            <CostoTotalMP :active="`tab-${report['idReporte']}` === currentView" v-else-if="report.codigo === 'r53'"/>
            <CostosPorTiposMantenimientos :active="`tab-${report['idReporte']}` === currentView" v-else-if="report.codigo === 'r54'"/>
            <CostosPorCategoriaMantenimiento :active="`tab-${report['idReporte']}` === currentView" v-else-if="report.codigo === 'r55'"/>
            <CostosPorUbicacionMantenimiento :active="`tab-${report['idReporte']}` === currentView" v-else-if="report.codigo === 'r56'"/>
            <CostosPorUnidadMaquinaMantenimiento :active="`tab-${report['idReporte']}` === currentView" v-else-if="report.codigo === 'r57'"/>
            <ReportesProyeccion :active="`tab-${report['idReporte']}` === currentView" v-else-if="report.codigo === 'r58'"/> <!--Para la proyección de reportes-->
            <ProyeccionMaquinas :active="`tab-${report['idReporte']}` === currentView" v-else-if="report.codigo === 'r59'"/> <!--Para la lectura PLC en tiempo real-->
            <OEEMaquinaDummy :active="`tab-${report['idReporte']}` == currentView" v-else-if="report.codigo === 'r60'"/> <!--Para OEE tiempo real-->
            <MaquinaEstado :active="`tab-${report['idReporte']}` == currentView" v-else-if="report.codigo === 'r61'"/> <!--Para estado de las máquinas-->
            <ReporteFlujoMpyMerma :active="`tab-${report['idReporte']}` == currentView" v-else-if="report.codigo === 'r64'"/> <!--Para estado de las máquinas-->

            <reporte-acumulado-maquinas :active="`tab-${report.idReporte}` == currentView"
                                        v-else-if="report.codigo == 'r35'"/>
            <AlertasActivas :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r36'"/>
            <ReporteTiempoNoDisponiblePorMaquina :active="`tab-${report.idReporte}` == currentView"
                                                 v-else-if="report.codigo == 'r37'"/>
            <ReporteAcumuladoMaquinas :active="`tab-${report.idReporte}` == currentView"
                                      v-else-if="report.codigo == 'r38'"/>
            <OEEMaquinas :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r39'"/>
            <ReporteInnactividadMaquinas :active="`tab-${report.idReporte}` == currentView"
                                         v-else-if="report.codigo == 'r40'"/>
            <ReporteAcumuladoMaquinasReceta :active="`tab-${report.idReporte}` == currentView"
                                            v-else-if="report.codigo == 'r41'"/>
            <ReporteProduccionPorHora :active="`tab-${report.idReporte}` == currentView"
                                      v-else-if="report.codigo == 'r42'"/>
            <ReporteIncidencias :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r43'"/>
            <ReporteProporcionMezcla :active="`tab-${report.idReporte}` == currentView"
                                     v-else-if="report.codigo == 'r44'"/>
            <ReporteVariacionMezcla :active="`tab-${report.idReporte}` == currentView"
                                    v-else-if="report.codigo == 'r45'"/>
            <ReporteDistribucionOperadores :active="`tab-${report.idReporte}` == currentView"
                                           v-else-if="report.codigo == 'r46'"/>
            <reportedecumplimientodemantenimientosporoperador :active="`tab-${report.idReporte}` == currentView"
                                                              v-else-if="report.codigo == 'r47'"/>
            <ReportedeProcesodeProduccion :active="`tab-${report.idReporte}` == currentView"
                                          v-else-if="report.codigo == 'r49'"/>
            <ReporteCumplimientoMantenimientoMaquina :active="`tab-${report.idReporte}` == currentView"
                                                     v-else-if="report.codigo == 'r50'"/>
            <ReporteMPSemanal :active="`tab-${report.idReporte}` == currentView" v-else-if="report.codigo == 'r51'"/>
            <ReportedeProcesosdeProduccionporFechas :active="`tab-${report.idReporte}` == currentView"
                                                    v-else-if="report.codigo == 'r52'"/>

            <ReporteRevisionUnidadesViaje
              v-else-if="report.codigo == 'r62' && esIndasa"
              :active="`tab-${report.idReporte}` == currentView"
            />

            <ReporteRevisionUnidadesViajePromedio
              v-else-if="report.codigo == 'r63' && esIndasa"
              :active="`tab-${report.idReporte}` == currentView"
            />

            <ReporteEstatusMontacargas
              v-else-if="report.codigo == 'r65' && esIndasa"
              :active="`tab-${report.idReporte}` == currentView"
            />

            <ReporteEstatusMontacargasRango
              v-else-if="report.codigo == 'r66' && esIndasa"
              :active="`tab-${report.idReporte}` == currentView"
            />

            <ReporteEstatusBasculas
              v-else-if="report.codigo == 'r67' && esIndasa"
              :active="`tab-${report.idReporte}` == currentView"
            />

            <ReporteEstatusBasculasRango
              v-else-if="report.codigo == 'r68' && esIndasa"
              :active="`tab-${report.idReporte}` == currentView"
            />

            <ReporteEstatusInspecciones
              v-else-if="report.codigo == 'r69' && esIndasa"
              :active="`tab-${report.idReporte}` == currentView"
            />
            <MaquinasUsoVue
              v-else-if="report.codigo == 'r70'" :active="`tab-${report.idReporte}` == currentView"
            />
            <v-container v-else-if="report.url != null" fluid grid-list-xl>
              <v-layout wrap>
                <iframe
                    id="info"
                    width="100%"
                    height="920"
                    :src="report.url"
                    frameborder="0"
                    allowFullScreen="true"
                ></iframe>
              </v-layout>
            </v-container>
          </v-col>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-col>
</template>

<script>
import axios from "axios";
import {mapState, mapMutations} from "vuex";
import OEEFactor from "./Reportes/OEEFactor.vue";
import Grafica1 from "./Dashboards/Grafica1.vue";
import Grafica2 from "./Dashboards/Grafica2.vue";
import Grafica3 from "./Dashboards/Grafica3.vue";
import Grafica4 from "./Dashboards/Grafica4.vue";
import Grafica6 from "./Dashboards/Grafica6.vue";
import Grafica7 from "./Dashboards/Grafica7.vue";
import Matriz1 from "./Dashboards/Matriz1.vue";
import Matriz2 from "./Dashboards/Matriz2.vue";
import Matriz3 from "./Dashboards/Matriz3.vue";
import Grafica5 from "./Dashboards/Grafica5.vue";
import ReporteRendimiento from "./Reportes/ReporteRendimiento.vue";
import ReporteSubOperaciones from "./Reportes/ReporteSubOperaciones.vue";
import ReporteOperaciones from "./Reportes/Operaciones.vue";
import ReporteComparativaMaquinas from "./Reportes/ComparativaMaquinas.vue";
import ReporteTiempoNoProductivoMaquinas from "./Reportes/TiempoNoProductivoMaquinas.vue";
import ReporteGraficaDatos from "./Reportes/Graficas.vue";
import ReporteProduccion from "./Reportes/ProduccionTiempoReal.vue";
import ReporteMaquinaSensor from "./Reportes/MaquinaSensorTiempoReal.vue";
import ReporteTiempoCiclo from './Reportes/ReporteTiempoCiclo.vue';
import ReporteOperadoresOperacion from './Reportes/ReporteOperadoresOperacion.vue';
import ReporteEficienciaOperadores from './Reportes/ReporteEficienciaOperadores.vue';
import ReportePiezasTerminadas from './Reportes/ReportePiezasTerminadas.vue';
import ReporteAsistenciasEmpleado from './Reportes/ReporteAsistenciasEmpleado.vue';
import ReporteFactibilidadOrdenTrabajo from './Reportes/ReporteFactibilidadOrdenTrabajo.vue';
import ReportesProyeccion from './Reportes/ReportesProyeccion.vue';
import OEEMaquinaDummy from './Reportes/OEEMaquinaDummy.vue';
import MaquinaEstado from './Reportes/MaquinaEstado.vue';
import ReporteOperadorTiempoSinReportar from './Reportes/ReporteOperadorTiempoSinReportar';
import Faltante from './Reportes/Faltante.vue'
import ReporteSubordinado from './Reportes/ReporteSubordinado.vue'
import ReporteRelojChecador from './Reportes/ReporteRelojChecador.vue'
import ReporteTendenciaRendimiento from './Reportes/ReporteTendenciaRendimiento.vue';
import ReporteTendenciaRendimientoJefes from './Reportes/ReporteTendenciaRendimientoJefes.vue';
import ReporteTiempoActivoMaquinas from './Reportes/ReporteTiempoActivoMaquinas.vue';
import ReporteProyeccionProduccion from './Reportes/ReporteProyeccionProduccion.vue';
import ReporteMPEntregar from './Reportes/ReporteMPEntregar.vue';
import ProyeccionMaquinas from './Reportes/ProyeccionMaquinas.vue';
import ReporteAcumuladoMaquinas from './Reportes/ReporteAcumuladoMaquinas.vue';
import AlertasActivas from './Reportes/AlertasActivas.vue'
import ReporteTiempoNoDisponiblePorMaquina from "./Reportes/TiempoNoDisponiblePorMaquina.vue";
import OEEMaquinas from './Dashboards/OEEMaquinas.vue';
import ReporteInnactividadMaquinas from "./Reportes/ReporteInnactividadMaquinas.vue"
import ReporteAcumuladoMaquinasReceta from "./Reportes/ReporteAcumuladoMaquinasReceta.vue"
import ReporteProduccionPorHora from "./Reportes/ProduccionTiempoRealPorHora.vue";
import ReporteIncidencias from './Reportes/ReporteIncidencias.vue';
import ReporteProporcionMezcla from './Reportes/ReporteProporcionMezcla.vue';
import ReporteVariacionMezcla from './Reportes/ReporteVariacionMezcla.vue';
import ReporteDistribucionOperadores from './Reportes/ReporteDistribucionOperadores.vue'
import reportedecumplimientodemantenimientosporoperador
  from './Reportes/reportedecumplimientodemantenimientosporoperador.vue'
import PorcentajeMantenimiento from './Reportes/PorcentajeMantenimiento.vue'
import ReportedeProcesodeProduccion from './Reportes/ReportedeProcesodeProduccion.vue'
import ReporteCumplimientoMantenimientoMaquina from './Reportes/ReporteCumplimientoMantenimientoMaquina.vue';
import ReportedeProcesosdeProduccionporFechas from './Reportes/ReportedeProcesosdeProduccionporFechas.vue';
import ReporteFlujoMpyMerma from './Reportes/ReporteFlujoMpyMerma.vue';
import ReporteMPSemanal from './Reportes/ReporteMPSemanal.vue';
import OEEMaquinaCardDummy from "./Reportes/Proyeccion/OEEMaquinaCardDummy.vue";
import CostoTotalMP from "@/components/DashViews/Reportes/CostoTotalMP.vue";
import CostosPorTiposMantenimientos from "@/components/DashViews/Reportes/CostosPorTiposMantenimientos.vue";
import CostosPorCategoriaMantenimiento from "@/components/DashViews/Reportes/CostosPorCategoriaMantenimiento.vue";
import CostosPorUnidadMaquinaMantenimiento
  from "@/components/DashViews/Reportes/CostosPorUnidadMaquinaMantenimiento.vue";
import CostosPorUbicacionMantenimiento from "@/components/DashViews/Reportes/CostosPorUbicacionMantenimiento.vue";
import ReporteRevisionUnidadesViaje from './Reportes/ReporteRevisionUnidadesViaje.vue';
import ReporteRevisionUnidadesViajePromedio from './Reportes/ReporteRevisionUnidadesViajePromedio.vue';
import ReporteEstatusMontacargas from './Reportes/ReporteEstatusMontacargas.vue';
import ReporteEstatusMontacargasRango from './Reportes/ReporteEstatusMontacargasRango.vue';
import ReporteEstatusBasculas from './Reportes/ReporteEstatusBasculas.vue';
import ReporteEstatusBasculasRango from './Reportes/ReporteEstatusBasculasRango.vue';
import ReporteEstatusInspecciones from "./Reportes/ReporteEstatusInspecciones.vue";
import MaquinasUsoVue from './Mantenimiento/MaquinasUso.vue';
import floatChat from '../core/FloatChat.vue';

/* ReporteSubordinado.vue */
export default {
  components: {
    CostosPorUbicacionMantenimiento,
    CostosPorUnidadMaquinaMantenimiento,
    CostosPorCategoriaMantenimiento,
    CostosPorTiposMantenimientos,
    CostoTotalMP,
    OEEMaquinaCardDummy,
    ReporteOEE: OEEFactor,
    Grafica1,
    Grafica2,
    Grafica3,
    Grafica4,
    Grafica5,
    Grafica6,
    Grafica7,
    Matriz1,
    Matriz2,
    Matriz3,
    ReporteRendimiento,
    ReporteSubOperaciones,
    ReporteOperaciones,
    ReporteComparativaMaquinas,
    ReporteTiempoNoProductivoMaquinas,
    ReporteGraficaDatos,
    ReporteProduccion,
    ReporteMaquinaSensor,
    ReporteTiempoCiclo,
    ReporteOperadoresOperacion,
    ReporteEficienciaOperadores,
    ReportePiezasTerminadas,
    ReporteAsistenciasEmpleado,
    ReporteFactibilidadOrdenTrabajo,
    ReportesProyeccion,
    ReporteOperadorTiempoSinReportar,
    Faltante,
    ReporteSubordinado,
    ReporteRelojChecador,
    ReporteTendenciaRendimiento,
    ReporteTendenciaRendimientoJefes,
    ReporteTiempoActivoMaquinas,
    ReporteProyeccionProduccion,
    ReporteMPEntregar,
    ProyeccionMaquinas,
    ReporteAcumuladoMaquinas,
    OEEMaquinaDummy,
    MaquinaEstado,
    AlertasActivas,
    ReporteTiempoNoDisponiblePorMaquina,
    OEEMaquinas,
    ReporteInnactividadMaquinas,
    ReporteAcumuladoMaquinasReceta,
    ReporteProduccionPorHora,
    ReporteIncidencias,
    ReporteProporcionMezcla,
    ReporteVariacionMezcla,
    ReporteDistribucionOperadores,
    reportedecumplimientodemantenimientosporoperador,
    PorcentajeMantenimiento,
    ReportedeProcesodeProduccion,
    ReporteCumplimientoMantenimientoMaquina,
    ReportedeProcesosdeProduccionporFechas,
    ReporteMPSemanal,
    ReporteRevisionUnidadesViaje,
    ReporteRevisionUnidadesViajePromedio,
    ReporteFlujoMpyMerma,
    ReporteEstatusMontacargas,
    ReporteEstatusMontacargasRango,
    ReporteEstatusBasculas,
    ReporteEstatusBasculasRango,
    ReporteEstatusInspecciones,
    MaquinasUsoVue,
    floatChat
  },
  data() {
    return {
      mostrarProyeccionMaquinas: false,
      items: [
        {
          src: 'backgrounds/bg.jpg',
        },
        {
          src: 'backgrounds/md.jpg',
        },
        {
          src: 'backgrounds/bg-2.jpg',
        },
        {
          src: 'backgrounds/md2.jpg',
        },
      ],
      tabsKey: 0,
      tab: null,
      currentView: null,
      ultimoClick: null,
      currentItem: "tab-0",
      show: true,
      favorite: false,
      reports: [],
      head: [
        {
          code: "favoritos",
          title: "Favoritos",
          icon: "mdi-star-outline",
        },
        {
          code: "usabilidad",
          title: "Usabilidad",
          icon: "mdi-source-branch-check",
        },
        {
          code: "produccion",
          title: "Reportes Generales de Producción",
          icon: "mdi-factory",
        },
        {
          code: "operadores",
          title: "Reportes de Operadores",
          icon: "mdi-account-hard-hat",
        },
        {
          code: "maquinas",
          title: "Reportes de Máquinas",
          icon: "mdi-car-shift-pattern",
        },
        {
          code: "almacen",
          title: "Reportes de Almacen",
          icon: "mdi-warehouse",
        },
        {
          code: "mantenimiento",
          title: "Mantenimiento",
          icon: "mdi-toolbox",
        },
        {
          code: "oee",
          title: "OEE",
          icon: "mdi-speedometer",
        },
        {
          code: "calidad",
          title: "Calidad",
          icon: "mdi-certificate",
        },
        {
          code: "analitica",
          title: "Analitica de Datos",
          icon: "mdi-chart-line",
        },
      ],
    
    };
  },
  mounted() {
    this.getReportsDB();
  },
  computed: {
    ...mapState({
      favs: "idsReportes",
      colorTabs: "colorTabs",
      modoAyuda: "modoAyuda",
      demostracion: "demostracion",
      tipoFiltroReportes: "tipoFiltroReportes",
      nombreEmpresa: "nombreEmpresa"
    }),
    esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
    headsToShow() {
      var secciones = new Set(this.reports.map(x => x.seccion))
      //console.log(secciones)
      var result = this.head.filter(h => {
        return secciones.has(h.code) || h.code == 'favoritos'
      });
      //console.log(result)
      return result;

    }
  },
  methods: {
    ...mapMutations(["add_report", "delete_report"]),
    listFavs() {
      var response = [];
      this.head.forEach((h) => {
        if (h.code != "favoritos") {
          var reportsSection = this.reports.filter(
              (r) => r.seccion == h.code && this.favs.indexOf(r.idReporte) >= 0
          );
          if (reportsSection.length > 0) {
            reportsSection = reportsSection.sort(
                (a, b) => a.posicion - b.posicion
            );
            response = response.concat(reportsSection);
          }
        }
      });
      return response;
    },
    getReports(seccion) {
      var reports = this.reports.filter((r) => r.seccion == seccion);
      reports = reports.sort((a, b) => a.posicion - b.posicion);
      return reports;
    },
    changeView(idReporte) {
      if (idReporte == "mounted") {
        const listaFavoritos = this.listFavs();
        var report = listaFavoritos.length > 0 ? listaFavoritos[0] : null;
        if (report == null) this.currentView = null;
        else {
          this.currentView = "tab-" + report.idReporte;
          this.tabsKey++;
        }
      } else {
        this.currentView = "tab-" + idReporte;
      }
    },
    changeSection(seccion) {
      if (seccion != "favoritos") {
        var minPosition = Math.min.apply(
            Math,
            this.reports
                .filter((r) => r.seccion == seccion)
                .map((r) => r.posicion)
        );
        if (isNaN(minPosition)) this.currentView = null;
        var report = this.reports.find(
            (r) => r.seccion == seccion && r.posicion == minPosition
        );
        if (report == null) this.currentView = null;
        else {
          this.currentView = "tab-" + report.idReporte;
          this.tabsKey++;
        }
      } else {
        this.changeView("mounted");
      }
    },
    isInFavs(idReporte) {
      if (this.favs.find((id) => id == idReporte)) return true;
      else return false;
    },
    addFavs(idReporte) {
      this.add_report(idReporte);
      localStorage.setItem("idsReportes", JSON.stringify(this.favs));
      axios
          .put("/Usuarios/AgregarReporte/" + idReporte)
          .then(() => {
          })
          .catch((error) => {
            Error.log(error);
          });
    },
    removeFavs(idReporte) {
      this.delete_report(idReporte);
      localStorage.setItem("idsReportes", JSON.stringify(this.favs));
      if (this.currentItem == 'tab-0') {
        setTimeout(() => {
          this.changeView("mounted");
        }, 50);
      }
      axios
          .put("/Usuarios/EliminarReporte/" + idReporte)
          .then(() => {
          })
          .catch((error) => {
            Error.log(error);
          });
    },
    getReportsDB() {
      axios
          .get("/CatReportes/Listar")
          .then((response) => {
            this.reports = response.data.filter((reporte) => {
              if (this.tipoFiltroReportes == 1) return reporte.sensores;
              else if (this.tipoFiltroReportes == 2) return !reporte.sensores;
              return true;
            });
            this.changeSection("favoritos");
          })
          .catch((error) => {
            console.log(error);
          });
    }
  },
};
</script>

<style>
#reportes .v-btn__content {
  white-space: normal;
  flex: auto;
  text-align: center;
}

#reportes .v-btn {
  min-height: 52px;
  height: 100% !important;
  align-items: center;
}

#favoritos .v-btn__content {
  white-space: normal;
  flex: auto;
  text-align: center;
}

#favoritos .v-btn {
  min-height: 52px;
  height: 100% !important;
  align-items: center;
}
</style>
