import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTabs,{attrs:{"vertical":""}},[_c(VTab,{on:{"click":function($event){return _vm.updateNotificacionesConfig()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-bell-check ")]),_vm._v(" Notificaciones ")],1),_c(VTabItem,[_c(VCard,{attrs:{"flat":"","color":"gray"}},[_c(VCardText,[_c('NotificacionesConfig',{ref:"notificaciones"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }