<template>
  <div>
    <v-dialog v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>

        <v-toolbar color="primary">
          <v-spacer />
          <v-toolbar-title class="white--text">Análisis de Costos</v-toolbar-title>
          <v-spacer />
          <v-icon dark @click="hide_dialog()">mdi-close</v-icon>
        </v-toolbar>

        <v-list-item style="width: 98%; margin: auto" v-if="dialog">
          <v-list-item-content>
            <v-list-item-title class="my-title">Datos generales:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-row class="col-12">
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Nombre del Cliente: </h6>
                    {{ dataCotizacion.cliente }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Piezas: </h6>
                    {{ formatearCifra(dataCotizacion.cantidadPza, 2) }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Nombre del Trabajo: </h6>
                    {{ dataCotizacion.nombreTrabajo }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Frecuencia: </h6>
                    {{ dataCotizacion.frecuencia }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Medidas:</v-list-item-title>
            <v-card v-bind="cardStyle" class="my-card">
              <v-card-text>
                <v-col cols="12">
                  <v-row v-bind="itemCenterStyle">
                    <h6 class="col-2 font-weight-bold my-auto">Medida del Pliego</h6>
                    <span class="col-3 mr-5 d-inline">Ancho {{ dataCotizacion.pliegoAncho }} Mts</span>
                    <span class="col-3 mr-5 d-inline">Hilo {{ dataCotizacion.pliegoHilo }} Mts</span>
                  </v-row>
                  <v-row v-bind="itemCenterStyle">
                    <h6 class="col-2 font-weight-bold my-auto">Medida Tamaño</h6>
                    <span class="col-3 mr-5">Ancho {{ dataCotizacion.tamanoAncho }} Mts</span>
                    <span class="col-3 mr-5">Hilo {{ dataCotizacion.tamanoHilo }} Mts</span>
                  </v-row>
                  <v-row v-bind="itemCenterStyle">
                    <h6 class="col-2 font-weight-bold my-auto">Área de Impresión</h6>
                    <span class="col-3 mr-5">Largo {{ dataCotizacion.impresionLargo }} Mts</span>
                    <span class="col-3 mr-5">Ancho {{ dataCotizacion.impresionAncho }} Mts</span>
                  </v-row>
                  <v-row v-bind="itemCenterStyle">
                    <h6 class="col-2 font-weight-bold my-auto">Pieza Individual</h6>
                    <span class="col-3 mr-5">Largo {{ dataCotizacion.pzIndividualLargo }} Mts</span>
                    <span class="col-3 mr-5">Ancho {{ dataCotizacion.pzIndividualAncho }} Mts</span>
                  </v-row>
                  <v-row v-bind="itemCenterStyle">
                    <h6 class="col-2 font-weight-bold my-auto">Piezas</h6>
                    <span class="col-3 mr-5">Pinza {{ dataCotizacion.piezasPinza }} Mts</span>
                    <span class="col-3 mr-5">Alto {{ dataCotizacion.piezasAlto }} Mts</span>
                  </v-row>
                </v-col>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Papel:</v-list-item-title>
            <v-card v-bind="cardStyle" class="my-card">
              <v-card-text>
                <v-row class="col-12">
                  <v-col cols="7">
                    <v-row class="d-flex align-center">
                      <h6 class="font-weight-bold my-auto mx-3">Especialidad del Papel:</h6>
                      <v-checkbox v-model="dataCotizacion.papelAlimenticio" label="Grado Alimenticio" />
                      <v-checkbox v-model="dataCotizacion.papelResistenteHumedad" class=" ml-5"
                        label="Resistente a la Humedad" />
                    </v-row>
                  </v-col>
                  <v-col cols="5" class="d-flex align-center">
                    <h6 class="font-weight-bold my-auto mx-3">Papel:</h6> {{ dataCotizacion.papel }}
                  </v-col>
                  <v-col cols="6" class="d-flex align-center">
                    <h6 class="font-weight-bold my-auto mr-3">Proveedor de Papel:</h6> {{ dataCotizacion.proveedorPapel
                    }}
                  </v-col>
                  <v-col cols="6" class="d-flex align-center">
                    <h6 class="font-weight-bold my-auto mr-3">Hojeadora:</h6> {{ dataCotizacion.hojeadora }}
                  </v-col>
                  <v-col cols="3" class="d-flex align-center">
                    <h6 class="font-weight-bold my-auto mr-3">Tamaños por Pliego:</h6> {{ dataCotizacion.tamanoPliego }}
                  </v-col>
                  <v-col cols="3" class="d-flex align-center">
                    <h6 class="font-weight-bold my-auto mr-3">Tamaño Barniz Plastificado:</h6> {{
      dataCotizacion.tamanoBarnizPlastificado }}
                  </v-col>
                  <v-col cols="3" class="d-flex align-center">
                    <h6 class="font-weight-bold my-auto mr-3">Tamaños por Suaje:</h6> {{ dataCotizacion.tamanoSuaje }}
                  </v-col>
                  <v-col cols="3" class="d-flex align-center">
                    <h6 class="font-weight-bold my-auto mr-3">Protección Papel %:</h6> {{
      dataCotizacion.ptjeProteccionPapel }}
                  </v-col>
                  <v-col cols="6" class="d-flex align-center">
                    <h6 class="font-weight-bold my-auto mr-3">Costo Millar Papel:</h6> {{
      formatearCifra(dataCotizacion.costoMillar, 2) }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Datos Generales:</v-list-item-title>
            <v-card v-bind="cardStyle" class="my-card">
              <v-card-text>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="4" class="d-flex align-center">
                      <h6 class="font-weight-bold my-auto mr-3">Placas Frente:</h6> {{ dataCotizacion.placaFrente }}
                    </v-col>
                    <v-col cols="4" class="d-flex align-center">
                      <h6 class="font-weight-bold my-auto mr-3">Placas Vuelta:</h6> {{ dataCotizacion.placaVuelta }}
                    </v-col>
                    <v-col cols="4" class="d-flex align-center">
                      <h6 class="font-weight-bold my-auto mr-3">Placas Delta:</h6> {{
      textDelta(dataCotizacion.placaDelta) }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row class="d-flex align-center">
                    <v-col cols="3" class="d-flex align-center">
                      <h6 class="font-weight-bold my-auto mr-3">Especialidad de Tintas:</h6>
                    </v-col>
                    <v-row style="margin: 10px 0 10px 0">
                      <v-checkbox v-model="dataCotizacion.tintaAlimenticio" label="Grado Alimenticio" />
                      <v-checkbox v-model="dataCotizacion.tintaImpermeable" label="Imperneable" class="ml-10" />
                    </v-row>
                    <v-col class="d-flex align-center">
                      <h6 class="font-weight-bold my-auto mr-3">Delta Impresión:</h6> {{
      textDelta(dataCotizacion.idTintaDelta) }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Cara
                          </th>
                          <th class="text-center">
                            Tinta Tipo
                          </th>
                          <th class="text-center">
                            Tinta
                          </th>
                          <th class="text-center">
                            Porcentaje de Tinta
                          </th>
                          <th class="text-center">
                            Proveedor
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in dataCotizacion.impresionTintaCotizacionesString">
                          <td class="text-center">{{ textCara(item.idCara) }}</td>
                          <td class="text-center">{{ item.tintaTipo }}</td>
                          <td class="text-center">{{ item.articulo }}</td>
                          <td class="text-center">{{ item.porcentajeTinta }} %</td>
                          <td class="text-center">{{ item.proveedor }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <br>
                  <h6 class="font-weight-bold my-auto mr-3">Máquina Para Impresión:</h6> {{
      dataCotizacion.impresionMaquina }}
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col class="col-6">
                      <v-checkbox v-model="dataCotizacion.barniz" label="Barniz Base Agua" readonly />
                      <v-checkbox v-model="dataCotizacion.uvPlasta" label="Barniz UV Plasta" class="my-n3" readonly />
                      <v-checkbox v-model="dataCotizacion.uvRegistro" label="Barniz UV Registro" class="my-n3"
                        readonly />
                      <v-checkbox v-model="dataCotizacion.plastificado" label="Plastificado" class="my-n3" readonly />
                      <v-checkbox v-model="dataCotizacion.suajado" label="Suajado" class="my-n3" readonly />
                    </v-col>
                    <v-col class="col-6">
                      <v-checkbox v-model="dataCotizacion.corte" label="Corte" readonly />
                      <v-checkbox v-model="dataCotizacion.empalme" label="Empalme" class="my-n3" readonly />
                      <v-checkbox v-model="dataCotizacion.pegado" label="Pegado Lineal" class="my-n3" readonly />
                      <v-checkbox v-model="dataCotizacion.pegadoGolpe" label="Pegado de golpe" class="my-n3" readonly />
                      <v-checkbox v-model="dataCotizacion.troquel" label="Troquel" class="my-n3" readonly />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <div v-if="dataCotizacion.barniz">
                    <h5 style="margin-top: 10px">Barniz Base Agua</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-12 my-col">Frente</h5>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Tipo:</h6> {{ dataCotizacion.barnizFrente }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Barniz:</h6> {{ dataCotizacion.barnizFrente2 }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{
      textDelta(dataCotizacion.barnizFrenteDelta)
    }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{
        dataCotizacion.barnizFrenteMaquina }}
                          </span>
                          <span class="col-5 my-col d-flex align-center" v-if="dataCotizacion.barnizFrenteSecado">
                            <h6 class="font-weight-bold my-auto mr-3">Secado:</h6> {{
      dataCotizacion.secadoFrente }}
                          </span>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-12 my-col">Vuelta</h5>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Tipo:</h6> {{ dataCotizacion.barnizVuelta }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Barniz:</h6> {{ dataCotizacion.barnizVuelta2 }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{
      textDelta(dataCotizacion.barnizVueltaDelta)
    }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{
        dataCotizacion.barnizVueltaMaquina }}
                          </span>
                          <span class="col-5 my-col d-flex align-center" v-if="dataCotizacion.barnizVueltaSecado">
                            <h6 class="font-weight-bold my-auto mr-3">Secado:</h6> {{
      dataCotizacion.secadoVuelta }}
                          </span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider v-if="dataCotizacion.uvPlasta" style="margin: 25px 0 25px 0;" />
                  <div v-if="dataCotizacion.uvPlasta">
                    <h5 style="margin-top: 10px">Barniz UV Plasta</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-12 my-col">Frente</h5>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Barniz:</h6> {{ dataCotizacion.uvPlastaFrente }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{
      textDelta(dataCotizacion.uvPlastaFrenteDelta)
    }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{
        dataCotizacion.uvPlastaFrenteMaquina }}
                          </span>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-12 my-col">Vuelta</h5>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Barniz:</h6> {{ dataCotizacion.uvPlastaVuelta }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{
      textDelta(dataCotizacion.uvPlastaVueltaDelta)
    }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{
        dataCotizacion.uvPlastaVueltaMaquina }}
                          </span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider v-if="dataCotizacion.uvRegistro" style="margin: 25px 0 25px 0;" />
                  <div v-if="dataCotizacion.uvRegistro">
                    <h5 style="margin-top: 10px">Barniz UV Registro</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-12 my-col">Frente</h5>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Barniz:</h6> {{ dataCotizacion.uvRegistroFrente }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{
      textDelta(dataCotizacion.uvRegistroFrenteDelta)
    }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{
        dataCotizacion.uvRegistroFrenteMaquina }}
                          </span>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-12 my-col">Vuelta</h5>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Barniz:</h6> {{ dataCotizacion.uvRegistroVuelta }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{
      textDelta(dataCotizacion.uvRegistroVueltaDelta)
    }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{
        dataCotizacion.uvRegistroVueltaMaquina }}
                          </span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider v-if="dataCotizacion.plastificado" style="margin: 25px 0 25px 0;" />
                  <div v-if="dataCotizacion.plastificado">
                    <h5 style="margin-top: 10px">Plastificado</h5>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-12 my-col">Frente</h5>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Plastificado:</h6> {{
      dataCotizacion.plastificadoFrente }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{
      textDelta(dataCotizacion.plastificadoFrenteDelta)
    }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{
        dataCotizacion.idPlastificadoFrenteMaquina
      }}
                          </span>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <h5 class="col-12 my-col">Vuelta</h5>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Plastificado:</h6> {{
        dataCotizacion.plastificadoVuelta }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{
      textDelta(dataCotizacion.plastificadoVueltaDelta)
    }}
                          </span>
                          <span class="col-5 my-col d-flex align-center">
                            <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{
        dataCotizacion.plastificadoVueltaMaquina }}
                          </span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider v-if="dataCotizacion.suajado" style="margin: 25px 0 25px 0;" />
                  <div v-if="dataCotizacion.suajado">
                    <h5 style="margin-top: 10px">Suajado</h5>
                    <v-row>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Tipo:</h6> {{
      textTipoSuaje(dataCotizacion.suajadoTipo) }}
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{ textDelta(dataCotizacion.suajadoDelta)
                        }}
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{ dataCotizacion.suajadoMaquina
                        }}
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{ dataCotizacion.suajadoMMaquina
                        }}
                      </span>
                      <v-checkbox class="col-5 my-col" v-model="dataCotizacion.suajadoTablaSuaje"
                        label="Incluir Tabla de Suaje" />
                      <v-col cols="7"></v-col>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Tabla Suaje-Base:</h6> {{
      dataCotizacion.suajeTablaBase }}
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Tabla Suaje-Altura:</h6> {{
      dataCotizacion.suajeTablaAltura }}
                      </span>
                      <v-col cols="6"></v-col>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Tabla Desbarbe-Base:</h6> {{
      dataCotizacion.suajeDesbarbeBase }}
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Tabla Desbarbe-Altura:</h6> {{
      dataCotizacion.suajeDesbarbeAltura
    }}
                      </span>
                      <v-col cols="6"></v-col>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Centímetros Suaje:</h6> {{
        dataCotizacion.suajeCentimetros }}
                        Cms
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Centímetros Matrix:</h6> {{ dataCotizacion.suajeMatrix
                        }} Cms
                      </span>
                    </v-row>
                  </div>
                  <v-divider v-if="dataCotizacion.corte" style="margin: 25px 0 25px 0;" />
                  <div v-if="dataCotizacion.corte">
                    <h5 style="margin-top: 10px">Corte</h5>
                    <v-row>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{ textDelta(dataCotizacion.corteDelta) }}
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{ dataCotizacion.corteMaquina }}
                      </span>
                    </v-row>
                  </div>
                  <v-divider v-if="dataCotizacion.empalme" style="margin: 25px 0 25px 0;" />
                  <div v-if="dataCotizacion.empalme">
                    <h5 style="margin-top: 10px">Empalme</h5>
                    <v-row>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{ textDelta(dataCotizacion.empalmeDelta)
                        }}
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{ dataCotizacion.empalmeMaquina }}Mts
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Metros:</h6> {{ dataCotizacion.empalmeMetros }}Mts
                      </span>
                    </v-row>
                  </div>
                  <v-divider v-if="dataCotizacion.pegado" style="margin: 25px 0 25px 0;" />
                  <div v-if="dataCotizacion.pegado">
                    <h5 style="margin-top: 10px">Pegado Lineal</h5>
                    <v-row>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Cms Pegado Lineal:</h6> {{ dataCotizacion.pegadoCms }}
                        Cms
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{ textDelta(dataCotizacion.pegadoDelta)
                        }}
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{ dataCotizacion.pegadoMaquina }}
                      </span>
                    </v-row>
                  </div>
                  <v-divider v-if="dataCotizacion.pegadoGolpe" style="margin: 25px 0 25px 0;" />
                  <div v-if="dataCotizacion.pegadoGolpe">
                    <h5 style="margin-top: 10px">Pegado de Golpe</h5>
                    <v-row>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Número de Esquinas:</h6> {{
      dataCotizacion.pegadoGolpeEsquinas }}
                        Cms
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{
      textDelta(dataCotizacion.pegadoGolpeDelta) }}
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{ dataCotizacion.pegadoGolpeMaquina }}
                      </span>
                    </v-row>
                  </div>
                  <v-divider v-if="dataCotizacion.troquel" style="margin: 25px 0 25px 0;" />
                  <div v-if="dataCotizacion.troquel">
                    <h5 style="margin-top: 10px">Troquel</h5>
                    <v-row>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Tipo de Troquel:</h6> {{
      textTipoTroquel(dataCotizacion.troquelTipo)
    }}
                        Cms
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Delta:</h6> {{ textDelta(dataCotizacion.troquelDelta)
                        }}
                      </span>
                      <span class="col-3 my-col d-flex align-center">
                        <h6 class="font-weight-bold my-auto mr-3">Máquina:</h6> {{
      textDelta(dataCotizacion.troquelMaquina) }}
                      </span>
                    </v-row>
                  </div>
                </v-col>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Extras:</v-list-item-title>
            <v-card v-bind="cardStyle" class="my-card">
              <v-card-text>
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Concepto
                          </th>
                          <th class="text-center">
                            Descripción
                          </th>
                          <th class="text-center">
                            Tipo
                          </th>
                          <th class="text-center">
                            Monto
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in dataCotizacion.extrasCotizaciones">
                          <td>{{ item.concepto }}</td>
                          <td>{{ item.descripcion }}</td>
                          <td>{{ item.tipo }}</td>
                          <td>{{ item.monto }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="dataCotizacion.variantes" outlined readonly label="Observaciones" />
                </v-col>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Transporte:</v-list-item-title>
            <v-card v-bind="cardStyle" class="my-card">
              <v-card-text>
                <v-row class="col-12">
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">
                              <b> Requerimientos de Transporte </b>
                            </th>
                            <th class="text-center">
                              <b> Aplica: </b>
                            </th>
                            <th class="text-center">
                              <b> Monto </b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in dataTransporte">
                            <!--<td>{{ textTransporte(item.idTransportePregunta).pregunta }}</td>
                            <td>{{ textTransporteCot(item.idTransportePregunta).respuesta ? 'Si' : 'No' }}</td>
                            <td>${{ textTransporteCot(item.idTransportePregunta).monto }}</td>-->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center">
                    <h6 class="font-weight-bold my-auto mr-3">Costo Unidad Especial: </h6>${{
                    formatearCifra(dataCotizacion.montoTransporte) }}
                  </v-col>
                  <v-col cols="6" class="d-flex align-center">
                    <h6 class="font-weight-bold my-auto mr-3">Tipo: </h6>{{
                    textTipoSumaExtra(dataCotizacion.tipoMontoTransporte) }}
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="dataCotizacion.observacionesTransporte" outlined readonly
                      label="Observaciones / Condiciones Particulares..." />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Empaque y Envío:</v-list-item-title>
            <v-card v-bind="cardStyle" class="my-card">
              <v-card-text>
                <v-row class="col-12">
                  <v-col cols="6">
                    <h5>Empaque Cliente: {{ dataCotizacion.empaqueC }}</h5>
                  </v-col>
                  <v-col cols="6">
                    <h5>Empaque Planta: {{ dataCotizacion.empaqueP }}</h5>
                  </v-col>
                  <v-col cols="6">
                    <h5>Envío a Planta: {{ dataCotizacion.envioPlanta }}</h5>
                  </v-col>
                  <v-col cols="6">
                    <h5>Tipo: {{ textTipoSumaExtra(dataCotizacion.envioPlantaTipo) }}</h5>
                  </v-col>
                  <v-col cols="6">
                    <h5>Envío a Cliente: {{ dataCotizacion.envioCliente }}</h5>
                  </v-col>
                  <v-col cols="6">
                    <h5>Tipo: {{ textTipoSumaExtra(dataCotizacion.envioClienteTipo) }}</h5>
                  </v-col>
                  <v-col cols="3">
                    <h5>Utilidad: {{ dataCotizacion.utilidad }}</h5>
                  </v-col>
                  <v-col cols="3">
                    <h5>Herramental: {{ textTipoSumaEnvio(dataCotizacion.herramental) }}</h5>
                  </v-col>
                  <v-col cols="3">
                    <h5>Preprensa: {{ textPreprensa(dataCotizacion.preprensa) }}</h5>
                  </v-col>
                  <v-col cols="3">
                    <h5>Costos Fijos: {{ textTipoSumaEnvio(dataCotizacion.costosFijos) }}</h5>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-list-item-content>
        </v-list-item>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['idOrden'],
  data() {
    return {
      cardStyle: {
        outlined: true,
        elevation: 5,
        style: {
          padding: "0px",
          width: "100%",
          border: "1px solid #C7C7C7",
        },
      },
      itemCenterStyle: {
        justify: 'center',
        'align-items': 'center',
        style: {
          height: '100%',
        },
      },

      dataCotizacion: [],
      dataTransporte: [],

      dataTipoCostoPreprensa: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Cobrar por Separado",
        }, {
          id: 3,
          value: "Mostrarlo y no Cobrarlo",
        }, {
          id: 4,
          value: "No Mostrarlo ni Cobrarlo",
        },
      ],
      dataTipoCosto: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Sumar al Costo Sin Utilidad",
        }, {
          id: 3,
          value: "Cobrar por Separado",
        }, {
          id: 4,
          value: "Mostrarlo y no Cobrarlo",
        }, {
          id: 5,
          value: "No Mostrarlo ni Cobrarlo",
        },
      ],
      dataDelta: [
        {
          id: 1, value: "Sencillo",
        }, {
          id: 2, value: "Complejo",
        }
      ],
      dataCaras: [
        {
          id: 1,
          value: "Frente"
        }, {
          id: 2,
          value: "Vuelta"
        }
      ],
      dataTipoSuaje: [
        {
          id: 1,
          value: "Con Desbarbe Automático"
        }, {
          id: 2,
          value: "Con Desbarbe Manual"
        }, {
          id: 3,
          value: "Sin Desbarbe"
        }
      ],
      dataTroquelTipo: [
        {
          id: 1,
          value: "Bobina"
        }, {
          id: 2,
          value: "Manual"
        }, {
          id: 3,
          value: "Automática"
        }
      ],
      dataSumaExtras: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Sumar al Costo sin Utilidad",
        }
      ],
      dataSumaEnvio: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Sumar al Costo Sin Utilidad",
        }, {
          id: 3,
          value: "Cobrar por Separado",
        }, {
          id: 4,
          value: "Mostrarlo y no Cobrarlo",
        }, {
          id: 5,
          value: "No Mostrarlo ni Cobrarlo",
        },
      ],
      dataPreprensaSumaEnvio: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Cobrar por Separado",
        }, {
          id: 3,
          value: "Mostrarlo y no Cobrarlo",
        }, {
          id: 4,
          value: "No Mostrarlo ni Cobrarlo",
        },
      ],

      dialog: false,
    }
  },
  methods: {
    getTransporte() {
      axios
        .get("/TransportePreguntas/Listar")
        .then((response) => {
          this.dataTransporte = response.data
        })
        .catch((error) => { console.log(error); });
    },
    getMostrarCotizacion() {
      axios
        .get(`/Orden/MostrarDatos/${this.idOrden}`)
        .then((response) => {
          if (response.status == 204) {
            this.$emit("error_mostrarCotizacion", 204);
          }
          else {
            this.dataCotizacion = response.data;
            this.getTransporte();
            this.dialog = true;
          }
        })
        .catch((error) => {
          this.$emit("error_mostrarCotizacion", error);
          this.dialog = false;
        })
    },

    formatearCifra(number, decimales = 2) {
      let numero = 0;
      if (number != null) numero = number;
      return numero.toLocaleString('en-US', { minimumFractionDigits: decimales, maximumFractionDigits: decimales });
    },

    textDelta(id) {
      if (!id || !Number.isInteger(id)) return "";
      return this.dataDelta.find((item) => item.id === id).value;
    },
    textCara(id) {
      if (!id || !Number.isInteger(id)) return "";
      return this.dataCaras.find((item) => item.id === id).value;
    },
    textTipoCosto(id) {
      if (!id || !Number.isInteger(id)) return "";
      return this.dataTipoCosto.find((item) => item.id === id).value;
    },
    textTipoSuaje(id) {
      if (!id || !Number.isInteger(id)) return "";
      return this.dataTipoSuaje.find((item) => item.id === id).value;
    },
    textTipoTroquel(id) {
      if (!id || !Number.isInteger(id)) return "";
      return this.dataTroquelTipo.find((item) => item.id === id).value;
    },
    textTipoSumaExtra(id) {
      if (!id || !Number.isInteger(id)) return "";
       return this.dataSumaExtras.find((item) => item.id === id).value;
    },
    textTipoSumaEnvio(id) {
      if (!id || !Number.isInteger(id)) return "";
       return this.dataSumaEnvio.find((item) => item.id === id).value;
    },
    textTransporte(id) {
      if (!id) return "";
      return this.dataTransporte.find((item) => item.idTransportePregunta === id);
    },
    textTransporteCot(id) {
      if (!id) return "";
      return this.dataCotizacion.transporte.find((item) => item.idTransportePregunta === id);
    },
    textPreprensa(id) {
      if (!id) return "";
      return this.dataPreprensaSumaEnvio.find((item) => item.id === id).value;
    },
    /*textTipoCostoPreprensa(id) {
      return this.dataTipoCostoPreprensa.find((item) => item.id === id).value;
    },*/

    hide_dialog() {
      Object.assign(this.dataCotizacion, []);
      this.dialog = false;
    },
  },
}
</script>

<style scoped>
.my-card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 15px;
  width: 100%;
}

.my-col {
  padding: 12px 12px 12px 12px !important;
}

.my-title {
  color: #5c5c5c;
  margin-top: 50px;
  padding-bottom: 10px;
  width: 100%;
}

.padding-hr {
  padding: 0 0 15px 0;
}

.boton {
  height: 50px;
  width: 40%;
}

.selectCosto {
  width: auto;
  height: 10px;
  margin: auto auto auto -5px;
}
</style>