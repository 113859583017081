<template>
    <div>
      <v-col cols="12" class="pt-0">
        <v-row>
          <v-col cols="3" v-if="false">
            <datePicker
              v-model="filtros.desde"
              format="YYYY-MM-DD"
              :maxDate="filtros.hasta"
              label="Desde (Obligatorio)"
              clearable
              maxToday
              @input="getODTS()"
              :disabled="cargando"
            ></datePicker>
          </v-col>
          <v-col cols="3" v-if="false">
            <datePicker
              v-model="filtros.hasta"
              format="YYYY-MM-DD"
              :minDate="filtros.desde"
              label="Hasta (Obligatorio)"
              clearable
              @input="getODTS()"
              :disabled="cargando"
            ></datePicker>
          </v-col>
          <v-col cols="12">
            <div class="d-flex align-center">
              <datePicker v-model="filtros.desde" format="YYYY-MM-DD" :maxDate="filtros.hasta" label="Desde (Obligatorio)"
                clearable maxToday @input="getODTS()" :disabled="cargando" class="mx-4"/>

              <datePicker v-model="filtros.hasta" format="YYYY-MM-DD" :minDate="filtros.desde" label="Hasta (Obligatorio)"
                clearable @input="getODTS()" :disabled="cargando" class="mx-4"/>

              <v-autocomplete v-model="filtros.odtsSeleccionadas" :items="odts" item-text="clave" item-value="idTrabajoOrden" 
                label="ODT(s)" clearable multiple chips :disabled="!filtros.desde || !filtros.hasta" :loading="loadingODTS" class="mx-4"/>

                <v-checkbox v-model="filtros.fechaEntrega" label="Usar fecha de entrega" @change="getODTS()"/>

              <v-btn color="primary" @click="getData()" :loading="loadingData" class="mx-4">Buscar</v-btn>
              <v-btn color="primary" :loading="loadingData" class="mx-4" @click="getXlsPasosReceta()">Generar Excel</v-btn>
            </div>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              v-if="false"
              color="primary"
              :disabled="disabledBtnRefresh"
            >
              <v-icon left>mdi-refresh</v-icon> Refrescar
            </v-btn>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="3" sm="12">
                <v-card class="fill-height d-flex">
                  <v-card-text class="text-center align-self-center">
                    <div class="subtitle-1 font-weight-bold">
                      Kilogramos Usados
                    </div>
                    <!-- :endVal="estadisticas.montoTotalEstimadoProduccion" -->
                    <ICountUp
                      v-if="loadingEstadisticas"
                      :delay="1000"
                      :endVal="this.totales.totalKg"
                      class="subtitle-1 font-weight-medium"
                      :options="{
                        decimalPlaces: 2,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                      }"
                    />
                    <div v-else class="text-center">
                      <v-progress-circular
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-card class="fill-height d-flex">
                  <v-card-text class="text-center align-self-center">
                    <div class="subtitle-1 font-weight-bold">
                      Cantidad de Laminas
                    </div>
                    <!-- :endVal="estadisticas.montoTotalEstimadoProduccion" -->
                    <ICountUp
                      v-if="loadingEstadisticas"
                      :delay="1000"
                      :endVal="this.totales.totalLaminas"
                      class="subtitle-1 font-weight-medium"
                      :options="{
                        decimalPlaces: 2,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                      }"
                    />
                    <div v-else class="text-center">
                      <v-progress-circular
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-card class="fill-height d-flex">
                  <v-card-text class="text-center align-self-center">
                    <div class="subtitle-1 font-weight-bold">
                      Piezas Fabricadas
                    </div>
                    <ICountUp
                      v-if="loadingEstadisticas"
                      :delay="1000"
                      :endVal="this.totales.totalPiezas"
                      class="subtitle-1 font-weight-medium"
                      :options="{
                        decimalPlaces: 2,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                      }"
                    />
                    <div v-else class="text-center">
                      <v-progress-circular
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12" sm="12" v-if="cargando">
            <v-progress-linear
              color="primary"
              height="5"
              indeterminate
            ></v-progress-linear>
          </v-col>
          <v-col v-else cols="12" md="12" sm="12">
            <!-- <apexchart
              type="bar"
              height="350"
              :options="chartOptions"
              :series="series"
            ></apexchart> -->
            <apexchart
              type="bar"
              height="500"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th v-for="(item, index) in headers" :key="index" class="text-center" style="min-width: 400px;" v-if="index % 2 === 0">
                <span class="text-h6">{{ item.text }}</span>
                <div class="subheaders" >
                  <div class="d-flex flex-column align-items-center">
                    <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">M.P. Teórica</th>
                    <th class="text-center">
                      
                      {{ (Number(items[index][0]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                    </th>
                  </div>
                  <div class="d-flex flex-column align-items-center">
                    <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">M.P. Real</th>
                    <th class="text-center">
                      {{ (Number(items[index+1][3]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                    </th>
                  </div>
                  <div class="d-flex flex-column align-items-center">
                    <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">Merma Teórica</th>
                    <th class="text-center">
                      {{ (Number(items[index][2]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                    </th>
                  </div>
                  <div class="d-flex flex-column align-items-center">
                    <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">Merma Real</th>
                    <th class="text-center">
                      {{ (Number(items[index+1][5]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                    </th>
                  </div>
                  <div class="d-flex flex-column align-items-center">
                    <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">Scrap Teórico</th>
                    <th class="text-center">
                      {{ (Number(items[index][1]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} 
                    </th>
                  </div>
                  <div class="d-flex flex-column align-items-center">
                    <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">Scrap Real</th>
                    <th class="text-center">
                      {{ (Number(items[index+1][4]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                    </th>
                  </div>
                </div> 
              </th>
            </tr>
          </thead>
        </v-simple-table>
      </v-col>
      <v-col cols="12" v-if="false">
        {{ itemsODT }}
        <v-data-table :headers="headersODT" :items="itemsODT" item-value="idTrabajoOrden" item-key="idTrabajoOrden">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.idTrabajoOrden }}</td>
              <td>{{ props.item.area }}</td>
              <td>{{ props.item.tipo }}</td>
              <td>{{ props.item.mp }}</td>
              <td>{{ props.item.merma }}</td>
              <td>{{ props.item.scrap }}</td>
            </tr>
          </template>
          <template v-slot:item.conceptosResultados="{ item }" v-if="false">
            <v-row>
              <v-col v-for="concepto in item.conceptosResultados" :key="concepto.idArea" class="d-flex flex-column align-items-center">
                <div class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">{{ concepto.area }}</div>
                <div class="text-center"></div>
                <div>M.P. Teórica: {{ concepto.teorico.mp.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div>Merma Teórica: {{ concepto.teorico.merma.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div>Scrap Teórico: {{ concepto.teorico.scrap.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div>M.P. Real: {{ concepto.real.mp.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div>Merma Real: {{ concepto.real.merma.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div>Scrap Real: {{ concepto.real.scrap.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>

    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center border-right text-h6" style="min-width: 200px;">ODT</th>
          <th class="text-center border-right text-h6" style="min-width: 200px;">Artículo</th>
          <th class="text-center border-right text-h6" style="min-width: 300px;">Totales</th>
          <!-- Generar columnas dinámicas -->
          <th v-for="(area, index) in dynamicColumns" :key="area.idArea" style="min-width: 500px;" class="text-center text-h6" :class="{'border-right': index !== dynamicColumns.length - 1}">{{ area.area }}</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>
            <v-row justify="center">
              <v-col v-for="subheader in dynamicTotalsColumns" :key="subheader" class="text-center border-bottom d-flex align-center justify-center">
                {{ subheader.nombre }}
              </v-col>
            </v-row>
          </th>
          <!-- Subheaders -->
          <th v-for="(header, index) in dynamicColumns" :key="'sub-' + index" class="text-center">
            <v-row justify="center">
              <v-col v-for="subheader in subHeaders" :key="subheader" class="text-center border-bottom d-flex align-center justify-center">
                {{ subheader.nombre }}
              </v-col>
            </v-row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in tableData" :key="index">
          <td class="text-center border-right">{{ row.odtClave }}</td>
          <td class="text-center border-right">{{ row.articulo }}</td>
          <td class="text-center border-right">
            <v-row justify="center">
              <v-col v-for="totalColumn in dynamicTotalsColumns" :key="subheader" class="text-center">
                {{ (Number(row.conceptosResultados.reduce((sum, item) => sum + valueSubHeaderData(item, "totalesLaminas", totalColumn.valor), 0)).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
              </v-col>
            </v-row>
          </td>
          <!-- Generar celdas dinámicas -->
          <td  v-for="(header, idx) in dynamicColumns" :key="idx" class="text-center border-right">
            <v-row justify="center">
              <v-col v-for="subheader in subHeaders" :key="subheader" class="text-center">
                {{ (Number(valueSubHeaderData(row.conceptosResultados.find(item => item.area == header.area), subheader.tipo, subheader.valor)).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
              </v-col>
            </v-row>
          </td>
        </tr>
        <tr>
      <td colspan="3" class="text-center text-h6">
        Totales:
      </td>
        <!-- Celdas dinámicas -->
        <td v-for="(header, idx) in dynamicColumns" :key="'footer-' + idx" class="text-center text-h6">
          <v-row justify="center">
              <v-col v-for="subheader in subHeaders" :key="subheader" class="text-center">
                {{ (Number(valueSubHeaderData(totalesRealTeorico.find(item => item.idArea == header.idArea), subheader.tipo, subheader.valor)).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
              </v-col>
            </v-row>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
    
    <script>
  import axios from "axios";
  import ICountUp from "vue-countup-v2";
  /* 
    import { mapState } from "vuex"; */
  /* import DownloadBtn from "../../../DownloadBtn.vue"; */
  
  export default {
    components: {
      ICountUp,
    },
    data() {
      return {
        loadingEstadisticas: true,
        cargando: false,
        filtros: {
          desde: null,
          hasta: null,
          fechaEntrega: false,
        },
        categories: [],
        series: [],
        loadingMaquinas: false,
        apex: {
          kgUtilizados: 0,
          cantidadLaminas: 0,
          pzaFabricadas: 0,
        },
        colors: [
          '#008FFB',
          '#00E396',
          '#FEB019',
          '#FF4560',
          '#775DD0',
          '#546E7A',
          '#26a69a',
          '#D10CE8'
        ],

        headersODT1: [
          {
            text: "ODT",
            value: "idTrabajoOrden",
            align: "center",
            sortable: false,
            visible: true,
          }, {
            text: "Materia Prima",
            value: "mp",
            align: "center",
            sortable: false,
            visible: true,
          }, {
            text: "Merma",
            value: "merma",
            align: "center",
            sortable: false,
            visible: true,
          }, {
            text: "Scrap",
            value: "scrap",
            align: "center",
            sortable: false,
            visible: true,
          }
        ],
        headersODT: [
        { text: 'ID Trabajo Orden', value: 'idTrabajoOrden' },
        { text: 'Área', value: 'area' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Materia Prima (MP)', value: 'mp' },
        { text: 'Merma', value: 'merma' },
        { text: 'Scrap', value: 'scrap' }
      ],

        // itemsODT: [],
        itemsODT: [
        { text: 'ID Trabajo Orden', value: 'idTrabajoOrden' },
        { text: 'Área', value: 'area' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Materia Prima (MP)', value: 'mp' },
        { text: 'Merma', value: 'merma' },
        { text: 'Scrap', value: 'scrap' }
      ],

        odts: [],

        loadingODTS: false,
        loadingData: false,

        dynamicColumns: [], // Aquí puedes agregar más columnas dinámicas
      subHeaders: [
        {
          nombre: 'M.P. Teórica',
          valor: "mp",
          tipo: "teorico",
        }, {
          nombre: 'M.P. Real',
          valor: "mp",
          tipo: "real",
        }, {
          nombre: 'Merma Teórica',
          valor: "merma",
          tipo: "teorico",
        }, {
          nombre: 'Merma Real',
          valor: "merma",
          tipo: "real",
        }, {
          nombre: 'Scrap Teórico',
          valor: "scrap",
          tipo: "teorico",
        }, {
          nombre: 'Scrap Real',
          valor: "scrap",
          tipo: "real",
        }
      ],
      dynamicTotalsColumns: [
        {
          nombre: 'Kilos',
          valor: "totalKg",
          tipo: "teorico",
        }, {
          nombre: 'Laminas',
          valor: "totalLaminas",
          tipo: "real",
        }, {
          nombre: 'Piezas',
          valor: "totalPiezas",
          tipo: "teorico",
        },
      ],
      tableData: [
        // {
        //   odt: 'ODT001',
        //   articulo: 'Artículo 1',
        //   cantidad: 10,
        //   // 'Columna 1': { Merma: 2, Scrap: 1, 'Materia Prima': 7 },
        //   // 'Columna 2': { Merma: 3, Scrap: 2, 'Materia Prima': 5 },
        // },
        // {
        //   odt: 'ODT002',
        //   articulo: 'Artículo 2',
        //   cantidad: 20,
          // 'Columna 1': { Merma: 4, Scrap: 3, 'Materia Prima': 13 },
          // 'Columna 2': { Merma: 2, Scrap: 1, 'Materia Prima': 17 },
        // },
      ],
      totales: [],
      totalesRealTeorico: [],
      };
    },
    mounted() {
      this.initialize();
    },
    computed: {
      disabledBtnRefresh(){
        return this.cargando || Object.values(this.filtros).some(value => value == null);
      },
      chartOptions() {
        return {
          colors: this.colors,
          states: {
            active: {
              allowMultipleDataPointsSelection: false,
            },
          },
          chart: {
            type: "bar",
            height: 500,
            barWidth: '10',
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "100%",
              dataLabels: {
                total: {
                  enabled: true,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              return val.toFixed(2)+' Kg';
            }
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: this.categories,
             labels: {
              maxHeight: 200,
            }
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(2)+' Kg';
              }
            }
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2)+' Kg';
              },
            },
          },
        };
      },
    },
    methods: {
      initialize() {
        this.getInfo();
      },
      getInfo() {  //Función que conecta con el end point para obtener datos a mostrar en tabla. 
        return;
        this.headers = [];
      this.items = [];
        if (this.filtros.desde == null) return;
        if (this.filtros.hasta == null) return;

        this.cargando = true;
        this.loadingEstadisticas = false;
        axios
          .get(`/Reportes/ReporteGeneralFlujo/`, {
            params: {
                desde: this.filtros.desde,
                hasta: this.filtros.hasta,
            },
          })
          .then((response) => {
            this.categories = response.data.categorias;
            this.series = response.data.series;
            this.apex.kgUtilizados = response.data.kgUtilizados;
            this.apex.cantidadLaminas = response.data.cantidadLaminas;
            this.apex.pzaFabricadas = response.data.pzaFabricadas;

            let dataTable = response.data.series;
            let categoriasTable = response.data.categorias;
            this.tableGenerate(dataTable, categoriasTable);

            this.cargando = false;
            this.loadingEstadisticas = true;
          })
          .catch((error) => {
            console.log(error);
            this.cargando = false;
            this.loadingEstadisticas = true;
          });
      },

      getODTS() {
        if (this.filtros.desde == null || this.filtros.hasta == null) return;
        
        this.filtros.odtsSeleccionadas = null;
        this.loadingODTS = true;
        // var url = `/TrabajoOrdenes/TrabajoOrdenHeaders?fechaEntrega=${this.filtros.fechaEntrega}&soloEnProgreso=false&desde=${this.filtros.desde}&hasta=${this.filtros.hasta}`;
        let params = new URLSearchParams();
        params.append("desde", this.filtros.desde);
        params.append("hasta", this.filtros.hasta);
        params.append("fechaEntrega", this.filtros.fechaEntrega);

        axios
          .get('/Reportes/ODTsEnPeriodoFechas', {params})
          .then((response) => {
            this.odts = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loadingODTS = false;
          });
      },
      getData() {
        if (this.filtros.desde == null || this.filtros.hasta == null) return;

        let params = new URLSearchParams();
        if (this.filtros.odtsSeleccionadas && this.filtros.odtsSeleccionadas.length > 0) {
          this.filtros.odtsSeleccionadas.forEach(odt => {
            params.append("odts", odt);
          });
        }
        
        params.append("desde", this.filtros.desde);
        params.append("hasta", this.filtros.hasta);
        params.append("fechaEntrega", this.filtros.fechaEntrega);

        // let odts = this.filtros.odtsSeleccionadas;
        this.loadingData = true;
        this.loadingEstadisticas = false;
        axios
        .get(`/Reportes/ReporteGeneralFlujo`, {params})
        .then(response => {
          this.itemsODT = response.data.resultadosTabla.flatMap((item) => {
            return item.conceptosResultados.flatMap((concepto) => [
              {
                idTrabajoOrden: item.idTrabajoOrden,
                area: concepto.area,
                tipo: "Real",
                mp: concepto.real.mp,
                merma: concepto.real.merma,
                scrap: concepto.real.scrap
              },
              {
                idTrabajoOrden: item.idTrabajoOrden,
                area: concepto.area,
                tipo: "Teórico",
                mp: concepto.teorico.mp,
                merma: concepto.teorico.merma,
                scrap: concepto.teorico.scrap
              }
            ]);
          });

          this.categories = response.data.apexChartDoubleSuma.categorias;
          this.series = response.data.apexChartDoubleSuma.series;
          this.dynamicColumns = response.data.areasUsadas;
          this.tableData = response.data.resultadosTabla;
          this.totales = response.data.totalesLaminas;
          this.totalesRealTeorico = response.data.totalesPorArea
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingData = false;
          this.loadingEstadisticas = true;
        });
      },
      async getXlsPasosReceta() {
        if (this.filtros.desde == null || this.filtros.hasta == null) return;

        let params = new URLSearchParams();
        if (this.filtros.odtsSeleccionadas != (null && undefined && [])) {
          this.filtros.odtsSeleccionadas.forEach(odt => {
        params.append("odts", odt);
          });
        }
        params.append("desde", this.filtros.desde);
        params.append("hasta", this.filtros.hasta);
        params.append("fechaEntrega", this.filtros.fechaEntrega);

        try {
        await this.$utils.axios.downloadFile(
        `/Reportes/XLSReporteFlujo?${params.toString()}`,
        "GET",
        "Pasos.xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        } catch (error) {
          console.log(error);
        } 
      },

      tableGenerate(dataTable, categoriasTable) {
        let newHeaders = [];
        let newData = [];
        const series = ["M.P Teorica", "Scrap Teorica", "Merma Teorica", "M.P Real", "Scrap Real", "Merma Real"]



        const filteredArray = categoriasTable.filter(item => !item.toLowerCase().includes("total"));
        const areas = filteredArray.map(item => item.split(" - ")[0]);
        const uniqueAreas = [...new Set(areas)];
        // const uniqueAreas = [...new Set(categoriasTable)];
        const toCamelCase = (str) => {
          return str
            .split(" ")
            .map((word, index) => {
              if (index === 0) return word.toLowerCase();
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join("");
        };

        uniqueAreas.forEach((area, index) => {
        newHeaders.push({
          text: area,
          value: area.toLowerCase().replace(/\s+/g, ''),
          align: "center",
          sortable: false
        });

        // Si no es el último elemento, agregar un objeto vacío
        if (index < uniqueAreas.length - 1) {
          newHeaders.push({});
        }
});

        let info = dataTable.map(item => item.data);
        const summed = info[0].map((_, i) => 
          info.reduce((sum, row) => sum + row[i], 0)
        );
        const transpuesta = info[0].map((_, i) => info.map(fila => fila[i]));

        this.items = transpuesta;
        this.items2 = info
        this.headers = newHeaders;
      },
      valueData(item, idx){
        let valores = Object.values(item);
        return valores[idx]
      },
      valueSubHeaderData (items, tipo, valor) {
        console.log(items)
        try {
          return items[tipo][valor];
        } catch (error) {
          return 0;
        }
      }
    },
  };
  </script>
<style>
  .v-autocomplete .v-text-field__details {
    display: none;
  }

  .border-right {
    border-right: 1px solid #ccc; /* Borde de 1px gris a la derecha */
  }

  .subheaders {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
  }
  
  .subheader {
    text-align: center;
    font-size: 12px;
    margin: 0 80px 0 80px;
    color: #616161;
  }
</style>