<template>
  <v-card>
    <v-tabs v-model="tab" style="width: 100%;">
      <v-tab href="#tab-1" class="flex-grow-1" style="max-width: 33%;" @click="initMermas()">
        Mermas
      </v-tab>
      <v-tab href="#tab-2" class="flex-grow-1" style="max-width: 33%;" @click="initTiempos()">
        Tiempos
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" :value="'tab-1'">
          <v-card>
            <Mermas ref="Mermas" class="my-tab" />
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" :value="'tab-2'">
          <v-card>
            <Tiempos ref="Tiempos" class="my-tab" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import Mermas from './Procesos/Mermas.vue';
import Tiempos from './Procesos/Tiempos.vue'

export default {
  components: {
    Mermas,
    Tiempos,
  },
  data() {
    return {
      tab: null,
    }
  },
  methods: {
    initMermas() {
      this.$refs.Mermas?.initialize();
    },
    initTiempos() {
      this.$refs.Tiempos?.initialize();
    },
  },
}
</script>
<style scoped>
.my-tab {
  padding: 15px 20px 0 20px;
}
</style>