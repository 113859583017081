<template>
    <div>
      <v-row cols="12">
        <v-col cols="4">
          <v-text-field
            label="Folio Remisión"
            item-text="nombre"
            item-value="idArea"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            :items="dataClientes"
            :item-text="nombreCliente"
            item-value="idCliente" 
            clearable
            label="Cliente" 
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
         <datePicker
            clearable
            format="YYYY-MM-DD"
            label="Fecha Inicio">
         </datePicker>
        </v-col>
        <v-col cols="4">
         <datePicker
          clearable
          format="YYYY-MM-DD"
          label="Fecha Final">
         </datePicker>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            label="Estatus"
            :item-text="nombrePersona"
            item-value="idPersona"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
            <v-btn color="primary">Buscar</v-btn>
        </v-col>
        <v-col cols="6">
        <v-btn color="primary" @click="abrirRemision()">Generar Remisión</v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="dataPreOrdenes" :search="search" :loading="loadingTable">
            <template v-slot:[`item.monto`]="{ item }">
              $ {{ formatearCifra(item.monto) }}
            </template>
            <template v-slot:[`item.piezas`]="{ item }">
              {{ formatearCifra(item.piezas, 0) }} {{ item.unidad }}
            </template>
            <template v-slot:[`item.receta`]="{ item }">
              <v-tooltip v-if="item.receta" bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success" v-bind="attrs" v-on="on">
                    mdi-check-bold
                  </v-icon>
                </template>
                <span class="white--text">Receta creada</span>
              </v-tooltip>
              <v-tooltip v-else bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="orange" v-bind="attrs" v-on="on">
                    mdi-clock-time-eight
                  </v-icon>
                </template>
                <span class="white--text">Sin Receta</span>
              </v-tooltip>
            </template>
            <!-- <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="red darken-1" icon :loading="loadingReceta">
                    <v-icon v-on="on" v-bind="attrs">mdi-file-download</v-icon>
                  </v-btn>
                </template>
                <span class="white--text">Descargar PDF </span>
              </v-tooltip>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
      <v-dialog v-model="remisiones" max-width="1000px" persistent> <!--Diálogo para la creación de remisiones-->
        <v-card>
          <v-card-title>
            <span class="headline ml-2">Generar Remisión</span>    
            <v-spacer></v-spacer>
            <v-icon @click="cerrarRemisiones()">mdi-close</v-icon>
          </v-card-title> 
          <v-card-text>
              <v-form ref="form">
                <v-row>
                <v-col>
                    <div class="ml-2" style="display: flex;">
                    <h5>Agregar Artículo para Remisión</h5>
                    </div>
                    <v-row no-gutters cols="12">
                    <v-col cols="4" class="px-2 mt-2">
                        <v-autocomplete
                        v-model="dataRemisiones.selecttCliente"
                        :items="dataClientes"
                        :item-text="nombreCliente"
                        item-value="idCliente"
                        clearable
                        v-bind="propsAutocompletee"
                        label="Cliente"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="px-2 mt-2">
                        <v-autocomplete v-model="dataRemisiones.pedido"
                        item-text="text"
                        item-value="value"
                        v-bind="propsAutocompletee"
                        label="Pedidos"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="px-2 mt-2">
                        <v-select v-model="dataRemisiones.tipo"
                        :items="dataTipo"
                        item-text="value"
                        item-value="id"
                        v-bind="propsAutocompletee"
                        label="Tipo"
                        ></v-select>
                    </v-col>
                    <v-col cols="4" class="px-2">
                        <v-autocomplete v-model="dataRemisiones.producto"
                        item-text="text"
                        item-value="value"
                        v-bind="propsAutocompletee"
                        label="Productos"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="px-2">
                        <v-text-field v-model="dataRemisiones.cantidad"
                        v-bind="inputNumberProps"
                        label="Cantidad"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="px-2">
                        <v-text-field v-model="dataRemisiones.cantidadDisponible"
                        v-bind="inputNumberProps"
                        label="Cantidad Disponible"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="px-2">
                        <v-text-field v-model="dataRemisiones.pesoNeto"
                        v-bind="inputNumberProps"
                        label="Peso Neto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="px-2">
                        <v-text-field v-model="dataRemisiones.pesoBruto"
                        v-bind="inputNumberProps"
                        label="Peso Bruto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-btn text color="primary" @click="eliminar">
                        Agregar
                        </v-btn> 
                    </v-col>
                        <v-col cols="12" >
                          <div class="ml-2" style="display: flex;">
                          <h5>Detalle Pedido</h5>
                          </div>
                        </v-col> 
                        <v-col cols="4">
                          <v-autocomplete class="px-2"
                          v-model="dataRemisiones.pedido"
                          v-bind="propsAutocompletee"
                          label="Pedidos"
                          ></v-autocomplete>
                        </v-col>
                    </v-row>
                    
                <!--La siguiente tabla solo se muestra cuando se introduce información en el segundo campo de "Pedidos"-->
                <v-data-table v-if="dataRemisiones.pedido.length > 0" :headers="headersPedidos"></v-data-table>
                </v-col>

                 </v-row>
                    <div v-if="dataRemisiones.selecttCliente != null" class="ml-2" style="display: flex;">
                    <h5>Bandeja para Generar Remisión</h5>
                    </div>
                    <!--El div de arriba y lo contenido en el row de abajo solo aparece cuando se selecciona un cliente-->
                    <v-row cols="12" no-gutters v-if="dataRemisiones.selecttCliente != null">
                    <v-col cols="3" class="px-2 mt-2">
                      <datePicker
                      v-model="dataRemisiones.fechaRemision"
                      outlined
                      dense
                      maxToday
                      clearable
                      format="YYYY-MM-DD"
                      label="Fecha Remisión">
                      </datePicker>
                    </v-col>
                    <v-col cols="3" class="px-2 mt-2">
                      <v-text-field v-model="dataRemisiones.conducto"
                      dense
                      outlined
                      label= "Conducto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="px-2 mt-2">
                      <v-text-field v-model="dataRemisiones.pesoBrutoKG"
                      dense
                      outlined
                      label= "Peso Bruto (KG)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="px-2 mt-2">
                      <v-text-field v-model="dataRemisiones.tipoCambio"
                      dense
                      clearable
                      outlined
                      label= "Tipo Cambio"
                      ></v-text-field>
                      <v-btn color="primary" text @click="abrirConvertidor()">Valor dólar</v-btn>
                    </v-col>
                    <v-col cols="6" class="px-2 mt-2">
                      <v-textarea v-model="dataRemisiones.comentarios"
                      dense
                      outlined
                      label= "Comentarios"
                      ></v-textarea>
                    </v-col>
                    <v-data-table 
                      v-model="selected"
                      :headers="headersRemision" 
                      :items="dataClientes"
                      :items-per-page="5"
                      :single-select="singleSelect"
                      item-key="idCliente"
                      show-select
                      hide-default-footer
                      class="elevation-1">
                      <template v-slot:[`item.actions`]="{ item }">
                       <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on" @click="deleteItem(item)">delete</v-icon>
                        </template>
                        <span class="white--text">Eliminar</span>
                       </v-tooltip>
                    </template>
                      <!-- <template v-slot:[`item.actions`]="{ item }">
                       <v-tooltip>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" elevation="0" small color="red">Eliminar</v-btn>
                        </template>
                       </v-tooltip>
                      </template> -->
                    </v-data-table>
                  </v-row>
              </v-form>
          </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="conversion" max-width="600px" persistent>  <!--Pa obtener y mostrar los valores del dólar dado un rango de fechas-->    
        <v-card>
            <v-card-title>
                <span class="headline">Tasas</span>
            </v-card-title>
            <v-card-text>
               <v-row cols="12">
                <!-- <v-col cols="4" class="px-2">
                  <v-text-field v-model="importe"
                    clearable
                    v-bind="inputNumberProops"
                    label="Importe">
                  </v-text-field>
                </v-col> -->
                <!-- <v-col cols="3" class="px-2">
                  <v-select v-model="monedaOrigen"
                    outlined
                    
                    label="De:"
                    :items="modenasCambio">
                  </v-select>
                </v-col>
                <v-col cols="1" class="d-flex justify-center">
                    <v-btn color="primary" icon>
                        <v-icon @click="swapMonedas()">mdi-swap-horizontal</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="3" class="px-2">
                  <v-select v-model="monedaCambio"
                    outlined
                    
                    label="a:"
                    :items="modenasCambio">
                  </v-select>
                </v-col> -->
                <v-col cols="6">
                    <datePicker
                      v-model="fechaInicio"
                      outlined
                      v-bind="inputTextProps"
                      dense
                      clearable
                      format="YYYY-MM-DD"
                      label="Desde">
                    </datePicker>
                </v-col>
                <v-col cols="6">
                    <datePicker 
                      v-model="fechaFin"
                      outlined
                      v-bind="inputTextProps"
                      dense
                      clearable
                      format="YYYY-MM-DD"
                      label="Hasta">
                    </datePicker>
                </v-col>
                <!-- <v-col cols="8" class="px-2">
                    <v-textarea
                      v-model="observacionesCambio"
                      outlined
                      rows="4"
                      label="Observaciones">
                    </v-textarea>
                </v-col> -->
                <v-col cols="12" v-if="valorDolar.length > 0">
                  <v-data-table
                  :items="valorDolarFormateado"
                  :headers="headersDolar"
                  hide-default-footer
                  ></v-data-table>
                </v-col>
               </v-row>
            </v-card-text>
            <v-card-actions> 
              <v-btn color="error" text class="ml-auto" @click="cerrarConvertidor()">
               Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click=cambioMonedas()>
               Consultar
              </v-btn>
            </v-card-actions>  
        </v-card>
    </v-dialog>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import datePicker from "@/components/DatePicker";
  
  export default {
    components: {
      datePicker
    },
    props: {
      // modeDetalle: {
      //   type: Boolean,
      //   default: false
      // },
      // modeSelect: {
      //   type: Boolean,
      //   default: false
      // },
      operaciones_items_per_page: {
        type: Number,
        default: 5
      },
      // idTrabajoOrden: {
      //   type: Number,
      //   default: 0,
      // },
      // idArea: {
      //   type: Number,
      //   default: 0,
      // },
    },
    data() {
      return {
        singleSelect: false,
        selected: [],
        // propsSearch: {
        //   clearable: true,
        //   class: "v-input__slot",
        //   label: "Buscar",
        //   clearIcon: 'mdi-close-circle',
        //   appendIcon: 'mdi-magnify',
        //   hideDetails: true,
        //   dense: true,
        // },

        nombreCliente(lista) {
        if (lista.clave) {
        return `[${lista.clave}] - ${lista.nombre}`;
        } else {
        return lista.nombre;
        }
        },

        propsAutocompletee: {   //pa remisiones
        outlined: true,
        dense: true,
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
        },
        inputTextProps: {
         required: true,
         rules: [
         (v) => !!v || "Dato Requerido"
         ],
        },
        inputNumberProps: {  //usado en algunos campos del formulario de remisiones
            outlined: true,
            dense: true,
            type: "number",
            inputmode: "numeric",
            required: true,
            rules: [
              (v) => {
                if (v === null || v === "") {
                  return "Dato requerido";
                } else if (!Number.isFinite(Number(v))) {
                  return "Ingrese solo números";
                } else if (Number(v) <= 0) {
                  return "Ingrese un número mayor a 0";
                }
                return true;
              },
            ],
          },
          propsAutocompletee: {  //pa remisiones
              outlined: true,
              dense: true,
              required: true,
              rules: [
                (v) => !!v || "Dato Requerido"
              ],
          },

        search: '',
        headers: [
          {
            text: "Remisión",
            value: "idCotizacion",
            align: "center",
            sortable: false,
          }, {
            text: "Referencia",
            value: "fechaCreacion",
            align: "center",
            sortable: false,
          }, {
            text: "Pedido",
            value: "rfc",
            align: "center",
            sortable: false
          }, {
            text: "Orden Producción",
            value: "nombreTrabajo",
            align: "center",
            sortable: false
          }, {
            text: "Cliente",
            value: "nombre",
            align: "center",
            sortable: false,
          }, {
            text: " ",
            value: "monto",
            align: "center",
            sortable: false,
           },  
           {
            text: "Importe",
            value: "",
            align: "center",
            sortable: false,
          }, {
            text: "Moneda",
            value: "",
            align: "center",
            sortable: false,
          }, {
            text: "Fecha Faturación",
            value: "",
            align: "center",
            sortable: false,
          }, {
            text: "PDF Remisión",
            value: "acciones",
            align: "center",
            sortable: false,
          }, {
            text: "Estatus",
            value: "",
            align: "center",
            sortable: false,
          }, {
            text: " ",
            value: "acciones",
            align: "center",
            sortable: false,
          }
        ],
        headersDolar: [
          {text: "Fecha",
           value: "fecha",
           align: "center",
           sortable: false,
          },{
            text: "Valor",
            value: "valor",
            align: "center",
            sortable: false
          }
        ],
        dataClientes: [],
  
        loadingTable: false,
        loadingReceta: false,

        headersPedidos: [

          {
            text: "Autorizado",
            value: "autorizado",
            align: "center",
            sortable: false
          }, 
          {
            text: "Pedido",
            value: "pedido",
            align: "center",
            sortable: false,
          },{
            text: "Cliente",
            value: "idCotizacion",
            align: "center",
            sortable: false,
          }, {
            text: "Producto",
            value: "fechaCreacion",
            align: "center",
            sortable: false,
          }, {
            text: "Observaciones",
            value: "cliente",
            align: "center",
            sortable: false
          }, {
            text: "Orden Producción",
            value: "nombreTrabajo",
            align: "center",
            sortable: false
          }, {
            text: "Precio Unidad",
            value: "piezas",
            align: "center",
            sortable: false,
          },
          {
            text: "Descuento",
            value: "idCotizacion",
            align: "center",
            sortable: false,
          }, {
            text: "Cantidad Solicitada",
            value: "fechaCreacion",
            align: "center",
            sortable: false,
          }, {
            text: "Cantidad Enviada",
            value: "cliente",
            align: "center",
            sortable: false
          }, {
            text: "Moneda",
            value: "nombreTrabajo",
            align: "center",
            sortable: false
          }
        ],

        headersRemision: [
          {
            text: "Orden_folio",
            value: "pedido",
            align: "center",
            sortable: false,
          }, {
            text: "Pedido",
            value: "idCotizacion",
            align: "center",
            sortable: false,
          }, {
            text: "Observaciones",
            value: "rfc",
            align: "center",
            sortable: false,
          }, {
            text: "Clave",
            value: "idCliente",
            align: "center",
            sortable: false
          }, {
            text: "Cantidad",
            value: "nombreTrabajo",
            align: "center",
            sortable: false
          }, {
            text: "Peso Unidad",
            value: "nombre",
            align: "center",
            sortable: false,
          },{
            text: "Peso Total",
            value: "idCotizacion",
            align: "center",
            sortable: false,
          }, {
            text: "Moneda",
            value: "fechaCreacion",
            align: "center",
            sortable: false,
          }, {
            text: "Material",
            value: "cliente",
            align: "center",
            sortable: false
          }, {
            text: "Acciones",
            value: "actions",
            align: "center",
            sortable: false
          }, {
            text: "Fecha Salida",
            value: "fecha",
            align: "center",
            sortable: false
          }
        ],

        dataTipo: [ //pa campo en formulario remisiones
        {
        id: 1, value: "Materia Prima",
        }, {
        id: 2, value: "Merma",
        }, {
        id: 3, value: "Producto Terminado"
        }, {
        id: 4, value: "Servicio"
        }
        ],
        remisiones: false,
        dataRemisiones: {
        selecttCliente: null,
        pedido: [],
        tipo: null,
        producto: null,
        cantidad: null,
        cantidadDisponible: null,
        pesoNeto: null,
        pesoBruto: null,
        //------------------------
        fechaRemision: null,
        conducto: null,
        pesoBrutoKG: null,
        tipoCambio: null,
        comentarios: null
        },
        defaultRemisiones: {
        selecttCliente: null,
        pedido: [],
        tipo: null,
        producto: null,
        cantidad: null,
        cantidadDisponible: null,
        pesoNeto: null,
        pesoBruto: null,
        //-------------------------  
        fechaRemision: null,
        conducto: null,
        pesoBrutoKG: null,
        tipoCambio: null,
        comentarios: null
        },  //fin datos usados en crud de remisiones

        //value: [],
        //modeSelect: false,

        conversion: false,  //datos usados en
        fechaInicio: null,
        fechaFin: null,
        valorDolar: [],
        valorDolarFormateado: [],
        cambio: null,  //la obtención del valor del dólar

        dataProductosTerminados: [],
      }
    },
    mounted() {
      this.initialize();
    },
    methods: {

      initialize() {
        this.getClientes();
        this.getProductosTerminados();
        this.loadingReceta = false;
      },

      getClientes() {
        axios
          .get("/Clientes")
          .then(response => { this.dataClientes = response.data 
          //console.log("Soy de Geometrika");
          })
          .catch(error => { console.log(error.response) })
    },

    getProductosTerminados() {
      axios
        .get("Articulos/Tipo/3?precio=false")
        .then((response) => {
          this.dataProductosTerminados = response.data;
          //console.log(this.dataProductosTerminados);
          //this.dataProductosTerminados.unshift({ nombre: "Agregar Nuevo Producto Terminado" })
        })
        .catch((error) => {
          console.log(error)
        })
    },

    deleteItem(item) {
      let deleteIndex = this.dataRemisiones.pedido.indexOf(item);
      this.dataRemisiones.pedido.splice(deleteIndex, 1);
    },

    abrirRemision() {
      this.remisiones = true;
      //this.getClientes();
      //console.log("Soy para crear remisiones");
      },
    cerrarRemisiones() { //cierra y reinicia el formulario de remisiones
      this.$refs.form?.reset();
      this.remisiones = false;
      this.selected = []; 
      this.$nextTick(() => {
          this.dataRemisiones = Object.assign({}, this.defaultRemisiones);
      }); 
      //console.log("Adiós a las remisiones");
    },
    postRemision() {

    },
    abrirConvertidor() {
      this.conversion = true;
    },
    cerrarConvertidor() {
        this.conversion = false;
        this.fechaInicio = null;
        this.fechaFin = null;
        this.valorDolar = [];
        this.valorDolarFormateado = [];
        //this.importe = 0;
        //this.fechaCambio = null;
        //this.cambio = null;
        //this.observacionesCambio = null;
    },
    cambioMonedas() {  //Función usada para obtener y mostrar los valores del dólar dado un rango de fechas.
        axios
          .get("/PreOrden/ConsultarDolar", {
            params:{
              FechaInicio: this.fechaInicio,
              FechaFin: this.fechaFin
            }
          })
          .then((response) => {
            this.valorDolar = response.data;
            this.valorDolarFormateado = this.valorDolar.map(objeto => {
              return {
                ...objeto,
                fecha: objeto.fecha.split('T')[0]
              };
            });
            //console.log("El valor obtenido es de: ", this.valorDolar);
            //this.cerrarConvertidor();
          })
          .catch((error) => {
            console.log(error);
            //console.log("Desde: ", this.fechaInicio);
            //console.log("Hasta: ", this.fechaFin);
          })
    },
    },
}
</script>