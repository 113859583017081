<template>
  <div>
    <v-dialog v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>

        <v-toolbar color="primary">
          <v-spacer />
          <v-toolbar-title class="white--text">Análisis de Costos</v-toolbar-title>
          <v-spacer />
          <v-icon dark @click="hide_dialog()">mdi-close</v-icon>
        </v-toolbar>

        <v-list-item style="width: 98%; margin: auto" v-if="dialog">
          <v-list-item-content>
            <v-list-item-title class="my-title">Datos del Cliente:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-row class="col-12">
                  <v-col cols="12">
                    <h6 class="font-weight-bold d-inline">Nombre del Cliente: </h6> {{
      dataCostos.clienteMostrar.nombreCliente }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Contacto: </h6>
                    {{ dataCostos.clienteMostrar.contacto }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Teléfono: </h6>
                    {{ dataCostos.clienteMostrar.telefono }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Datos del Trabajo:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-row class="col-12">
                  <v-col cols="12">
                    <h6 class="font-weight-bold d-inline">Nombre del Trabajo: </h6>
                    {{ dataCostos.trabajoMostrar.nombreTrabajo }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-bold d-inline">Cantidad Solicitada:</h6>
                    {{ formatearCifra(dataCostos.trabajoMostrar.cantidadPza, 0) }} Pzas.
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-bold d-inline">Largo Pieza:</h6> {{
      dataCostos.trabajoMostrar.pzIndividualLargo
    }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-bold d-inline">Ancho Pieza:</h6> {{
        dataCostos.trabajoMostrar.pzIndividualAncho
      }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-bold d-inline">Piezas Pinza:</h6> {{
        dataCostos.trabajoMostrar.piezasPinza }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-bold d-inline">Piezas Alto:</h6> {{
      dataCostos.trabajoMostrar.piezasAlto }}
                  </v-col>
                  <v-col cols="4" />
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Cantidad de Pliegos:</h6>
                    {{ formatearCifra(Math.ceil(dataCostos.trabajoMostrar.numeroPliegos), 0) }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Medida:</h6> {{ dataCostos.trabajoMostrar.medidas }}
                    Mts.
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Papel:</h6> {{ dataCostos.trabajoMostrar.tipoPapel }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-bold d-inline">Gramaje de Papel:</h6>
                    {{ formatearCifra(dataCostos.trabajoMostrar.pesoPapel, 3) }} Grs.
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-bold d-inline">Proveedor de Papel:</h6> {{
      dataCostos.trabajoMostrar.proveedorPapel }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-bold d-inline">Medida del Pliego:</h6>
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-bold d-inline">Base:</h6> {{ dataCostos.trabajoMostrar.base }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-bold d-inline">Altura:</h6> {{ dataCostos.trabajoMostrar.altura }}
                  </v-col>
                  <v-col cols="12">
                    <h6 class="font-weight-bold d-inline">Tintas:</h6> {{ dataCostos.trabajoMostrar.numTintas }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Referencias:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <br>
              <v-card-text>
                <v-row class="col-12">
                  <v-col cols="8">
                    <v-row class="padding-hr">
                      <v-col cols="4">
                        <h6 class="font-weight-bold d-inline">Papel:</h6>
                      </v-col>
                      <v-col cols="4">$ {{ formatearCifra(dataCostos.referenciaMostrar.papelCosto, 2) }}</v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>
                  <v-col cols="4">
                    <v-row class="padding-hr">
                      <v-col cols="8">
                        <h6 class="font-weight-bold d-inline">Kilos Totales:</h6>
                      </v-col>
                      <v-col cols="4"> {{ formatearCifra(dataCostos.referenciaMostrar.kilosTotal) }} Kg</v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>

                  <v-col cols="8">
                    <v-row class="padding-hr">
                      <v-col cols="4">
                        <h6 class="font-weight-bold d-inline">Variables:</h6>
                      </v-col>
                      <v-col cols="4">$ {{ formatearCifra(dataCostos.referenciaMostrar.variablesCosto)
                        }}</v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>
                  <v-col cols="4">
                    <v-row class="padding-hr">
                      <v-col cols="8">
                        <h6 class="font-weight-bold d-inline">Costo/Kg:</h6>
                      </v-col>
                      <v-col cols="4">$ {{ formatearCifra(dataCostos.referenciaMostrar.costoKilo, 2) }}</v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>

                  <v-col cols="8">
                    <v-row class="padding-hr">
                      <v-col cols="4">
                        <h6 class="font-weight-bold d-inline">Fijos:</h6>
                      </v-col>
                      <v-col cols="3">$ {{ formatearCifra(dataCostos.referenciaMostrar.fijosCosto, 2) }}</v-col>
                      <v-col cols="5">
                        {{ textTipoCosto(dataCostos.referenciaMostrar.fijosTipo) }}
                      </v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>
                  <v-col cols="4">
                    <v-row class="padding-hr">
                      <v-col cols="8">
                        <h6 class="font-weight-bold d-inline">Precio de Venta/Kg:</h6>
                      </v-col>
                      <v-col cols="4">$ {{ formatearCifra(dataCostos.referenciaMostrar.precioKilo, 2) }}</v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>

                  <v-col cols="8">
                    <v-row class="padding-hr">
                      <v-col cols="4">
                        <h6 class="font-weight-bold d-inline">Extras:</h6>
                      </v-col>
                      <v-col cols="4">$ {{ formatearCifra(dataCostos.referenciaMostrar.extrasCosto, 2)
                        }}</v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>
                  <v-col cols="4">
                    <v-row class="padding-hr">
                      <v-col cols="8">
                        <h6 class="font-weight-bold d-inline">Utilidad en Pesos:</h6>
                      </v-col>
                      <v-col cols="4">$ {{ formatearCifra(dataCostos.referenciaMostrar.utilidad, 2) }}</v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>

                  <!--<v-col cols="8">
                        <v-row class="padding-hr">
                          <v-col cols="4">
                            <h6 class="font-weight-bold d-inline">Insumos:</h6>
                          </v-col>
                          <v-col cols="8">$ {{ formatearCifra(dataCostos.referenciaMostrar.costoInsumo, 2)
                          }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>-->

                  <v-col cols="8">
                    <v-row class="padding-hr">
                      <v-col cols="4">
                        <h6 class="font-weight-bold d-inline">Empaques y Envios:</h6>
                      </v-col>
                      <v-col cols="8">$ {{ formatearCifra(dataCostos.referenciaMostrar.empaquesEnvioCosto, 2)
                        }}</v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>

                  <v-col cols="8">
                    <v-row class="padding-hr">
                      <v-col cols="4">
                        <h6 class="font-weight-bold d-inline">Preprensa:</h6>
                      </v-col>
                      <v-col cols="3">$ {{ formatearCifra(dataCostos.referenciaMostrar.preprensaCosto)
                        }}</v-col>
                      <v-col cols="5">
                        {{ textTipoCostoPreprensa(dataCostos.referenciaMostrar.preprensaTipo) }}
                      </v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>

                  <v-col cols="8">
                    <v-row class="padding-hr">
                      <v-col cols="4">
                        <h6 class="font-weight-bold d-inline">Herramentales:</h6>
                      </v-col>
                      <v-col cols="3">$ {{ formatearCifra(dataCostos.referenciaMostrar.herramentalesCosto)
                        }}</v-col>
                      <v-col cols="5">
                        {{ textTipoCosto(dataCostos.referenciaMostrar.herramentalesTipo) }}
                      </v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>

                  <v-col cols="8">
                    <v-row class="padding-hr">
                      <v-col cols="4">
                        <h6 class="font-weight-bold d-inline">Observaciones:</h6>
                      </v-col>
                      <v-col cols="4">{{ dataCostos.referenciaMostrar.observacionesReferencia }}</v-col>
                      <v-col cols="4"></v-col>
                    </v-row>
                    <hr color="#C9CFD1">
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-list-item-title class="my-title">Precio - Venta:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <br>
              <v-card-text>
                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Costo Total:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.costoTotal, 2) }}</v-col>
                    <v-col cols="2">
                      <h6 class="font-weight-bold d-inline">Utilidad:</h6>
                    </v-col>
                    <v-col cols="2">{{ }}%</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Utilidad en pesos:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.utilidadPesos, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Subtotal:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.subtotal, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Envío a Planta:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.envioPlanta, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Envío a Cliente:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.envioCliente, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Herramental:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.herramental, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Total:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.total, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Precio por Millar:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.precioMillar, 2) }}</v-col>
                    <v-col cols="2">
                      <h6 class="font-weight-bold d-inline">Costo Real:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.costoReal, 2) }}</v-col>
                    <v-col cols="2">
                      <h6 class="font-weight-bold d-inline">Utilidad Real:</h6>
                    </v-col>
                    <v-col cols="1">% {{ formatearCifra(dataCostos.precioVenta.utilidadReal, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col cols="3">
                  <v-card style="width: auto;">
                    <v-card-subtitle>PRECIO TOTAL</v-card-subtitle>
                    <v-card-title>$ {{ formatearCifra(dataCostos.precioVenta.total, 2) }}</v-card-title>
                  </v-card>
                </v-col>
              </v-card-text>
            </v-card>

            <v-expansion-panels v-bind="cardStyle" class="my-title">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Costos por Grupos:
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="6">
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Costo de Papel:</h6>
                          </v-col>
                          <v-col cols="4">$ {{ formatearCifra(dataCostos.costoGrupo.costoPapel, 2) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <!--<v-col cols="12">
                            <v-row class="padding-hr">
                              <v-col cols="8">
                                <h6 class="font-weight-bold d-inline">Costo de Insumos:</h6>
                              </v-col>
                              <v-col cols="4">$ {{ formatearCifra(dataCostos.costoGrupo.costoInsumo, 2) }}</v-col>
                            </v-row>
                            <hr color="#C9CFD1">
                          </v-col>-->
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Costo de Luz:</h6>
                          </v-col>
                          <v-col cols="4">$ {{ formatearCifra(dataCostos.costoGrupo.costoLuz, 2) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Costo Insumos:</h6>
                          </v-col>
                          <v-col cols="4">$ {{ formatearCifra(dataCostos.costoGrupo.costoInsumos, 2) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Costo Mod:</h6>
                          </v-col>
                          <v-col cols="4">$ {{ formatearCifra(dataCostos.costoGrupo.costoMod, 2) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Costos Fijos:</h6>
                          </v-col>
                          <v-col cols="4">$ {{ formatearCifra(dataCostos.costoGrupo.costoFijos, 2) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <!--<v-col cols="12" v-if="dataCostos.referenciaMostrar.extrasTrabajo.length > 0"
                            v-for="(item) in dataCostos.referenciaMostrar.extrasTrabajo">
                            <v-row class="padding-hr">
                              <v-col cols="8">
                                <h6 class="font-weight-bold d-inline">{{ item.concepto }}</h6>
                              </v-col>
                              <v-col cols="4">$ {{ formatearCifra(item.monto, 2) }}</v-col>
                            </v-row>
                            <hr color="#C9CFD1">
                          </v-col>-->
                      <!--<v-col cols="12" v-if="dataCostos.referenciaMostrar.extrasTrabajo.length === 0">
                            <v-row class="padding-hr">
                              <v-col cols="8">
                                <h6 class="font-weight-bold d-inline">Extras:</h6>
                              </v-col>
                              <v-col cols="4">$ 0</v-col>
                            </v-row>
                            <hr color="#C9CFD1">
                          </v-col>-->
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Empaque Cliente:</h6>
                          </v-col>
                          <v-col cols="4">$ {{ formatearCifra(dataCostos.costoGrupo.empaqueC, 2) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Empaque Planta:</h6>
                          </v-col>
                          <v-col cols="4">$ {{ formatearCifra(dataCostos.costoGrupo.empaqueP, 2) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Envios:</h6>
                          </v-col>
                          <v-col cols="4">$ {{ formatearCifra(dataCostos.costoGrupo.envios, 2) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>

                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Costos Preprensa:</h6>
                          </v-col>
                          <v-col cols="4">$ {{ formatearCifra(dataCostos.referenciaMostrar.preprensaCosto)
                            }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Tabla Suaje, Desbarbe y Molde:</h6>
                          </v-col>
                          <v-col cols="4">$ {{ formatearCifra(dataCostos.referenciaMostrar.herramentalesCosto)
                            }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <!--<v-col cols="12">
                            <v-row class="padding-hr">
                              <v-col cols="8">
                                <h6 class="font-weight-bold d-inline">Secado</h6>
                              </v-col>
                              <v-col cols="4">$ {{ formatearCifra(dataCostos.costoGrupo.costoSecado, 2) }}</v-col>
                            </v-row>
                            <hr color="#C9CFD1">
                          </v-col>-->
                    </v-col>
                    <v-col cols="6">
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Tamaños de Papel:</h6>
                          </v-col>
                          <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.tamanoPapel, 0) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Mermas de Impresión:</h6>
                          </v-col>
                          <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.impresionMerma, 0) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Mermas de Plastificado:</h6>
                          </v-col>
                          <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.plastificadoMerma, 0)
                            }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Mermas de Barniz:</h6>
                          </v-col>
                          <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.barnizMerma, 0) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <!--<v-col cols="12">
                            <v-row class="padding-hr">
                              <v-col cols="8">
                                <h6 class="font-weight-bold d-inline">Mermas de Barniz UV Plasta:</h6>
                              </v-col>
                              <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.uvPlastaMerma, 0) }}</v-col>
                            </v-row>
                            <hr color="#C9CFD1">
                          </v-col>
                          <v-col cols="12">
                            <v-row class="padding-hr">
                              <v-col cols="8">
                                <h6 class="font-weight-bold d-inline">Mermas de Barniz UV Registro:</h6>
                              </v-col>
                              <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.uvRegistroMerma, 0) }}</v-col>
                            </v-row>
                            <hr color="#C9CFD1">
                          </v-col>-->
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Mermas de Suaje:</h6>
                          </v-col>
                          <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.suajeMerma, 0) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Mermas de Pegado:</h6>
                          </v-col>
                          <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.pegadoMerma, 0) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Mermas de Empalme:</h6>
                          </v-col>
                          <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.empalmeMerma, 0) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Mermas de Troquel:</h6>
                          </v-col>
                          <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.troquelMerma, 0) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Tamaño de Mermas:</h6>
                          </v-col>
                          <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.tamanoMermas, 0) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                      <v-col cols="12">
                        <v-row class="padding-hr">
                          <v-col cols="8">
                            <h6 class="font-weight-bold d-inline">Tamaños Totales:</h6>
                          </v-col>
                          <v-col cols="4">{{ formatearCifra(dataCostos.costoGrupo.tamanoTotal, 0) }}</v-col>
                        </v-row>
                        <hr color="#C9CFD1">
                      </v-col>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels v-bind="cardStyle">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Calculo por Costos:
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p class="my-title" style="margin-top: 0 !important;">Análisis:</p>
                  <v-card>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" width="30%">
                              Proceso
                            </th>
                            <th class="text-left">
                              Total Sin Fijos
                            </th>
                            <th class="text-left">
                              Total Con Fijos
                            </th>
                            <th class="text-left">
                              Fijos
                            </th>
                            <th class="text-left">
                              Luz
                            </th>
                            <th class="text-left">
                              MOD
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in dataCostos.calculoCostos.analisis.datoLista" :key="item.idProceso">
                            <td>{{ item.nombre }}</td>
                            <td>{{ formatearCifra(item.totalSFijos, 2) }}</td>
                            <td>{{ formatearCifra(item.totalCFijos, 2) }}</td>
                            <td>{{ formatearCifra(item.fijos, 2) }}</td>
                            <td>{{ formatearCifra(item.luz, 2) }}</td>
                            <td>{{ formatearCifra(item.mod, 2) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                  <p class="my-title">Materiales:</p>
                  <v-card>
                    <v-simple-table v-if="dataCostos.calculoCostos.materiales">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" width="30%">
                              Tinta
                            </th>
                            <th class="text-left">

                            </th>
                            <th class="text-left">

                            </th>
                            <th class="text-left">
                              Precio
                            </th>
                            <th class="text-left">
                              Frente
                            </th>
                            <th class="text-left">
                              Vuelta
                            </th>
                            <th class="text-left">
                              Kilos
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in dataCostos.calculoCostos.materiales.tinta">
                            <td class="text-capitalize">{{ item.nombrePorcentaje.toLowerCase().trim() }}</td>
                            <td></td>
                            <td></td>
                            <td>$ {{ formatearCifra(item.precio) }}</td>
                            <td>$ {{ formatearCifra(item.frente) }}</td>
                            <td>$ {{ formatearCifra(item.vuelta) }}</td>
                            <td>{{ formatearCifra(item.kilos, 2) }} Kg</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-simple-table v-if="dataCostos.calculoCostos.materiales">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" width="30%">
                              Barniz
                            </th>
                            <th class="text-left">

                            </th>
                            <th class="text-left">

                            </th>
                            <th class="text-left">
                              Precio
                            </th>
                            <th class="text-left">
                              Frente
                            </th>
                            <th class="text-left">
                              Vuelta
                            </th>
                            <th class="text-left">
                              Kilos
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in dataCostos.calculoCostos.materiales.barniz">
                            <td class="text-capitalize">{{ item.nombrePorcentaje.toLowerCase().trim() }}</td>
                            <td></td>
                            <td></td>
                            <td>$ {{ formatearCifra(item.precio) }}</td>
                            <td>$ {{ formatearCifra(item.frente) }}</td>
                            <td>$ {{ formatearCifra(item.vuelta) }}</td>
                            <td>{{ formatearCifra(item.kilos, 2) }} Kg</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                  <br>
                  <v-card>
                    <v-simple-table v-if="dataCostos.precioVenta.extras">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" width="30%">
                              Extra
                            </th>
                            <th class="text-left">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in dataCostos.precioVenta.extras">
                            <td class="text-capitalize">{{ item.descripcion }}</td>
                            <td>$ {{ formatearCifra(item.monto) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" width="30%">
                            </th>
                            <th class="text-left">
                              Sin fijos
                            </th>
                            <th class="text-left">
                              Con fijos
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-capitalize">Totales</td>
                            <td>${{ formatearCifra(dataCostos.calculoCostos.materiales.totales.totalCFijo) }}</td>
                            <td>${{ formatearCifra(dataCostos.calculoCostos.materiales.totales.totalSFijo) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item-content>
        </v-list-item>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['idOrden'],
  data() {
    return {
      cardStyle: {
        outlined: true,
        elevation: 5,
        style: {
          padding: "0px",
          width: "100%",
          border: "1px solid #C7C7C7",
        },
      },

      dataCostos: [],
      dataTipoCostoPreprensa: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Cobrar por Separado",
        }, {
          id: 3,
          value: "Mostrarlo y no Cobrarlo",
        }, {
          id: 4,
          value: "No Mostrarlo ni Cobrarlo",
        },
      ],
      dataTipoCosto: [
        {
          id: 1,
          value: "Sumar al Costo",
        }, {
          id: 2,
          value: "Sumar al Costo Sin Utilidad",
        }, {
          id: 3,
          value: "Cobrar por Separado",
        }, {
          id: 4,
          value: "Mostrarlo y no Cobrarlo",
        }, {
          id: 5,
          value: "No Mostrarlo ni Cobrarlo",
        },
      ],

      dialog: false,
    }
  },
  methods: {
    getMostrarCalculos() {
      axios
        .get(`/Orden/MostrarCalculos/${this.idOrden}`)
        .then((response) => {
          if (response.status == 204 || !response.data) {
            this.$emit("error_mostrarCalculos", 204);
          }
          else {
            this.dataCostos = response.data;
            this.dialog = true;
            //console.log(this.dataCostos);
          }
        })
        .catch((error) => {
          this.$emit("error_mostrarCalculos", error.response.status);
          this.dialog = false;
        })
    },

    formatearCifra(number, decimales = 2) {
      let numero = 0;
      if (number != null) numero = number;
      return numero.toLocaleString('en-US', { minimumFractionDigits: decimales, maximumFractionDigits: decimales });
    },

    textTipoCosto(id) {
      return this.dataTipoCosto.find((item) => item.id === id).value;
    },
    textTipoCostoPreprensa(id) {
      return this.dataTipoCostoPreprensa.find((item) => item.id === id).value;
    },

    hide_dialog() {
      Object.assign(this.dataCostos, []);
      this.dialog = false;
    },
  },
}
</script>

<style scoped>
.my-title {
  color: #5c5c5c;
  margin-top: 50px;
  padding-bottom: 10px;
  width: 100%;
}

.padding-hr {
  padding: 0 0 15px 0;
}

.boton {
  height: 50px;
  width: 40%;
}

.selectCosto {
  width: auto;
  height: 10px;
  margin: auto auto auto -5px;
}
</style>