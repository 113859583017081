import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"0 20px 0 20px"}},[_c(VCol),_c(VDialog,{attrs:{"width":"750"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Tareas del modulo '"+_vm._s(_vm.apartadoProblemas)+"' ")]),_c(VCardText,[_c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.pendientesProblemas),function(pendientes,i){return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,{attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle ")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(pendientes.titulo)+" ")]),_c(VExpansionPanelContent,[_vm._v(" "+_vm._s(pendientes.descripcion)+" "),_c('br'),_c('br'),(pendientes.descripcion)?_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"width":"50%"}},[_vm._v(" TAREA PENDIENTE EN: ")]),(pendientes.lista.find(function (item) { return item.concepto != null; }))?_c('th',{staticClass:"text-center"},[_vm._v(" Concepto ")]):_vm._e()])]),_c('tbody',_vm._l((pendientes.lista),function(item,i){return _c('tr',[_c('td',[_vm._v("["+_vm._s(item.item)+"] - "+_vm._s(item.extra))]),(item.concepto)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.concepto)+" ")]):_vm._e()])}),0)]},proxy:true}],null,true)}):_vm._e()],1)],1)}),1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"ml-auto",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.hide_dialog()}}},[_vm._v("Cerrar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }