<template>
    <v-col cols="12" class="mt-4">
      <!-- Cuadro de dialogo de advertencia al momento de eliminar un registro -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="550px" :key="eliminar">
          <v-card>
            <v-card-title class="headline" style="word-break: break-word;">¿Está seguro que desea eliminar este registro?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Se declara la tabla y sus componentes  -->
    <v-data-table      
      :headers="headers"
      :items="seccionNotificaciones"
      
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    ><!-- v-if="permisoVista('parotipos','r')" :search="search"-->
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <!-- Boton para crear un nuevo registro -->
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" >Asignar persona</v-btn><!-- v-if="permisoVista('parotipos','c')" -->
            </template>
            <!-- Formulario de edicion o de creacion -->
            <v-card>
              <!-- Titulo del formulario, cambiara con forme lo que dicte la funcion formTitle -->
               <!-- Si se abrio desde el boton de un nuevo registro mandara un formurario vacio -->
                <!-- Si se abre desde el boton de edicion mandara el formulario con los datos del registro a editar -->
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <!-- Cuerpo del formulario -->
              <v-card-text>
                <v-container>
                  <v-row>
                    <!-- Formulario de edidion o creación -->
                     <!-- En el caso de que se este creando un nuevo registro se mostrara un formulario vacio -->
                     <!-- En el caso de que se este editando un registro se mostrara el formulario con los datos del registro a editar, los cuales sacara de la variable editItem -->
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <!-- combobox de los usuarios, traera una lista de usuarios disponibles para el registro a su dispocision -->
                      <v-combobox
                        v-model="editedItem.usuario"
                        :items="usuarios"
                        item-text="nombre"
                        item-value="idUsuario"
                        label="Usuario"
                                                
                      ></v-combobox>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12" >
                      <!-- combobox de las secciones, traera una lista de secciones disponibles para el registro a su dispocision -->
                       <!-- Se le agrego instrucciones al momento de que el valor dentro del recuadro cambie -->
                      <v-combobox
                        v-model="editedItem.seccion"
                        :items="seccionNotificaciones"
                        item-text="nombre"
                        item-value="idSeccionNotificacion"
                        label="Sección"
                        @change="getSubSecciones(); editedItem.subSeccion='';"
                      ></v-combobox>
                    </v-col>                    
                    <v-col justify="center" cols="12" sm="12" md="12" @click="validateList()">
                      <!-- combobox de las subsecciones, traera una lista de subsecciones disponibles para el registro a su dispocision  -->
                       <!--  -->
                      <v-combobox
                        v-model="editedItem.subSeccion"
                        :items="subSeccionesNotificaciones"
                        item-text="nombre"
                        item-value="idSubSeccion"
                        label="Subsecciones"                        
                      ></v-combobox>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <!-- combobox de los medios de envio, traera una lista de los medios de envio disponibles para el registro a su dispocision -->
                      <v-combobox
                        v-model="editedItem.medioEnvio"
                        :items="medios"
                        item-text="medioEnvio"
                        item-value="idMedioEnvio"
                        label="Medio"
                      ></v-combobox>
                    </v-col>
                    <!-- Checkbox para activar o desactivar el registro -->
                    <v-col cols="6" sm="6" md="6">
                      <p class="mb-0">
                        ¿Activo?
                      </p>
                      <div class="d-flex">
                        <v-btn-toggle
                          v-model="editedItem.activo"
                          class="my-3"
                        >
                        <!-- Se agregan 2 botones para configurar un valor booleano -->
                          <v-btn
                            depressed
                            small
                            class="ma-0"
                            :color="
                              editedItem.activo == 0
                                ? 'success'
                                : 'grey lighten-1'
                            "
                            >Si</v-btn
                          >
                          <v-btn
                            depressed
                            small
                            class="ma-0"
                            :color="
                              editedItem.activo == 1
                                ? 'error'
                                : 'grey lighten-1'
                            "
                            >No</v-btn
                          >
                        </v-btn-toggle>
                      </div>
                    </v-col>
                    <!-- CheckBox para activar o desactivar el llamado a mantenimiento del registro -->
                    <v-col cols="6" sm="6" md="6">
                      <p class="mb-0">
                        ¿Llamado a Mantenimiento?
                      </p>
                      <div class="d-flex">
                        <v-btn-toggle
                          v-model="editedItem.mantenimiento"
                          class="my-3"
                        >
                        <!-- Se agregan 2 botones para configurar un valor booleano -->
                        <v-btn
                            depressed
                            small
                            class="ma-0"
                            :color="
                              editedItem.mantenimiento == 0
                                ? 'success'
                                : 'grey lighten-1'
                            "
                            >Si</v-btn
                          >
                          <v-btn
                            depressed
                            small
                            class="ma-0"
                            :color="
                              editedItem.mantenimiento == 1
                                ? 'error'
                                : 'grey lighten-1'
                            "
                            >No</v-btn
                          >                          
                        </v-btn-toggle>
                      </div>
                    </v-col>
                    
                  </v-row>
                  <!-- <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul> -->
                </v-container>
              </v-card-text>
              <!-- Configuracion de los botones que van incluidos en el formulario -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- Boton para cancelar la accion y dejar todo como estaba -->
                <v-btn color="blue darken-1" text :disabled="saving" @click="close(); dialog=false; errores=[]; subSeccionesNotificaciones= [];">Cancelar</v-btn>
                <!-- Boton para guardar los cambios -->
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>          
        </v-toolbar>
      </template>
      <!-- Informacion que va dentro de la tabla -->
      <template v-slot:body="{ items }">
        <tbody>
          <template v-for="item in items" v-blind:key="item.idSeccionNotificacion">
            <!-- Como se recolecta informacion de 4 tablas diferentes -->
            
            <template v-for="(subsec, indexSub) in item.subseccionesNotificacions" v-blind:key="subsec.idSubSeccion">

              <!-- Se tiene que generar 4 ciclos v-for que anden recolectando la informacion de la manera deseada -->
              
              <template v-for="(users, indexUser) in subsec.usuariosAsignados" v-blind:key="users.idUsuario">
                <!-- Al llegar al ultimo ciclado se empieza a armar la informacion para la tabla -->              

                <tr v-for="(medio, indexMed) in users.medios" v-blind:key="medio.idMedioEnvio" >
                  <!-- Los v-if sirven para evitar que un dato se repita en la tabla -->
                   <!-- Con los rowspan nos aseguramos de que los datos se alineen correctamente -->
                  <template v-if="indexSub === 0">
                    <template v-if="indexUser === 0">
                      <template v-if="indexMed === 0">
                        <td :rowspan=item.count>{{ item.nombre }}</td>
                      </template>
                    </template>
                  </template>
                  
                  <template v-if="indexUser === 0">
                    <template v-if="indexMed === 0">                      
                      <td :rowspan=subsec.count>{{ subsec.nombre }}</td>
                    </template>
                  </template>
                  
                  <template v-if="indexMed === 0">
                      <td :rowspan =users.count>{{ users.nombre }}</td>
                  </template>
                  
                  <td style="text-align: center">{{ medio.medioEnvio }}</td>

                  <td style="text-align: center">{{ medio.activo ? 'SI' : 'NO' }}</td>

                  <td style="text-align: center">{{ medio.llamadoMantenimiento ? 'SI' : 'NO' }}</td>
                  <!-- Una vez obtenida y acomodada la informacion se les asignan los botones de eliminacion y edición a cada registro -->                  
                  <td style="text-align: center">
                        <v-tooltip bottom color="black" class="white--text">
                      <template v-slot:activator="{ on }">
                  <!-- Para el boton de editar se recopilan los valores correspondientes en cada fila para que aparezcan al momento de querer ser editados -->
                      <v-icon v-on="on"          
                        small
                        class="mr-2"
                        @click="editItem(medio.idNotificacion, item.idSeccionNotificacion, item.nombre, subsec.idSubSeccion, subsec.nombre, users.idUsuario, users.nombre, medio.idMedioEnvio, medio.medioEnvio, +!medio.activo, +!medio.llamadoMantenimiento);
                        getSubSecciones();
                        this.editedItem.IdSubSeccion=subsec.idSubSeccion;"
                        
                      >
                        
                        edit
                      </v-icon>
                       </template>
                        <span class="white--text">Editar</span>
                      </v-tooltip>
                      <v-tooltip bottom color="black" class="white--text">
                        <template v-slot:activator="{ on }">
                      <!-- Para el boton de eliminar solo es necesario hacer el llamado a la funcion que se encarga de eliminar el registro -->
                      <v-icon v-on="on"          
                        small
                        @click="deleteItem(medio.idNotificacion)"
                      >
                        
                        delete
                      </v-icon>
                       </template>
                        <span class="white--text">Eliminar</span>
                    </v-tooltip>
                      </td>
                </tr>
              </template>
            </template>

          </template>
        </tbody>        
      </template> 
      <!-- En el caso de que no aparezca informacion se agregara un boton que permita refrescar la informacion de la tabla -->     
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      <!-- Un cuadro de advertencia que describe el error que se produjo -->
      <!-- Verifique la informacion ingresada en el registro -->
      {{ errores[errores.length-1] }}
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
</template>

<script>
import axios from "axios";
export default {
    data: () => ({      
        //Se declaran los encabezados de cada columna de la tabla
        headers: [
        {
          text: 'Seccion',
          align: 'center',
          sortable: true,
          value: 'seccion',
        },
        {
          text: 'Subseccion',
          align: 'center',
          sortable: true,
          value: 'subseccion',
        },
        {
          text: 'Usuario',
          align: 'center',
          sortable: true,
          value: 'usuario',
        },
        {
          text: 'Medio',
          align: 'center',
          sortable: true,
          value: 'medio',
        },
        {
          text: 'Activo',
          align: 'center',
          sortable: true,
          value: 'activo',
        },
        {
          text: '¿Llamado a Mantenimiento?',
          align: 'center',
          sortable: true,
          value: 'mantenimiento',
        },
        { 
          text: 'Acciones', 
          value: 'action', 
          sortable: false, 
          align:'center' },
      ],
      //Se declaran las variables que usaremos para el funcionamiento de las funciones de la tabla
      loading: false,
      dialog: false,
      alerta: false,
      delItem: -1,
      eliminar: false,
      saving: false,
      action: 0,
      errores: [],
      errorTiempo: null,
      editedIndex: -1,      //Una bandera que nos permitira saber si estamos editando o creando un nuevo registro      
      ups:0,
      seccionNotificaciones: [],//Listas de los registros disponibles para la tabla
      subSeccionesNotificaciones: [],
      usuarios:[],
      medios:[],
      editedItem:{//Objeto donde se guardara la informacion del registro que se esta editando o el registro que se va a crear
        idNotificacion: -1,
        idSeccion: -1,
        seccion: '',
        idSubSeccion: -1,
        subSeccion: '',
        idUsuario: -1,
        usuario: '',
        idMedioEnvio: -1,
        medioEnvio: '',
        activo: 1,
        mantenimiento: 1
      },
      defaultItem:{
        idNotificacion: -1,
        idSeccion: -1,
        seccion: '',
        idSubSeccion: -1,
        subSeccion: '',
        idUsuario: -1,
        usuario: '',
        idMedioEnvio: -1,
        medioEnvio: '',
        activo: 1,
        mantenimiento: 1
      }
      
    }),
    computed: { //Relacionada con el titulo de edicion y de creacion, el formulario es el mismo, lo que cambia es la variable editedIndex
      formTitle () {//Al darle click en el boton de agregar, la variable editedIndex se establece en -1 y al darle click dese un icono de edicion el valor cambiara
        return this.editedIndex === -1 ? 'Asignar Nueva Persona' : 'Editar'
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    created () {//Manda a cargar la pagina
      this.initialize()
    },
    methods:{
        initialize(){//LLama a las funciones encargadas de obtener informacion del backend al momento de que cargue la pagina
            this.getConfiguracionNotificaciones();            
            this.getMediosEnvios();
            this.getUsuariosConfiguracionNotificaciones();
        },

        getConfiguracionNotificaciones(){//Llama al backend para que le devuelva los datos con los que se llenara la tabla
            axios.get('/ConfiguracionNotificaciones')
            .then(response =>  {
					    this.seccionNotificaciones = response.data.map( notificaciones => ({
						    ...notificaciones                
					    }))
            })            
            .catch(error => {            
                console.log(error)
            })
            .finally(() => {
                this.loading = false;
            })
            
        },       

        getSubSecciones(){//Llama al backend para que le devuelva los datos con los que se llenara la lista desplegable de las subsecciones al momento de hacer la edición
          //En este caso, hacemos la busqueda por medio del id de la seccion que quedo seleccionada en el recuadro anterior
          //Si this.editedItem.seccion.idSeccionNotificacion se encuentra vacio usamos el valor que se encuentra en this.editedItem.idSeccion
          var listaId=this.$utils.strings.isNullOrEmpty(this.editedItem.seccion.idSeccionNotificacion)?this.editedItem.idSeccion:this.editedItem.seccion.idSeccionNotificacion;  
          axios.get('/ConfiguracionNotificaciones/SubSeccionesNotificaciones/'+ listaId)
            .then(response =>  {
					    this.subSeccionesNotificaciones = response.data.map( subSeccion => ({
						    ...subSeccion                
					    }))
            })    
            .catch(error => {                         
                console.log(error)
            })
            .finally(() => {
                this.loading = false;
            })
        
        },

        getMediosEnvios(){//Llama al backend para que le devuelva los datos con los que se llenara la lista desplegable de los medios de envio al momento de hacer la edición
            axios.get('/ConfiguracionNotificaciones/MediosEnvios')
            .then(response =>  {
					    this.medios = response.data.map( mediosEnvio => ({
						    ...mediosEnvio                
					    }))
            })    
            .catch(error => {            
                console.log(error)
            })
            .finally(() => {
                this.loading = false;
            })
        },

        getUsuariosConfiguracionNotificaciones(){//Llama al backend para que le devuelva los datos con los que se llenara la lista desplegable de los usuarios al momento de hacer la edición
            axios.get('/ConfiguracionNotificaciones/UsuariosConfiguracionNotificacion')
            .then(response =>  {
					    this.usuarios = response.data.map( users => ({
						    ...users                
					    }))
            })    
            .catch(error => {            
                console.log(error)
            })
            .finally(() => {
                this.loading = false;
            })
        },

        editItem (idNotifica, idSecc, secc, idSubsecc, subsecc, idUsuari, usuari, IdMedia, media, activ, mante) {//Mandar por aqui los componentes para armarlos en un objeto y visualizarlos en la ventana de edicion
          this.action = 1;// Se configura la variable action para declarar que se esta editando
          this.editedItem = Object.assign({},//Se recopila la informacion para que sea visible en la ventana de edicion
            {idNotificacion: idNotifica},
            {idSeccion: idSecc},
            {seccion: secc},
            {idSubSeccion: idSubsecc},
            {subSeccion: subsecc},
            {idUsuario: idUsuari},
            {usuario: usuari},
            {idMedioEnvio: IdMedia},
            {medioEnvio: media},
            {activo: activ},
            {mantenimiento: mante})
          this.editedIndex = this.editedItem.idNotificacion//Se edita la variable editedIndex con el fin de configurar el titulo del cuadro de dialogo
          
          this.dialog = true
        },

        deleteItem (item) {//Funcion para eliminar un item de la lista
          this.eliminar = true;
          this.delItem = item;
        },
        deleteItemDB(){//Funcion para eliminar un item de la lista, llama al backend para que elimine el item de la base de datos
          let me = this;
          let itemId = this.delItem;
          this.delItem = -1;
          axios
          .put("/ConfiguracionNotificaciones/EliminarConfiguracionNotificacion/" + itemId)
          .then(() =>  {
            me.initialize();
          })
          .catch(error => {
            console.log(error);
          });

        },

        close() {//Funcion que se encarga de cerrar los formularios al momento de oprimir el boton de cancelar, donde los valores alterados al momento de tratar de crear o editar un registro vuelven a sus valores por defecto
            this.action = 0,
            this.dialog = false
            this.editedItem = Object.assign({}, this.defaultItem)            
            this.editedIndex = -1            
            this.ups++;
        
        },
        validate(){//Validaciones para asegurarse de que no envien formularios vacios o incompletos
        this.errores =  [];
        this.errorTiempo = false;
        
        if (
          this.editedItem.medioEnvio == ''
        )
        {
          this.alerta = true;
          this.errores.push(
            "Debe seleccionar un Medio de Envio."
          );
        }    
        
        if (
          this.editedItem.subSeccion == ''          
        )
        {
          this.alerta = true;
          this.errores.push(
            "Debe seleccionar una Subsección."
          );
        }
        
        if (
          this.editedItem.seccion == ''     
        )
        {
          this.alerta = true;
          this.errores.push(
            "Debe seleccionar una Sección."
          );
        }

        if (
          this.editedItem.usuario == ''          
        )
        {
          this.alerta = true;
          this.errores.push(
            "Debe seleccionar un Usuario."
          );
        }

        return this.errores.length == 0;
      },
      validateList(){//Validamos si se encuentra un id aceptable en la seccion
        //Para ello debemos contemplar los lugares donde se puede encontrar el id de la seccion, el el caso de que no se encuentre donde guardamos la informacion nueva buscamos en donde guardamos la informacion nueva
        var lista=this.$utils.strings.isNullOrEmpty(this.editedItem.seccion.idSeccionNotificacion)?this.editedItem.idUsuario:this.editedItem.seccion.idSeccionNotificacion;
        if (lista < 1 )//En caso de que todabia no se haya seleccionado una seccion lanzara un mensaje de error
        {
          this.alerta = true;
          this.errores.push(
            "Debe seleccionar una Sección antes de poder elegir una Subsección."
          );
        }
      },
        save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            //this.editedItem.usuario.idUsuario guardara los datos que se vayan a editar, mientras que
            //this.editedItem.idUsuario guarda los datos ya guardados, en el caso de que haya datos que acualizar se mandara
            //this.editedItem.usuario.idUsuario y en caso de que se mantengan igual se mandara this.editedItem.idUsuario
            //De este modo se mantienen los datos no alterados
            axios
              .put("/ConfiguracionNotificaciones/ActualizarConfiguracionNotificacion/"+this.editedItem.idNotificacion, {
                idUsuario: this.$utils.strings.isNullOrEmpty(this.editedItem.usuario.idUsuario)?this.editedItem.idUsuario:this.editedItem.usuario.idUsuario,
                idSubSeccion: this.$utils.strings.isNullOrEmpty(this.editedItem.subSeccion.idSubSeccion)?this.editedItem.idSubSeccion:this.editedItem.subSeccion.idSubSeccion,
                idMedioEnvio: this.$utils.strings.isNullOrEmpty(this.editedItem.medioEnvio.idMedioEnvio)?this.editedItem.idMedioEnvio:this.editedItem.medioEnvio.idMedioEnvio,
                activo: !this.editedItem.activo,
                llamadoMantenimiento: !this.editedItem.mantenimiento
                
              })
              .then(() =>  {//Se deja todo como estaba
                this.saving = false;
                this.close();
                this.initialize();
              })
              .catch(error => {
                this.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    this.alerta = true;
                    this.errores.push("Existe un registro Activo con los mismos datos");
                  }
                  if(error.response.status == 404){
                    this.alerta = true;
                    this.errores.push("El registro no se encuentra en la base de datos");
                  }
                }
              });
          }
          else
          {
            //Codigo para crear nuevos registros
            //En el caso del idUsuario, como todos los datos seran nuevos, bastara con mandarlo desde this.editedItem.usuario.idUsuario
            axios
              .post("/ConfiguracionNotificaciones/", {
                idUsuario: this.editedItem.usuario.idUsuario,
                idSubSeccion: this.editedItem.subSeccion.idSubSeccion,
                idMedioEnvio: this.editedItem.medioEnvio.idMedioEnvio,
                activo:!this.editedItem.activo,
                llamadoMantenimiento: !this.editedItem.mantenimiento

              })
              .then(() =>  {//Se deja todo como estaba
                this.saving = false;
                this.close();
                this.initialize();
              })
              .catch(error => {
                this.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    this.alerta = true;
                    this.errores.push("Existe un registro Activo con los mismos datos");
                  }
                }
              });
          }
        }
      },

    },
    filters: {
      
    }
}
</script>