<template>
  <div>
    <v-dialog v-model="dialogError" max-width="500px">
      <v-card>
        <v-card-text class="text-center justify-center">
          <v-icon size="200px" color="red">mdi-alert</v-icon>
          <div class="headline">{{mensajeError}}</div>
          <br>
          <div class="v-btn v-btn--contained blue theme--dark display-2 ma-5 pa-5" @click="dialogError = false">
            Cerrar
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLiberacionOperacion" persistent max-width="40%">
      <v-card>
        <v-card-title>¿Está seguro que desea desbloquear esta operación?</v-card-title>
        <v-card-text>
          Esta acción no se podrá revertir una vez realizada.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            :disabled="liberando"
            @click="cerrarDialogLiberacionOperacion()"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="error"
            :disabled="liberando"
            :loading="liberando"
            @click="liberarOperacion()"
            >Desbloquear</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="mostrarIniciarOperacion"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          Iniciar Operación
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="iniciarOperacionItem"
                label="Operador o Equipo"
                :loading="loadingSesiones"
                :items="sesiones"
                item-text="nombre"
                return-object
              >
                <template v-slot:item="data">
                  <template v-if="(typeof data.item) !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.integrantes != null && data.item.integrantes.length > 0">
                        <ul>
                          <li v-for="(integrante,idx) in data.item.integrantes" :key="'integrante-'+idx">{{integrante.nombre}}</li>
                        </ul>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="$utils.isValid(operacion.maquinas) && operacion.maquinas.length > 0">
              <div v-for="(maquina,idx) in operacion.maquinas" :key="'maquinas'+idx">
                <h5>{{maquina.nombre}}</h5>
                <v-radio-group v-model="idsMaquinasIns[idx]">
                  <v-radio
                    v-for="instancia in maquina.maquinaInstancias"
                    :key="instancia.idMaquinaInstancia"
                    :label="instancia.nombre"
                    :value="instancia.idMaquinaInstancia"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-switch
                label="Especificar Hora de Inicio"
                v-model="horaDiferente"
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="horaDiferente" cols="12" md="12" sm="12">
              <VueCtkDateTimePicker
                v-model="horaInicio"
                label="Fecha de Ingreso"
                format="YYYY-MM-DD HH:mm"
                formatted="YYYY-MM-DD HH:mm"
                noHeader
                buttonNowTranslation="Ahora"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="resetIniciarOperacion">Cancelar</v-btn>
          <v-btn color="red" :loading="loadBottom" text @click="iniciarOperacionRun">Iniciar Operación</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12" md="12" sm="12" v-if="operaciones.length === 0 && sinDatos">
      <v-card flat>
        <div class="text-center mt-16 pt-16">
        <v-progress-circular
          :size="70"
          :width="7"
          color="green"
          indeterminate
    ></v-progress-circular>
    <h2>Cargando...</h2>
        </div>
      </v-card>
    </v-col>
    <v-col v-else cols="12" md="12" sm="12" v-for="ot in operaciones" :key="'ot-'+ot.idTrabajoOrden">
      <v-alert
        outlined
        color="#26A69A"
      >
        <div v-if="ot.clave == null" class="headline text-center black--text">
          <v-tooltip v-if="ot.desbloqueda" bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="green" class="mr-2" x-large>
              mdi-newspaper-check
            </v-icon>
          </template>
          <span class="white--text">Esta ODT ya se encuentra habilitada</span>
        </v-tooltip>
        <v-tooltip v-else bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="orange" class="mr-2" x-large @click="mostrarAreaTrabajo(ot.idTrabajoOrden)">
              mdi-newspaper-remove
            </v-icon>
          </template>
          <span class="white--text">Habilitar ODT</span>
        </v-tooltip>
          ODT-{{ot.idTrabajoOrden}}
          </div>
        <div v-else class="headline text-center black--text">
        <v-tooltip v-if="ot.desbloqueda" bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="green" class="mr-2" x-large>
              mdi-newspaper-check
            </v-icon>
          </template>
          <span class="white--text">Esta ODT ya se encuentra habilitada</span>
        </v-tooltip>
        <v-tooltip v-else bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="orange" class="mr-2" x-large @click="mostrarAreaTrabajo(ot.idTrabajoOrden)">
              mdi-newspaper-remove
            </v-icon>
          </template>
          <span class="white--text">Habilitar ODT</span>
        </v-tooltip>
          ODT Clave {{ot.clave}}
        </div>        
        <v-col cols="12" md="12" sm="12" v-for="detalle in ot.detalles" :key="'det-'+detalle.idTrabajoOrdenDetalle">
          <v-alert
            outlined
            :color="(detalle.mensaje == null)?'#26A69A':'red'"
          >
            <div :class="(detalle.mensaje == null)?'title text-center black--text':'title text-center red--text'">{{detalle.cantidad}} - {{detalle.articulo}}</div> 
            <div v-if="!detalle.primeraArea && detalle.porcentaje < 100.0" class="headline text-center">Avance de Áreas Previas: {{detalle.porcentaje.toFixed(2)}}%</div>
            <div v-if="detalle.mensaje != null" class="title text-center red--text">Especial: {{detalle.mensaje}}</div>
            <div v-if="detalle.porcentaje >= 100.0" class="text-center">
              <template v-if="listadoChips">
                <v-data-table
                  :items="detallesFiltrados(detalle.operaciones)"
                  :headers="headersOperaciones"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:[`item.cantidad`]="{ item }">
                    {{item.cantidad}}<span class="red--text" v-if="item.cantidadExcedente > 0" style="font-size: 0.875rem !important"> + {{item.cantidadExcedente}} </span> {{ item.unidad }}
                  </template>
                  <template v-slot:[`item.operacion`]="{ item }">
                    <div class="py-2" style="font-size: 0.875rem !important">
                      {{item.articulo}}
                      <template v-if="item.idSubpaso != null">
                        <div class="pt-2 pb-1" style="font-size: 0.875rem !important">
                          {{ `[${item.codigoSubpaso}] - ${item.nombreSubpaso}` }}
                        </div>
                      </template>
                      <div class="red--text pt-1" v-if="item.operacionesEnProgreso.length > 0" style="font-size: 0.875rem !important">
                        Operadores o Equipos Asignados: {{item.operacionesEnProgreso.length}}
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip v-if="!item.operacionDesbloqueada" bottom color="black" class="white--text">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          size="20"
                          class="mr-2"
                          :disabled="!ot.desbloqueda"
                          @click="showDialogLiberarOperacion(item)"
                        >
                          mdi-lock-open-variant
                        </v-icon>
                      </template>
                      <span class="white--text">Desbloquear</span>
                    </v-tooltip>
                    <v-tooltip bottom color="black" class="white--text">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          size="20"
                          class="mr-2"
                          @click="editOperador(ot,item)"
                        >
                          mdi-account-multiple-plus
                        </v-icon>
                      </template>
                      <span class="white--text">Asignar operadores</span>
                    </v-tooltip>
                    <v-tooltip bottom color="black" class="white--text">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          size="20"
                          class="mr-2"
                          @click="iniciarOperacion(item)"
                        >
                          mdi-play
                        </v-icon>
                      </template>
                      <span class="white--text">Iniciar operación</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </template>
              <template v-else>
                <div >
                  <v-chip
                    color="teal lighten-1"
                    outlined
                    v-for="op in detallesFiltrados(detalle.operaciones)" :key="'op-'+op.idOperacion"
                  >
                  <v-btn 
                  class="no-text-transform ma-2"
                  x-large
                  text
                  rounded
                  color="teal lighten-1"
                  @click="iniciarOperacion(op)"
                >
                  <span style="white-space: normal;">
                    {{op.cantidad}}&nbsp;<div class="red--text" style="display: contents;" v-if="op.cantidadExcedente > 0">&nbsp;+&nbsp;{{op.cantidadExcedente}}&nbsp;</div> {{ op.unidad }}&nbsp;-&nbsp;{{op.articulo}}
                    <br>
                    <div v-if="op.idSubpaso != null">
                      {{ `[${op.codigoSubpaso}] - ${op.nombreSubpaso}` }}
                    </div>
                    <div class="red--text" v-if="op.operacionesEnProgreso.length > 0">
                      Operadores o Equipos Asignados:&nbsp; {{op.operacionesEnProgreso.length}}
                    </div>
                  </span>
                </v-btn>
                <v-tooltip v-if="!op.operacionDesbloqueada" bottom color="black" class="white--text">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small
                      class="mr-2"
                      :disabled="!ot.desbloqueda"
                      @click="showDialogLiberarOperacion(op)"
                    >
                      mdi-lock-open-variant
                    </v-icon>
                  </template>
                  <span class="white--text">Desbloquear</span>
                </v-tooltip>
                <v-tooltip bottom color="black" class="white--text">
                  <template v-slot:activator="{ on }">
                        <v-icon
                        v-on="on"
                        small
                        @click="editOperador(ot,op)"
                        >
                          mdi-account-multiple-plus
                        </v-icon>
                      </template>
                      <span class="white--text">Asignar operadores</span>
                    </v-tooltip>
                  </v-chip>
                </div>
              </template>
              
            </div>
            <div v-else class="text-center red--text">
              <br>
              <br>
              <h3>Este Árticulo aún no esta Disponible</h3>
              <br>
              <br>
            </div>
          </v-alert>
        </v-col>
      </v-alert>
    </v-col>
    <v-col cols="12" md="12" sm="12" v-if="operaciones.length === 0 && !sinDatos">
      <v-card flat>
        <div class="h2 text-center">No hay Operaciones Pendientes en Esta Área</div>
      </v-card>
    </v-col>
    <v-dialog v-model="dialogOPT" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5">
          ¿Desea mostrar esta operación en el área de trabajo?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="dialogOPT = false"
          >
            CANCELAR
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="sendData()"
          >
            ACEPTAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogOperadores" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          <span class="text-h5">Asignar operadores</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="idPersona"
            :items="operadores"
            item-value="idPersona"
            :item-text="nombrePersona"
            label="Operador"
            clearable
            small-chips
            multiple
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelar()">
            CANCELAR
          </v-btn>
          <v-btn color="red darken-1" :disabled="disableButton" :loading="disableButton" text @click="saveOperadoresDisponibles()">
            GUARDAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';

export default {
  props:{
    area:{
      type:Object,
      default:{
        area:'',
        idArea:-1,
        operacionesCurso: 0,
      },
    },
  },
  data: () => ({
    headersOperaciones: [
      {
        text: "Cantidad",
        value: "cantidad",
        align: "center",
        width: "10%"
      },
      {
        text: "Operacion",
        value: "operacion",
        align: "center",
        width: "80%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%"
      }
    ],
    disableButton:false,
    dialogOPT: false,
    operaciones:[],
    sesiones:[],
    mostrarIniciarOperacion:false,
    operacion:{
      maquinas:[],
    },
    defaultOperacion:{
      maquinas:[],
    },
    idsMaquinasIns:[],
    iniciarOperacionItem: null,
    horaInicio: null,
    horaDiferente:false,
    dialogError: false,
    mensajeError:"",
    sinDatos:true,
    loadBottom:false,
    idTrabajoOrden: null,
    operadores: [],
    dialogOperadores: false,
    editedIndex: -1,
    editedIndexOperacion: -1,
    liberacionItem: {
      idOperacion: null,
      otDesbloqueda: null
    },
    dialogLiberacionOperacion: false,
    liberando: false
  }),
  computed: {
    ...mapState(['listadoChips',"operacionesCantidadCero","seleccionaUnaMaquina"])
  },
  methods:{
    nombrePersona: ({ nombre, paterno, materno }) =>
      `${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
        materno ? `${materno} ` : ""
      }`.trim(),
    liberarOperacion(){
      this.liberando = true;
      axios
        .post(`/ComandoYControl/CrearOperacionLiberada/${this.liberacionItem.idOperacion}`)
        .then( () => {
          this.liberando = false;
          this.cerrarDialogLiberacionOperacion();
          this.actualizar();
        })
        .catch( error => {
          console.log(error);
          this.liberando = false;
        });
    },
    cerrarDialogLiberacionOperacion(){
      this.dialogLiberacionOperacion = false;

      this.$nextTick( () => {
        this.liberacionItem.idOperacion = null;
        this.liberacionItem.otDesbloqueda = null;
      })
    },
    showDialogLiberarOperacion({ idOperacion, otDesbloqueda }){
      this.liberacionItem.idOperacion = idOperacion;
      this.liberacionItem.otDesbloqueda = otDesbloqueda;

      this.dialogLiberacionOperacion = true;
    },
    mostrarAreaTrabajo(item){
      this.idTrabajoOrden = item;
      this.dialogOPT = true;
    },
    cancelSendData(){
      this.idTrabajoOrden = null;
      this.dialogOPT = false;

    },
    sendData(){
      if(this.idTrabajoOrden == null) return;
      if(this.area.idArea == null) return;

      axios
      .post("/ComandoYControl/CrearLimiteOperacion",{
        idTrabajoOrden: this.idTrabajoOrden,
        idArea: this.area.idArea, 
      })
      .then(() => {
        this.dialogOPT = false;
        this.idTrabajoOrden = null;
        this.actualizar()
      })
      .catch(error => {
        console.log(error);
        this.dialogOPT = false;
      })
    },
    editOperador(item1,item){
      this.editedIndex = this.operaciones.indexOf(item1);
      this.editedIndexOperacion = this.operaciones[this.editedIndex].detalles[0].operaciones.indexOf(item);
      this.idPersona = this.operaciones[this.editedIndex].detalles[0].operaciones[this.editedIndexOperacion].operadores.map(
        (x) => x.idPersona
      );
      
     this.dialogOperadores = true;
    },
    cancelar(){
      this.dialogOperadores = false;
      this.editedIndexOperacion = -1;
      this.editedIndex = -1;
      this.idPersona = [];
    },
    saveOperadoresDisponibles(){

      if(this.editedIndexOperacion == -1) return;
      if(this.editedIndex == -1) return;
      
      let obj = new Object();
      obj.idOperacion = this.operaciones[this.editedIndex].detalles[0].operaciones[this.editedIndexOperacion].idOperacion;
      obj.idsPersonas = this.idPersona;

      this.disableButton = true;

      axios
        .post("/ComandoYControl/CrearActualizarAsignacionOperacion", obj)
        .then(() => {
          this.cancelar();
          this.getOperaciones();
          this.disableButton = false;
        })
        .catch((error) => {
          this.dialogOperadores = false;
          this.disableButton = false;
          console.log(error);
        });
    },
    getOperadores() {
      axios
        .get("/Personas/Operadores")
        .then((response) => {
          this.operadores = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    actualizar(){
      this.getOperaciones();
      this.getOperadores();
    },
    detallesFiltrados(operaciones){
      return operaciones.filter((operacion) =>{
        const esCantidadValida = this.operacionesCantidadCero || ((operacion.cantidad + operacion.cantidadExcedente) > 0);
        return !operacion.iniciada && esCantidadValida;
      })
    },
    getSesiones(){
      this.loadingSesiones = true;
      this.sesiones = [];
      axios
      .get("/ComandoYControl/SesionesAbiertas/"+this.area.idArea)
      .then(response => {
        this.loadingSesiones = false;
        this.sesiones = response.data;
      }) 
      .catch(error => {
        this.loadingSesiones = false;
        console.log(error);
      })
    },
    getOperaciones(){
      this.operaciones = [];
      axios
      .get("/ComandoYControl/OperacionesDisponibles/"+this.area.idArea)
      .then(response => {
        this.operaciones = response.data;
        if(this.operaciones.length == 0) this.sinDatos = false;
      })
      .catch(error => {
        console.log(error);
      });
    },
    iniciarOperacion(op){
      if(!this.permisoVista('control','c')){
        return;
      }
      this.getSesiones();
      this.operacion = op;
      this.idsMaquinas = [];
      if(this.$utils.isValid(this.operacion.maquinas) & this.operacion.maquinas.length > 0){
        var nPos = this.operacion.maquinas.length;
        for (var i = 0; i < nPos; i++) {
          this.idsMaquinasIns.push(-1);
        }
      }
      this.mostrarIniciarOperacion = true;
    },
    resetIniciarOperacion(){
      this.operacion = {
        maquinas:[]
      };
      this.idsMaquinasIns = [];
      this.iniciarOperacionItem = null;
      this.mostrarIniciarOperacion = false;
      this.horaInicio = null;
      this.horaDiferente = false;
    },
    iniciarOperacionRun(){
      if(this.iniciarOperacionItem == null || (this.iniciarOperacionItem.idOperador == null && this.iniciarOperacionItem.idEquipo == null) ){
        this.mensajeError = "Debe de indicar un operador o un equipo.";
        this.dialogError = true;
        return;
      }
      
      
      if (this.seleccionaUnaMaquina) {
        var numMaquinas = this.idsMaquinasIns.filter(mi => mi == -1).length;

        if (numMaquinas > 0) {
          this.mensajeError = "Hay Máquinas que no se ha seleccionado la máquina que se empleara";
          this.dialogError = true;
          return;
        }
      } else {
        var hayMaquinaSeleccionada = this.idsMaquinasIns.some(mi => mi !== -1);

        if (!hayMaquinaSeleccionada) {
          this.mensajeError = "Debe seleccionar al menos una máquina.";
          this.dialogError = true;
          return;
        }
      }
      
      this.loadBottom=true;
      axios
      .post("/ComandoYControl/IniciarOperacion",{
        IdArea: this.area.idArea,
        IdOperacion: this.operacion.idOperacion,
        IdOperador: this.iniciarOperacionItem.idOperador,
        IdEquipo: this.iniciarOperacionItem.idEquipo,
        Inicio: this.horaDiferente ? this.horaInicio: null,
        IdsInstanciasMaquinas: this.idsMaquinasIns
      })
      .then(response => {
        this.loadBottom=false;
        this.resetIniciarOperacion();
        this.actualizar();
      })
      .catch(error => {
        this.resetIniciarOperacion();
        if (error.response.status === 461){
          this.mensajeError = "Esta Operación se encuentra en Curso";
          this.dialogError = true;
        }
        else if(error.response.status === 462){
          this.mensajeError = "Este Operador Tiene Otra Operación en Curso";
          this.dialogError = true;
        }
        else console.log(error);
      })
    }
  },
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>