<template>
  <div  v-if="permisoVista('costos','r')">
    <v-data-table :headers="headers" :items="dataCostos" :search="search" :loading="loadingTable">
      <template v-slot:top>
        <div class="d-flex align-center">
          <v-text-field v-model="search" v-bind="propsSearch" />
        </div>
      </template>

      <template v-slot:item.costo="{ item }">
        $ {{ item.costo }}
      </template>

      <template v-slot:item.acciones="{ item }">
        <v-tooltip v-if="permisoVista('costos','u')" bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small @click="show_dialog(item)"> mdi-pencil-outline</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title>Editar Costo de "{{ concepto }}"</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="costo" label="Costo" prefix="$" v-bind="propsText" :loading="loadingDialog" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn class="ml-auto" text color="blue darken-1" @click="hide_dialog()" :loading="loadingDialog">Cancelar</v-btn>
          <v-btn text color="error" :loading="loadingDialog" @click="validate()">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>
  
<script>
import axios from 'axios'
import AlertSnackbar from '@/components/AlertSnackbar.vue'

export default {
  components: {
    AlertSnackbar,
  },
  data() {
    return {
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
      },
      propsText: {
        outlined: false,
        dense: true,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },

      search: '',

      headers: [
        {
          text: "Concepto",
          value: "concepto",
          align: "center",
          sortable: false,
        }, {
          text: "Costo",
          value: "costo",
          align: "center",
          sortable: false,
        }, {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        }
      ],

      dataCostos: [],

      idConcepto: null,
      concepto: null,
      costo: null,

      dialog: false,

      loadingTable: false,
      loadingDialog: false,
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.putConcepto()
      } else {
        this.alerta(422)
      }
    },

    initialize() {
      this.getConceptos();
    },

    getConceptos() {
      this.loadingTable = true;
      axios
        .get("/GKCostoOtros/Listar")
        .then((response) => {
          this.dataCostos = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    postConcepto() {
      this.loadingDialog = true;
      axios
        .post("/GKCostoOtros/Crear", {
          /*concepto: this.concepto,
          costo: this.costo*/
        })
        .then((response) => {
          //this.alerta(200)
          this.getConceptos();
          this.hide_dialog();
        })
        .catch((error) => {
          console.log(error)
          //this.alerta(error.response.status)
        }).
        finally(() => {
          this.loadingDialog = false;
        })
    },
    putConcepto() {
      this.loadingDialog = true;
      axios
        .put("/GKCostoOtros/Actualizar/" + Number(this.idConcepto), {
          concepto: this.concepto,
          costo: this.costo
        })
        .then((response) => {
          //this.alerta(200)
          this.getConceptos();
          this.hide_dialog();
        })
        .catch((error) => {
          console.log(error)
          //this.alerta(error.response.status)
        }).
        finally(() => {
          this.loadingDialog = false;
        })
    },

    show_dialog(item) {
      this.dialog = true;

      if (item) {
        this.idConcepto = item.idCosto;
        this.concepto = item.concepto;
        this.costo = item.costo;
      }
    },
    hide_dialog() {
      this.$refs.form.reset();
      this.dialog = false;
      this.idConcepto = null;
      this.concepto = null;
      this.costo = null;
    },

    alerta(code, color, mensaje, icono) {
      if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
      } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
      }
    },
  },
  mounted() {
    this.initialize();
  }
}
</script>

<style scoped></style>