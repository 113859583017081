<template>
    <div>
        <v-dialog v-model="dialogDelete" persistent max-width="40%">
            <v-card>
            <v-card-text>
                <p class="text-h5 pt-4 font-weight-medium text--primary">
                    ¿Está seguro que desea eliminar esta PreOrden?
                </p>
                <p class="subtitle-1 text--primary">
                    Esta acción no se puede revertir y será permanente.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="blue darken-1"
                    :disabled="deleting"
                    @click="closeDialog()"
                    >Cancelar</v-btn
                >
                <v-btn
                    text
                    color="red darken-1"
                    :disabled="deleting"
                    :loading="deleting"
                    @click="deletePreOrden()"
                    >Aceptar</v-btn
                >
            </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table :headers="headers" :search="search" :items="dataPreOrdenes" >
           
            <template v-slot:[`item.nombreTrabajo`]="{ item }">
                {{ item.nombreTrabajo }}
            </template>
            <template v-slot:[`item.cantidadPza`]="{ item }">
                {{ item.cantidadPza }}
            </template>
            <template v-slot:[`item.precio`]="{ item }">
                {{ item.precio }}
            </template>
            <template v-slot:[`item.anticipo`]="{ item }">
                {{ item.anticipo }}
            </template>
            <template v-slot:top>
                <div class="d-flex justify-center">
                <v-text-field v-model="search" v-bind="propsSearch" />
                </div>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip bottom color="black">
                 <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" small class="mr-1" 
                  @click="showEdit(item.idOrdenCotizacion)">edit</v-icon>
                 </template>
                 <span class="white--text">Editar</span>
                </v-tooltip>
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" small class="mr-1"
                      @click="showDelete(item)">delete</v-icon>
                  </template>
                  <span class="white--text">Eliminar</span>
                </v-tooltip>
                <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" small class="mr-1" 
                    @click="showAutorice(item)">mdi-check-circle</v-icon>
                </template>
                <span class="white--text">Autorizar</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogAutorizar" persistent max-width="40%">
            <v-card>
            <v-card-text>
                <p class="text-h5 pt-4 font-weight-medium text--primary">
                    ¿Esta seguro de autorizar esta preorden?
                    Asegurese de revisar los detalles
                </p>
                <p class="subtitle-1 text--primary">
                    Esta acción no se puede revertir y será permanente.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="blue darken-1"
                    :disabled="autorizing"
                    @click="closeAutDialog()"
                    >Cancelar</v-btn
                >
                <v-btn
                    text
                    color="red darken-1"
                    :disabled="autorizing"
                    :loading="autorizing"
                    @click="autorizarPreorden()"
                    >Aceptar</v-btn
                >
            </v-card-actions>
            </v-card>
        </v-dialog>

        <!--Se obtinen, muestran y guardan datos para la preorden-->
        <v-dialog v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar color="primary">
              <v-btn icon @click="hide_dialog()"><v-icon>mdi-close</v-icon></v-btn>
              <v-toolbar-title class="white--text">Editar Preorden</v-toolbar-title>
              <v-spacer />
              <v-btn text @click="editPreorden()">Guardar</v-btn>
            </v-toolbar>

            <v-list-item style="width: 98%; margin: auto" v-if="dialog">
            <v-list-item-content>
                <v-list-item-title class="my-title font-weight-bold">Cotización:</v-list-item-title>
                <v-card v-bind="cardStyle">
                <v-card-text>
                 <v-row class="col-12">
                   <v-col cols="6">
                     <h6 class="font-weight-medium d-inline">Folio de cotización: </h6>
                     {{ dataOrdenn.folioCotizacion }}
                   </v-col>
                   <v-col cols="6">
                      <h6 class="font-weight-medium d-inline">Fecha de cotización: </h6>
                      {{ dataOrdenn.fechaCotizacion }}
                   </v-col>
                </v-row>
                </v-card-text>
            </v-card>

                <v-list-item-title class="my-title font-weight-bold">Datos del cliente:</v-list-item-title>
                <v-card v-bind="cardStyle">
                <v-card-text>
                    <v-row class="col-12">
                    <v-col cols="4">
                        <h6 class="font-weight-medium d-inline">Cliente: </h6>
                        {{ dataCostos.clienteMostrar && dataCostos.clienteMostrar.nombreCliente ? dataCostos.clienteMostrar.nombreCliente : ' ' }}
                    </v-col>
                    <v-col cols="4">
                        <h6 class="font-weight-medium d-inline">Contacto: </h6>
                        {{ dataCostos.clienteMostrar && dataCostos.clienteMostrar.contacto ? dataCostos.clienteMostrar.contacto : ' ' }}
                    </v-col>
                    <v-col cols="4">
                        <h6 class="font-weight-medium d-inline">Teléfono: </h6>
                        {{ dataOrdenn.telefono }}
                    </v-col>
                    </v-row>
                </v-card-text>
                    </v-card>

                    <v-list-item-title class="my-title font-weight-bold">Datos del trabajo:</v-list-item-title> <!--Diálogo info trabajo-->
                    <v-card v-bind="cardStyle">
                    <v-card-text>
                        <v-row class="d-flex align-center">
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Trabajo: </h6>
                            {{  dataOrdenn.nombreTrabajo }}
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Cantidad: </h6>
                            {{  dataOrdenn.cantidadPza }} Pza(s)
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                            v-model="dataOrdenn.piezasExtra"
                            outlined 
                            dense 
                            label="Piezas Extras" 
                            suffix="Pza(s)" 
                            />
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Tintas: </h6>
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Frente: </h6>
                            {{ dataOrdenn.tintasFrente }}
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Vuelta: </h6>
                            {{ dataOrdenn.tintasVuelta }}
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Colores: </h6>
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Frente: </h6>
                            {{ (dataCotizacion && dataCotizacion.impresionTintaCotizacionesString && Array.isArray(dataCotizacion.impresionTintaCotizacionesString)
                                ? dataCotizacion.impresionTintaCotizacionesString.map(item => item.articulo).join(', ')
                                : ' ') }}
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Vuelta: </h6>
                            {{ 'No' }}
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Tamaño Final: </h6>
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Largo: </h6>
                            {{  dataOrdenn.largo }} Mts.
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Ancho: </h6>
                            {{  dataOrdenn.ancho }} Mts.
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Papel: </h6>
                            {{ dataCotizacion.papel }}
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Gramaje: </h6>
                            {{  dataCostos.trabajoMostrar && dataCostos.trabajoMostrar.pesoPapel ? dataCostos.trabajoMostrar.pesoPapel : ' ' }} Grs.
                        </v-col>
                        <v-col cols="12">
                            <h6 class="font-weight-medium d-inline">Barniz Frente: </h6>
                            {{  dataOrdenn.barnizFrente }}
                        </v-col>
                        <v-col cols="12">
                            <h6 class="font-weight-medium d-inline">Suajado: </h6>
                            {{  dataOrdenn.suajado }}
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Tabla de Suaje: </h6>
                            {{  dataOrdenn.tablaSuaje ? 'Si' : 'No' }}
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Base: </h6>
                            {{  dataOrdenn.tablaDesbarbeBase }}
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Altura: </h6>
                            {{  dataOrdenn.tablaSuajeAltura }}
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Tabla Desbarbe: </h6>
                            {{ dataOrdenn.tablaDesbarbe ? 'Si' : 'No' }}
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Base: </h6>
                            {{  dataOrdenn.tablaDesbarbeBase }}
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Altura: </h6>
                            {{  dataOrdenn.tablaDesbarbeAltura }}
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline"></h6>
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Centimetros Suaje: </h6>
                            {{  dataOrdenn.suajeCms }}
                        </v-col>
                        <v-col cols="3">
                            <h6 class="font-weight-medium d-inline">Centimetros Matrix: </h6>
                            {{  dataOrdenn.suajeCms }}
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Empalme: </h6>
                            {{  dataOrdenn.empalme ? 'Si' : 'No' }}
                        </v-col>
                        <v-col cols="6">
                            <h6 class="font-weight-medium d-inline">Metros: </h6>
                            {{  dataOrdenn.empalmeMts}}
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Pegado Lineal: </h6>
                            {{  dataOrdenn.pegadoLineal ? 'Si' : 'No' }}
                        </v-col>
                        <v-col cols="6">
                            <h6 class="font-weight-medium d-inline">Cms: </h6>
                            {{  dataOrdenn.pegadoCms }}
                        </v-col>
                        <v-col cols="4">
                            <h6 class="font-weight-medium d-inline">Pegado de Golpes: </h6>
                            {{  dataOrdenn.pegadoGolpe ? 'Si' : 'No' }}
                        </v-col>
                        <v-col cols="6">
                            <h6 class="font-weight-medium d-inline">Esquinas: </h6>
                            {{  dataOrdenn.pegadoEsquinas }}
                        </v-col>
                        <v-col cols="6">
                            <v-text-field 
                            v-model="dataOrdenn.entregarEn" 
                            outlined 
                            dense
                            :disabled="saving" 
                            label="Entregar en "/>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field 
                            v-model="dataOrdenn.ordenCompra"
                            outlined
                            :disabled="saving" 
                            dense
                            :error="dataOrdenn.errorOrdenCompraEstatus"
                            :error-messages="dataOrdenn.errorOrdenCompra"
                            :key="up"
                            label="Orden de Compra "/>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea 
                            v-model="dataOrdenn.trabajoObservaciones"
                            outlined
                            :disabled="saving" 
                            rows="2"
                            dense
                            label="Observaciones" />
                        </v-col>
                        </v-row>
                    </v-card-text>
                    </v-card>
                    <v-list-item-title class="my-title font-weight-bold">Datos Económicos:</v-list-item-title> <!--Diálogo info económico-->
                    <v-card v-bind="cardStyle">
                    <v-card-text>
                        <v-row cols="12">
                        <v-col cols="6">
                            <v-text-field 
                            v-model="dataOrdenn.precio"
                            outlined
                            :disabled="saving" 
                            dense
                            :error="dataOrdenn.errorPrecioEstatus"
                            :error-messages="dataOrdenn.errorPrecio"
                            :key="up"
                            label="$ Precio"/>
                            <span>(Incluir desglosado si aplica)</span>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field 
                            v-model="dataOrdenn.anticipo"
                            outlined 
                            dense
                            :error="dataOrdenn.errorAnticipoEstatus"
                            :error-messages="dataOrdenn.errorAnticipo"
                            :key="up"
                            label="$ Anticipo "/>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete 
                            v-model="dataOrdenn.desglosado"
                            :items="dataDesglosado"
                            item-text="text"
                            item-value="value"
                            :disabled="saving"
                            outlined 
                            dense 
                            label="Desglosado"/>
                            </v-col>
                            <v-col cols="6">
                            <v-autocomplete 
                            v-model="dataOrdenn.formaPago"
                            :items="dataFormaPago"
                            item-text="text"
                            item-value="value"
                            :disabled="saving"
                            outlined
                            :error="dataOrdenn.errorFormaPagoEstatus"
                            :error-messages="dataOrdenn.errorFormaPago" 
                            dense
                            :key="up"
                            label="Forma de Pago"/>
                            </v-col>
                            <v-col cols="12">
                            <v-textarea
                            v-model="dataOrdenn.referenciaPago" 
                            outlined
                            :disabled="saving" 
                            dense
                            rows="2"    
                            label="Referencia del Pago" />
                            </v-col>
                        <v-col cols="12">
                            <v-textarea
                            v-model="dataOrdenn.economicoObservaciones"
                            dense
                            :disabled="saving"
                            rows="2" 
                            outlined
                            :error="dataOrdenn.errorEconomicoObservacionesEstatus"
                            :error-messages="dataOrdenn.errorEconomicoObservaciones"
                            :key="up" 
                            label="Observaciones" />
                        </v-col>
                        <v-col cols="4">
                            <datePicker
                            v-model="dataOrdenn.fechaPromesa"
                            label="Fecha Prometida"
                            format="YYYY-MM-DD"
                            clearable
                            :disabled="saving"
                            outlined
                            dense
                            hide-details
                            />
                        </v-col>
                        </v-row>
                    </v-card-text>
                    </v-card>

                    <!--Adaptación de pre-orden geom a ODT mana-->
            <v-list-item-title class="my-title font-weight-bold">Pedido:</v-list-item-title> 
               <v-card v-bind="cardStyle">
                <v-card-text>
                  <v-row no-gutters>
                  <v-col cols="4" class="px-2 mt-2">
                    <v-autocomplete
                    v-model="lineaComercial"
                    label="Línea Comercial"
                    :items="lineasComerciales"
                    item-text="nombre"
                    item-value="idComercialLinea"
                    outlined
                    dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <v-autocomplete
                      v-model="selectCliente"
                      :items="dataClientes"
                      :item-text="nombreCliente"
                      item-value="idCliente"
                      outlined 
                      dense
                      clearable
                      label="Cliente" 
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <v-text-field
                    label="Clave de la Orden de Trabajo"
                    outlined
                    dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <datePicker
                      v-model="dataArtiExtra.fechaCompromiso"
                      label="Fecha de Compromiso de Entrega"
                      clearable
                      outlined
                      dense
                      format="YYYY-MM-DD"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <v-autocomplete
                    v-model="vendedor"
                    label="Vendedores"
                    :items="vendedores"
                    :item-text="nombrePersona"
                    item-value="idVendedor"
                    outlined
                    dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <datePicker
                      v-model="dataArtiExtra.fechaInicio"
                      label="Fecha de Inicio de producción"
                      format="YYYY-MM-DD"
                      clearable
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="4" class="px-2 mt-2">
                    <v-autocomplete
                    v-model="almacen"
                    label="Almacén"
                    :items="almacenes"
                    item-text="nombre"
                    item-value="idAlmacen"
                    outlined
                    dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-card-actions>
                    <v-btn class="mb-2" text color="blue darken-1" @click="agregarArticulo()"
                    
                    >Agregar Artículo </v-btn>
                  </v-card-actions>
                </v-row>
                <v-data-table
                  :items="dataArtiExtra.articulos"
                  hide-default-footer
                  :items-per-page="-1"
                  :headers="extras"
                >
                  <template v-slot:item="{item}">
                    <tr>
                      <td class="text-center">
                        <v-autocomplete
                          v-model="item.articulo"
                          :items="dataArticulos"
                          item-value="idArticulo"
                          :item-text="codigoNombre"
                          dense
                          hide-details
                          single-line
                        ></v-autocomplete>
                      </td>
                      <td class="text-center pa-0">
                        <v-col cols="10" class="pa-0">
                          <v-text-field
                            v-model="item.cantidad"
                            dense
                            hide-details
                            single-line
                            type="number"
                            min="0"
                            @change="validarCantidad(item)"
                            @keyup="validarCantidad(item)"
                          ></v-text-field>
                        </v-col>
                      </td>
                      <td class="text-center">
                        $
                        {{
                          `${precioUnitario.toFixed(2)} x Pza`
                        }}
                      </td>
                      <td class="text-center">
                        ${{ Math.min((precioUnitario * item.cantidad), precioDivision).toFixed(2) }}
                      </td>
                      <td class="text-center pa-0">
                        <v-col cols="10" class="pa-0">
                          <v-textarea
                            v-model="item.nota"
                            dense
                            outlined
                            rows="2"
                            hide-details
                            auto-grow
                            single-line
                          ></v-textarea>
                        </v-col>
                      </td>
                      <td> 
                        <div class="d-flex align-center justify-center">
                          <v-tooltip bottom color="black" class="white--text">
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                small
                                class="mr-2"
                                @click="deleteItem(item)"
                              >
                                delete
                              </v-icon>
                            </template>
                            <span class="white--text">Eliminar</span>
                          </v-tooltip>
                        </div>
                      </td>    
                    </tr>
                  </template>
                </v-data-table>
                <v-col cols="12" class="text-end">
                  <h3 class="ma-0 pa-0">
                    Total: ${{ Math.min(costoTotal, dataOrdenn.precio).toFixed(2) }}
                  </h3>
                </v-col>
                </v-card-text>
              </v-card>
                </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-dialog> 
    </div>   
</template>

<script>
import axios from 'axios'
import Preordenar from '../PreCotizacion/Preordenar.vue';
import datePicker from "@/components/DatePicker";


export default {

components: {
    Preordenar,
    datePicker
}, 
props: {
    idOrden: {
      type: Number,
      default: 0,
    },
    articles: {
      type: Array,
      default: () => []
    },
  },
data() {
    return {
    propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: true,
    },
    nombreCliente(lista) {
        if (lista.clave) {
        return `[${lista.clave}] - ${lista.nombre}`;
        } else {
        return lista.nombre;
        }
    },
    nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
    codigoNombre: (element) => "[" + element.codigo + "] - " + element.nombre,
    dataDesglosado: [
        { text: 'Si', value: true}, 
        { text: 'No', value: false},
      ],
      dataFormaPago: [
        { text: 'Efectivo', value: 1}, 
        { text: 'Tarjeta', value: 2},
        { text: 'Otro', value: 3},
      ],

    search: '',
    headers: [
        {
        text: "Folio",
        value: "idOrdenA",
        align: "center",
        sortable: false,
        }, {
        text: "Trabajo",
        value: "nombreTrabajo",
        align: "center",
        sortable: false
        },{
        text: "Fecha Promesa",
        value: "fechaPromesa",
        align: "center",
        sortable: false,
        }, ,{
        text: "Cantidad",
        value: "cantidadPza",
        align: "center",
        sortable: false,
        },{
        text: "Precio",
        value: "precio",
        align: "center",
        sortable: false
        },
        /* {
        text: "Cotizado Por",
        value: "cotizadoPor",
        align: "center",
        sortable: false,
        },*/ {
        text: "Anticipo",
        value: "anticipo",
        align: "center",
        sortable: false,
        }, {
        text: "Referencia Pago",
        value: "referenciaPago",
        align: "center",
        sortable: false,
        }, {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        }
    ],
    idOrden: null,
    idCotizacion: null,
    editarOrden: null,
    //idCotizacion: null,
    idPreOrden: null,
    autorizing: false,
    deleting: false,
    dataCotizaciones: [],
    dataPreOrdenes: [],
    dataPreOrdenesInd: [],
    dataOrden: [],
    dataOrdenes: [],
    dataCostos: [],
    dataTransporte: [],
    dataCotizacion: [],
    dataEdit: [],

    estatus: null,
    up: 0,
    dataOrdenn: {  //Datos a usar en el diálogo de edición de una preorden
        folioCotizacion: null,
        fechaCotizacion: null,  // Obligatorio
        telefono: null,
        nombreTrabajo: null,
        cantidadPza: null,
        piezasExtra: null,
        tintasFrente: null,  // Obligatorio
        tintasVuelta: null,  // Obligatorio
        largo: null,  // Obligatorio
        ancho: null,  // Obligatorio
        idArticuloPapel: null,  // Obligatorio
        barnizFrente: null,  // Obligatorio
        suajado: null,  // Obligatorio
        tablaSuaje: null,
        tablaSuajeBase: null,
        tablaSuajeAltura: null,
        suajeCms: null,
        tablaDesbarbe: null,
        tablaDesbarbeBase: null,
        tablaDesbarbeAltura: null,
        matrixCms: null,
        empalme: null,
        empalmeMts: null,
        pegadoLineal: null,
        pegadoCms: null,
        pegadoGolpe: null,
        pegadoEsquinas: null,
        entregarEn: null,
        ordenCompra: null,  // Obligatorio
        errorOrdenCompra: null,
        errorOrdenCompraEstatus: false,
        trabajoObservaciones: null,
        precio: null,  // Obligatorio
        errorPrecio: null,
        errorPrecioEstatus: false,
        anticipo: null,  // Obligatorio
        errorAnticipo: null,
        errorAnticipoEstatus: false,
        desglosado: null,
        formaPago: null,  // Obligatorio
        errorFormaPago: null,
        errorFormaPagoEstatus: false,
        referenciaPago: null,
        economicoObservaciones: null,  // Obligatorio
        errorEconomicoObservaciones: null,
        errorEconomicoObservacionesEstatus: false,
        fechaPromesa: null,
        tipoTablaSuaje: null,  // Obligatorio
      },

    saving: false,  

    idxPaso: 1,

    dialog: false,
    dialogDelete: false,
    
    dialogAutorizar: false,
    id: null,  
    loadingTable: false,
    loadingReceta: false,
    loadingPreOrden: false,

    //lo usado en la tabla de artículos en "Pedido"
    extras: [  //usados en la tabla para añadir artículos
        {
          text: "Artículo",
          align:"center",
          sortable: true,
          value: "nombre",
        },{
          text: "Cantidad",
          align:"center",
          sortable: true,
          value:"cantidad",
        }, {
          text: "Precio",
          align:"center",
          sortable: true,
          value:"costo",
        },{
          text: "Precio Total",
          align: "center",
          sortable: true,
          value: "precioTotal"
        },{
          text: "Observaciones",
          align: "center",
          sortable: true,
          value: "nota"
        },{
          text: "Acciones",
          value: "action",
          sortable: false,
          align: "center",
        }
    ],
    dataPreOrden: {
        idPreOrden: null,
        idCotizacion: null,
        idCliente: null,
        fechaCreacion: new Date(),
        estatus: null,
        orden: {
          folioCotizacion: null,
          fechaCotizacion: null,  // Obligatorio
          errorFechaCotizacion: null,
          telefono: null,
          nombreTrabajo: null,
          cantidadPza: null,
          piezasExtra: null,
          tintasFrente: null,  // Obligatorio
          errorTintasFrente: null,
          tintasVuelta: null,  // Obligatorio
          errorTintasVuelta: null,
          largo: null,  // Obligatorio
          errorLargo: null,
          ancho: null,  // Obligatorio
          errorAncho: null,
          idArticuloPapel: null,  // Obligatorio
          errorIdArticuloPapel: null,
          barnizFrente: null,  // Obligatorio
          errorBarnizFrente: null,
          suajado: null,  // Obligatorio
          errorSuajado: null,
          tablaSuaje: null,
          tablaSuajeBase: null,
          tablaSuajeAltura: null,
          suajeCms: null,
          tablaDesbarbe: null,
          tablaDesbarbeBase: null,
          tablaDesbarbeAltura: null,
          matrixCms: null,
          empalme: null,
          empalmeMts: null,
          pegadoLineal: null,
          pegadoCms: null,
          pegadoGolpe: null,
          pegadoEsquinas: null,
          entregarEn: null,
          ordenCompra: null,  // Obligatorio
          errorOrdenCompra: null,
          trabajoObservaciones: null,
          precio: null,  // Obligatorio
          errorPrecio: null,
          anticipo: null,  // Obligatorio
          errorAnticipo: null,
          desglosado: null,
          formaPago: null,  // Obligatorio
          errorFormaPago: null,
          referenciaPago: null,
          economicoObservaciones: null,  // Obligatorio
          errorEconomicoObservaciones: null,
          fechaPromesa: null,
          tipoTablaSuaje: null,  // Obligatorio
          errorTipoTablaSuaje: null,
        },

        unidad: null, //Pa probar agregar artículo
    },
    defaultDataPreOrden: {
      idPreOrden: null,
      idCotizacion: null,
      idCliente: null,
      fechaCreacion: new Date(), 
      estatus: null,
      orden: {
        folioCotizacion: null,
        fechaCotizacion: null,  // Obligatorio
        errorFechaCotizacion: null,
        telefono: null,
        nombreTrabajo: null,
        cantidadPza: null,
        piezasExtra: null,
        tintasFrente: null,  // Obligatorio
        errorTintasFrente: null,
        tintasVuelta: null,  // Obligatorio
        errorTintasVuelta: null,
        largo: null,  // Obligatorio
        errorLargo: null,
        ancho: null,  // Obligatorio
        errorAncho: null,
        idArticuloPapel: null,  // Obligatorio
        errorIdArticuloPapel: null,
        barnizFrente: null,  // Obligatorio
        errorBarnizFrente: null,
        suajado: null,  // Obligatorio
        errorSuajado: null,
        tablaSuaje: null,
        tablaSuajeBase: null,
        tablaSuajeAltura: null,
        suajeCms: null,
        tablaDesbarbe: null,
        tablaDesbarbeBase: null,
        tablaDesbarbeAltura: null,
        matrixCms: null,
        empalme: null,
        empalmeMts: null,
        pegadoLineal: null,
        pegadoCms: null,
        pegadoGolpe: null,
        pegadoEsquinas: null,
        entregarEn: null,
        ordenCompra: null,  // Obligatorio
        errorOrdenCompra: null,
        trabajoObservaciones: null,
        precio: null,  // Obligatorio
        errorPrecio: null,
        anticipo: null,  // Obligatorio
        errorAnticipo: null,
        desglosado: null,
        formaPago: null,  // Obligatorio
        errorFormaPago: null,
        referenciaPago: null,
        economicoObservaciones: null,  // Obligatorio
        errorEconomicoObservaciones: null,
        fechaPromesa: null,
        tipoTablaSuaje: null,  // Obligatorio
        errorTipoTablaSuaje: null,
      }
    },
    articulos: [],
    dataArticulos: [],
    dataArtiExtra: {  //Datos usados al momento de agregar artículos (tabla)
      articulos: [],
      cantidad: null,
      fechaCompromiso: null,
      fechaInicio: null,
      observaciones: null,
      precio: null,
      iniciarAuto: false,
    },
    dataArtiExtraDefault: {  //Lo mismo de arriba, pero para reiniciar tabla 
      articulos: [],
      cantidad: null,
      fechaCompromiso: null,
      fechaInicio: null,
      observaciones: null,
      precio: null,
      iniciarAuto: false,
    },
    lineasComerciales: [],
    lineaComercial: null,
    dataClientes: [],
    selectCliente: null,
    almacenes: [],
    almacen: null,
    vendedores: [],
    vendedor: null,
    piezasTotal: 0,
    precioTotal: null,
    costoTotal: 0.0,
    cantidadRestante: 0,
    precioDivision: 0,
    precioUnitario: 0,
    pos: 0,
  }
},
computed: {
  articulos(){
      return this.articles
    },
},
methods: {
    initialize() {
    this.getPreOrdenes();
    //this.getDataOrden();
    },

    getPreOrdenes() {  //Obtenemos preordenes y sus datos pa mostrarlos en la tabla
    this.loadingTable = true;
    axios
        .get("/PreOrden/Listar")
        .then((response) => {
            this.dataPreOrdenes = response.data.map((ordenes) => ({
                ...ordenes,

                idCotizacion: ordenes.idCotizacion,
                folioCotizacion: ordenes.orden.folioCotizacion,
                fechaPromesa: ordenes.orden.fechaPromesa,
                nombreTrabajo: ordenes.orden.nombreTrabajo,
                cantidadPza: ordenes.orden.cantidadPza,
                precio: ordenes.orden.precio,
                anticipo: ordenes.orden.anticipo,
                referenciaPago: ordenes.orden.referenciaPago,
            }));
            //console.log(todas: ", this.dataPreOrdenes");
        })
        .catch((error) => {
            this.loadingTable = false;    
            console.log(error.response.status);
        });
    },

    // getDataOrden(id) { 
    // this.loadingPreOrden = true;
    // //this.dialog = true;
    // axios
    //     .get("/PreOrden/Listar/")
    //     .then((response) => {
    //         this.dataOrdenes = response.data;
    //         const ordenEncontrada = this.dataOrdenes.find(orden => orden.idCotizacion === id);

    //         if (ordenEncontrada) {
    //             this.dataOrden = ordenEncontrada.orden;
                
    //             this.dataOrdenn.ancho = this.dataOrden.ancho;
    //             this.dataOrdenn.anticipo = this.dataOrden.anticipo;
    //             this.dataOrdenn.barnizFrente = this.dataOrden.barnizFrente;
    //             this.dataOrdenn.cantidadPza = this.dataOrden.cantidadPza;
    //             this.dataOrdenn.desglosado = this.dataOrden.desglosado;
    //             this.dataOrdenn.economicoObservaciones = this.dataOrden.economicoObservaciones;
    //             this.dataOrdenn.empalme = this.dataOrden.empalme;
    //             this.dataOrdenn.empalmeMts = this.dataOrden.empalmeMts;
    //             this.dataOrdenn.entregarEn = this.dataOrden.entregarEn;
    //             this.dataOrdenn.fechaCotizacion = this.dataOrden.fechaCotizacion;
    //             this.dataOrdenn.fechaPromesa = this.dataOrden.fechaPromesa;
    //             this.dataOrdenn.folioCotizacion = this.dataOrden.folioCotizacion;
    //             this.dataOrdenn.formaPago = this.dataOrden.formaPago;
    //             this.dataOrdenn.idArticuloPapel = this.dataOrden.idArticuloPapel;
    //             this.dataOrdenn.largo = this.dataOrden.largo;
    //             this.dataOrdenn.matrixCms = this.dataOrden.matrixCms;
    //             this.dataOrdenn.nombreTrabajo = this.dataOrden.nombreTrabajo;
    //             this.dataOrdenn.ordenCompra = this.dataOrden.ordenCompra;
    //             this.dataOrdenn.pegadoCms = this.dataOrden.pegadoCms;
    //             this.dataOrdenn.pegadoEsquinas = this.dataOrden.pegadoEsquinas;
    //             this.dataOrdenn.pegadoGolpe = this.dataOrden.pegadoGolpe;
    //             this.dataOrdenn.pegadoLineal = this.dataOrden.pegadoLineal;
    //             this.dataOrdenn.piezasExtra = this.dataOrden.piezasExtra;
    //             this.dataOrdenn.precio = this.dataOrden.precio;
    //             this.dataOrdenn.referenciaPago = this.dataOrden.referenciaPago;
    //             this.dataOrdenn.suajado = this.dataOrden.suajado;
    //             this.dataOrdenn.suajeCms = this.dataOrden.suajeCms;
    //             this.dataOrdenn.tablaDesbarbe = this.dataOrden.tablaDesbarbe;
    //             this.dataOrdenn.tablaDesbarbeAltura = this.dataOrden.tablaDesbarbeAltura;
    //             this.dataOrdenn.tablaDesbarbeBase = this.dataOrden.tablaDesbarbeBase;
    //             this.dataOrdenn.tablaSuaje = this.dataOrden.tablaSuaje;
    //             this.dataOrdenn.tablaSuajeAltura = this.dataOrden.tablaSuajeAltura;
    //             this.dataOrdenn.tablaSuajeBase = this.dataOrden.tablaSuajeBase;
    //             this.dataOrdenn.telefono = this.dataOrden.telefono;
    //             this.dataOrdenn.tintasFrente = this.dataOrden.tintasFrente;
    //             this.dataOrdenn.tintasVuelta = this.dataOrden.tintasVuelta;
    //             this.dataOrdenn.tipoTablaSuaje = this.dataOrden.tipoTablaSuaje;
    //             this.dataOrdenn.trabajoObservaciones = this.dataOrden.trabajoObservaciones;
    //         } else {
    //             console.error("Orden no encontrada con id:", id);
    //         }

    //         this.loadingPreOrden = false;
    //         console.log("ordenes:", this.dataOrden);
    //     })
    //     .catch((error) => { 
    //         console.log(error);
    //         this.loadingPreOrden = false; 
    //     });
    // },

    showDelete(item) {  //abrir diálogo para eliminar pre-orden
      this.idPreOrden = item.idOrdenA;
      this.dialogDelete = true;
      //console.log(this.idPreOrden);
    },
    showAutorice(item) {  //abrir diálogo para autorizar pre-orden
      this.idPreOrden = item.idOrdenA;
      this.dialogAutorizar = true;
    },

    getDataOrden(id) {  //Obtención de pre-orden individual a editar.
    this.loadingPreOrden = true;
    return new Promise((resolve, reject) => {
        axios
            .get("/PreOrden/Listar")
            .then((response) => {
                this.dataOrdenes = response.data;
                const ordenEncontrada = this.dataOrdenes.find(orden => orden.idOrdenCotizacion === id);

                if (ordenEncontrada) {
                    this.dataOrden = ordenEncontrada.orden;
                    this.estatus = ordenEncontrada.estatus;
                    this.dataOrdenn = { ...this.dataOrden };
                    this.precioDivision = this.dataOrdenn.precio.toFixed(2);
                    resolve(); 
                } else {
                    console.error("Orden no encontrada con id:", id);
                    reject("Orden no encontrada");
                }

                this.loadingPreOrden = false;
                // console.log("general: ", this.dataOrdenes);
                // console.log("ordenesss:", this.dataOrdenn);
                // console.log("el estatus:", this.estatus);
            })
            .catch((error) => {
                console.log(error);
                this.loadingPreOrden = false;
                reject(error);
            });
    });
    },

    showEdit(idOrdenCotizacion) {  //Función usada en el ícono del lápiz
    this.idPreOrden = idOrdenCotizacion;
    //console.log(this.idPreOrden);
    this.getLineasComercial();
    this.getClientes();
    this.getAlmacenes();
    this.getVendedores();
    this.getOtrosArticulos();
    this.getDataOrden(this.idPreOrden)
        .then(() => {
            this.dialog = true; 
            this.getMostrarCalculosPaPre();
            //this.dialog = true;
            this.getMostrarCotizacionPre();
        })
        .catch((error) => {
            console.error(error);
        });
    },
    
    validatePreOrden() {  //Validaciones pa poder editar
        //let error = false;

        if (!this.dataOrdenn.ordenCompra && this.dataOrdenn.ordenCompra !== 0) {
            this.dataOrdenn.errorOrdenCompra = "Requerido";
            this.dataOrdenn.errorOrdenCompraEstatus = true;
        } else if (isNaN(parseInt(this.dataOrdenn.ordenCompra))) {
            this.dataOrdenn.errorOrdenCompra = "Debe ser un número entero";
            this.dataOrdenn.errorOrdenCompraEstatus = true;

        } else if (parseInt(this.dataOrdenn.ordenCompra) <= 0) {
            this.dataOrdenn.errorOrdenCompra = "Debe ser mayor a 0";
            this.dataOrdenn.errorOrdenCompraEstatus = true;

        } else {
            this.dataOrdenn.errorOrdenCompra = null;
            this.dataOrdenn.errorOrdenCompraEstatus = null;
        }

        if (!this.dataOrdenn.precio && this.dataOrdenn.precio !== 0) {
            this.dataOrdenn.errorPrecio = "Requerido";
            this.dataOrdenn.errorPrecioEstatus = true;

        } else if (isNaN(parseFloat(this.dataOrdenn.precio))) {
            this.dataOrdenn.errorPrecio = "Debe ser un número decimal";
            this.dataOrdenn.errorPrecioEstatus = true;

        } else if (parseFloat(this.dataOrdenn.precio) <= 0) {
            this.dataOrdenn.errorPrecio = "Debe ser mayor a 0";
            this.dataOrdenn.errorPrecioEstatus = true;

        } else {
            this.dataOrdenn.errorPrecio = null;
            this.dataOrdenn.errorPrecioEstatus = null;
        }

        if (!this.dataOrdenn.anticipo && this.dataOrdenn.anticipo !== 0) {
            this.dataOrdenn.errorAnticipo = "Requerido";
            this.dataOrdenn.errorAnticipoEstatus = true;

        } else if (isNaN(parseFloat(this.dataOrdenn.anticipo))) {
            this.dataOrdenn.errorAnticipo = "Debe ser un número decimal";
            this.dataOrdenn.errorAnticipoEstatus = true;

        } else if (parseFloat(this.dataOrdenn.anticipo) < 0) {
            this.dataOrdenn.errorAnticipo = "Debe ser mayor o igual a 0";
            this.dataOrdenn.errorAnticipoEstatus = true;

        } else if (parseFloat(this.dataOrdenn.anticipo) > parseFloat(this.dataOrdenn.precio)) {
            this.dataOrdenn.errorAnticipo = "El anticipo no puede ser mayor al precio";
            this.dataOrdenn.errorAnticipoEstatus = true;

        } else {
            this.dataOrdenn.errorAnticipo = null;
            this.dataOrdenn.errorAnticipoEstatus = null;
        }

        if (!this.dataOrdenn.formaPago && this.dataOrdenn.formaPago !== 0) {
            this.dataOrdenn.errorFormaPago = "Requerido";
            this.dataOrdenn.errorFormaPagoEstatus = true;

        } else if (isNaN(parseInt(this.dataOrdenn.formaPago))) {
            this.dataOrdenn.errorFormaPago = "Debe ser un número entero";
            this.dataOrdenn.errorFormaPagoEstatus = true;

        } else {
            this.dataOrdenn.errorFormaPago = null;
            this.dataOrdenn.errorFormaPagoEstatus = null;
        }

        if (!this.dataOrdenn.economicoObservaciones) {
            this.dataOrdenn.errorEconomicoObservaciones = "Requerido";
            this.dataOrdenn.errorEconomicoObservacionesEstatus = true;

        } else {
            this.dataOrdenn.errorEconomicoObservaciones = null;
            this.dataOrdenn.errorEconomicoObservacionesEstatus = null;
        }

        this.up++;
        if (
            this.dataOrdenn.errorAnticipoEstatus == null &&
            this.dataOrdenn.errorEconomicoObservacionesEstatus == null &&
            this.dataOrdenn.errorOrdenCompraEstatus == null &&
            this.dataOrdenn.errorFormaPagoEstatus == null &&
            this.dataOrdenn.errorPrecioEstatus == null
        )
          return false;
        else return true;  
    },
    autorizarPreorden(){
      if (this.idPreOrden !== null) {
        //console.log(this.idPreOrden);
        this.autorizing = true;
        axios
        .put(`/PreOrden/ActualizarEstatus?IdPreorden=${this.idPreOrden}&EstatusActual=0&Estado=1`)
        .then(() => {
            this.autorizing = false;
            this.dialogAutorizar = false;
            this.getPreOrdenes(); 
        })
        .catch((error) => {
            this.autorizing = false;
            this.dialogAutorizar = false;
            console.log(error);
        });
      }
    },

    
    editPreorden() {
      if (this.validatePreOrden()) return ;

      this.saving = true;
      const data = {
        idCotizacion : this.dataOrden.folioCotizacion,
        idCliente: 4,//this.dataOrden.idCliente, 
        orden: {
          ancho: this.dataOrden.ancho,
            anticipo: parseFloat(this.dataOrdenn.anticipo),
            barnizFrente: this.dataOrden.barnizFrente,
            cantidadPza: this.dataOrden.cantidadPza,
            desglosado: this.dataOrdenn.desglosado,
            economicoObservaciones: this.dataOrdenn.economicoObservaciones,
            empalme: this.dataOrden.empalme,
            empalmeMts: parseFloat(this.dataOrden.empalmeMts),
            entregarEn: this.dataOrdenn.entregarEn,
            fechaCotizacion: this.dataOrden.fechaCotizacion,
            fechaPromesa: this.dataOrdenn.fechaPromesa,
            formaPago: this.dataOrdenn.formaPago,
            folioCotizacion: this.dataOrden.folioCotizacion,
            idArticuloPapel: this.dataCostos.idPapel,//this.dataOrden.idArticuloPapel,
            largo: this.dataOrden.largo,
            matrixCms: parseFloat(this.dataOrden.matrixCms),
            nombreTrabajo: this.dataOrden.nombreTrabajo,
            ordenCompra: parseInt(this.dataOrdenn.ordenCompra),
            piezasExtra: parseInt(this.dataOrdenn.piezasExtra),
            pegadoCms: this.dataOrden.pegadoCms,
            pegadoEsquinas: this.dataOrden.pegadoEsquinas,
            pegadoGolpe: this.dataOrden.pegadoGolpe,
            pegadoLineal: this.dataOrden.pegadoLineal,
            precio: parseFloat(this.dataOrdenn.precio),
            referenciaPago: this.dataOrdenn.referenciaPago,
            suajado: this.dataOrden.suajado,
            tablaDesbarbe: this.dataOrden.tablaDesbarbe,
            tablaDesbarbeAltura: parseFloat(this.dataOrden.tablaDesbarbeAltura),
            tablaDesbarbeBase: parseFloat(this.dataOrden.tablaDesbarbeBase),
            tablaSuaje: this.dataOrden.tablaSuaje,
            tablaSuajeAltura: parseFloat(this.dataOrden.tablaSuajeAltura),
            tablaSuajeBase: parseFloat(this.dataOrden.tablaSuajeBase),
            telefono: this.dataOrden.telefono,
            tintasFrente: this.dataOrden.tintasFrente,
            tintasVuelta: this.dataOrden.tintasVuelta,
            tipoTablaSuaje: this.dataOrden.tipoTablaSuaje,
            trabajoObservaciones: this.dataOrdenn.trabajoObservaciones,
            
        },
        fechaCreacion: this.dataOrden.fechaCotizacion,
        estatus: this.estatus,//this.dataOrden.estatus,
      };
      axios
        .put(`/PreOrden/Actualizar/${this.idPreOrden}`, data)
        .then(() => {
          //this.dataEdit = response.data;
          //console.log("Datos actualizados:");
          this.saving = false;
          this.dialog = false; 
          this.getPreOrdenes();
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    getTransporte() {
      axios
        .get("/TransportePreguntas/Listar")
        .then((response) => {
          this.dataTransporte = response.data
        })
        .catch((error) => { console.log(error); });
    },

    getMostrarCotizacionPre() {  //También pa mostrar datos al momento de editar
      axios
        .get(`/PreOrden/Mostrar/`,{params:{IdOrden: this.idPreOrden}})
        
        .then((response) => {
          if (response.status == 204) {
            this.$emit("error_mostrarCotizacion", 204);
          }
          else {
            this.dataCotizacion = response.data.Datos;
            this.dataCostos = response.data.Calculos;
            this.getTransporte();
            //this.dialog = true;
            //console.log("nuevainfo", this.dataCotizacion);
          }
        })
        .catch((error) => {
          this.$emit("error_mostrarCotizacion", error);
          this.dialog = false;
        })
    },
    
    deletePreOrden() {  //Función que nos permite eliminar una preorden.
    if (this.idPreOrden !== null) {
        //console.log(this.idPreOrden);
        this.deleting = true;
        axios
        .put(`/PreOrden/Desactivar/${this.idPreOrden}`)
        .then(() => {
            this.deleting = false;
            this.dialogDelete = false;
            this.getPreOrdenes(); 
        })
        .catch((error) => {
            this.deleting = false;
            this.dialogDelete = false;
            console.log(error);
        });
    }
    },

    closeDialog() {
      this.dialogDelete = false;
      this.idPreOrden = null;
    },
    closeAutDialog() {
      this.dialogAutorizar = false;
      this.idPreOrden = null;
    },
    hide_dialog() {
      this.dialog = false;
      this.idPreOrden = null;
      this.dataOrdenn = [];
      this.dataArtiExtra.articulos = [];
      this.costoTotal = 0;
     
    },
    obtenerPrecioUnitario() {  //Se hace este cálculo porque todas las pzas van a valer lo mismo (su costo) sin importar el artículo
      this.precioUnitario = this.precioDivision / this.dataOrdenn.cantidadPza;
      //console.log("Precio desde operación: ", this.precioDivision);
      //console.log(this.precioUnitario);
    },
    actualizarCostoTotal() { //Control de pzas y costo total al añadir artículos en preorden para mandar a orden.
      let costo = 0.0;
      let piezas = 0;
      let resto = 0;

      this.dataArtiExtra.articulos.forEach((element) => {

        let cantidad = parseInt(element.cantidad);
        if(isNaN(cantidad)){
            cantidad = 0;
            element.cantidad = 0;
        }
        //console.log("cantidad por fila: ", cantidad);
        if (element.cantidad > this.dataOrdenn.cantidadPza) {
          element.cantidad = 0;
          //console.log("superé el max de pzas", element.cantidad);
        }
        costo += parseFloat(cantidad * this.precioUnitario);
        piezas += parseInt(cantidad);
        resto = Math.max(0, parseInt(this.dataOrdenn.cantidadPza - piezas));
        if(isNaN(resto)){
          element.cantidad = 0;
        }
        //console.log("cuento las pzas: ", piezas);
        //console.log("resto original: ", resto);
        if(cantidad > resto && piezas > this.dataOrdenn.cantidadPza ) {
          piezas -= cantidad;
         //console.log("soy las piezas ajustadas: ", piezas)
          resto = parseInt(this.dataOrdenn.cantidadPza - piezas);
          //console.log("soy el resto modificado", resto);
          element.cantidad = resto;
        }
      });

      this.piezasTotal = piezas;
      this.costoTotal = costo;
      //this.cantidadRestante = resto;
      //console.log("es o no cero: ", this.cantidadRestante);
    },
    validarCantidad(item) { //Controlar que las piezas por artículo no superen el máximo
      // let totalCantidad = parseInt(this.piezasTotal);
      // // console.log("Otro contéo: ", totalCantidad);
      // // // Calculamos la cantidad máxima restante
      // let maxPiezas = this.dataCotizacion.cantidadPza;
      // let cantidadRestante = maxPiezas - totalCantidad;
       //let resto = 0
      // console.log("resto de piezas disponibles", cantidadRestante);
    
      if (this.piezasTotal > this.dataOrdenn.cantidadPza) {
        item.cantidad = this.cantidadRestante;
        //("cantidad ajustada en validar", item.cantidad);
      }
      this.actualizarCostoTotal();
    },
    agregarArticulo() {  //Pa agregar múltiples artículos en la tabla de la sección "Pedido"
      this.obtenerPrecioUnitario();
      var detalle = Object.assign({}, this.dataArtiExtraDefault);
      this.pos += 1;
      detalle.id = this.pos;
      this.dataArtiExtra.articulos.push(detalle);
    },
    deleteItem(item) {  //Elimina filas en la tabla de la sección "Pedido" y recalcula cantidad pzas y costo
      let deleteIndex = this.dataArtiExtra.articulos.indexOf(item);
      if (deleteIndex !== -1) {
        const cantidadEliminada = this.dataArtiExtra.articulos[deleteIndex].cantidad;
        this.dataArtiExtra.articulos.splice(deleteIndex, 1);
        //this.actualizarCostoTotal();

        this.piezasTotal -= parseInt(cantidadEliminada);
        this.costoTotal -= (cantidadEliminada * this.precioUnitario);
        this.actualizarCostoTotal();
      }
    },
    getLineasComercial() {
      axios
        .get("/ComercialLineas")
        .then(response => {
          this.lineasComerciales = response.data;
        })
        .catch(error => {
          console.log(error)
        })
    },
    getClientes() {
    axios
        .get("/Clientes")
        .then(response => { this.dataClientes = response.data 
        //console.log("Soy de Geometrika");
        })
        .catch(error => { console.log(error.response) })
    },
    getAlmacenes() {
      axios
        .get("/Almacenes")
        .then(response => {
          this.almacenes = response.data;
        })
        .catch(error => {
          console.log(error)
        })
    },
    getVendedores() {
      axios
        .get("/vendedores")
        .then((response) => {
          this.vendedores = response.data
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOtrosArticulos() {  //Obtención de artículos para seleccionar en la tabla de "Pedidos"
      axios
        .get("/Articulos/OTS")
        .then(response => {
          this.dataArticulos = response.data;
          //console.log("Holi :D");
        })
        .catch(error => {
          console.log(error);
        })
    },
    // getMostrarCalculosPaPre() { //Se obtienen datos para mostrar en el formualario principal
    //   axios
    //     .get(`/Orden/MostrarCalculos/${this.idOrden}`)
    //     .then((response) => {
    //       if (response.status == 204 || !response.data) {
    //         this.$emit("error_mostrarCalculos", 204);
    //       }
    //       else {
    //         this.dataCostos = response.data;
    //         this.dataPreOrden.orden.precio = this.dataCostos.precioVenta.total.toFixed(2); //Dato colocado en el campo "precio"
    //         this.precioDivision = this.dataPreOrden.orden.precio; //Se manda para cálculo de costo por pieza individual.
    //         //this.dialog = true;
    //         //console.log("Info", this.dataCostos);
    //       }
    //     })
    //     .catch((error) => {
    //       this.$emit("error_mostrarCalculos", error.response.status);
    //       //this.dialog = false;
    //     })
    // },
},
mounted() {
    this.initialize();
}
}
</script>